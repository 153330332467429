import { Type } from '@angular/core';
import { MediumTeaser } from '@mbcs/mbcs-lib';
import { ProductTeaserCarouselComponent } from '@shared/components/campaign/product-teaser-carousel/product-teaser-carousel.component';
import { ProductTeaserMediumCampaign } from '@shared/components/campaign/product-teaser-medium/product-teaser-medium-campaign.component';
import { StageSeparatorComponent } from '@shared/components/campaign/stage-separator/stage-separator.component';
import { StageComponent } from '@shared/components/campaign/stage/stage.component';
import { Stage } from '@shared/components/campaign/stage/stage.model';
import { TeaserItem } from '@shared/components/campaign/teaser-item/teaser-item.model';
import { TeaserLargeComponent } from '@shared/components/campaign/teaser-large/teaser-large.component';
import { TeaserLarge } from '@shared/components/campaign/teaser-large/teaser-large.model';
import { TeaserMediumComponent } from '@shared/components/campaign/teaser-medium/teaser-medium.component';
import { TextTeaserComponent } from '@shared/components/campaign/text-teaser/text-teaser.component';

export class CampaignBaseComponent {
	constructor(public campaignComponent: Type<componentTypes>, public campaignComponentData: dataModels) {
	}
}

type dataModels =
	Stage
	| TeaserLarge
	| TeaserItem
	| MediumTeaser;

export type componentTypes =
	StageComponent
	| TextTeaserComponent
	| TeaserLargeComponent
	| ProductTeaserMediumCampaign
	| StageSeparatorComponent
	| ProductTeaserCarouselComponent
	| TeaserMediumComponent;
