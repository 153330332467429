// ng
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
// own - modules
import { SharedModule } from '@shared/shared.module';
// own - components
import { ProductDetailsPageComponent } from './product-details-page.component';
import { CompleteWheelsInfoComponent } from './complete-wheels-info/complete-wheels-info.component';
// own - services
import { ProductDetailsService } from './product-details.service';

@NgModule({
    imports: [CommonModule, SharedModule, RouterModule],
    exports: [],
    declarations: [ProductDetailsPageComponent, CompleteWheelsInfoComponent],
    providers: [ProductDetailsService],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ProductDetailsModule {}
