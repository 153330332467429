<div class="error-message_wrapper"
     [ngClass]="{'is-light': lightBackground}">
    <span [zkTranslate]="translationKey"></span>
    <a [routerLink]="errorMessageLink"
       queryParamsHandling="preserve"
       class="error-message_link"
       [ngClass]="{'is-light': lightBackground}"
       *ngIf="showLink">
        <mba-icon icon="arrow-thin-right"
                  [iconClass]="!lightBackground ? 'icon_text icon_white' : 'icon_text icon_red'">
        </mba-icon>
        <span [zkTranslate]="errorMessageLinkText"></span>
    </a>
</div>
