// ng
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
// own
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

// *********************************************************************

/**
 * Disable Angular's development mode, which turns off assertions and other checks within the framework.
 *
 * One important assertion this disables verifies that a change detection pass
 * does not result in additional changes to any bindings (also known as unidirectional data flow).
 */
if (environment.production) {
    enableProdMode();
}

document.addEventListener('DOMContentLoaded', domContentLoaded);
// *********************************************************************

function domContentLoaded() {
    platformBrowserDynamic()
        .bootstrapModule(AppModule)
        .catch((err) => console.log(err));

    document.removeEventListener('DOMContentLoaded', domContentLoaded);

    if (window && window.navigator.userAgent.indexOf('Prerender') == -1 && !environment.testEnv) {
        // console.log('domContentLoaded user agent: ', window.navigator.userAgent);
        initUsercentricsScript();
    }

    if (!environment.enableTracking) {
        activateTracking(false);
    }
}

/**
 * Enables or disables gtag.js from sending data to Google Analytics.
 */
function activateTracking(activate: boolean) {
    // stage related border
    if (!environment.enableTracking) {
        activate = false;
        console.log('Google Analytics is forbidden on this stage. Tracking is disabled.');
    }

    window['ga-disable-G-JSFVBVBS6X'] = !activate;
}

/**
 * Set up the usercentrics consent layer
 */
function initUsercentricsScript() {
    // get market and language from URL
    const currentMarket: string = window.location.pathname.split('/')[1];
    const currentLang: string = currentMarket.substr(0,2);

    // console.log('environment.base:%s | currentMarket:%s | currentLang:%s', environment.base, currentMarket, currentLang);

    const head: HTMLHeadElement = document.querySelector('head');
    const s: HTMLScriptElement = document.createElement('script');
    s.id = 'usercentrics-cmp';
    // don´t use 'loader.js' script ! Does not work in FF - see ZKDEV-1495
    s.src = 'https://app.usercentrics.eu/browser-ui/latest/bundle.js';

    // settings id is market dependent, initial language depends on URL (language switch option can be defined in usercentrics admin interface)
    s.setAttribute('data-settings-id', getUsercentricsSettingsIdByMarket(currentMarket));
    s.setAttribute('data-language', currentLang);
    s.defer = true;

    // wait till script is initialized
    window.addEventListener('UC_UI_INITIALIZED', function() {
        listenToUsercentricEvents();
    });

    // add script
    head.appendChild(s);
}

/**
 * listen for user consent decision (info: 'cmp_event' is the name of the window event defined in the usercentrics admin interface)
 */
function listenToUsercentricEvents(): void {
    window.addEventListener('cmp_event', function (e: any) {
        if (e.detail && e.detail.event == 'consent_status') {
            // implicit: Consents that are not set purposely by the user, eg by Usercentrics on the first-time page visit with the configured default status.
            // explicit: Consents that are set purposely by the user, eg a click on a button.
            if (e.detail.type === 'explicit') {
                console.log('Usercentrics: Consents explicit set by user');
                // check for consent status of service "Google Analytics"
                if (e.detail['Google Analytics'] === true && e.detail['Google Tag Manager'] === true) {
                    console.log('Usercentrics: Google Analytics has consent. Activate tracking.');
                    activateTracking(true);
                }
                else if (e.detail['Google Analytics'] === false || e.detail['Google Tag Manager'] === false) {
                    console.log('Usercentrics: Google Analytics has no consent. Deactivate tracking.');
                    activateTracking(false);
                }
            }
        }
    });
}

/**
 * Returns the usercentric setting id for the active market
 * @param market
 */
function getUsercentricsSettingsIdByMarket(market: string) : string {
    // note: needs to be enhanced if new markets get released in ZK+
    // and new market consent config is set up properly in usercentrics admin interface with an according new settings ID too!
    switch (market) {
        case 'en-MB' : return '98we1AHV36';
        case 'de-DE' : return 'h9KTWzjk7';
        case 'es-AR' : return 'ow4CJloo6';
        case 'de-AT' : return 'WKK5hi5Ag';
        case 'en-AU' : return 'TyZVnGYLu';
        case 'fr-BE' : return 'NFkM6c3eY';
        case 'en-CA' : return 'WuKUbh0i9';
        case 'it-CH' : return '1deskQbnD';
        case 'zh-CN' : return 'HDx9Q71_f';
        case 'en-CZ' : return '_rSaI6B6S';
        case 'en-DK' : return '6tycS8Vms';
        case 'es-ES' : return 'b1WXBR5Nu';
        case 'en-GB' : return 'FrnpBbtAy';
        case 'en-GR' : return 'WfqLYcKrX';
        case 'en-HK' : return 'T0luQtuqL';
        case 'en-IN' : return 'X6WnzawpL';
        case 'it-IT' : return '5dw7bq2bS';
        case 'fr-LU' : return 'aWg8ekDmX';
        case 'es-MX' : return '_P3lVxcZ5';
        case 'en-MY' : return 'yWgh75zpo';
        case 'nl-NL' : return 'wPeh_pMZ1';
        case 'en-NO' : return 'KSferW390';
        case 'en-NZ' : return '9qQJblvGo';
        case 'en-PL' : return 'zv3aoSfdF';
        case 'en-RO' : return 'ZmdM-ZO4n';
        case 'ru-RU' : return 'pZZKTo30G';
        case 'sv-SE' : return 'Yx6F5hwY0';
        case 'en-SG' : return 'xddXhJC1s';
        case 'en-SK' : return '9Omz3Ap_d';
        case 'en-TR' : return 'M-OfZAoR7';
        case 'en-US' : return '4cRVabV6N';
        case 'en-ZA' : return 'hbD9-eXle';
        // en-MB market settings id as fallback for not yet defined markets in usercentrics admin interface
        default: return '98we1AHV36';
    }
}
