<!-- Navigation only for main group wheels or amg-wheels -->
<div *ngIf="isGroupTypeWheels">
    <wb-tabs [selected]="subGroupName">
        <wb-tab *ngFor="let group of wheelProductGroup.subGroups"
                (click)="routeToSubcategory(group.urlName)"
                [name]="group.name"
                [selected]="subGroupName === group.name">{{group.name}}
        </wb-tab>
    </wb-tabs>
</div>

<zk-error-message class="spacing-s" *ngIf="errorCode" [errorCode]="errorCode"></zk-error-message>

<!-- Complete or Alloy Wheels subcategories -->
<div *ngIf="subGroupType === 'complete-wheels' || subGroupType === 'alloy-wheels'; else notWheelSubGroup">
    <zk-complete-wheel *ngIf="subGroupType === 'complete-wheels'"
                       class="spacing-s"
                       [subGroupId]="subGroupId">
    </zk-complete-wheel>

    <zk-wheel-special *ngIf="subGroupType === 'alloy-wheels'" class="spacing-s"></zk-wheel-special>
</div>

<!-- All other subcategories-->
<ng-template #notWheelSubGroup>
    <section class="{{isGroupTypeWheels ? 'spacing-s' : ''}}">
        <wb-heading size="m" class="word-break">{{subGroupName}}</wb-heading>

        <wb-spinner *ngIf="isLoading" class="loading-spinner" theme="light"></wb-spinner>

        <zk-product-teaser-cards class="spacing-s">
            <zk-product-teaser-card *ngFor="let article of articlesOfPage?.articles"
                                    [article]="article"
                                    [isCollection]="false">
            </zk-product-teaser-card>
        </zk-product-teaser-cards>

        <wb-pagination *ngIf="articlesOfPage?.articleInfo?.pages > 1"
                       class="pagination spacing-s"
                       zkPagination
                       compact="{{isMobile}}"
                       pages="{{articlesOfPage.articleInfo.pages}}"
                       [currentPage]="articlesOfPage.articleInfo.page + 1"
                       (currentPageEmitter)="handlePagination($event)">
        </wb-pagination>
    </section>
</ng-template>
