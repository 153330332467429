import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LegalPagesService } from './legal-pages.service';

import { ImprintPageComponent } from './imprint-page/imprint-page.component';
import { PrivacyPageComponent } from './privacy-page/privacy-page.component';
import { CopyrightPageComponent } from './copyright-page/copyright-page.component';
import { ModernSlaveryActPageComponent } from './modernslaveryact-page/modern-slavery-act-page.component';

@NgModule({
    imports: [CommonModule],
    exports: [],
    declarations: [
        ImprintPageComponent,
        PrivacyPageComponent,
        CopyrightPageComponent,
        ModernSlaveryActPageComponent
    ],
    providers: [LegalPagesService]
})
export class LegalPagesModule {}
