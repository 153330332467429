// Ng
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

// Services
import { ProductGroup } from '@mbcs/mbcs-lib';
import { ProductGroupsService } from '@shared/shared-services/product-groups/product-groups.service';
import { SeoService } from '@shared/shared-services/seo/seo.service';

@Component({
    selector: 'zk-collection-product-list-subcategory-page',
    templateUrl: './collection-sub-category-page.component.html',
    styleUrls: ['./collection-sub-category-page.component.scss']
})
export class CollectionSubCategoryPageComponent implements OnInit {
    constructor(private _activatedRoute: ActivatedRoute,
				private _groupsService: ProductGroupsService,
				private _seoService: SeoService) {}

    activeProductSubGroup: ProductGroup;
    private _activeProductGroup: ProductGroup;

    ngOnInit(): void {
        this._activatedRoute.params.subscribe((params) => {
            this._activeProductGroup = this._groupsService.groups.find((x: ProductGroup) => x.urlName === params['category']);
            this.activeProductSubGroup = this._activeProductGroup.subGroups.find((x: ProductGroup) => x.urlName === params['subcategory']);

			this.setSeoInfo();
        });
    }

	private setSeoInfo() {
		const title: string = this._seoService.getTranslation('ACCESSORIES.FLYOUT.TEASER.TITEL');

		this._seoService.updatePageTitle(`${this.activeProductSubGroup.name} | ${this._activeProductGroup.name} | ${title}`);
		this._seoService.updateMetaDescription(this._seoService.getTranslation('LIFESTYLE.SEO.METADESCRIPTION.PRODUCTLISTSUBCATPAGE') + `: ${this.activeProductSubGroup.name} | ${this._activeProductGroup.name}`);
	}
}
