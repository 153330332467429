// ng
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subject, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

// model
import { BodyType, CarClass, CarLine, ModelDesign } from '../../components/car-chooser/models';
import { VehicleType } from '@shared/global-models/vehicle-switch.model';
import { VIN } from '@shared/shared-services/compatibility/vin.model';
import { CarClassRequestInfo } from '@shared/shared-services/selected-car/carClassRequestInfo.model';
import { StorageEnum } from '@shared/shared-services/storage/storage.enum';

// service
import { LocalStorageService } from '@shared/shared-services/storage/local-storage.service';
import { TranslationService } from '@shared/shared-services/translate/translation.service';

@Injectable()
export class SelectedCarService {
    private currentCarClasses: CarClass[] = null;
    private currentVehicleType: VehicleType = null;
    // carClass, bodyType, CarLine, ModelDesign (amg only) references
    private currentCarClass: CarClass = null;
    private currentBodyType: BodyType = null;
    private currentCarLine: CarLine = null;
    private previousCarClass: CarClass = null;
    private previousBodyType: BodyType = null;
    private previousCarLine: CarLine = null;
    private carClassRequestInfo: CarClassRequestInfo = null;

    private currentCarClassSubscriber = new Subject<CarClass>();
    private currentBodyTypeSubscriber = new Subject<BodyType>();
    private currentCarLineSubscriber = new Subject<CarLine>();
    private setByVinSubscriber = new Subject<boolean>();
    private selectingCarByIdFailedSubscriber = new Subject<boolean>();
    private vehicleTypeChangedSubscriber = new Subject<VehicleType>();

    // constants
    readonly vehicleTypeCar: VehicleType = {
        name: 'Car',
        vehicleTypeId: 'car',
        urlPart: '/accessories/passengercars',
        isActive: true
    };
    readonly vehicleTypeTransporter: VehicleType = {
        name: 'Van',
        vehicleTypeId: 'transporter',
        urlPart: '/accessories/vans',
        isActive: true
    };
    readonly vehicleTypeAmg: VehicleType = {
        name: 'AMG',
        vehicleTypeId: 'amg',
        urlPart: '/accessories/amg',
        isActive: true
    };

    constructor(
        private httpClient: HttpClient,
        private _translationService: TranslationService,
        private _localStorageService: LocalStorageService
    ) {}

    /**
     * Sets the initial vehicle type based on URL that is needed for other API calls
     * @param url
     */
    setInitialVehicleType(url: string) {
        if (url.indexOf('/accessories/vans') >= 0) {
            this.vehicleType = this.vehicleTypeTransporter;
        } else if (url.indexOf('/accessories/amg') >= 0) {
            this.vehicleType = this.vehicleTypeAmg;
        } else if (url.indexOf('/accessories/passengercars') >= 0) {
            this.vehicleType = this.vehicleTypeCar;
        }
    }

    /**
     * Get the current vehicle type (based on mode).
     */
    get vehicleType(): VehicleType {
        return this.currentVehicleType;
    }

    /**
     * Get the last saved vehicle type from local storage.
     * Useful when switching between ACC and COLL modes.
     */
    get lastVehicleType(): VehicleType {
        return this._localStorageService.getItem(StorageEnum.VEHICLE_TYPE);
    }

    /**
     * Sets the current vehicle type and saves it to the local storage if first time or vehicle type has changed.
     * Informs to reload all product groups for the new vehicle type.
     */
    set vehicleType(newVehicleType: VehicleType) {
        if (!this.currentVehicleType || this.currentVehicleType.vehicleTypeId !== newVehicleType.vehicleTypeId) {
            this._localStorageService.setItem(StorageEnum.VEHICLE_TYPE, newVehicleType);

            this.currentVehicleType = newVehicleType;
            // inform services - a new vehicle type needs new groups - so that app components update correctly
            this.vehicleTypeChangedSubscriber.next(newVehicleType);
        }
    }

    /**
     * Subscribe to get informed when vehicle type (assortment) changed.
     */
    get vehicleTypeChangedInfo() {
        return this.vehicleTypeChangedSubscriber.asObservable();
    }

    /**
     * Subscribe to get informed if selecting car by car line id failed.
     * Useful for rerouting to 404 page if URL contains a deep link which is not valid.
     */
    get selectCarByIdFailed() {
        return this.selectingCarByIdFailedSubscriber.asObservable();
    }

    /**
     * Set a new car class
     */
    set carClass(newCarClass: CarClass) {
        this.previousCarClass = newCarClass ? this.currentCarClass : null; // keep old values for comparing
        this.currentCarClass = newCarClass;
        if (this.currentCarClass) {
            this._localStorageService.setItem(StorageEnum.CAR_CLASS, newCarClass);
        }
        // inform
        this.currentCarClassSubscriber.next(newCarClass);
    }

    /**
     * Get the current car class
     */
    get carClass(): CarClass {
        return this._localStorageService.getItem(StorageEnum.CAR_CLASS);
    }

    /**
     * Notification for car class changes
     */
    get carClassSubscriber() {
        return this.currentCarClassSubscriber.asObservable();
    }

    /**
     * Set a new body type
     */
    set bodyType(newBodyType: BodyType) {
        this.previousBodyType = newBodyType ? this.currentBodyType : null; // keep old values for comparing
        this.currentBodyType = newBodyType;
        if (this.currentBodyType) {
            this._localStorageService.setItem(StorageEnum.BODY_TYPE, newBodyType);
        }
        // inform
        this.currentBodyTypeSubscriber.next(newBodyType);
    }

    /**
     * Get the current set body type
     */
    get bodyType(): BodyType {
        return this._localStorageService.getItem(StorageEnum.BODY_TYPE);
    }

	/**
	 * Update storage with selected modelDesign
	 */
	set modelDesign(modelDesign: ModelDesign) {
        if (modelDesign) {
		    this._localStorageService.setItem(StorageEnum.MODEL_TYPE, modelDesign);
        }
	}

	/**
	 * Get the current set modelDesign type
	 */
	get modelDesign(): ModelDesign {
		return this._localStorageService.getItem(StorageEnum.MODEL_TYPE);
	}

    /**
     * Set a new carline and triggers a notification if the car line is different from the last one or was reset to null
     */
    set carLine(newCarLine: CarLine) {
        if (newCarLine !== this.currentCarLine) {
            this.previousCarLine = newCarLine ? this.currentCarLine : null; // keep old values for comparing
            this.currentCarLine = newCarLine;
            if (newCarLine) {
                this._localStorageService.setItem(StorageEnum.CAR_LINE, newCarLine);
            }
            // inform
            this.currentCarLineSubscriber.next(newCarLine);
        }
    }

    /**
     * Get the current car line
     */
    get carLine(): CarLine {
        return this._localStorageService.getItem(StorageEnum.CAR_LINE);
    }

    /**
     * Notification when car line changes (due to user selection or deep linking entry)
     */
    get carLineSubscriber() {
        return this.currentCarLineSubscriber.asObservable();
    }

    /**
     * Notification when car is logged in with a VIN or the VIN is removed
     */
    get LoggedInWithVINChanged() {
        return this.setByVinSubscriber;
    }

    /**
     * Check if the car was logged in with the VIN
     */
    get loggedInWithVIN(): boolean {
        return this._localStorageService.getItem(StorageEnum.VIN) !== null;
    }

    /**
     * Get the current VIN number from the local storage
     */
    get VIN(): string {
        return this._localStorageService.getItem(StorageEnum.VIN);
    }

    /**
     * Writes the VIN Number to the local storage
     * @param vin
     */
    set VIN(vin: string) {
        this._localStorageService.setItem(StorageEnum.VIN, vin);
    }

    /**
     * Check if the car class/body-type/line were changed
     */
    get checkCarSelectionChanged(): boolean {
        return this.carClass !== this.previousCarClass || this.bodyType !== this.previousBodyType || this.carLine !== this.previousCarLine;
    }

    /**
     * Clear the VIN out of the local storage
     */
    clearVin() {
        if (this.VIN) {
            this._localStorageService.removeItem(StorageEnum.VIN);
            this.LoggedInWithVINChanged.next(false);
        }
    }

    getSelectedCarName(): string {
        let selectedCar: string;
        const loggedInByVIN = !!this.loggedInWithVIN;

        // If the vehicle was logged in with the VIN we always prefer this
        if (loggedInByVIN) {
            selectedCar = this.VIN;
        } else if (this.carClass?.name && this.bodyType?.name) {
            // The second-best thing we could have is the car class and body type
            selectedCar = `${this.carClass?.name} ${this.bodyType?.name} ${this.carLine?.name}`;
        } else {
            // Sometimes we do not have a car selected, so just show the "select a car" label
            selectedCar = this._translationService.translate('GENERAL.SELECTVEHICLE');
        }

        return selectedCar;
    }

    /**
     * Reset the current selected car service variables
     */
    resetLoggedCarData() {
        this._localStorageService.removeItem(StorageEnum.CAR_CLASS);
        this._localStorageService.removeItem(StorageEnum.CAR_LINE);
        this._localStorageService.removeItem(StorageEnum.BODY_TYPE);
        this._localStorageService.removeItem(StorageEnum.MODEL_TYPE);
        this.clearVin();

        this.carClass = null;
        this.bodyType = null;
        this.modelDesign = null;
        this.carLine = null;
    }

    removeVehicleType(): void {
        this.currentVehicleType = null;
        this._localStorageService.removeItem(StorageEnum.VEHICLE_TYPE);
    }

    /**
     * Finds car in tree and logs it.
     * @param carLineId Required.
     * @param modelDesignId Optional. Needed for AMG cars.
     */
    private setCarToNewClasses(carLineId: string, modelDesignId?: string): void {
        for (const carClass of this.currentCarClasses) {
            for (const bodyType of carClass.bodyTypes) {
                // AMG - one vehicle layer more
                if (modelDesignId) {
                    const modelType: ModelDesign = bodyType.modelDesigns.find(x => x.modelDesignId === modelDesignId);
                    if (modelType) {
                        const carLine: CarLine = modelType.carLines.find(x => x.carLineId === carLineId);
                        if (carLine) {
                            this.carClass = carClass;
                            this.bodyType = bodyType;
                            this.carLine = carLine;
                            this.modelDesign = modelType;

                            return;
                        }
                    }
                } else {
                    const carLine: CarLine = bodyType.carLines.find(x => x.carLineId === carLineId);
                    if (carLine) {
                        this.carClass = carClass;
                        this.bodyType = bodyType;
                        this.carLine = carLine;

                        return;
                    }
                }
            }
        }
    }

    /**
     * Gets the available models from the API and selects the car by the given SAP value.
     * @param carLineId Required. Unique SAP identifier for cars and transporters
     * @param modelDesignId Optional. Unique SAP identifier for AMG cars
     * @param tryOtherVehicleType Optional: Use this vehicle type instead of current active vehicle type
     */
    selectCarByCarLineId(carLineId: string, modelDesignId?: string, tryOtherVehicleType?: VehicleType) {
        if (this.carLine?.carLineId === carLineId && this.modelDesign?.modelDesignId === modelDesignId) {
            return;
        }

        this.getAvailableClasses(tryOtherVehicleType).subscribe(
            () => {
                this.setCarToNewClasses(carLineId, modelDesignId);

                // handle fail
                if (!this.carClass && !this.bodyType && !this.carLine) {
                    // deeplink entry edge case: maybe the carLineId from URL is a valid carLineId but only for the other vehicle type - let´s try one more time
                    if (tryOtherVehicleType) {
                        const otherVehicleType: VehicleType = this.vehicleType.vehicleTypeId === 'car' ? this.vehicleTypeTransporter : this.vehicleTypeCar;
                        this.selectCarByCarLineId(carLineId, modelDesignId, otherVehicleType);
                    } else {
                        // inform about definite fail that carLineId is not valid for any known vehicle types
                        this.selectingCarByIdFailedSubscriber.next(true);
                    }
                }

                // carLineId was indeed valid for the other vehicle type - so we need to update vehicleType (especially to update car chooser)
                if (tryOtherVehicleType && this.carClass && this.bodyType && this.carLine) {
                    this.vehicleType = tryOtherVehicleType;
                }
            },
            (error) => {
                console.log('Preselecting a car failed', error);
            }
        );
    }

    /**
     * Log in a car by its VIN.
     * @param vinResponse VIN number to select the vehicle with
     */
    selectCarByVINResponse(vinResponse: VIN) {
        // TODO: Handle case "VIN login with a VIN that does not exist for current vehicle type" in a better UX way
        //  (example: on transporter as vehicle type and then use a VIN for a car) => ask concept ...
        if (this.currentCarClasses && vinResponse) {
            const actualCarClass: CarClass = this.currentCarClasses.find(x => x.carClassId === vinResponse.carClass.carClassId);
            const actualBodyType: BodyType = actualCarClass.bodyTypes.find(
                x => x.bodyTypeId === vinResponse.carClass.bodyTypes[0].bodyTypeId);

            let actualCarLine: CarLine;
            let actualModelDesign: ModelDesign;
            // case amg VIN
            if (this.currentVehicleType.vehicleTypeId === 'amg') {
                actualModelDesign = actualBodyType.modelDesigns.find(
                    x => x.modelDesignId === vinResponse.carClass.bodyTypes[0].modelDesigns[0].modelDesignId);

                actualCarLine = actualModelDesign.carLines.find(
                    x => x.carLineId === vinResponse.carClass.bodyTypes[0].modelDesigns[0].carLines[0].carLineId);
            } else {
                actualCarLine = actualBodyType.carLines.find(
                    x => x.carLineId === vinResponse.carClass.bodyTypes[0].carLines[0].carLineId);
            }

            // save and inform
            this.carClass = actualCarClass;
            this.bodyType = actualBodyType;
            if (actualModelDesign) {
                this.modelDesign = actualModelDesign;
            }
            this.carLine = actualCarLine;
            this.LoggedInWithVINChanged.next(true);
        }
    }

    /**
     * Get all available car line classes for current active vehicle type.
     * @param forceVehicleType - Optional: Use this vehicle type instead of current active vehicle type.
     * @param adjustCarlineAfterLanguageChange - Optional: Update logged in car line to its new language / vehicle tree data
     */
    getAvailableClasses(forceVehicleType: VehicleType = null, adjustCarlineAfterLanguageChange: boolean = false): Observable<CarClass[]> {
        // case: lifestyle mode or redirect from Assortment splash page
        if (!this.currentVehicleType) {
            // trigger groups loading for collection is required
            this.vehicleTypeChangedSubscriber.next(null);
            // complete stream
            return of(null);
        }

        const vehicleType: string = forceVehicleType ? forceVehicleType.vehicleTypeId : this.vehicleType.vehicleTypeId;
        const currentLanguage: string = this._translationService.currentLang;

        if (
            this.currentCarClasses &&
            this.carClassRequestInfo &&
            this.carClassRequestInfo.language === currentLanguage &&
            this.carClassRequestInfo.vehicleType === vehicleType
        ) {
            return of(this.currentCarClasses);
        } else {
            return this.httpClient.get<CarClass[]>(`api/vehicle/tree/${currentLanguage}/${vehicleType}`).pipe(
                map((response: CarClass[]) => {
                    this.currentCarClasses = response;

                    this.carClassRequestInfo = {
                        language: currentLanguage,
                        vehicleType: vehicleType
                    };

                    if (adjustCarlineAfterLanguageChange && this.currentCarLine) {
                        this.setCarToNewClasses(this.currentCarLine.carLineId);
                    }

                    return response;
                })
            );
        }
    }

    getVehicleTypesData(): Observable<VehicleType[]> {
        return this.httpClient.get<VehicleType[]>('api/basic/vehicle-types/' + this._translationService.currentLang);
    }
}
