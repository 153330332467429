<div class="product-teaser-item">
    <article>
        <a [routerLink]="urlToNavigate">
            
            <div class="image-wrapper">
                <img class="image" *ngIf="teaserItemContent.articleType === 'collection'; else accessories"
                     [src]="teaserItemContent.images.image448" [alt]="teaserItemContent.headline">
    
                <ng-template #accessories>
                    <img class="image" [src]="teaserItemContent.images.image" [alt]="teaserItemContent.headline">
                </ng-template>
            </div>

            <p class="headline">{{ teaserItemContent.headline }}</p>
            <p class="description" *ngIf="teaserItemContent.marketingText">{{ teaserItemContent.marketingText }}</p>
            <p class="color" *ngIf="teaserItemContent.color">{{ teaserItemContent.color }}</p>
            <p class="price" *ngIf="teaserItemContent.price">{{ teaserItemContent.price }}</p>
        </a>
    </article>
</div>
