<div class="product-teaser-carousel" [ngClass]="{'has-grey-background': hasGreyBackground}">
    <!--Enable once it is implemented on BE side-->
    <!--<h4 class="headline">Unsere Dachträgersysteme</h4>-->

    <wb-slider class="slider"
               scroll-snap show-arrow-navigation show-dot-navigation auto-rotate-interval-ms="3000000">
        <wb-slider-item class="slider-item" *ngFor="let article of data">
            <zk-product-teaser-item [teaserItemContent]="article"></zk-product-teaser-item>
        </wb-slider-item>
    </wb-slider>

</div>
