export interface LegalPageModel {
    content: string; // html content from CMS
    lang: string;
    market: string;
    typ: string;
}

export enum LegalPageType {
    IMPRINT = 'imprint',
    PRIVACY = 'privacy',
    COPYRIGHT = 'copyright',
    MODERNSLAVERYACT = 'modernslaveryact'
}
