// ng
import { Component, OnInit } from '@angular/core';
// services
import { PopupModalService } from "@shared/modals/popup-modal/popup-modal.service";

@Component({
  selector: 'zk-dealer-locator-link',
  templateUrl: './dealer-locator-link.component.html',
  styleUrls: ['./dealer-locator-link.component.scss']
})
export class DealerLocatorLinkComponent implements OnInit {

  constructor(private modalService: PopupModalService) { }

    ngOnInit(): void {}

    openDealerLocatorModal() {
        this.modalService.open('dealer-locator-modal');
    }
}
