import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

import { LegalPageModel, LegalPageType } from '../legal-page.model';
import { LegalPagesService } from '../legal-pages.service';

@Component({
  selector: 'zk-privacy-page',
  templateUrl: './privacy-page.component.html',
  styleUrls: ['./privacy-page.component.scss']
})
export class PrivacyPageComponent implements OnInit {
  cmsContent: LegalPageModel;

  constructor(private _legalPagesService: LegalPagesService) {}

  ngOnInit() {
    const sub: Subscription = this._legalPagesService.getLegalPageContentFromCMS(LegalPageType.PRIVACY).subscribe(
        (result: LegalPageModel) => {
          this.cmsContent = result;
          sub.unsubscribe();
          window.scrollTo(0, 0);
        },
        (error) => console.log('Error: No content defined for this market in CMS! ', error),
        () => sub.unsubscribe()
    );
  }
}
