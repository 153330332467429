// ng
import { Component, OnInit } from '@angular/core';
// Service
import { TranslationService } from '@shared/shared-services/translate/translation.service';
import { PopupModalService } from "@shared/modals/popup-modal/popup-modal.service";
import { ResetWarningModalService } from '@shared/modals/reset-warning-modal/reset-warning-modal.service';

@Component({
    selector: 'zk-reset-warning-modal',
    templateUrl: 'reset-warning-modal.component.html',
    styleUrls: ['./reset-warning-modal.component.scss']
})
export class ResetWarningModalComponent implements OnInit {
    modalHeadline: string = '';
    modalContentText: string = '';

    constructor(
        private translationService: TranslationService,
        private modalService: PopupModalService,
        private resetWarningService: ResetWarningModalService
    ) {}

    ngOnInit() {}

    initComponent() {
        this.modalHeadline = this.resetWarningService.willRemoveWishlist
            ? this.translationService.translate('HEADER.MODAL.REMOVEVEHICLEANDWISHLISTHEADLINE')
            : this.translationService.translate('HEADER.MODAL.REMOVEVEHICLEHEADLINE');

        this.modalContentText = this.resetWarningService.willRemoveWishlist
            ? this.translationService.translate('HEADER.MODAL.REMOVEVEHICLEANDWISHLISTFORVEHICLECHANGETEXT')
            : this.translationService.translate('HEADER.MODAL.REMOVEVEHICLEFORSTARTPAGETEXT');
    }

    onCancel() {
        this.modalService.close('reset-warning-modal');
    }

    onContinue() {
        this.modalService.close('reset-warning-modal');
        this.resetWarningService.resetUserJourney();
    }
}
