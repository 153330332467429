<section>
    <wb-spinner *ngIf="isLoading" class="loading-spinner" theme="light"></wb-spinner>

    <div class="filterbar">
        <div *ngIf="filters?.diameters"
             class="filterbar_container">
            <wb-text size="l" class="filterbar_container_headline"
                  zkTranslate="WHEELS.COMPLETEWHEELS.RIM_DIAMETER">
            </wb-text>
            <mba-multiselect (clicked)="handleFilterTyreSizeEvent($event)"
                             [allSelectedEqualsNoSelection]="true">
                <mba-multiselect-item *ngFor="let item of filters.diameters"
                                      [title]="item.diameterText"
                                      [id]="item.diameterId"
                                      [isSelected]="item.active">
                </mba-multiselect-item>
            </mba-multiselect>
        </div>

        <div *ngIf="filters?.tyrePressureControls"
             class="filterbar_container">
            <wb-text tag="span" size="l" class="filterbar_container_headline"
                  zkTranslate="WHEELS.COMPLETEWHEELS.RIM_PRESSURE_SYSTEM">
            </wb-text>
            <mba-multiselect (clicked)="handleFilterTyrePressureSystemEvent($event)"
                             [allSelectedEqualsNoSelection]="true">
                <mba-multiselect-item *ngFor="let item of filters.tyrePressureControls"
                                      [title]="item.resourceKey"
                                      [id]="item.hasPressure"
                                      [isSelected]="item.active">
                </mba-multiselect-item>
            </mba-multiselect>
        </div>

        <div *ngIf="filters?.tyreTypes"
             class="filterbar_container">
            <wb-text tag="span" size="l" class="filterbar_container_headline"
                  zkTranslate="WHEELS.COMPLETEWHEELS.RIM_TYPE">
            </wb-text>
            <mba-multiselect (clicked)="handleFilterTyreTypeEvent($event)"
                             [allSelectedEqualsNoSelection]="true">
                <mba-multiselect-item *ngFor="let item of filters.tyreTypes"
                                      [title]="item.resourceKey"
                                      [id]="item.tyreType"
                                      [isSelected]="item.active">
                </mba-multiselect-item>
            </mba-multiselect>
        </div>
    </div>

    <zk-product-teaser-cards>
        <zk-product-teaser-card *ngFor="let article of articles"
                                [article]="article"
                                [isCollection]="false">
        </zk-product-teaser-card>
    </zk-product-teaser-cards>

    <wb-pagination *ngIf="pagination?.pages > 1"
                   class="pagination"
                   zkPagination
                   compact="{{isMobile}}"
                   pages="{{pagination.pages}}"
                   [currentPage]="pagination.page + 1"
                   (currentPageEmitter)="handlePagination($event)">
    </wb-pagination>
</section>

