// ng
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
// own
import { SharedModule } from '@shared/shared.module';
import { WheelSpecialService } from './light-alloy-wheel/wheel-special.service';
// components
import { CompleteWheelComponent } from './complete-wheel/complete-wheel.component';
import { WheelSpecialComponent } from './light-alloy-wheel/wheel-special.component';
import { WheelComponent } from './light-alloy-wheel/wheel/wheel.component';
import { WheelMoreInfoComponent } from './light-alloy-wheel/wheel-more-info/wheel-more-info.component';
import { WheelFilterComponent } from './light-alloy-wheel/wheel-filter/wheel-filter.component';

@NgModule({
  declarations: [
    CompleteWheelComponent,
    WheelSpecialComponent,
    WheelComponent,
    WheelMoreInfoComponent,
    WheelFilterComponent
  ],
  imports: [
    CommonModule,
    SharedModule
  ],
  exports: [
    CompleteWheelComponent,
    WheelSpecialComponent,
    WheelComponent,
    WheelMoreInfoComponent,
    WheelFilterComponent
  ],
  providers: [WheelSpecialService],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class WheelCategoriesModule { }
