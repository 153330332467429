// NG
import { Component, OnDestroy, OnInit } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';

// Own
import { AppService } from '../../../app.service';
import { MODE } from '@shared/global-models/mode.enum';

@Component({
    selector: 'zk-sticky-footer',
    templateUrl: 'sticky-footer.component.html',
    styleUrls: ['./sticky-footer.component.scss'],
    animations: [
        trigger('stickyFooterAnimation', [
            state(
                'true',
                style({
                    'max-height': '300px',
                    height: '100%',
                    visibility: 'visible'
                })
            ),
            state('false', style({ 'max-height': '0' })),
            transition('* => false', [animate('100ms ease-out')]),
            transition('* => true', [animate('100ms ease-in')])
        ])
    ]
})
export class StickyFooterComponent implements OnInit, OnDestroy {
    isInfoOpen: boolean = false;
    applyMargin: boolean = false;
	isAccessoriesMode: boolean;

	private _routerSub: Subscription;

    constructor(private _appService: AppService,
				private _router: Router) {}

    ngOnInit() {
		this.isAccessoriesMode = this._appService.appMode === MODE.ACCESSORIES;

		this._routerSub = this._router.events.subscribe((event) => {
			if (event instanceof NavigationEnd) {
				this.isAccessoriesMode = this._appService.appMode === MODE.ACCESSORIES;
			}
		});
    }

    togglePriceInfo() {
        this.isInfoOpen = !this.isInfoOpen;

        if (this.isInfoOpen) {
            this.applyMargin = true;
        } else {
            setTimeout(() => {
                this.applyMargin = false;
            }, 100);
        }
    }

	ngOnDestroy(): void {
		this._routerSub.unsubscribe();
	}
}
