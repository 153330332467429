// The values here are set at runtime. The default after || are fallback values
export const environment = {
    production: false,
    enableTracking: false,
    apiUrl: '',
	testEnv: true,
    /**
     * INT API Key for Dealer Locator Widget
     */
    dlcApiKey: '4a856337-90b2-4576-b39c-748a2612f302',
};
