<div class="teaser-big_wrapper">
    <picture *ngIf="article?.image"
             class="teaser-big_wrapper_picture">
        <source [srcset]="article.image320"
                media="(max-width:319.9px)"
                *ngIf="article.image320">
        <source [srcset]="article.image400"
                media="(max-width:399.9px)"
                *ngIf="article.image400">
        <source [srcset]="article.image480"
                media="(max-width:479.9px)"
                *ngIf="article.image480">
        <source [srcset]="article.image740"
                media="(max-width:739.9px)"
                *ngIf="article.image740">
        <img class="ui-picture teaser-big_wrapper_picture"
             [src]="article.image"
             [alt]="article?.imageDescription"/>
    </picture>

    <div class="teaser-big_wrapper_text">
          <wb-heading size="s" *ngIf="article?.headline"
                class="teaser-big_wrapper_text_headline">
            {{article.headline}}
          </wb-heading>
          <wb-text size="l" *ngIf="article?.headline2"
              class="exclude-mobile">
            {{article.headline2}}
          </wb-text>

        <wb-text size="l" *ngIf="article?.description"
                 class="teaser-big_wrapper_text_description"
              [innerHTML]="article.description"></wb-text>

        <wb-heading size="s" *ngIf="article?.grossPriceText"
              class="teaser-big_wrapper_text_price">
            {{article.grossPriceText}}
        </wb-heading>

        <wb-button variant="secondary"
                   size="s"
                   (click)="onClickPicture(article)">{{buttonLinkLabel}}
        </wb-button>
    </div>
</div>

