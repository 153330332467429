import { Injectable } from '@angular/core';

@Injectable()
export class CampaignService {
	constructor() {}

	private availableTranslations: Object;

	set campaignTranslations(translations: Object) {
		this.availableTranslations = translations;
	}

	get campaignTranslations() {
		return this.availableTranslations;
	}

	clearTranslations() {
		this.availableTranslations = null;
	}
}
