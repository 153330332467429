import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ProductGroup } from '@mbcs/mbcs-lib';
import { fromEvent, Subscription } from 'rxjs';
import { tap, throttleTime } from 'rxjs/operators';

@Component({
	selector: 'zk-subnavigation',
	templateUrl: './subnavigation.component.html',
	styleUrls: ['./subnavigation.component.scss']
})
export class SubnavigationComponent implements OnInit, OnDestroy {
	isSticky: boolean = false;

	@Input()
	activeProductGroup: ProductGroup;

	@Input()
	showBackButton: boolean;

	@Output()
	clicked: EventEmitter<string> = new EventEmitter<string>();

	@ViewChild('stickyEl') stickyEl: ElementRef;

	private scrollEventSub: Subscription;

	constructor() {}

	ngOnInit(): void {
		this.scrollEventSub = fromEvent(window, 'scroll')
			.pipe(
				throttleTime(100), // emits once, then ignores subsequent emissions for 100ms, repeat...
				tap((event) => this.scroll())
			)
			.subscribe();
	}

	navItemClicked(groupUrlName: string): void {
		// we use group urlName property because it has already escaped chars for '&' or ':'
		// and CSS.escape() to be sure for id´s valid in the HTML5 spec, but not valid in CSS
		// which would make document.querySelector fail.
		this.clicked.emit(CSS.escape(groupUrlName));
	}

	scroll() {
		this.isSticky = this.stickyEl && this.stickyEl.nativeElement.getBoundingClientRect().y < 0;
	}

	ngOnDestroy() {
		this.scrollEventSub.unsubscribe();
	}
}
