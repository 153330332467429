<zk-popup-modal id="wishlist-compatibility-feedback"
                 class="feedback-modal"
                 [headline]="modalHeadline"
                 (onClose)="closeComponent()"
                 (onOpen)="initComponent()">

    <div modalContent class="content">
        <wb-text size="l" zkTranslate="COMPATIBILITYCHECK.INTROSHORT"></wb-text>
        <wb-text size="l" strong class="gap" zkTranslate="COMPATIBILITYCHECK.NOTALLCOMPATIBLE"></wb-text>
        <div class="car-name">
            <wb-text tag="span" size="l" zkTranslate="COMPATIBILITYCHECK.YOURCAR"></wb-text>
            <wb-text tag="span" size="l" strong class="car-name-id">{{carName}}</wb-text>
        </div>

        <!-- Shopping Cart -->
        <div class="content_cart-articles"
             *ngIf="articlesInCart">
            <wb-text tag="h2" size="l" class="headline"
                zkTranslate="COMPATIBILITYCHECK.ARTICLEINWISHLIST"></wb-text>
            <ul class="content_unordered-list">
                <li class="content_unordered-list"
                    *ngFor="let article of articlesInCart.items">

                    <!-- STATE: Available for car or unchecked -->
                    <zk-cart-item [cartItem]="article"
                                  *ngIf="article.appMode === appMode.ACCESSORIES && article.fit !== false"
                                  [isCompatibilityCheck]="true">
                    </zk-cart-item>

                    <!-- STATE: Not available for car -->
                    <div class="content_not-orderable_wrapper"
                         *ngIf="article.fit === false">
                        <img class="content_not-orderable_image"
                             [src]="article.image"
                             [alt]="article.headline">
                        <div class="content_not-orderable_description">
                            <wb-text size="l" strong zkTranslate="COMPATIBILITYCHECK.ARTICLEREMOVEDWISHLIST"></wb-text>
                            <wb-text size="l" zkTranslate="COMPATIBILITYCHECK.NOTORDERABLEREASON"></wb-text>
                            <wb-link variant="standalone" class="content_link"
                                     [routerLink]="goToCategory(article)"
                                     (click)="close()">
                                <span zkTranslate="GENERAL.SEARCHALTERNATIVE"></span>
                            </wb-link>
                        </div>
                    </div>
                </li>
                <li class="content_unordered-list">
                    <mba-separator separatorClass="separator_is-light-grey"></mba-separator>
                </li>
            </ul>
        </div>
    </div>

    <div modalFooter class="modal-footer">
        <wb-button variant="primary"
                   (click)="close()">
            <span zkTranslate="GENERAL.CONTINUE"></span>
        </wb-button>
    </div>
</zk-popup-modal>
