// ng
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';

// model
import { Image } from '@mbcs/mbcs-lib';
import { ProductGroup } from '@mbcs/mbcs-lib';
import { PageName } from '@shared/shared-services/google-analytics/google-analytics.model';
import { ResponsiveImage } from "@shared/global-models/responsiveImages.model";

// services
import { PopupModalService } from "@shared/modals/popup-modal/popup-modal.service";
import { SelectedCarService } from '@shared/shared-services/selected-car/selected-car.service';
import { TeaserService } from '@shared/shared-services/teaser/teaser.service';
import { GroupMapperService } from '@shared/shared-services/group-mapper/group-mapper.service';
import { ProductGroupsService } from '@shared/shared-services/product-groups/product-groups.service';
import { SeoService } from '@shared/shared-services/seo/seo.service';
import { GoogleAnalyticsService } from '@shared/shared-services/google-analytics/google-analytics.service';

@Component({
    selector: 'zk-category-page',
    templateUrl: './category-page.component.html',
    styleUrls: ['./category-page.component.scss']
})
export class CategoryPageComponent implements OnInit, OnDestroy {
    image: ResponsiveImage;
    headline = '';
    mainGroupId = '';
    isGroupTypeWheels = false;

    private _carLogged = false;
    private _mainGroupName = '';
    private _productGroupsConfig: ProductGroup[];
    private _routerSubscription: Subscription;
    private _groupsChangedSubscription: Subscription;

    constructor(
        private _popupModalService: PopupModalService,
        private _router: Router,
        private _activatedRoute: ActivatedRoute,
        private _selectedCarService: SelectedCarService,
        private _teaserService: TeaserService,
        private _groupMapperService: GroupMapperService,
        private _productGroupsService: ProductGroupsService,
        private _seoService: SeoService,
        private _gaService: GoogleAnalyticsService
    ) {}

    ngOnInit(): void {
        this._carLogged = this._activatedRoute.snapshot.params.carclass !== 'products';
        this._productGroupsConfig = this._productGroupsService.groups;
        this.parseCategory();

        if (this._carLogged && !this.checkIfGroupExistsForCar()) {
            return;
        }

        this.initStageTeaser();
        this.setSeoInfo();
        this.listenToChanges();
    }

    ngOnDestroy(): void {
        if (this._routerSubscription) {
            this._routerSubscription.unsubscribe();
        }

        if (this._groupsChangedSubscription) {
            this._groupsChangedSubscription.unsubscribe();
        }
    }

    /**
     * Checks if the current active main category exists for the new selected car.
     * If not it redirects the user to the model start page.
     */
    private checkIfGroupExistsForCar(): boolean {
        const currentMainGroup: ProductGroup = this._productGroupsConfig.find((x) => x.groupId === this.mainGroupId);
        if (!currentMainGroup) {
            this._popupModalService.open('category-not-available-modal');

            return false;
        }

        return true;
    }

    private listenToChanges(): void {
        // detect if the main category has changed. Re-init of the component needed to display the new main category
        this._routerSubscription = this._router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                this.ngOnDestroy();
                this.ngOnInit();
            }
        });

        // react to car log in / change. Re-init of the component needed to check if category is available for the new car
        this._groupsChangedSubscription = this._productGroupsService.groupsChangedInfo.subscribe(() => {
            this.ngOnDestroy();
            this.ngOnInit();
        });
    }

    private parseCategory(): void {
        this._mainGroupName = this._groupMapperService.getGroupNameFromUrlName(this._activatedRoute.snapshot.params.category);
        this.mainGroupId = this._groupMapperService.getGroupIdFromGroupName(this._mainGroupName);
        const mainGroupType = this._groupMapperService.getGroupTypeById(this.mainGroupId);
        this.isGroupTypeWheels = mainGroupType === 'wheels' || mainGroupType === 'amg-wheels';

        // Wheel related product groups are treated different
        if (this.isGroupTypeWheels) {
            const wheelGroups: ProductGroup[] = this._productGroupsConfig.find((x) => x.type === mainGroupType).subGroups;
            wheelGroups.sort((a, b) => a.position - b.position);
            // redirect to the first sub group/tab if a wheel main category was requested via URL
            // (so that URL matches exactly what is shown as active tab)
            this.routeToSubcategory(wheelGroups[0].urlName);
        }
    }

    /**
     * Handles the routing to the according subcategory page for wheels
     * @param subGroupUrlName
     */
    private routeToSubcategory(subGroupUrlName: string): void {
        let pathToGoTo: string = this._carLogged ? this._selectedCarService.carLine.urlName : 'products';
        const mainGroupUrlName: string = this._activatedRoute.snapshot.params.category;
        pathToGoTo += '/' + mainGroupUrlName + '/' + subGroupUrlName;
        this._router.navigate([pathToGoTo], { queryParamsHandling: 'preserve' });
    }

    private initStageTeaser(): void {
        this._teaserService.getMainCategoryImage(this.mainGroupId).subscribe(
            (response: Image) => this.image = response
        );
        this.headline = this._teaserService.getHeadlineForSingleStageWide(this._mainGroupName);
    }

    private setSeoInfo(): void {
        this._gaService.trackPageView(PageName.CATEGORY_PAGE);

        const title: string = this._seoService.getTranslation('GENERAL.PAGES.STARTPAGE_STATICTITLE');
        // get the sub groups of the current main category
        let subCategoryTitle: string = '';
        const mainCategoryExists: ProductGroup = this._productGroupsConfig.find((x) => x.groupId === this.mainGroupId);
        if (mainCategoryExists) {
            const subCategories: ProductGroup[] = mainCategoryExists.subGroups;
            // Concatenate the array sub-strings into sub1, sub2, sub3
            let index: number = 1;
            const max: number = subCategories.length;
            for (const subCat of subCategories) {
                subCategoryTitle += subCat.name;
                if (index < max) {
                    subCategoryTitle += ', ';
                    index++;
                }
            }
        }

        // page title when there is a logged car
        // <Cat> ["("] <SubCat1> [","] <SubCat2> [","] <…> [") |"] <CarBodyLine> ["| Mercedes-Benz Genuine Accessories"]
        if (this._carLogged && this._selectedCarService.carLine) {
            this._seoService.updatePageTitle(
                this._mainGroupName +
                    ' (' +
                    subCategoryTitle +
                    ') | ' +
                    this._selectedCarService.bodyType.name +
                    ' ' +
                    this._selectedCarService.carLine.name +
                    ' | ' +
                    title
            );

            // Meta content: <CarBodyLine> [" | staticTxt "] <Cat> ["("] <SubCat1> [","] <SubCat2> [","] <…> [")"]
            this._seoService.updateMetaDescription(
                this._selectedCarService.bodyType.name +
                    ' ' +
                    this._selectedCarService.carLine.name +
                    ' | ' +
					this._seoService.getTranslation('SEO.METADESCRIPTION.PRODUCTLISTPAGE') +
                    ' ' +
                    this._mainGroupName +
                    ' (' +
                    subCategoryTitle +
                    ')'
            );
        } else {
            // page title when there is no logged car
            // <Cat> ["("] <SubCat1> [","] <SubCat2> [","] <…> [") | Mercedes-Benz Genuine Accessories"
            this._seoService.updatePageTitle(this._mainGroupName + ' (' + subCategoryTitle + ') | ' + title);

            // Meta content: [" staticTxt "] <Cat> ["("] <SubCat1> [","] <SubCat2> [","] <…> [")"]
            this._seoService.updateMetaDescription(
				this._seoService.getTranslation('SEO.METADESCRIPTION.PRODUCTLISTPAGE') +
                    ' ' +
                    this._mainGroupName +
                    ' (' +
                    subCategoryTitle +
                    ')'
            );
        }
    }
}
