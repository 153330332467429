// ng
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { HttpClient } from "@angular/common/http";
// services
import { TranslationService } from "@shared/shared-services/translate/translation.service";
import { AppService } from "../../../app.service";
import { LocalStorageService } from "@shared/shared-services/storage/local-storage.service";
import { SelectedCarService } from "@shared/shared-services/selected-car/selected-car.service";
// model
import { MODE, SUBMODE } from "@shared/global-models/mode.enum";
import { SearchSuggest, Suggestion } from "@shared/global-models/search-suggest.model";
import { StorageEnum } from "@shared/shared-services/storage/storage.enum";
import { SearchResult } from "@shared/global-models/search-result.model";

@Injectable()
export class SearchService {

    constructor(private _http: HttpClient,
                private _translate: TranslationService,
                private _appService: AppService,
                private _storage: LocalStorageService,
                private _selectedCarService: SelectedCarService,
                private _translationService: TranslationService) {
    }

    /**
     * Notifier to open the search input field for desktop
     */
    openSearch: Subject<void> = new Subject<void>();

    /**
     * Creates or updates the search history storage
     * @param term
     */
    addTermToStorageHistory (term: Suggestion) {
        let history: Suggestion[] = this._storage.getItem(StorageEnum.SEARCH_HISTORY);

        if (!history) {
            history = [];
            history.push(term);
        }
        else {
            const added: Suggestion = history.find((item: Suggestion) => item.suggestion === term.suggestion);
            if (!added) {
                if (history.length === 6) {
                    history.splice(0, 1);
                }
                history.push(term);
            }
        }

        this._storage.setItem(StorageEnum.SEARCH_HISTORY, history);
    }

    /**
     * Returns search history storage
     */
    getSearchHistory(): Suggestion[] | null {
        return this._storage.getItem(StorageEnum.SEARCH_HISTORY);
    }

    /**
     * Redirects to the search results page of the other assortment mode.
     * @param toCollection - if true redirect to COL, otherwise to ACC
     * @param query - current search term
     */
    redirectToOtherSearchPage(toCollection: boolean, query: string) {
        let redirectTarget = this._translationService.currentLang;
        if (toCollection) {
            redirectTarget += MODE.LIFESTYLE + SUBMODE.COLLECTION;
        } else {
            redirectTarget += MODE.ACCESSORIES + SUBMODE.PASSENGERCARS;
        }
        redirectTarget += '/search?q=' + query;

        location.href = redirectTarget;
    }

    get isAmg(): boolean {
        return this._appService.appSubMode === SUBMODE.AMG;
    }

    get isCollection(): boolean {
        return this._appService.appSubMode === SUBMODE.COLLECTION
    }

    /**
     * Returns '&vehicleType=car|transporter|collection' or '' if AMG.
     */
    private addMode(): string {
        if (this.isAmg) {
            return '';
        }

        let param = '&vehicleType=';
        param += this.isCollection ? 'collection' : this._selectedCarService.vehicleType.vehicleTypeId;

        return param;
    }

    /**
     * Returns '&carLineId=XYZ' or '' if collection or no car is logged in.
     */
    private addCarId(): string {
        let param = '';

        if (this.isCollection) {
            return param;
        }

        if (this._selectedCarService?.carLine) {
            param = '&carLineId=' + this._selectedCarService.carLine.carLineId;
        }

        return param;
    }

    /**
     * Returns '&modelDesignId=XYZ' or '' if collection mode or no AMG car is logged in.
     */
    private addModelDesignId(): string {
        let param = '';

        if (this.isCollection || !this.isAmg) {
            return param;
        }

        if (this._selectedCarService?.modelDesign) {
            param = '&modelDesignId=' + this._selectedCarService.modelDesign.modelDesignId;
        }

        return param;
    }

    /**
     * Constructs the base params for search related api calls.
     * @param term
     */
    private createBaseParams(term: string): string {
        const lang: string = this._translate.currentLang.substring(0,2);
        const code: string = this._appService.currentMarket.marketCode;
        const mode: string = this.addMode();
        const carLineId: string = this.addCarId();
        const modelDesignId: string = this.addModelDesignId();

        return `?q=${term}&language=${lang}&market=${code}${mode}${carLineId}${modelDesignId}`
    }

    /**
     * Fetches suggestions based on the term.
     * @param term - search term
     */
    fetchSuggests(term: string): Observable<SearchSuggest> {
        const baseParam: string = this.createBaseParams(term);
        const amgParam: string = this.isAmg ? 'amg/' : '';
        const url: string = `api/search/${amgParam}suggest${baseParam}`;

        return this._http.get<SearchSuggest>(url);
    }

    /**
     * Get all articles (acc and wheels) for search term paginated.
     * Returns list of articles and article info for pagination logic.
     * @param term - search term
     */
    fetchAllArticlesForSearch(term: string): Observable<SearchResult> {
        const baseParam: string = this.createBaseParams(term);
        const amgParam: string = this.isAmg ? '/amg' : '';
        const url: string = `api/search${amgParam}${baseParam}`;

        return this._http.get<SearchResult>(url);
    }

    /**
     * Get next page of article types from search result
     * @param term - search term
     * @param isWheel - defines pagination in wheels or acc assortment category
     * @param page - page number, starting with 0
     * @param amount - page size, defaults to 8 or 10
     */
    fetchNextPageOfArticles(term: string, isWheel: boolean, page: number, amount: number): Observable<SearchResult> {
        const baseParam: string = this.createBaseParams(term);
        const amgParam: string = this.isAmg ? '/amg' : '';
        const pagination: string = isWheel ? `&page_wheels=${page}&size_wheels=${amount}` : `&page=${page}&size=${amount}`;
        const url: string = `api/search${amgParam}${baseParam}${pagination}`;

        return this._http.get<SearchResult>(url);
    }
}
