// ng
import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewEncapsulation,
    Renderer2,
    Inject,
    PLATFORM_ID,
    OnDestroy
} from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { Subscription } from 'rxjs';
// services
import { PopupModalService } from './popup-modal.service';
import { ViewportService } from "@mbcs/mbcs-lib";

@Component({
    selector: 'zk-popup-modal',
    templateUrl: './popup-modal.component.html',
    styleUrls: ['./popup-modal.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class PopupModalComponent implements OnInit, OnDestroy {
    /**
     * Required: Unique ID/name of the modal. Used by PopupModalService to open and close the modal instance.
     */
    @Input() id: string;

    /**
     * Optional: Headline of the modal.
     */
    @Input() headline: string;

    /**
     * Optional: Displays a close icon to close the modal. Default is true.
     */
    @Input() showCloseButton: boolean = true;

    /**
     * Optional: Modal will be closed when click event occurs outside of the modal. Default is true.
     */
    @Input() closeOnClickOutside: boolean = true;

    /**
     * Optional: Modal has bigger width and different appearance. Only for rendering the Dealer Locator Widget. Default is false.
     */
    @Input() isDealerLocator: boolean = false;

    /**
     * Informs when the modal got closed. Useful to run logic/clean up of subscriptions etc. when the modal is not displayed anymore.
     * Event param: ID of the modal.
     */
    @Output() onClose: EventEmitter<string> = new EventEmitter();

    /**
     * Informs when the modal got open. Useful to run logic/subscriptions etc. only when the modal is actually displayed.
     * Event param: ID of the modal.
     */
    @Output() onOpen: EventEmitter<string> = new EventEmitter();

    isModalOpen: boolean = false;
    distanceToTop: number = 0;

    private readonly _element: any;
    private _vpSubscription: Subscription;
    private _appContentHeightChangedSubscriber: Subscription;
    private _modalHeight: number = 0;

    constructor(
        @Inject(PLATFORM_ID) private _platformId: Object,
        private _modalService: PopupModalService,
        private _viewPortService: ViewportService,
        private _el: ElementRef,
        private _renderer: Renderer2
    ) {
        this._element = _el.nativeElement;
    }

    ngOnInit(): void {
        // ensure id attribute exists
        if (!this.id) {
            console.error('modal must have an id');

            return;
        }
        // check needed cause DOM manipulation is not available for Angular Universal
        const parent: any = isPlatformBrowser(this._platformId) ? document.body : null;

        if (isPlatformBrowser(this._platformId)) {
            // move element to bottom of page (just before </body>) so it can be displayed above everything else
            this._renderer.appendChild(parent, this._element);
        }

        // add self (this modal instance) to the modal service so it's accessible from controllers
        this._modalService.add(this);

        // Let's listen to some change events
        this.setupSubscriptions();
    }

    /**
     * Remove self from modal service when directive is destroyed
     */
    ngOnDestroy() {
        if (this.id) {
            // remove instance from it´s service
            this._modalService.remove(this.id);

            // check needed cause DOM manipulation is not available for Angular Universal
            const parent: any = isPlatformBrowser(this._platformId) ? document.body : null;

            // maybe the modal was not shown yet so a pre-check is needed
            if (this._element && parent) {
                this._renderer.removeChild(parent, this._element);
            }
        }

        if (this._vpSubscription) {
            this._vpSubscription.unsubscribe();
        }

        if (this._appContentHeightChangedSubscriber) {
            this._appContentHeightChangedSubscriber.unsubscribe();
        }
    }

    /**
     * Open modal by service
     */
    open() {
        this.isModalOpen = true;
        this.checkPositioning();
        // inform
        this.onOpen.emit(this.id);
    }

    /**
     * Close modal by service
     * @param id instance id
     * @param clickOutside boolean if click outside modal happened
     */
    closeModal(id: string, clickOutside: boolean) {
        if (clickOutside && !this.closeOnClickOutside) {
            return;
        }
        this._modalService.close(id);
    }

    /**
     * Callback from service when modal got closed
     * @param skipEventEmit boolean if inform event should be skipped. Default is false.
     */
    close(skipEventEmit: boolean = false) {
        this.isModalOpen = false;

        if (!skipEventEmit) {
            // inform
            this.onClose.emit();
        }
    }

    /**
     * Directive callback to react on height of modal changes (see ZKDEV-1189)
     * @param newHeight - sum of height
     */
    heightOfModalChanged(newHeight: number) {
        if (newHeight !== this._modalHeight) {
            this._modalHeight = newHeight;
            // prevent expression changed after view checked error
            setTimeout(() => this.checkPositioning());
        }
    }

    private setupSubscriptions() {
        // react to browser resize
        this._vpSubscription = this._viewPortService.getViewportChangedObserver().subscribe(
            () => {
                this.checkPositioning();
            },
            (error) => {
                console.log('Get updated ViewPort failed', error);
            }
        );

        // get the page content height and repositions the modal-popup
        // if application content height changed, the modal-popup has to update it's position as well (see ZKDEV-1033)
        this._appContentHeightChangedSubscriber = this._viewPortService.getAppContentHeightObserver().subscribe(
            () => {
                this.checkPositioning();
            },
            (error) => {
                console.log('Get updated ViewPortHeight failed', error);
            }
        );
    }

    /**
     * Check the height of the modal and the innerHeight of the window and the current scrollY position
     * to place the modal centered in the current viewport. If the modal gets bigger then the available
     * height of the inner window itself we ensure readability via scrollbars and reposition it.
     */
    private checkPositioning() {
        // isPlatformBrowser check needed cause DOM manipulation is not available for Angular Universal
        if (isPlatformBrowser(this._platformId)) {
            if (this._modalHeight >= window.innerHeight) {
                // set to top
                this.distanceToTop = this._modalHeight / 2;
            } else {
                // set centered in current viewport
                this.distanceToTop = window.pageYOffset + this._modalHeight / 2 + (window.innerHeight - this._modalHeight) / 2;
            }
        }
    }
}
