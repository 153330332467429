import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CanActivateCollectionGuard } from '@shared/shared-services/guards/can-activate-collection-guard';

// page components
import { LifestyleComponent } from './lifestyle.component';
import { CollectionComponent } from './collection/collection.component';
import { CollectionStartPageComponent } from './collection/collection-start-page/collection-start-page.component';
import { CollectionProductListPageComponent } from './collection/collection-product-list-page/collection-product-list-page.component';
import { CollectionSubCategoryPageComponent } from './collection/collection-sub-category-page/collection-sub-category-page.component';
import { CollectionSubSubCategoryPageComponent } from './collection/collection-sub-sub-category-page/collection-sub-sub-category-page.component';
import { CollectionProductDetailsPageComponent } from './collection/collection-product-details-page/collection-product-details-page.component';
import { CampaignComponent } from "../../pages/campaign/campaign.component";
import { WishlistComponent } from '@shared/components/wishlist/wishlist.component';
import { SearchResultsPageComponent } from '../../pages/search-results-page/search-results-page.component';

// app mode
import { SUBMODE } from '@shared/global-models/mode.enum';

const routes: Routes = [
    {
        path: ':market/lifestyle',
        component: LifestyleComponent,
		canActivate: [CanActivateCollectionGuard],
        children: [
            // sub brand A
            { path: '', redirectTo: SUBMODE.COLLECTION, pathMatch: 'full' },
            {
                path: SUBMODE.COLLECTION,
                component: CollectionComponent,
                children: [
                    { path: '', component: CollectionStartPageComponent },
                    // marketing / campaign page
                    { path: 'campaign/:id', component: CampaignComponent},
                    { path: 'wishlist', component: WishlistComponent},
                    { path: 'search', component: SearchResultsPageComponent},
                    // collection related pages
                    { path: 'products/:category', component: CollectionProductListPageComponent },
                    { path: 'products/:category/:subcategory', component: CollectionSubCategoryPageComponent },
                    { path: 'products/:category/:subcategory/:subsubcategory', component: CollectionSubSubCategoryPageComponent }, // can be a sub sub category or article url
                    { path: 'products/:category/:subcategory/:subsubcategory/:product', component: CollectionProductDetailsPageComponent }
                ]
            }
            // to be enhanced for further sub brands of assortment lifestyle
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
	providers: [CanActivateCollectionGuard]
})
export class LifestyleRoutingModule {}
