<!-- Component -->
<div *ngIf="wheelData"
     class="wheel">
    <div class="wheel">
        <picture>
            <source [srcset]="wheelData?.image320"
                    media="(max-width:320px)"
                    *ngIf="wheelData?.image320">
            <source [srcset]="wheelData?.image400"
                    media="(max-width:400px)"
                    *ngIf="wheelData?.image400">
            <source [srcset]="wheelData?.image480"
                    media="(max-width:480px)"
                    *ngIf="wheelData?.image480">
            <source [srcset]="wheelData?.image740"
                    media="(max-width:740px)"
                    *ngIf="wheelData?.image740">
            <img class="wheel_img"
                 [src]="wheelData?.image"
                 [alt]="wheelData?.imageDescription">
        </picture>
        <div class="wheel_details">
            <wb-heading size="m" class="wheel_details_headline">
                {{wheelData.headline}}
            </wb-heading>
            <wb-text size="l" class="wheel_details_desc">{{wheelData.headline2}}</wb-text>
            <wb-heading size="m" class="no-margin-bottom wheel_details_price">
                {{wheelData.grossPriceText}}
            </wb-heading>
            <wb-text size="s" class="is-light-grey wheel_details_note"
                  zkTranslate="WHEELS.NOTYRE">
            </wb-text>

            <div class="wheel_details_button-wrapper">
                <wb-button class="button"
                           variant="secondary"
                           zkTranslate="WHEELS.TOWISHLIST"
                           (click)="addToCart(wheelData, true)"
                           [disabled]="articleAmountInfo?.maxReached && articleAmountInfo?.quantity === 10">
                </wb-button>

                <wb-button class="button"
                           variant="primary"
                           zkTranslate="WHEELS.ADDWHEELWISHLIST"
                           style="margin-top: 10px"
                           (click)="addToCart(wheelData, false)"
                           [disabled]="articleAmountInfo?.maxReached && articleAmountInfo?.quantity === 10">
                </wb-button>
            </div>

            <wb-text tag="span" size="l" *ngIf="articleAmountInfo?.maxReached"
                  class="wheel_details_feedback"
                  [zkTranslate]="getArticleAmountFeedbackText()">
            </wb-text>

            <mba-button class="wheel_details_btn-info"
                        [icon]="moreInfoIsOpen ? 'arrow-thin-up-new' : 'arrow-thin-down-new'"
                        [isLink]="true"
                        [hasIconOnMobile]="true"
                        (clicked)="toggleMoreInfo(wheelData)">
                <wb-text size="m" strong
                      [zkTranslate]="moreInfoIsOpen ? 'WHEELS.LESSINFO' : 'WHEELS.MOREINFO'">
                </wb-text>
            </mba-button>
        </div>
    </div>
</div>

<!-- More info flyout -->
<div class="wheel_more-info-wrapper">
    <wb-spinner *ngIf="showLoadingSpinner" class="loading-spinner" theme="light"></wb-spinner>

    <zk-wheel-more-info [wheel]="detailedWheelData"
                        (toggleMoreInfoEvent)="toggleMoreInfo(wheelData)"
                        [ngClass]="{'toggle-slide': moreInfoIsOpen}"
                        class="info-box">
    </zk-wheel-more-info>
</div>
