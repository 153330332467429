<div class="category-teaser" *ngIf="config">
    <div class="category-teaser-card">
        <a [routerLink]="config.left.link">
            <img class="image"
                 [src]="config.left.image.image"
                 [alt]="config.left.image.imageDescription">
            <span class="text">{{config.left.text}}</span>
        </a>
    </div>
    <div class="category-teaser-card">
        <a [routerLink]="config.middle.link">
            <img class="image"
                 [src]="config.middle.image.image"
                 [alt]="config.middle.image.imageDescription">
            <span class="text">{{config.middle.text}}</span>
        </a>
    </div>
    <div class="category-teaser-card">
        <a [routerLink]="config.right.link">
            <img class="image"
                 [src]="config.right.image.image"
                 [alt]="config.right.image.imageDescription">
            <span class="text">{{config.right.text}}</span>
        </a>
    </div>
</div>

<div class="category-teaser-link">
    <wb-link variant="standalone" (click)="scrollToCategoryFooter()">
        <span zkTranslate="LIFESTYLE.PDL.TOALLCATEGORIES"></span>
    </wb-link>
</div>
