// ng
import { Component, OnInit } from '@angular/core';
import { environment } from "../../../../environments/environment";
// services
import { TranslationService } from "@shared/shared-services/translate/translation.service";

declare var DealerLocator: any;

@Component({
  selector: 'zk-dealer-locator',
  templateUrl: './dealer-locator.component.html',
  styleUrls: ['./dealer-locator.component.scss']
})
export class DealerLocatorComponent implements OnInit {
    constructor(private _translate: TranslationService) { }

    ngOnInit(): void {
        this.initDealerLocatorScript();
    }

    private initDealerLocatorScript() {
        const head: HTMLHeadElement = document.querySelector('head');
        // JS
        const s: HTMLScriptElement = document.createElement('script');
        s.src = environment.production ? 'https://assets.oneweb.mercedes-benz.com/plugin/dlc/one-dlc/1.x/onedlc.js'
                                       : 'https://int.assets.oneweb.mercedes-benz.com/plugin/dlc/one-dlc/1.x/onedlc.js';
        s.defer = true;
        s.id = 'DLC';
        s.onload = () => this.initWidget();
        head.appendChild(s);

        // CSS
        const css: HTMLLinkElement = document.createElement('link');
        css.setAttribute('rel', 'stylesheet');
        css.href = environment.production ? 'https://assets.oneweb.mercedes-benz.com/plugin/dlc/one-dlc/1.x/onedlc.css'
                                          : 'https://int.assets.oneweb.mercedes-benz.com/plugin/dlc/one-dlc/1.x/onedlc.css';
        head.appendChild(css);
    }

    private initWidget() {
        let lang = this._translate.currentLang.replace('-', '_');
        let market = this._translate.currentLang.substring(3, 5);

        // MB market workaround
        if (market === 'MB') {
            market = 'DE';
            lang = lang === 'de_MB' ? 'de_DE' : 'en_GB';
        }

        const dlc = new DealerLocator({
            "element": "dlc",
            "configId": "OneDLC_AC_COLOS_Informative",
            "searchProfileName": "AC_COLOS_All",
            "apiKey": environment.dlcApiKey,
            "market": market,
            "language": lang,
            selectDealerCallback: console.log
        });

        dlc.init();
    }
}
