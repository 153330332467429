<div class="teaser-medium">
    <article >
        <a [href]="data.left.url">
            <div class="image-wrapper">
                <img class="image" [src]="data.left.images.image720" [alt]="data.left.imageDescription">
            </div>

            <h4 class="headline">{{data.left.headline}}</h4>
            <p class="description">{{data.left.marketingText}}</p>
        </a>
    </article>

    <article>
        <a [href]="data.right.url">
            <div class="image-wrapper">
                <img class="image" [src]="data.right.images.image720" [alt]="data.right.imageDescription">
            </div>

            <h4 class="headline">{{data.right.headline}}</h4>
            <p class="description">{{data.right.marketingText}}</p>
        </a>
    </article>
</div>
