// ng
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { Subscription } from "rxjs";
// service
import { SearchService } from "@shared/header/header-base/search.service";
// model
import { SearchResult}  from "@shared/global-models/search-result.model";

@Component({
    selector: 'zk-search-results-page',
    templateUrl: './search-results-page.component.html',
    styleUrls: ['./search-results-page.component.scss']
})
export class SearchResultsPageComponent implements OnInit, OnDestroy {
    result: SearchResult;
    term: string;
    isError = false;

    private _routeSub: Subscription = new Subscription();

    constructor(private _activatedRoute: ActivatedRoute,
                private _searchService: SearchService) {
    }

    ngOnInit(): void {
        this._routeSub = this._activatedRoute.queryParams.subscribe((params) => {
            this.term = params['q'];
            if (this.term === undefined) {
                this.isError = true;

                return;
            }

            // reset error state needed for case: re-search on Error page
            this.isError = false;
            this.fetchArticles();
        });
    }

    fetchArticles() {
        this.result = null;

        const sub: Subscription = this._searchService.fetchAllArticlesForSearch(this.term).subscribe(
            (response: SearchResult) => {
                sub.unsubscribe();
                this.result = response;
                this._searchService.addTermToStorageHistory({suggestion: this.term});
            }, (error) => {
                sub.unsubscribe();
                this.isError = true;
                console.log('Error: ', error);
            }
        );
    }

    ngOnDestroy(): void {
        this._routeSub.unsubscribe();
    }
}
