import { Directive, Renderer2, ElementRef, HostListener, Output, EventEmitter, Input } from '@angular/core';

@Directive({
    selector: '[zkPagination]'
})
export class PaginationDirective {
    @Output() currentPageEmitter = new EventEmitter<number>();

    constructor(private renderer: Renderer2, private el: ElementRef) {}

    @HostListener('wbchange', ['$event'])
    onClick(event: any) {
        this.currentPageEmitter.emit(event.detail);
    }

    @Input()
    set currentPage(page: number) {
        this.renderer.setAttribute(this.el.nativeElement, 'current-page', page.toString());
    }
}
