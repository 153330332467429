<div class="page-wrapper">
    <h1 class="headline ui-title-heading-collection">{{activeProductGroup?.name}}</h1>

    <zk-subnavigation [showBackButton]="true"
                      [activeProductGroup]="activeProductGroup">
    </zk-subnavigation>

    <div class="wrapper">
        <wb-spinner *ngIf="isLoading" class="loading-spinner" theme="light"></wb-spinner>

        <zk-product-teaser-cards *ngIf="articlesOfPage?.articles" class="spacing-xs">
            <zk-product-teaser-card *ngFor="let article of articlesOfPage.articles"
                                    [article]="article">
            </zk-product-teaser-card>
        </zk-product-teaser-cards>

        <wb-pagination *ngIf="articlesOfPage?.articleInfo?.pages > 1"
                       class="pagination spacing-xs"
                       zkPagination
                       compact="{{isMobile}}"
                       pages="{{articlesOfPage.articleInfo.pages}}"
                       [currentPage]="articlesOfPage.articleInfo.page + 1"
                       (currentPageEmitter)="handlePagination($event)">
        </wb-pagination>
    </div>
</div>
