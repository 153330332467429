// NG
import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

// Own
import { TranslationService } from '@shared/shared-services/translate/translation.service';
import { SeoMetaData } from '@shared/components/campaign/seo.model';

@Injectable()
export class SeoService {
    constructor(private _meta: Meta,
				private _title: Title,
				private _translateService: TranslationService) {}

    updateMetaDescription(content: string) {
        this._meta.updateTag({ name: 'description', content: content });
    }

	updateOgTags(metaData: SeoMetaData, reset= false) {
        if (reset) {
            this._meta.removeTag('property = "og:title"');
            this._meta.removeTag('property = "og:description"');
            this._meta.removeTag('property = "og:image"');
            this._meta.removeTag('property = "og:type"');
            this._meta.removeTag('property = "og:url"');

            return;
        }

		this._meta.updateTag({ property: 'og:title', content: metaData.title });
		this._meta.updateTag({ property: 'og:description', content: metaData.description });
		this._meta.updateTag({ property: 'og:image', content: metaData.imageUrl });
		this._meta.updateTag({ property: 'og:type', content: 'website' });
		this._meta.updateTag({ property: 'og:url', content: window.location.href });
    }

    updatePageTitle(title: string) {
        this._title.setTitle(title);
    }

	getTranslation(meta: string): string {
		return this._translateService.translate(meta);
	}
}
