// ng
import { Component, Input, OnInit } from '@angular/core';

// Service
import { TranslationService } from '@shared/shared-services/translate/translation.service';
import { PopupModalService } from "@shared/modals/popup-modal/popup-modal.service";
import { ShoppingCartService } from '@shared/shared-services/shopping-cart/shopping-cart.service';
import { SelectedCarService } from '@shared/shared-services/selected-car/selected-car.service';

// Model
import { ShoppingCartItemModalInfoModel } from '@shared/shared-services/shopping-cart/model/shopping-cart-item-modal-info.model';

@Component({
    selector: 'zk-shopping-cart-modal',
    templateUrl: 'shopping-cart-modal.component.html',
    styleUrls: ['./shopping-cart-modal.component.scss']
})
export class ShoppingCartModalComponent implements OnInit {
    @Input()
    article: ShoppingCartItemModalInfoModel;

    modalHeadline = this.translationService.translate('SHOPPINGCART.ADDITEMFEEDBACKHEADLINEWISHLIST');
    compatibilityChecked = false;
    compatibilityPre = this.translationService.translate('SHOPPINGCART.COMPATIBILITYCHECK.PRE') + ' ';
    compatibilityLink = this.translationService.translate('PRODUCTDETAILS.COMPATIBILITYCHECK');
    compatibilityPost = ' ' + this.translationService.translate('SHOPPINGCART.COMPATIBILITYCHECK.POST');

    constructor(
        private translationService: TranslationService,
        private modalService: PopupModalService,
        private shoppingCartService: ShoppingCartService,
		private selectedCarService: SelectedCarService
    ) {}

    ngOnInit() {}

    modalOpens() {
        this.compatibilityChecked = this.selectedCarService.VIN !== null;
    }

    openCompatibilityCheck() {
        this.closeModal();
        this.modalService.open('compatibility-check');
    }

    printPDF() {
        this.closeModal();
		this.shoppingCartService.proceedToDownloadPDF();
    }

    closeModal() {
        this.modalService.close('shopping-cart-info-modal');
    }
}
