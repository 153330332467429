export class Notification {
	constructor(
		public type: NotificationTypes,
		public icon: string,
		public message: string,
		public buttonText?: string,
		public notificationAction?: NotificationActions,
		public lifeTime: number = 5000,
		public isActive: boolean = true
	) {
	}
}

export enum NotificationTypes {
	SUCCESS = 'success',
	ERROR  = 'error',
	WARNING  = 'warning',
	HIGHLIGHT = 'highlight',
	NONE = 'none'
}

export enum NotificationActions {
	GOTO_CART = 'open_cart',
	UNDO_ARTICLE_DELETE = 'undo_article_delete'
}

export enum NotificationIcons {
	CHECKMARK_CIRCLE = 'checkmark-circle.svg',
	WARNING = 'warning.svg',
	ADD_TO_CART = 'star-filled.svg',
	REMOVE_FROM_CART = 'star-outline.svg'
}
