<!-- Only needed on page / not in flyout -->
<div *ngIf="!isInHeader"
     #overscrollIndicator>
</div>

<div class="car-chooser_wrapper"
     [ngClass]="{'is-header': isInHeader,
                 'is-fixed-on-top': isFixed,
                 'force-display': openStickyCarChooser}"
     #wholeCarChooser>
    <span class="car-chooser_select-class-text is-dark"
          [ngClass]="{'ui-copy-text' : !isInHeader && isOnStartPage, 'ui-sub-heading' : isInHeader || !isOnStartPage}"
          [zkTranslate]="chooseCarText">
    </span>

    <div *ngIf="!vinLayerIsActive" class="car-chooser_class-wrapper">
        <wb-text size="l" strong class="car-chooser_car-class-label" zkTranslate="CARCHOOSER.CARCLASS"></wb-text>

        <ul class="car-chooser_class-list"
            id="zk-car-chooser-model">
            <li *ngFor="let c of carClasses"
                class="car-chooser_list-item"
                [ngStyle]="{'padding-bottom': c.isActive ? classPaddingBottom : '0', 'z-index': c.isActive ? classZIndex : 'auto'}"
                #carClassItem>
                <span class="car-chooser_car-class"
                      [ngClass]="{'is-active' : c.isActive, 'ui-minor-heading': !isInHeader, 'ui-copy-text': isInHeader}"
                      (click)="clickCarClass(c)"
                      (mouseenter)="carClassHover(c, carClassItem)">{{c.shortName}}
                </span>
            </li>
        </ul>

        <div [ngClass]="{'car-chooser_details': !isInHeader,
                         'car-chooser_details-header': isInHeader}"
             [@detailsAnimation]="{value: currentState, params: {topValue: currentTop, heightValue: currentHeight, closedTop: closedTop}}">
            <picture *ngIf="!isMobile && mostRecentCarLine"
                     class="car-chooser_picture">
                <source [srcset]="mostRecentCarLine.image480"
                        media="(max-width:479.9px)"
                        *ngIf="mostRecentCarLine.image480">
                <source [srcset]="mostRecentCarLine.image768"
                        media="(max-width:767.9px))"
                        *ngIf="mostRecentCarLine.image768">
                <source [srcset]="mostRecentCarLine.image1024"
                        media="(max-width:1023.9px)"
                        *ngIf="mostRecentCarLine.image1024">
                <source [srcset]="mostRecentCarLine.image1280"
                        media="(max-width:1279.9px)"
                        *ngIf="mostRecentCarLine.image1280">
                <!-- car image is retrieved via api call -->
                <img class="car-chooser_preview-image"
                     #carImage
                     [src]="environment.apiUrl + mostRecentCarLine.image"
                     (error)="carImage.src='ui/assets/img/fallback-565.png'"
                     [alt]="currentCarClass?.name"/>
            </picture>

            <div *ngIf="currentCarClass"
                 class="car-chooser_right-cell">
                <span class="car-chooser_selected-bodytype ui-sub-heading is-dark">{{currentCarClass?.name}}</span>

                <zk-body-type [bodyTypes]="currentCarClass?.bodyTypes"
                              [isInHeader]="isInHeader"
                              (hoverBodyType)="bodyTypeHover($event)"
                              (bodyTypeClicked)="clickBodyType($event)"
                              (dimensionsChanged)="bodyTypeDimensionsChanged($event)"
                              #bodyTypes>
                </zk-body-type>
            </div>
        </div>

        <!-- AMG cars have an additional layer-->
        <zk-model-design *ngIf="isAmg && isInHeader && currentBodyType"
                         [modelDesigns]="currentBodyType.modelDesigns"
                         (modelDesignClicked)="clickModelDesign($event)">
        </zk-model-design>

        <zk-car-line *ngIf="isInHeader && (!isAmg && currentBodyType || isAmg && currentModelDesign)"
                     [carLines]="isAmg ? currentModelDesign.carLines : currentBodyType.carLines"
                     (carLineClicked)="clickCarLine($event)">
        </zk-car-line>

        <!-- Car login / logout button on header car chooser flyout -->
        <div *ngIf="isInHeader" class="car-chooser_selection-buttons">
            <wb-button *ngIf="carIsLogged" variant="secondary" (click)="removeCar()">
                <span zkTranslate="CARCHOOSER.LOGOUT_CAR"></span>
            </wb-button>
            <wb-button variant="primary" [disabled]="carLoginDisabled" (click)="loginCar()">
                <span zkTranslate="CARCHOOSER.LOGIN_CAR"></span>
            </wb-button>
        </div>
    </div>

    <!-- Only available on page / not in flyout -->
    <div class="car-chooser_footer"
         *ngIf="!isInHeader">
        <span (click)="openVinLockOverlay()" class="cta">
            <wb-icon name="bds/chevron-right/16" style="--size: 16px"></wb-icon>
            <span zkTranslate="VINLOCK.ENTER"></span>
        </span>
    </div>

    <!-- Only available on page / not in flyout -->
    <zk-vin-lock *ngIf="!isInHeader"
                 [openToBottom]="isFixed"
                 (isOpen)="vinLockLayerIsOpen($event)"
                 #vinLock>
    </zk-vin-lock>
</div>

<!-- Extra sticky car chooser design for mobile and tablet -->
<div class="car-chooser_sticky-mobile"
     (click)="clickStickyCarChooser()"
     *ngIf="canBeSticky && isFixed && !vinLayerIsActive">
    <span zkTranslate="GENERAL.SELECTVEHICLE"></span>
    <mba-icon [icon]="openStickyCarChooser ? 'arrow-thin-up-new' : 'arrow-thin-down-new'"
          iconClass="icon_text icon_align-middle icon_hover">
    </mba-icon>
</div>
