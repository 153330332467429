<div *ngIf="teasers && teasers.length > 0" class="page-wrapper">
    <wb-heading *ngIf="teaserHeadline" size="m" class="headline">{{teaserHeadline}}</wb-heading>
    <div class="category-teaser spacing-xs">
        <a *ngFor="let teaser of teasers; let i = index"
           class="item"
           [routerLink]="['../' + teaser.link]">
            <picture *ngIf="teaser?.image"
                     class="item-picture">
                <source [srcset]="teaser.image320"
                        media="(max-width:319.9px)"
                        *ngIf="teaser.image320">
                <source [srcset]="teaser.image400"
                        media="(max-width:399.9px)"
                        *ngIf="teaser.image400">
                <source [srcset]="teaser.image480"
                        media="(max-width:479.9px)"
                        *ngIf="teaser.image480">
                <source [srcset]="teaser.image740"
                        media="(max-width:739.9px)"
                        *ngIf="teaser.image740">
                <img class="ui-picture item-picture"
                     [src]="teaser.image"
                     [alt]="teaser?.imageDescription"/>
            </picture>
            <wb-heading size="m" class="spacing-xxs">{{teaser.headline}}</wb-heading>
            <wb-text size="l" class="spacing-xxs">{{teaser.subHeadline}}</wb-text>
        </a>
    </div>
</div>
