// ng
import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output, QueryList, Renderer2,
    ViewChild,
    ViewChildren
} from '@angular/core';
import { fromEvent, Subscription } from "rxjs";
import { debounceTime, distinctUntilChanged, filter, map, switchMap } from "rxjs/operators";
import { Router } from "@angular/router";
// services
import { SearchService } from "@shared/header/header-base/search.service";
import { WbMediaQueryService } from "@shared/shared-services/media-query/wb-media-query.service";
import { TranslationService } from "@shared/shared-services/translate/translation.service";
import { CurrentRouteService } from "@shared/shared-services/current-route/current-route.service";
import { MediaQuery, ScrollLockService } from "@workbench/core";
// model
import { SearchSuggest, Suggestion } from "@shared/global-models/search-suggest.model";
import { Article } from "@shared/global-models/article/article.model";

@Component({
  selector: 'zk-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit, OnDestroy {
    @Input()
    isAccessoriesMode = true;

    @Output()
    closeMobileFlyIn: EventEmitter<void> = new EventEmitter<void>();

    @ViewChild('searchInput', { static: false })
    searchInput: ElementRef;

    @ViewChildren('resultList')
    resultList: QueryList<ElementRef>;

    @ViewChildren('historyList')
    historyList: QueryList<ElementRef>;

    isMobile = false;
    query = '';
    showInput = false;
    result: SearchSuggest;
    searchHistory: Suggestion[];
    scrollLockService = new ScrollLockService();

    private _mediaQuerySub: Subscription;
    private _openCloseSub: Subscription;
    private _listening: boolean = false;
    private _kbIdx: number = -1;
    private _suggestSub: Subscription = new Subscription();

    constructor(private _searchService: SearchService,
                private _mediaQueryService: WbMediaQueryService,
                private _router: Router,
                private _translationService: TranslationService,
                private _renderer: Renderer2,
                private _currentRouteService: CurrentRouteService) {}

    ngOnInit(): void {
        // react to show info from header
        this._openCloseSub = this._searchService.openSearch.subscribe( () => this.showInputAndFocus());

        // define rendered template
        this._mediaQuerySub = this._mediaQueryService.mediaQuery.subscribe((mq: MediaQuery) => {
            this.isMobile = mq === 'mq1' || mq === 'mq2';
        });
    }

    ngOnDestroy(): void {
        this._openCloseSub.unsubscribe();
        this._mediaQuerySub.unsubscribe();
        this._suggestSub.unsubscribe();
    }

    /**
     * Callback to open search template.
     * Sets focus to input field.
     */
    showInputAndFocus() {
        if (!this.isMobile) {
            this.showInput = true;
        }

        this.searchHistory = this._searchService.getSearchHistory()?.reverse();

        // timeout needed for WB component view update
        setTimeout(() => {
            this.searchInput.nativeElement.getInputReference().then((input) => input.focus());
        });

        this.scrollLockService ? this.scrollLockService.lock() : null;
    }

    /**
     * We use the input callback here to set up the async listener once
     * cause view element is now definitely available in DOM for Angular.
     */
    onInput() {
        // empty previous suggestions
        if (this.query.trim().length > 0) {
            this.result = null;
            this._kbIdx = -1;
        }

        if (this._listening) {
            return;
        }

        // setup listener once
        this.handleSearch();
        this._listening = true;
    }

    /**
     * Search callback on icon or search result item.
     * Routes to search result page.
     * @param term | optional
     */
    onSearch(term?: string) {
        this.close();
        this._router.navigate(['/search'], {queryParams: {'q': term ? term : this.query.trim()}}).then();
    }

    /**
     * Article number input click handler
     * @param article
     */
    routeToPdP(article: Article) {
        this.close();
        this._currentRouteService.routeToProductDetails(article);
    }

    /**
     * Enter callback on input or keyboard navigation
     */
    handleEnter() {
        const queryLength = this.isAsianLang() ? this.query.trim().length === 0 : this.query.trim().length < 3;

        // not enough search input or no selection - prevent NPE
        if (this._kbIdx === -1 && queryLength) {
            return;
        }

        // use search value from input
        if (this._kbIdx === -1) {
            this.onSearch();
            return;
        }

        // use keyboard selected term
        const term: string = this.result?.suggestions?.suggestions.length > 0 ?
            this.result.suggestions.suggestions[this._kbIdx].suggestion :
            this.searchHistory[this._kbIdx].suggestion;

        this.onSearch(term);
    }

    /**
     * Clear input callback
     */
    clearSearch() {
        this.query = '';
        this.result = null;
        this._kbIdx = -1;
    }

    /**
     * Callback for search in other mode item.
     */
    searchInOtherMode() {
        this._searchService.redirectToOtherSearchPage(this.result.existsInCollection, this.query.trim());
    }

    /**
     * Close search component callback
     */
    close() {
        this.searchHistory = null;
        // prevent that suggest response arrives later
        this._suggestSub.unsubscribe();

        if (this.isMobile) {
            this.closeMobileFlyIn.emit();
        }

        this.scrollLockService?.isLocked ? this.scrollLockService.unlock() : null;

        // timeout needed for WB component view update
        setTimeout(() => {
            this.clearSearch();
            this.showInput = false;
            // former view element ref gets destroyed on desktop
            this._listening = false;
        });

    }

    /**
     * Keyboard navigation callback
     * @param e
     */
    handleKeyboard(e: KeyboardEvent) {
        // no history or no suggests
        if (this.resultList.toArray().length === 0 && this.historyList.toArray().length === 0) {
            return;
        }

        switch (e.key) {
            case 'Down':
            case 'ArrowDown': {
                this.getNextIndex(true);
                this.hoverItem();
                break;
            }
            case 'Up':
            case 'ArrowUp': {
                if (this._kbIdx > -1) {
                    this.getNextIndex(false);
                    this.hoverItem();
                }
                break;
            }
        }
    }

    /**
     * Prevent default behaviour of WB input to put cursor pack to start on arrow down.
     * @param e
     */
    prevent(e: KeyboardEvent) {
        e.preventDefault();
    }

    isAsianLang(): boolean {
        return this._translationService.currentLang === 'zh-CN';
    }

    /**
     * Highlight item via keyboard navigation.
     */
    private hoverItem() {
        const items = this.result?.suggestions?.suggestions.length > 0 ?
            this.resultList.toArray() : this.historyList.toArray();

        items.forEach(x=> this._renderer.removeClass(x.nativeElement, 'is-selected'));

        const curItem = items[this._kbIdx].nativeElement;
        this._renderer.addClass(curItem, 'is-selected');
    }

    /**
     * Update current keyboard index
     * @param next
     */
    private getNextIndex(next: boolean) {
        next ? ++this._kbIdx : --this._kbIdx;

        // beginning or end reached
        if (this._kbIdx <= -1) {
            this._kbIdx = 0;
        } else {
            const end: number = this.result?.suggestions?.suggestions ?
                           this.result.suggestions.suggestions.length :
                           this.searchHistory.length;

            if (this._kbIdx >= end) {
                this._kbIdx = end - 1;
            }
        }
    }

    /**
     * Sets up the reactive input callback
     */
    private handleSearch() {
        this._suggestSub = fromEvent(this.searchInput.nativeElement, 'keyup')
            .pipe(
                // project only input value with removed whitespaces
                map((event: any) => event.target.value.trim()),
                // limit the unnecessary API calls until the min character limit is typed by user
                filter((res) => this.isAsianLang() ? res.length > 0 : res.length > 2),
                // instead of making an API call for every key press, wait until the user has stopped typing
                debounceTime(500),
                // prevent duplicate API calls if the user types a character and then deletes it (the input string is the same as before)
                distinctUntilChanged(),
                // used to cancel the previous HTTP request if a new keyup event occurs.
                // Helping to discard the previous search and start a new one every time the user types a new character.
                // Prevents not used API calls and reduces server load.
                switchMap((term: string) => {
                    return this._searchService.fetchSuggests(term);
                })
            ).subscribe(
            (response: SearchSuggest) => {
                // console.log('response:', response);
                this.result = response;
            },
            (error) => {
                console.log('error:', error);
                // fromEvent unsubscribes on error
                this._listening = false;
            }
        )
    }
}
