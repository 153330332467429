<wb-notification-host *ngIf="notification && notification.isActive" class="notification" position="bottom-end">
    <wb-notification mode="{{ notification.type }}" theme="dark" hide-dismiss-button>

        <wb-notification-content class="notification-content">

            <ng-container [ngSwitch]="notification.icon">
                <ng-container *ngSwitchCase="notificationIcon.ADD_TO_CART">
                    <wb-icon *ngIf="notification.icon" src="ui/assets/icons/misc/star-filled.svg" class="wb-button__icon"></wb-icon>
                </ng-container>

                <ng-container *ngSwitchCase="notificationIcon.REMOVE_FROM_CART">
                    <wb-icon *ngIf="notification.icon" src="ui/assets/icons/misc/star-outline.svg" class="wb-button__icon"></wb-icon>
                </ng-container>

                <ng-container *ngSwitchCase="notificationIcon.WARNING">
                    <wb-icon *ngIf="notification.icon" src="ui/assets/icons/misc/warning.svg" class="wb-button__icon"></wb-icon>
                </ng-container>

                <ng-container *ngSwitchCase="notificationIcon.CHECKMARK_CIRCLE">
                    <wb-icon *ngIf="notification.icon" src="ui/assets/icons/misc/checkmark-circle.svg" class="wb-button__icon"></wb-icon>
                </ng-container>
            </ng-container>

            <span>{{ notification.message }}</span>
        </wb-notification-content>

        <wb-notification-footer *ngIf="notification.buttonText">
            <wb-button size="s" variant="tertiary" class="wb-button wb-button--tertiary wb-button--theme-dark"
            (click)="notificationAction()">
                {{ notification.buttonText }}
            </wb-button>
        </wb-notification-footer>

        <wb-round-button href="#home" size="s" class="notification-button" (click)="notification.isActive = false">
            <wb-icon name="close"></wb-icon>
        </wb-round-button>
    </wb-notification>
</wb-notification-host>
