<section class="wb-grid-container">
    <mba-image-wide [imageObject]="image"></mba-image-wide>
</section>
<div class="wb-grid-container">
    <wb-heading tag="h1" size="l" class="file-not-found_headline"
        zkTranslate="ERROR.404.HEADLINE">
    </wb-heading>
    <wb-text tag="p" size="l" class="file-not-found_text"
       zkTranslate="ERROR.404.TEXT">
    </wb-text>

    <wb-button variant="secondary" theme="dark"
               [routerLink]="'/'"
               zkTranslate="GENERAL.TOSTARTPAGE">
    </wb-button>
</div>
