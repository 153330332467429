// ng
import { Directive, HostListener, Input } from '@angular/core';
// own
import { GoogleAnalyticsService } from '@shared/shared-services/google-analytics/google-analytics.service';

@Directive({ selector: '[zkTrackClick]' })
export class TrackClickDirective {
    /**
     * a.k.a. eventName: The value that will appear as the event action in Google Analytics Event reports.
     */
    @Input('zkTrackClick')
    zkTrackClick: string;

    /**
     * a.k.a. eventCategory: The category of the event.
     */
    @Input('zkTrackClickPosition')
    zkTrackClickPosition: string;

    /**
     * a.k.a. eventLabel The label of the event.
     */
    @Input('zkTrackClickLabel')
    zkTrackClickLabel: string;

    /**
     * a.k.a. eventValue A non-negative integer that will appear as the event value.
     */
    @Input('zkTrackClickValue')
    zkTrackClickValue: number;

    constructor(private _gaService: GoogleAnalyticsService) {}

    @HostListener('click')
    onElementClick() {
        this._gaService.trackEvent(this.zkTrackClick, this.zkTrackClickPosition, this.zkTrackClickLabel, this.zkTrackClickValue);
    }
}
