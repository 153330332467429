import { Component } from '@angular/core';

@Component({
    selector: 'zk-lifestyle',
    templateUrl: './lifestyle.component.html',
    styleUrls: ['./lifestyle.component.scss']
})
export class LifestyleComponent {

}
