<zk-single-image-stage [image]="image"
                       [headline]="headlineForSingleStageWide">
</zk-single-image-stage>

<div class="model-start-page page-wrapper">
    <div *ngIf="co2Label && !isAmg" class="co2">
        <wb-text size="l">{{co2Label}}</wb-text>
        <wb-text size="m" zkTranslate="CO2LABEL.HINT" class="co2Hint"></wb-text>
    </div>
    <wb-text *ngIf="co2Label && isAmg" [innerHTML]="co2Label"
             class="co2"
             size="l">
        {{co2Label}}
    </wb-text>

    <zk-error-message *ngIf="displayErrorMessage && errorCode"
                      [errorCode]="errorCode">
    </zk-error-message>

    <wb-heading tag="h2" size="m" zkTranslate="GENERAL.OURHIGHLIGHTFORYOU" class="highlight-heading"></wb-heading>

    <zk-product-teaser-big-with-price [article]="highlightArticleConfig"
                                      [buttonLinkLabel]="moreInfo"
                                      (onProductClick)="routeToProductDetails($event)"
                                      class="spacing-s">
    </zk-product-teaser-big-with-price>

    <zk-product-overview class="spacing-xl"></zk-product-overview>

</div>

<section class="page-wrapper model-page-highlights">
    <div class="wb-grid-container">
        <wb-heading tag="h3" size="m" zkTranslate="GENERAL.HIGHLIGHTS"></wb-heading>
        <zk-product-teaser-cards class="spacing-s">
            <zk-product-teaser-card *ngFor="let article of highlightArticles"
                                    [article]="article"
                                    [isCollection]="false"
                                    zkTrackClick="highlights-click">
            </zk-product-teaser-card>
        </zk-product-teaser-cards>
    </div>
</section>

