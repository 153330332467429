// Ng
import { Component } from '@angular/core';
import { Router } from '@angular/router';

// Models
import { BodyType, ModelDesign } from '@shared/components/car-chooser/models';
import { PopupModalService } from "@shared/modals/popup-modal/popup-modal.service";

// Services
import { SelectedCarService } from '@shared/shared-services/selected-car/selected-car.service';
import { Co2Service } from '@shared/shared-services/co2-label/co2.service';

@Component({
	selector: 'zk-model-design-chooser-modal',
	templateUrl: './model-design-chooser-modal.component.html',
	styleUrls: ['./model-design-chooser-modal.component.scss']
})
export class ModelDesignChooserModalComponent {
    mostRecentModelDesign: ModelDesign;
	selectedBodyType: BodyType;
	co2Label: string;

	constructor(private _selectedCarService: SelectedCarService,
				private _modalService: PopupModalService,
				private _router: Router,
				private _co2Service: Co2Service) {
	}

	initComponent() {
		this.selectedBodyType = this._selectedCarService.bodyType;
        this.mostRecentModelDesign = this.selectedBodyType.modelDesigns[0];
		this.co2Label = this._co2Service.getCo2Label(this.mostRecentModelDesign.modelDesignId, true, true);
	}

    /**
     * Updates the selected car service and route to model start page
     * @param modelDesign
     */
	routeToModelStartPage(modelDesign: ModelDesign) {
		this._selectedCarService.clearVin();
		this._selectedCarService.modelDesign = modelDesign;

        this._modalService.close('car-chooser-modal');
		this._router.navigate([modelDesign.carLines[0].urlName], { queryParamsHandling: 'preserve' }).then();
	}
}
