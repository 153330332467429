export enum StorageEnum {
    VIN = 'zk_vin',
    CAR_CLASS = 'zk_car_class',
	CAR_LINE = 'zk_car_line',
    BODY_TYPE = 'zk_body_type',
    VEHICLE_TYPE = 'zk_vehicle_type',
    MODEL_TYPE = 'zk_model_type',
    SHOPPING_CART = 'zk_shopping_cart',
	ACTIVE_MARKET = 'zk_active_market',
    SEARCH_HISTORY = 'zk_search_history'
}
