<div class="page-wrapper">
    <div *ngIf="isSecondLevel">
        <h1 class="ui-title-heading-collection main-headline">{{activeProductGroup?.name}}</h1>
    </div>

    <zk-subnavigation [showBackButton]="isSecondLevel"
                      [activeProductGroup]="activeProductGroup"
                      (clicked)="scrollToCategory($event)">
    </zk-subnavigation>

    <!-- Sub Categories -->
    <section *ngFor="let singleCategory of productSubGroups" class="spacing-m">
        <wb-spinner *ngIf="isLoading" class="loading-spinner" theme="light"></wb-spinner>

        <wb-heading size="l" [id]="singleCategory.urlName" class="word-break scroll-margin">
            {{singleCategory.name}}
        </wb-heading>

        <zk-product-teaser-cards class="spacing-xs">
            <zk-product-teaser-card *ngFor="let article of singleCategory.articles"
                                    [article]="article">
            </zk-product-teaser-card>
        </zk-product-teaser-cards>

        <mba-typo-teaser-small *ngIf="singleCategory.articleInfo.more"
                               [text]="typoTeaserText + ' ' + singleCategory.name"
                               [url]="singleCategory.urlName"
                               (clicked)="routeToSubcategoryPage($event)"
                               textClass="ui-typo-teaser-collection"
                               textColor="#262626"
                               backgroundColor="#F4F4F4"
                               class="spacing-xs">
        </mba-typo-teaser-small>
    </section>
</div>
