// Ng
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';

// Own
import { SelectedCarService } from '@shared/shared-services/selected-car/selected-car.service';
import { PopupModalService } from "@shared/modals/popup-modal/popup-modal.service";
import { ShoppingCartService } from '@shared/shared-services/shopping-cart/shopping-cart.service';
import { AppService } from '../../../app.service';
import { MODE } from '@shared/global-models/mode.enum';

@Injectable()
export class ResetWarningModalService {
	isAccessoriesMode = true;

    private _urlToNavigateTo: string = '/';
    private _willRemoveWishlist = false;
    private _resetUserJourney: Subject<any> = new Subject<any>();

    constructor(
        private _selectedCarService: SelectedCarService,
        private _popupModalService: PopupModalService,
        private _router: Router,
        private _shoppingCartService: ShoppingCartService,
		private _appService: AppService
    ) {}

    get willRemoveWishlist(): boolean {
        return this._willRemoveWishlist;
    }

    /**
     * Subject to inform if user Journey is restarted
     */
    get userJourneyRestarted(): Subject<any> {
        return this._resetUserJourney;
    }

    /**
     * Tell every listener that the user decided to reset his journey.
     * Consequence is to remove the previously selected car and empty a filled wishlist on car or vehicle type (assortment) change.
     */
    resetUserJourney() {
        if (this.willRemoveWishlist) {
            this._shoppingCartService.clearShoppingCart();
        }

		this._selectedCarService.resetLoggedCarData();

        this._resetUserJourney.next();
    }

    /**
     * Informs the user that the wishlist needs to be emptied when he changes the vehicle type (assortment)
     * or that going back to start page will reset his car.
     */
    openDialog(url: string, toStartPage: boolean, vehicleTypeChange: boolean = false) {
		this.isAccessoriesMode = this._appService.appMode === MODE.ACCESSORIES;

        this._urlToNavigateTo = url;
        const hasSelectedBodyType = this._selectedCarService.bodyType !== null;
        const hasShoppingCartItems = this._shoppingCartService.currentShoppingCart && this._shoppingCartService.currentShoppingCart.items.length > 0;

        this._willRemoveWishlist = vehicleTypeChange && hasShoppingCartItems;
        const willRemoveCar = hasSelectedBodyType && toStartPage || vehicleTypeChange;

        if ((this._willRemoveWishlist || willRemoveCar) && this.isAccessoriesMode) {
            this._popupModalService.open('reset-warning-modal');
        } else  {
            this.navigateToDestination();
        }
    }

    /**
     * Navigate to the previously saved destination
     */
    navigateToDestination() {
        this._router.navigate([this._urlToNavigateTo]);
    }
}
