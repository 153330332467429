import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CanActivateAmgGuard } from "@shared/shared-services/guards/can-activate-amg-guard";
// page components
import { ModelStartPageComponent } from './pages/model-start-page/model-start-page.component';
import { AccessoriesComponent } from './accessories.component';
import { PassengercarsComponent } from './passengercars/passengercars.component';
import { CategoryPageComponent } from './pages/category-page/category-page.component';
import { SubCategoryPageComponent } from './pages/sub-category-page/sub-category-page.component';
import { ProductDetailsPageComponent } from './pages/product-details-page/product-details-page.component';
import { VansComponent } from './vans/vans.component';
import { StartPageComponent } from './pages/start-page/start-page.component';
import { FileNotFoundPageComponent } from '../../pages/404/file-not-found-page.component';
import { AmgComponent } from './amg/amg.component';
import { CampaignComponent } from "../../pages/campaign/campaign.component";
import { WishlistComponent } from '@shared/components/wishlist/wishlist.component';
import { SearchResultsPageComponent } from '../../pages/search-results-page/search-results-page.component';
// app mode
import { SUBMODE } from '@shared/global-models/mode.enum';

const routes: Routes = [
    {
        path: ':market', // will be a market specific splash page in the future
        children: [
            { path: '', redirectTo: 'accessories', pathMatch: 'full' },
            {
                path: 'accessories',
                component: AccessoriesComponent,
                children: [
                    // sub brand A
                    { path: '', redirectTo: SUBMODE.PASSENGERCARS, pathMatch: 'full' },
                    { path: SUBMODE.PASSENGERCARS,
                      component: PassengercarsComponent,
                      children: [
                          { path: '', component: StartPageComponent},
                          // marketing / campaign page
                          { path: 'campaign', redirectTo: '/404', pathMatch: 'full'},
                          { path: 'campaign/:id', component: CampaignComponent},
                          { path: 'wishlist', component: WishlistComponent},
                          { path: 'search', component: SearchResultsPageComponent},
                          // car related pages
                          { path: ':carclass', component: ModelStartPageComponent },
                          { path: ':carclass/:category', component: CategoryPageComponent },
                          { path: ':carclass/:category/:subcategory', component: SubCategoryPageComponent },
                          { path: ':carclass/:category/:subcategory/:product', component: ProductDetailsPageComponent },
                      ]
                    },
                    // sub brand B
                    { path: SUBMODE.VANS,
                      component: VansComponent,
                      children: [
                          { path: '', component: StartPageComponent},
                          // marketing / campaign page
                          { path: 'campaign', redirectTo: '/404', pathMatch: 'full'},
                          { path: 'campaign/:id', component: CampaignComponent},
                          { path: 'wishlist', component: WishlistComponent},
                          { path: 'search', component: SearchResultsPageComponent},
                          // vans related pages
                          { path: ':carclass', component: ModelStartPageComponent },
                          { path: ':carclass/:category', component: CategoryPageComponent },
                          { path: ':carclass/:category/:subcategory', component: SubCategoryPageComponent },
                          { path: ':carclass/:category/:subcategory/:product', component: ProductDetailsPageComponent },
                      ]
                    },
                    // sub brand C
                    { path: SUBMODE.AMG,
                      component: AmgComponent,
                      canActivate: [CanActivateAmgGuard],
                      children: [
                          { path: '', component: StartPageComponent},
						  // marketing / campaign page
						  { path: 'campaign', redirectTo: '/404', pathMatch: 'full'},
						  { path: 'campaign/:id', component: CampaignComponent},
                          { path: 'wishlist', component: WishlistComponent},
                          { path: 'search', component: SearchResultsPageComponent},
                          // amg cars related pages
                          { path: ':carclass', component: ModelStartPageComponent },
                          { path: ':carclass/:category', component: CategoryPageComponent },
                          { path: ':carclass/:category/:subcategory', component: SubCategoryPageComponent },
                          { path: ':carclass/:category/:subcategory/:product', component: ProductDetailsPageComponent },
                      ]
                    }
                    // to be enhanced for further sub brands of assortment accessories
                ]
            },
            { path: '404', component: FileNotFoundPageComponent },
            { path: '**', redirectTo: '404', pathMatch: 'full' }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    providers: [CanActivateAmgGuard]
})
export class AccessoriesRoutingModule {}
