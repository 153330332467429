// Modules - ng
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// Modules - own
import { ModalModule } from '@shared/modals/modal.module';
import { ComponentsModule } from '@shared/components/components.module';
// Services
import { ProductGroupsService } from '@shared/shared-services/product-groups/product-groups.service';
import { SearchService } from '@shared/header/header-base/search.service';
// Components
import { HeaderBaseComponent } from './header-base/header-base.component';
import { LanguageSwitchComponent } from '@shared/header/language-switch/language-switch.component';
import { SearchComponent } from './search/search.component';
// Pipes
import { HighlightTextPipe } from './search/highlight-text.pipe';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        ModalModule,
        ComponentsModule
    ],
    declarations: [
        HeaderBaseComponent,
        LanguageSwitchComponent,
        SearchComponent,
        HighlightTextPipe
    ],
    exports: [
        HeaderBaseComponent
    ],
    providers: [
        ProductGroupsService,
        SearchService
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class HeaderModule {}
