import { Injectable } from '@angular/core';
import { MediaQuery, MediaQueryChangeEvent, MediaQueryService } from '@workbench/core';
import { BehaviorSubject } from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class WbMediaQueryService {
    mediaQueryService = new MediaQueryService(window);
    initialMediaQuery: MediaQuery = this.mediaQueryService.getCurrentMediaQuery();
    mediaQuery = new BehaviorSubject<MediaQuery>(this.initialMediaQuery);

    constructor() {
        window.addEventListener('wbresize', ((e: CustomEvent<MediaQueryChangeEvent>) => {
            this.mediaQuery.next(e.detail.current);
        }) as EventListener);
    }
}
