import { NgModule } from '@angular/core';

import { TranslationService } from './translation.service';
import { TranslateDirective } from './translate.directive';

@NgModule({
    imports: [],
    exports: [TranslateDirective],
    declarations: [TranslateDirective],
    providers: [TranslationService]
})
export class TranslateModule {}
