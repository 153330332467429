export enum MODE {
    ACCESSORIES = '/accessories/',
    LIFESTYLE = '/lifestyle/',
    NONE = ''
}

export enum SUBMODE {
    NONE = '',
    // accessories sub brands
    PASSENGERCARS = 'passengercars',
    VANS = 'vans',
    AMG = 'amg',
    // lifestyle sub brands
    COLLECTION = 'collection'
}
