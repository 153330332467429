<div class="wb-grid-container vin-lock">
    <wb-text tag="span" size="l" *ngIf="noVinProvidedOrWrong" [zkTranslate]="errorMessageKey"></wb-text>

    <div *ngIf="showLoadingSpinner"
         class="vin-lock_loading-spinner">
        <mba-loading-spinner [showLoadingPercent]="true"
                             [loadingPercent]="loadingSpinnerText"
                             [positionAbsolute]="false"
                             [leftFiftyPercent]="false">
        </mba-loading-spinner>
        <wb-text tag="p" size="l">{{progressFeedbackText}}</wb-text>
    </div>
</div>

