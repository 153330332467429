<div class="start-page wb-grid-container is-full-width-on-small-screen"
         [ngStyle]="slides.length === 0 && {'overflow': 'visible'}">
    <mba-slider *ngIf="slides.length > 0"
                [slides]="slides"
                [config]="sliderConfig"
                (onClickSliderButton)="routeToPage($event)">
    </mba-slider>
    <div [ngStyle]="{'min-height.px': heightOfStickyCarChooser}">
        <zk-car-chooser [canBeSticky]="true"
                        [isOnStartPage]="true"
                        (offsetHeightWhenSticky)="preventLayoutJump($event)">
        </zk-car-chooser>
    </div>
</div>
