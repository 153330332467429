<div class="body-type_wrapper"
     [ngClass]="{'is-header' : isInHeader}">
    <wb-text size="l" strong class="body-type_label" zkTranslate="CARCHOOSER.BODYTYPE"></wb-text>

    <ul class="body-type_list"
        id="zk-car-chooser-body-type"
        #bodyType>
        <li *ngFor="let singleBodyType of bodyTypes"
            class="body-type_list-item"
            [ngClass]="{'is-active' : singleBodyType.isActive}">
            <a (click)="clickBodyType(singleBodyType)"
               class="body-type_link"
               (mouseenter)="emitHover(singleBodyType)">
                <wb-text size="l" [ngClass]="{ 'is-dark' : !isInHeader }">{{singleBodyType.name}}</wb-text>
            </a>
        </li>
    </ul>
</div>
