import { UrlSerializer, UrlTree, DefaultUrlSerializer } from '@angular/router';
import { Injectable } from '@angular/core';

/**
 * Custom Url Serializer which relies on the default serializer
 */
@Injectable()
export class CustomUrlSerializer implements UrlSerializer {
    // Default serializer
    private defaultUrlSerializer: DefaultUrlSerializer;
    constructor() {
        this.defaultUrlSerializer = new DefaultUrlSerializer();
    }

    /**
     * Takes the URL, decodes it and parses it with the default parser
     * @param url Url to parse
     */
    parse(url: string): UrlTree {
        let urlToParse: string = url;
        // Decoding is needed for the encoded symbols like ©
        // See Ticket ZKDEV-603 for more information
        if (url.indexOf('%20') > -1) {
            urlToParse = decodeURIComponent(url.replace(/\+/g, ' '));
        }

        return this.defaultUrlSerializer.parse(urlToParse);
    }

    /**
     * Let the default serializer take care of the serialization
     * @param tree Url tree to serialize
     */
    serialize(tree: UrlTree): string {
        return decodeURI(this.defaultUrlSerializer.serialize(tree));
    }
}
