// ng
import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
// service
import { CarChooserService } from '@shared/components/car-chooser/car-chooser.service';
import { SelectedCarService } from '@shared/shared-services/selected-car/selected-car.service';
import { ViewportService } from '@mbcs/mbcs-lib';
import { TranslationService } from '@shared/shared-services/translate/translation.service';
import { CarChooserHelperService } from '@shared/components/car-chooser/car-chooser-helper.service';
// model
import { VinLockProgressStepsModel } from '@shared/components/vin-lock/vin-lock-progress-steps.model';

@Component({
    selector: 'zk-vin-lock',
    templateUrl: 'vin-lock.component.html',
    styleUrls: ['./vin-lock.component.scss']
})
export class VinLockComponent implements OnInit {
    @Input()
    openToBottom: boolean = false;

    @ViewChild('element')
    container: ElementRef;

    @Output()
    isOpen: EventEmitter<boolean> = new EventEmitter<boolean>();

    isVinLockUnsuccessful = false;
    showLoadingSpinner = false;
    state = 'CLOSE';
    progressFeedbackText = '';
    loadingSpinnerText = '';
    errorMessage = '';
    showDescription = false;
    placeholder = 'z.B. WDD1690071J236589';

    constructor(
        private carChooserService: CarChooserService,
        private carChooserHelperService: CarChooserHelperService,
        private viewportService: ViewportService,
        private selectedCarService: SelectedCarService,
        private router: Router,
        private componentReference: ElementRef,
        private translationService: TranslationService
    ) {}

    ngOnInit() {
        // vin description image is only for certain markets needed
        switch (this.translationService.currentLang) {
            case 'de-DE' : this.showDescription = true; break;
            case 'de-MB' : this.showDescription = true; break;
            case 'en-MB' : this.showDescription = true; break;
            case 'de-CH' : this.showDescription = true; break;
            case 'fr-CH' : this.showDescription = true; break;
            case 'it-CH' : this.showDescription = true; break;
            case 'en-GB' : {
                this.showDescription = false;
                this.placeholder = 'e. g. W238 WK';
                break;
            }
            default: this.showDescription = false;
        }
    }

    /**
     * Called by parent - Car Chooser component
     */
    open() {
        this.state = 'OPEN';
        this.isOpen.emit(true);
        // timeout needed to scroll component into view when animation finished so position is defined - especially needed for ux mobile
        setTimeout(() => {
            const bodyRect = document.body.getBoundingClientRect().top;
            const elemRect = this.container.nativeElement.getBoundingClientRect().top;
            this.viewportService.scrollTop(elemRect - bodyRect, true);
        }, 200);
    }

    close() {
        this.isVinLockUnsuccessful = false;
        this.state = 'CLOSE';

        setTimeout(() => {
            this.isOpen.emit(false);
        }, 1000);
    }

    onLockVin(vin: string, event: any) {
        event.stopImmediatePropagation();
        // ZKDEV-1583: remove non-alphanumeric, whitespaces and underscore via copy&paste before request to API. Allow "Umlaute" for Licence plate.
        const regex = /[^\w)\u00C0-\u00FF]|[_]/g;
        const escapedVIN = vin.replace(regex,'');

        if (escapedVIN.length === 0) {
            this.isVinLockUnsuccessful = true;
        } else  {
            this.showLoadingSpinner = true;
            this.requestVinCheck(escapedVIN);
        }
    }

    /**
     * Click outside of parent car chooser should close VIN Layer
     * @param event
     */
    @HostListener('document:mousedown', ['$event'])
    listenToDocumentClicks(event) {
        if (this.state === 'OPEN' && !this.componentReference.nativeElement.parentElement.contains(event.target)) {
            this.close();
        }
    }

    private requestVinCheck(inputVin: string) {
        const sub: Subscription = this.carChooserService.lockVehicleByVin(inputVin, true).subscribe(
            (response: HttpResponse<VinLockProgressStepsModel>) => {
                if (response.status === 202) {
                    // give feedback to the user about the current step (because this call takes some time)
                    this.progressFeedbackText = this.translationService.translate('LOADINGSPINNER.PROGRESS.' + response.body.name.toUpperCase());
                    this.loadingSpinnerText = response.body.step + '/' + response.body.totalSteps;
                    // trigger next step
                    if (sub) {
                        sub.unsubscribe();
                    }
                    this.requestVinCheck(inputVin);
                } else {
                    // Finished: If it is not 202 we expect it to be 200
                    if (sub) {
                        sub.unsubscribe();
                    }
                    this.showLoadingSpinner = false;
                    // save valid VIN / Licence plate and try to log in according car
                    this.selectedCarService.VIN = inputVin;
                    this.selectedCarService.selectCarByVINResponse(response.body);

                    // reload current page with new logged in car - if it was successful
                    if (!this.selectedCarService.carLine) {
                        this.isVinLockUnsuccessful = true;
                        this.errorMessage = this.translationService.translate('VINLOCK.NOCARFOUND');
                    } else {
                        this.router.navigate([this.carChooserHelperService.updateCurrentRoute(this.selectedCarService.carLine)], {
                            queryParamsHandling: 'preserve'
                        });
                    }
                }
            },
            (error) => {
                if (sub) {
                    sub.unsubscribe();
                }
                this.showLoadingSpinner = false;
                this.isVinLockUnsuccessful = true;

                switch (error.status) {
                    case 400: this.errorMessage = this.translationService.translate('VINLOCK.NOCARFOUND'); break;
                    case 404: this.errorMessage = this.translationService.translate('VINLOCK.NOCARFOUND'); break;
                    case 500: this.errorMessage = error.error.detail; break;
                    default:  this.errorMessage = this.translationService.translate('ERRORMESSAGE.SERVICEUNAVAILABLE');
                }

                console.log('Error getting VIN:', error);
            }
        );
    }
}
