// ng
import { Injectable } from '@angular/core';
// model
import { ProductGroup } from '@mbcs/mbcs-lib';

@Injectable()
export class GroupMapperService {
    private _groupIdMap: Map<string, string> = new Map<string, string>();
    private _groupNameMap: Map<string, string> = new Map<string, string>();
    private _groupUrlNameMap: Map<string, string> = new Map<string, string>();
    private _groupUrlNameIdMap: Map<string, string> = new Map<string, string>();
    private _groupTypeMap: Map<string, string> = new Map<string, string>();

    /**
     * lifestyle / collection categories svg icon map
     */
    groupIdIconMap: Map<string, string> = new Map<string, string>([
            ['994305', 'accessories'],
            ['994301', 'clothing'],
            ['994307', 'bike'],
            ['994303', 'caps'],
            ['994313', 'electro'],
            ['990015', 'free-time'],
            ['994314', 'deco'],
            ['994306', 'kids'],
            ['994312', 'media'],
            ['994308', 'model-cars'],
            ['994304', 'luggage'],
            ['994300', 'watches']
        ]
    );

    constructor() {}

    /**
     * Define the group maps once.
     * @param {ProductGroup[]} items
     */
    set groupMaps(items: ProductGroup[]) {
        if (items && items.length > 0) {
            this.clearAllMaps();
            // 1st level
            for (const x of items) {
                this._groupIdMap.set(x.groupId, x.urlName);
                this._groupNameMap.set(x.name, x.groupId);
                this._groupUrlNameIdMap.set(x.urlName, x.groupId);
                this._groupUrlNameMap.set(x.urlName, x.name);

                if (x.type) {
                    this._groupTypeMap.set(x.groupId, x.type);
                }

                // 2nd level
                const subGroups: ProductGroup[] = x.subGroups;
                if (subGroups && subGroups.length > 0) {
                    for (const y of subGroups) {
                        this._groupIdMap.set(y.groupId, y.urlName);
                        // name is not unique and can appear in multiple sub groups (for instance 'tires')
                        // that´s why we concat the name with pipe to make the keys uniques
                        this._groupNameMap.set(x.name + '|' + y.name, y.groupId);
                        this._groupUrlNameIdMap.set(x.urlName + '|' + y.urlName, y.groupId);
                        this._groupUrlNameMap.set(x.urlName + '|' + y.urlName, y.name);

                        if (y.type) {
                            this._groupTypeMap.set(y.groupId, y.type);
                        }

                        // 3rd level (collection articles)
                        const subSubGroups: ProductGroup[] = y.subGroups;
                        if (subSubGroups && subSubGroups.length > 0) {
                            for (const z of subSubGroups) {
                                this._groupIdMap.set(z.groupId, z.urlName);
                                this._groupNameMap.set(x.name + '|' + y.name + '|' + z.name, z.groupId);
                                this._groupUrlNameIdMap.set(x.urlName + '|' + y.urlName + '|' + z.urlName, z.groupId);
                                this._groupUrlNameMap.set(x.urlName + '|' + y.urlName + '|' + z.urlName, z.name);

                                if (z.type) {
                                    this._groupTypeMap.set(z.groupId, z.type);
                                }
                            }
                        }
                    }
                }
            }

            // console.log('ID to URL Name: ', this._groupIdMap);
            // console.log('Name to ID: ', this._groupNameMap);
            // console.log('URL Name to ID: ', this._groupUrlNameIdMap);
            // console.log('URL Name to Name: ', this._groupUrlNameMap);
        }
    }

    /**
     * Returns the url name of the group with the given id.
     * @param {string} id
     * @returns {string | undefined}
     */
    getGroupUrlNameFromGroupId(id: string): string {
        return this._groupIdMap.get(id);
    }

    /**
     * Returns the id of the group with the given (readable) name.
     * @param {string} mainGroupName
     * @param {string} subGroupName
     * @param {string} subSubGroupName
     * @returns {string | undefined}
     */
    getGroupIdFromGroupName(mainGroupName: string, subGroupName?: string, subSubGroupName?: string): string {
        let search: string = subGroupName ? mainGroupName + '|' + subGroupName : mainGroupName;
        if (subSubGroupName) {
            search += '|' + subSubGroupName;
        }

        return this._groupNameMap.get(search);
    }

    /**
     * Returns the id of the group with the given (URL) name.
     * @param {string} mainGroupUrlName
     * @param {string} subGroupUrlName
     * @param {string} subSubGroupUrlName
     * @returns {string | undefined}
     */
    getGroupIdFromGroupUrlName(mainGroupUrlName: string, subGroupUrlName?: string, subSubGroupUrlName?: string): string {
        let search: string = subGroupUrlName ? mainGroupUrlName + '|' + subGroupUrlName : mainGroupUrlName;
        if (subSubGroupUrlName) {
            search += '|' + subSubGroupUrlName;
        }

        return this._groupUrlNameIdMap.get(search);
    }

    /**
     * Returns the readable name of the group with the given url name.
     * @param {string} mainGroupUrlName
     * @param {string} subGroupUrlName
     * @param {string} subSubGroupUrlName
     * @returns {string | undefined}
     */
    getGroupNameFromUrlName(mainGroupUrlName: string, subGroupUrlName?: string, subSubGroupUrlName?: string): string {
        let search: string = subGroupUrlName ? mainGroupUrlName + '|' + subGroupUrlName : mainGroupUrlName;
        if (subSubGroupUrlName) {
            search += '|' + subSubGroupUrlName;
        }

        return this._groupUrlNameMap.get(decodeURIComponent(search));
    }

    /**
     * Returns the group type by the unique group id if the group has a type assigned.
     * @param {string} mainOrSubGroupId
     * @returns {string | undefined}
     */
    getGroupTypeById(mainOrSubGroupId: string): string {
        return this._groupTypeMap.get(mainOrSubGroupId);
    }

    /**
     * Helper function that cleans the map. Needed when language changes.
     */
    private clearAllMaps() {
        this._groupIdMap.clear();
        this._groupNameMap.clear();
        this._groupUrlNameMap.clear();
        this._groupUrlNameIdMap.clear();
        this._groupTypeMap.clear();
    }
}
