<zk-popup-modal id="car-chooser-modal"
                 (onOpen)="initComponent()"
                 [headline]="selectedBodyType?.name"
                 [showCloseButton]="true">
    <div modalContent *ngIf="selectedBodyType">
        <img #carImage
             class="car-chooser-modal_preview-image"
             [src]="mostRecentModelDesign.image"
             (error)="carImage.src='ui/assets/img/fallback-565.png'"
             [alt]="mostRecentModelDesign.name"/>

        <div class="car-chooser-modal_buttons">
             <span class="button"
                   *ngFor="let modelDesigns of selectedBodyType.modelDesigns"
                   (click)="routeToModelStartPage(modelDesigns)">{{modelDesigns.name}}
             </span>
        </div>
        <wb-text size="l" *ngIf="co2Label" class="car-chooser-modal-co2" [innerHTML]="co2Label"></wb-text>
    </div>
</zk-popup-modal>
