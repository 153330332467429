import { Component, Input } from '@angular/core';

// own
import { StageSeparator } from '@shared/components/campaign/stage-separator/stage-separator.model';

@Component({
	selector: 'zk-stage-separator[class=campaign-component]',
	templateUrl: './stage-separator.component.html',
	styleUrls: ['./stage-separator.component.scss']
})
export class StageSeparatorComponent {
	@Input() data: StageSeparator;
}
