// ng
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

// own
import { TranslatedItems } from '@shared/global-models/translateItems.model';

@Injectable()
export class TranslationService {
    private translationSheet: Object;
    private _currentLang: string;

    private readonly EMPTY_STRING_WANTED: string = 'EMPTY_STRING_WANTED';

    constructor(private httpClient: HttpClient) {}

    get currentLang(): string {
        return this._currentLang;
    }

    set currentLang(newLang: string) {
        this._currentLang = newLang;
    }

    set translations(newTranslations: Object) {
        this.translationSheet = newTranslations;
    }

    get translations() {
        return this.translationSheet;
    }

    /**
     * Triggers the loading of the language file
     */
    fillTranslation(): Observable<any> {
		return this.httpClient.get<any>('api/basic/texts/' + this._currentLang).pipe(
			tap(response => this.translations = response)
		);
    }

    translate(keyToTranslate: string): string {
        if (!this.translations || !keyToTranslate) {
            return null;
        }

        let returnValue: string;
        const splittedKey = keyToTranslate.split('.');

        if (splittedKey.length > 1) {
            let currentObject: any = this.translations;
            for (const singleKey of splittedKey) {
                if (!currentObject) {
                    break;
                }
                currentObject = currentObject[singleKey];
            }

            returnValue = currentObject;
        } else {
            returnValue = this.translations[keyToTranslate];
        }

        if (returnValue === this.EMPTY_STRING_WANTED) {
            return '';
        }

        if (!returnValue) {
            // returnValue = 'LOCALE "' + keyToTranslate + '" NOT FOUND';
            returnValue = keyToTranslate;
        }

        return returnValue;
    }

    /**
     * Translates 0...N groups, articles and carLines by their unique IDs.
     * @param targetLangMarket | The language to translate to. Example: en-MB
     * @param query | GET query. Example: ?carLines=0109,0223&articles=B67997895,B67997896&groups=990015,03
     */
    translateItems(targetLangMarket: string, query: string): Observable<TranslatedItems> {
        return this.httpClient.get<TranslatedItems>(`api/basic/data/${targetLangMarket}${query}`);
    }
}
