<div class="page-wrapper">
    <ng-container *ngIf="isError">
        <wb-heading tag="h1" size="l" zkTranslate="SEARCH.ERROR_HEADLINE"></wb-heading>
        <wb-text size="l" zkTranslate="SEARCH.ERROR_TEXT" class="spacing-xxs"></wb-text>
    </ng-container>

    <ng-container *ngIf="!isError">
        <wb-heading tag="h1" size="l">{{headline}}</wb-heading>
        <wb-text size="l" zkTranslate="SEARCH.TIPS.HEADLINE" class="spacing-xxs"></wb-text>

        <div class="spacing-m search-tips">
            <wb-heading size="xs" class="search-tips_headline" zkTranslate="SEARCH.TIPS.TIP"></wb-heading>
            <wb-list variant="unordered">
                <wb-list-item>
                    <wb-text size="l" zkTranslate="SEARCH.TIPS.TEXT1"></wb-text>
                </wb-list-item>
                <wb-list-item>
                    <wb-text size="l" zkTranslate="SEARCH.TIPS.TEXT2"></wb-text>
                </wb-list-item>
                <wb-list-item>
                    <wb-text size="l" zkTranslate="SEARCH.TIPS.TEXT3"></wb-text>
                </wb-list-item>
            </wb-list>
        </div>

        <!-- ACC Feedback -->
        <div *ngIf="isAccessoriesMode" class="spacing-m">
            <div *ngIf="searchResult?.numberOfAccessoryArticles > 0">
                <wb-heading size="s">
                    {{searchResult.numberOfAccessoryArticles}}&nbsp;<span zkTranslate="SEARCH.N_RESULTS_ALL_VEHICLE"></span>
                </wb-heading>
                <wb-button variant="secondary" class="spacing-xs" (click)="removeVehicle()">
                    <wb-icon name="bds/close/24" class="btn-icon"></wb-icon>
                    <span zkTranslate="CARCHOOSER.LOGOUT_CAR"></span>
                </wb-button>
            </div>

            <div *ngIf="searchResult?.numberOfCollectionArticles > 0" class="spacing-m">
                <wb-heading size="s">
                    {{searchResult.numberOfCollectionArticles}}&nbsp;<span zkTranslate="SEARCH.N_RESULTS_FOR_COLL"></span>
                </wb-heading>
                <wb-button variant="secondary" class="spacing-xs" (click)="searchInOtherMode()">
                    <wb-icon src="ui/assets/icons/misc/search_collection.svg" class="btn-icon"></wb-icon>
                    <span zkTranslate="SEARCH.IN_COLL"></span>
                </wb-button>
            </div>
        </div>

        <!-- COL Feedback -->
        <div *ngIf="!isAccessoriesMode && searchResult?.numberOfAccessoryArticles > 0" class="spacing-m">
            <wb-heading size="s">
                {{searchResult.numberOfAccessoryArticles}}&nbsp;<span zkTranslate="SEARCH.N_RESULTS_ALL_VEHICLE"></span>
            </wb-heading>
            <wb-button variant="secondary" class="spacing-xs" (click)="searchInOtherMode()">
                <wb-icon name="bds/vehicle-search/24" class="btn-icon"></wb-icon>
                <span zkTranslate="SEARCH.IN_ACC"></span>
            </wb-button>
        </div>
    </ng-container>

    <wb-heading size="s" class="spacing-xl" zkTranslate="SEARCH.DISCOVER_PRODUCTS"></wb-heading>
    <zk-product-overview [showDefaultHeadline]="false"></zk-product-overview>
</div>

<div *ngIf="recommendedArticles?.length > 0" class="page-wrapper recent_articles">
    <div class="wb-grid-container">
        <wb-heading size="s" zkTranslate="GENERAL.HIGHLIGHTS"></wb-heading>
        <wb-slider class="recent_articles_slider"
                   scroll-snap grid-gutter show-dot-navigation show-arrow-navigation>
            <wb-slider-item *ngFor="let article of recommendedArticles" mq1="6" mq3="4" mq5="3">
                <zk-product-teaser-card [article]="article"
                                        [isCollection]="!isAccessoriesMode"
                                        [limitWidth]="true">
                </zk-product-teaser-card>
            </wb-slider-item>
        </wb-slider>
    </div>
</div>
