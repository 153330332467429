import { Component, Input, OnInit } from '@angular/core';

// own
import { TeaserItem } from '@shared/components/campaign/teaser-item/teaser-item.model';
import { AppService } from '../../../../app.service';

@Component({
	selector: 'zk-product-teaser-item',
	templateUrl: './teaser-item.component.html',
	styleUrls: ['./teaser-item.component.scss']
})
export class TeaserItemComponent implements OnInit {
	@Input() teaserItemContent: TeaserItem;

	urlToNavigate: string;

	constructor(private _appService: AppService) {}

	ngOnInit(): void {
        // map BE internal name to FE internal name
        if (this.teaserItemContent.articleType === 'transporter') {
            this.teaserItemContent.articleType = 'vans';
        }
        // we only support routing to articles that belong to current mode // MBCS-234
		if (this.teaserItemContent.articleType === this._appService.appSubMode) {
			this.urlToNavigate = `/products/${this.teaserItemContent.groupUrlNamePath.join('/')}/${this.teaserItemContent.urlName}`;
		}
	}
}
