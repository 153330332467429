<div class="card" *ngIf="article">
    <div class="card_wishlist">
		<span class="star"
              [ngClass]="{'is-active' : wasAddedToCart || articleVariantsInCart.length > 0}"
              (click)="addRemoveFromCart()">
        </span>
    </div>
    <div class="card_image"
         (click)="routeToProductDetails()">
        <img [src]="imageTarget"
             [alt]="article.imageDescription"
             (mouseover)="onHoverMainImage(true)"
             (mouseout)="onHoverMainImage(false)">
    </div>

    <wb-tooltip *ngIf="!isMobile()" #tooltip position="top" class="card_tooltip">
        <span style="display: none">{{article.headline}}</span>
        <div slot="content">{{article.headline}}</div>
    </wb-tooltip>

    <div class="card_wrapper">
        <div #headline class="card_headline">
            <span (mouseover)="onHoverHeadline(true)"
                  (mouseleave)="onHoverHeadline(false)">{{article.headline}}
            </span>
        </div>

        <div class="card_price">
            <span>{{setPrice()}}</span>
        </div>
    </div>

    <!-- Collection article variants -->
    <div *ngIf="article.variantPropertiesCollection?.colors?.length > 1"
         class="card_variants">

        <span *ngFor="let colorVariant of article.variantPropertiesCollection.colors; let i = index"
              class="variant"
              [ngClass]="{'disable-first': i === 0}">
			<img [src]="colorVariant.image.thumb"
                 [alt]="colorVariant.name"
                 (mouseover)="onHoverSideImage(true, colorVariant, i)"
                 (mouseout)="onHoverSideImage(false, colorVariant, i)">
		</span>
    </div>
    <div *ngIf="article.variantPropertiesCollection?.colors?.length > 1"
         class="card_variants_mobile">{{'+' + article.variantPropertiesCollection.colors.length}} <span zkTranslate="LIFESTYLE.GENERAL.COLORS"></span>
    </div>

    <!-- Accessories article variants -->
    <div *ngIf="!isCollection">
        <div *ngIf="article?.variants > 0"
             class="card_variants"
             [ngClass]="{'one-line': limitWidth}">

            <span *ngFor="let variant of article.variantArticles"
                   class="variant">
                <img [src]="variant.image320"
                     [alt]="variant.headline2"
                     (mouseover)="onHoverVariant(true, variant)"
                     (mouseout)="onHoverVariant(false, variant)"
                     (click)="routeToProductDetails(variant)">
            </span>
            <div *ngIf="!isMobile() && article.variants > VARIANTS_LIMIT"
                 class="acc_variants">{{'+' + (article.variants - VARIANTS_LIMIT)}}
            </div>
        </div>
        <div *ngIf="isMobile() && article?.variants > 0"
             class="acc_variants">{{'+' + article.variants}} <span zkTranslate="LIFESTYLE.GENERAL.COLORS"></span>
        </div>
    </div>
</div>
