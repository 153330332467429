import { Component, Input, OnInit } from '@angular/core';
import { Router } from "@angular/router";

// own
import { TeaserLarge } from '@shared/components/campaign/teaser-large/teaser-large.model';
import { AppService } from "../../../../app.service";

@Component({
	selector: 'zk-teaser-large[class=campaign-component teaser-large]',
	templateUrl: './teaser-large.component.html',
	styleUrls: ['./teaser-large.component.scss']
})
export class TeaserLargeComponent implements OnInit {

	@Input() data: TeaserLarge;

	isStacked: boolean = false;

    constructor(private _appService: AppService,
                private _router: Router) {}

	ngOnInit(): void {
		this.isStacked = !!this.data.imageForeground;
	}

    routeToArticle() {
        // map BE internal name to FE internal name
        if (this.data.articleType === 'transporter') {
            this.data.articleType = 'vans';
        }
        // we only support routing to articles that belong to current mode // MBCS-234
        if (this.data.articleType === this._appService.appSubMode) {
            const url = `/products/${this.data.groupUrlNamePath.join('/')}/${this.data.urlName}`;
            this._router.navigate([url]).then();
        }
    }
}
