<wb-spinner *ngIf="showLoadingSpinner" class="loading-spinner" theme="light"></wb-spinner>

<div *ngIf="wheelsData" class="wb-grid-container">
    <zk-wheel-filter [filterOptions]="wheelsData.rimSizes"
                     (activeFilter)="handleDiameterFilter($event)">
    </zk-wheel-filter>

    <div *ngFor="let wheel of wheelsData.articles" class="wheel">
        <zk-wheel [wheelData]="wheel"></zk-wheel>
    </div>

    <wb-pagination *ngIf="wheelsData.articleInfo.pages > 1"
                   class="pagination"
                   zkPagination
                   compact="{{isMobile}}"
                   pages="{{wheelsData.articleInfo.pages}}"
                   [currentPage]="wheelsData.articleInfo.page + 1"
                   (currentPageEmitter)="handlePagination($event)">
    </wb-pagination>
</div>
