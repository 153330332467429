// Ng
import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

// Service
import { TeaserService } from '@shared/shared-services/teaser/teaser.service';

// Model
import { CollectionCategoryTeaser } from './collection-category-teaser.model';

@Component({
    selector: 'zk-collection-category-teaser',
    templateUrl: './collection-category-teaser.component.html',
    styleUrls: ['./collection-category-teaser.component.scss']
})
export class CollectionCategoryTeaserComponent implements OnInit {
    config: CollectionCategoryTeaser;

    constructor(private _teaserService: TeaserService) {}

    ngOnInit(): void {
        this.fetchData();
    }

    scrollToCategoryFooter(): void {
        const el = document.querySelector('#zk-product-categories');
        el.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
    }

    private fetchData(): void {
        const sub: Subscription = this._teaserService.getCmsDataForCategoryTeaser().subscribe(
            (response: CollectionCategoryTeaser) => {
                sub.unsubscribe();
                this.config = response;
            },
            (error) => {
                sub.unsubscribe();
                console.log('Error: ', error);
            }
        );
    }
}
