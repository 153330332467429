import { Component, Input } from '@angular/core';

// own
import { Stage } from '@shared/components/campaign/stage/stage.model';

@Component({
	selector: 'zk-stage[class=campaign-component stage]',
	templateUrl: './stage.component.html',
	styleUrls: ['./stage.component.scss']
})
export class StageComponent {
	@Input() data: Stage;
}
