// NG
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Observable, Subject, Subscription } from 'rxjs';
// Services
import { SelectedCarService } from '@shared/shared-services/selected-car/selected-car.service';
import { ShoppingCartService } from '@shared/shared-services/shopping-cart/shopping-cart.service';
import { TranslationService } from '@shared/shared-services/translate/translation.service';
import { LocalStorageService } from '@shared/shared-services/storage/local-storage.service';
import { AppService } from "../../../app.service";
import { PopupModalService } from "@shared/modals/popup-modal/popup-modal.service";
// models
import { Article } from '@shared/global-models/article/article.model';
import { ShoppingCartData } from '@shared/shared-services/shopping-cart/model/shopping-cart-data.model';
import { CompatibilityCheckArticle } from '@shared/modals/compatibility-check-modal/models/compatibility-check-article.model';
import { VIN } from '@shared/shared-services/compatibility/vin.model';
import { CompatibilityCheckProgressStepsModel } from '@shared/modals/compatibility-check-modal/models/compatibility-check-progress-steps.model';
import { StorageEnum } from '@shared/shared-services/storage/storage.enum';
import { CarLine } from '@shared/components/car-chooser/models';
import { CompatibilityCheck } from '@shared/modals/compatibility-check-modal/models/compatibility-check.model';

@Injectable()
export class CompatibilityCheckService {
    private currentArticleList: Article[] = null;
    private wasCompatibilityChecked: boolean = false;
    private compatibilitySubscriber: Subject<boolean> = new Subject<boolean>();
    private compatibilityStepFeedbackSubscriber: Subject<CompatibilityCheckProgressStepsModel> = new Subject<
        CompatibilityCheckProgressStepsModel
    >();

    constructor(
        private httpClient: HttpClient,
        private selectedCarService: SelectedCarService,
        private shoppingCartService: ShoppingCartService,
        private translationService: TranslationService,
        private popupModalService: PopupModalService,
		private _localStorageService: LocalStorageService,
        private _appService: AppService
    ) {
        this.selectedCarService.LoggedInWithVINChanged.subscribe((loggedIn: boolean) => {
            // case: VIN removed
            if (!loggedIn) {
                this.resetPreviousCompatibilityStatus();
            }
        });

        // User selects first time or changes the car - check articles of the wish list for compatibility with new car line
        this.selectedCarService.carLineSubscriber.subscribe((newCarLine: CarLine) => {
            const currentShoppingCart: ShoppingCartData = this.shoppingCartService.currentShoppingCart;
            if (newCarLine && currentShoppingCart && !this.wasCompatibilityChecked) {
                this.checkCompatibilityOfWishlistForNewCarLine(newCarLine.carLineId, currentShoppingCart);
            }
        });
    }

    get compatibilityStepFeedback(): Subject<CompatibilityCheckProgressStepsModel> {
        return this.compatibilityStepFeedbackSubscriber;
    }

    /**
     * Gets the current articles that are displayed in the compatibility check and haven´t been added to the shopping cart yet.
     */
    get currentArticles(): Article[] {
        return this.currentArticleList;
    }

    /**
     * Sets the current articles that are displayed in the compatibility check modal.
     * Current articles are articles that haven´t been added to the shopping cart yet.
     */
    set currentArticles(newArticles: Article[]) {
        if (newArticles !== this.currentArticleList) {
            // add acc mode info
            if (newArticles) {
                newArticles.forEach(x => x.appMode = this._appService.appMode);
            }
            this.currentArticleList = newArticles;
            this.resetPreviousCompatibilityStatus();
        }
    }

    get compatibilityChecked(): boolean {
        return this.wasCompatibilityChecked;
    }

    /**
     * Gets the subject to subscribe to get information about the current compatibility status
     */
    get compatibilityChanged(): Subject<boolean> {
        return this.compatibilitySubscriber;
    }

    /**
     * Gets more information about the vehicle with the given VIN
     * @param vin Vehicle identification number to get the data from
     */
    getCarByVin(vin: string): Observable<VIN> {
		let vehicleType = this.selectedCarService?.vehicleType?.vehicleTypeId;
        // edge case: Compatibility check started from collection mode for ACC articles (mixed wishlist)
        if (!vehicleType) {
            vehicleType = this.selectedCarService.lastVehicleType.vehicleTypeId;
        }

        return this.httpClient.get<VIN>(`api/vehicle/data/${vin}/${this.translationService.currentLang}/${vehicleType}`);
    }

    /**
     * Check the compatibility of the given article with the given VIN number
     * @param vin Vehicle identification number to check the compatibility with
     */
    checkCompatibility(vin: string) {
        if (!vin) {
            return;
        }

        // Get the current shopping cart from the local storage
        const currentShoppingCart: ShoppingCartData = this.shoppingCartService.currentShoppingCart;
        const currentItemIds: string[] = [];

        // already added items
        if (currentShoppingCart) {
            for (const shoppingItem of currentShoppingCart.items) {
                currentItemIds.push(shoppingItem.articleId);
            }
        }

        // in the compatibility check the current articles (which are not yet added to the cart) are also checked
        if (this.currentArticleList && this.currentArticleList.length > 0) {
            for (const item of this.currentArticleList) {
                if (currentItemIds.findIndex((singleId) => singleId === item.articleId) === -1) {
                    currentItemIds.push(item.articleId);
                }
            }
        }

        // failsafe
        if (currentItemIds.length === 0) {
            return;
        }

        // trigger API call that consists of 4 steps
        this.triggerCartCompatibilityCheck(vin, currentItemIds);
    }

    /**
     * Update the shopping cart in the local storage with the information if the article fits or not
     * @param fittingArticles Compatibility checked articles to update the shopping cart with
     */
    updateShoppingCart(fittingArticles: CompatibilityCheckArticle[]): ShoppingCartData {
        const shoppingCart: ShoppingCartData = this._localStorageService.getItem(StorageEnum.SHOPPING_CART);

        if (shoppingCart) {
            for (const cartItem of shoppingCart.items) {
                for (const singleArticle of fittingArticles) {
                    if (cartItem.articleId === singleArticle.articleId) {
                        cartItem.fit = singleArticle.fit;
                        cartItem.groupIdPath = singleArticle.groupIdPath;
                    }
                }
            }

			this._localStorageService.setItem(StorageEnum.SHOPPING_CART, shoppingCart);
        }

        return shoppingCart;
    }

    /**
     * Handles the case when a VIN was logged in previously and then the car is changed by the user via car chooser.
     * The previously given compatibility of the current articles and the wishlist content is not valid any more.
     */
    private resetPreviousCompatibilityStatus() {
        if (this.currentArticles && this.currentArticles.length > 0) {
            this.currentArticles.forEach((article: Article) => (article.fit = undefined));
        }

        this.shoppingCartService.resetCompatibilityStatus();
        this.wasCompatibilityChecked = false;
    }

    private triggerCartCompatibilityCheck(vin: string, currentItemIds: string[], isCheckInBackground: boolean = false) {
        const param: HttpParams = new HttpParams().set('progress', 'true');
        const mode: string = this.selectedCarService?.vehicleType ? '/' + this.selectedCarService.vehicleType.vehicleTypeId : '';

        const sub: Subscription = this.httpClient
            .post<CompatibilityCheckProgressStepsModel>(
                `api/cart/check/${this.translationService.currentLang}${mode}`,
                { vin: vin, articleIds: currentItemIds },
                { observe: 'response', params: param }
            )
            .subscribe(
                (response: HttpResponse<CompatibilityCheckProgressStepsModel>) => {
                    if (response.status === 202) {
                        // inform about step finished
                        this.compatibilityStepFeedbackSubscriber.next(response.body);
                        // trigger next step
                        if (sub) {
                            sub.unsubscribe();
                        }
                        this.triggerCartCompatibilityCheck(vin, currentItemIds, isCheckInBackground);
                    } else {
                        // Finished: If it is not 202 we expect it to be 200
                        if (sub) {
                            sub.unsubscribe();
                        }
                        // inform about result is now available
                        this.compatibilityStepFeedbackSubscriber.next(response.body);
                        // update compatibility status of shown detail article on PDP
                        this.checkIfCurrentArticlesAreCompatible(response.body.articles);

                        if (isCheckInBackground) {
                            // update compatibility status of existing wishlist
                            this.updateShoppingCart(response.body.articles);
                            // if at least one article is not fitting inform user about that
                            const shoppingCart: ShoppingCartData = this._localStorageService.getItem(StorageEnum.SHOPPING_CART);
                            if (shoppingCart.items.find(x => x.fit === false)) {
                                this.popupModalService.open('wishlist-compatibility-feedback');
                            }
                        }
                    }
                },
                (error) => {
                        if (sub) {
                            sub.unsubscribe();
                        }

                        let errorMessage;
                        switch (error.status) {
                            case 400: errorMessage = this.translationService.translate('COMPATIBILITYCHECK.MODAL.VEHICLENOTIDENTIFIABLE'); break;
                            case 500: errorMessage = error.error.detail; break;
                            default: errorMessage = this.translationService.translate('ERRORMESSAGE.SERVICEUNAVAILABLE');
                        }

                        const errorResponse: CompatibilityCheckProgressStepsModel = {
                            errorMessage: errorMessage,
                            articles: undefined
                        }

                        // Inform the user about the not existing VIN
                        this.compatibilityStepFeedbackSubscriber.next(errorResponse);
                        console.log('Error checking cart for compatibility', error);
                }
            );
    }

    private checkIfCurrentArticlesAreCompatible(articles: CompatibilityCheckArticle[]) {
        let isCompatible: boolean = false;
        if (this.currentArticleList && this.currentArticleList.length > 0 && articles) {
            // find the first match
            for (const item of this.currentArticleList) {
                const responseDetailArticle: CompatibilityCheckArticle = articles.find((x) => x.articleId === item.articleId);
                isCompatible = responseDetailArticle ? responseDetailArticle.fit : false;
            }
        }

        this.wasCompatibilityChecked = true;
        // inform so that the product detail page can give a visual feedback to the user that the article is compatible
        this.compatibilitySubscriber.next(isCompatible);
    }

    private checkCompatibilityOfWishlistForNewCarLine(newCarLineId: string, currentShoppingCart: ShoppingCartData): void {
        const currentItemIds: string[] = [];

        for (const shoppingItem of currentShoppingCart.items) {
            currentItemIds.push(shoppingItem.articleId);
        }

        // case VIN log in happened - but not via compatibility modal - via car chooser
        if (this.selectedCarService.loggedInWithVIN) {
            this.triggerCartCompatibilityCheck(this.selectedCarService.VIN, currentItemIds, true);
        } else {
            const sub: Subscription = this.httpClient
                .post<CompatibilityCheck>(
                    'api/cart/check/byLine/' + this.translationService.currentLang + '/' + this.selectedCarService.vehicleType.vehicleTypeId,
                    { carLineId: newCarLineId, articleIds: currentItemIds }
                )
                .subscribe(
                    (response: any) => {
                        sub.unsubscribe();
                        // mark items as not fitting and inform user about that
                        if (response.articles && response.articles.length > 0) {
                            this.shoppingCartService.markArticlesAsNotFitting(response.articles);
                            this.popupModalService.open('wishlist-compatibility-feedback');
                        }
                    },
                    (error) => {
                        console.log('Error checking wish list by car line id: ', error);
                        sub.unsubscribe();
                    }
                );
        }

    }
}
