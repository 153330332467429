<div class="splash-page">
    <div class="split" routerLink="accessories/passengercars">
        <picture>
            <source srcset="ui/assets/img/splash-page/accessories-mobile.png" media="(max-width:767px)">
            <source srcset="ui/assets/img/splash-page/accessories-tablet.jpg" media="(max-width:1023px)">
            <img src="ui/assets/img/splash-page/accessories-desktop.jpg" alt="">
        </picture>
        <div class="text">
            <h3 class="headline" zkTranslate="SPLASHPAGE.ACCESSORIES.HEADLINE"></h3>
            <p class="copy" zkTranslate="SPLASHPAGE.ACCESSORIES.SUBHEADLINE"></p>
            <wb-button variant="secondary" size="s" class="button" theme="dark" zkTranslate="SPLASHPAGE.BUTTON"></wb-button>
        </div>
    </div>
    <div class="split" routerLink="lifestyle/collection">
        <picture>
            <source srcset="ui/assets/img/splash-page/collection-mobile.png" media="(max-width:767px)">
            <source srcset="ui/assets/img/splash-page/collection-tablet.jpg" media="(max-width:1023px)">
            <img src="ui/assets/img/splash-page/collection-desktop.jpg" alt="">
        </picture>
        <div class="text">
            <h3 class="headline" zkTranslate="SPLASHPAGE.LIFESTYLE.HEADLINE"></h3>
            <p class="copy" zkTranslate="SPLASHPAGE.LIFESTYLE.SUBHEADLINE"></p>
            <wb-button variant="secondary" size="s" class="button" theme="dark" zkTranslate="SPLASHPAGE.BUTTON"></wb-button>
        </div>
    </div>
</div>
