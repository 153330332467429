// ng
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
// models
import { CarLine } from '@shared/components/car-chooser/models';

@Component({
    selector: 'zk-car-line',
    templateUrl: 'car-line.component.html',
    styleUrls: ['./car-line.component.scss']
})
export class CarLineComponent implements OnInit {
    @Input()
    carLines: CarLine[];

    @Output()
    carLineClicked: EventEmitter<CarLine> = new EventEmitter<CarLine>();

    constructor() {}

    ngOnInit() {}

    /**
     * Handle the click event on a car line
     * @param carLine Car line to activate
     */
    activateCarLine(carLine: CarLine) {
        if (carLine.isActive) {
            return;
        }

        this.setCarLineActive(carLine);
        this.carLineClicked.emit(carLine);
    }

    /**
     * Set the given car line to active and reset every other car line
     * @param carLine Car line to set active
     */
    private setCarLineActive(carLine: CarLine): void {
        for (const cl of this.carLines) {
            cl.isActive = cl.carLineId === carLine.carLineId;
        }
    }
}
