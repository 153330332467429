// Ng
import { Injectable } from '@angular/core';

// Service
import { TranslationService } from '@shared/shared-services/translate/translation.service';

// Model
import { Co2DataModel } from './co2.model';
import co2Data from './co2-label.json';


@Injectable()
export class Co2Service {
	private _co2Data: Co2DataModel;

	constructor(private _translationService: TranslationService) {}

	loadCO2Data() {
		const currentLanguage: string = this._translationService.currentLang.includes('de') ? 'de' : 'en';
		this._co2Data = co2Data[currentLanguage];
	}

	getCo2Label(modelDesignId: string, isCarChooser: boolean, isDark: boolean): string {
		if (!this._co2Data) this.loadCO2Data();

		const modelLabel = isCarChooser ? this._co2Data.carChooser[modelDesignId] : this._co2Data.modelStartPage[modelDesignId];

		const labelParts = `${modelLabel} | <a href="${this._co2Data.linkOutUrl}" target="_blank" class="ui-standalone-link no-padding ${isDark ? 'is-dark' : ''}">${this._co2Data.linkOutName}</a>`

		return modelLabel ? labelParts : null;
	}

	getFooterCo2Label(): string {
		if (!this._co2Data) this.loadCO2Data();

		return `${this._co2Data.footerInfo.text1}
		<a class="ui-standalone-link no-padding" href="${this._co2Data.footerInfo.linkOutUrl1}" target="_blank">${ this._co2Data.footerInfo.linkOutName1 }</a>
		${this._co2Data.footerInfo.text2}
		<a class="ui-standalone-link no-padding" href="${this._co2Data.footerInfo.linkOutUrl2}" target="_blank">${ this._co2Data.footerInfo.linkOutName2 }</a>
		${this._co2Data.footerInfo.text3}`;
	}
}
