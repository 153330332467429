<section class="product-categories page-wrapper" id="zk-product-categories">
    <div *ngIf="productGroups && productGroups.length > 0">
        <wb-heading size="m" zkTranslate="GENERAL.ALLCATEGORIESOVERVIEW"></wb-heading>
        <div class="wrapper">
            <div class="category" *ngFor="let mainGroup of productGroups">
                <a class="category-name" [routerLink]="baseUrl + mainGroup.urlName">
                    {{mainGroup.name}}
                </a>
                <a class="subcategory-name" [routerLink]="baseUrl + mainGroup.urlName + '/' + subGroup.urlName"
                   *ngFor="let subGroup of mainGroup.subGroups">
                    {{subGroup.name}}
                </a>
            </div>
        </div>
    </div>
</section>
