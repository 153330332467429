<div *ngIf="image" class="single-image-stage">
    <wb-aspect-ratio ratio="4x1">
        <picture>
            <source [srcset]="image.image480 "
                    media="(max-width:480px)">
            <source [srcset]="image.image768"
                    media="(max-width:768px)">
            <source [srcset]="image.image1024"
                    media="(max-width:1024px)">
            <source [srcset]="image.image1280"
                    media="(max-width:1280px)">
            <source [srcset]="image.image1440"
                    media="(max-width:1440px)">
            <source [srcset]="image.image1680"
                    media="(max-width:1680px)">
            <img [src]="image.image"
                 alt="{{image?.imageDescription}}">
        </picture>
    </wb-aspect-ratio>

    <div class="wb-grid-container">
        <wb-heading size="l" class="headline">{{headline}}</wb-heading>
    </div>
</div>
