// ng
import { Component, OnInit } from '@angular/core';

// services
import { SelectedCarService } from '@shared/shared-services/selected-car/selected-car.service';

@Component({
    selector: 'zk-vans',
    templateUrl: './vans.component.html',
    styleUrls: ['./vans.component.scss']
})
export class VansComponent implements OnInit{
    constructor(private _selectedCarService: SelectedCarService) {}

    ngOnInit(): void {
        // react to browser back button action (ZKDEV-1658)
        if (this._selectedCarService.vehicleType?.vehicleTypeId !== 'transporter') {
            this._selectedCarService.vehicleType = this._selectedCarService.vehicleTypeTransporter;
        }
    }
}
