import { Directive, ViewContainerRef } from '@angular/core';

@Directive({selector: '[header]'})
export class HeaderRefDirective {
	constructor(public viewContainerRef: ViewContainerRef) {}
}

@Directive({selector: '[body]'})
export class BodyRefDirective {
	constructor(public viewContainerRef: ViewContainerRef) {}
}

@Directive({selector: '[footer]'})
export class FooterRefDirective {
	constructor(public viewContainerRef: ViewContainerRef) {}
}

export type templateReferences = HeaderRefDirective | BodyRefDirective | FooterRefDirective;
