import { Component, Input } from '@angular/core';

// own
import { TeaserItem } from '@shared/components/campaign/teaser-item/teaser-item.model';

@Component({
	selector: 'zk-text-teaser[class=campaign-component]',
	templateUrl: './text-teaser.component.html',
	styleUrls: ['./text-teaser.component.scss']
})
export class TextTeaserComponent {
	@Input() data: TeaserItem;
}
