// ng
import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
// model
import { Article } from '@shared/global-models/article/article.model';
import { ShoppingCartData } from '@shared/shared-services/shopping-cart/model/shopping-cart-data.model';
import { MODE } from '@shared/global-models/mode.enum';
// services
import { PopupModalService } from "@shared/modals/popup-modal/popup-modal.service";
import { ShoppingCartService } from '@shared/shared-services/shopping-cart/shopping-cart.service';
import { SelectedCarService } from '@shared/shared-services/selected-car/selected-car.service';
import { GroupMapperService } from '@shared/shared-services/group-mapper/group-mapper.service';
import { TranslationService } from '@shared/shared-services/translate/translation.service';

@Component({
    selector: 'zk-wishlist-compatibility-feedback-modal',
    templateUrl: './wishlist-compatibility-feedback-modal.component.html',
    styleUrls: ['./wishlist-compatibility-feedback-modal.component.scss']
})
export class WishlistCompatibilityFeedbackModalComponent implements OnInit {
    articlesInCart: ShoppingCartData = null;
    carName = '';
    modalHeadline = this._translationService.translate('PRODUCTDETAILS.VEHICLECOMPATIBILITY');

	get appMode() {
		return MODE;
	}

    private shoppingCartChangedSubscription: Subscription = null;

    constructor( private _modalService: PopupModalService,
                 private _shoppingCartService: ShoppingCartService,
                 private _selectedCarService: SelectedCarService,
                 private _groupMapperService: GroupMapperService,
                 private _translationService: TranslationService) { }

    ngOnInit(): void {
    }

    /**
     * Callback when the modal opens.
     */
    initComponent() {
        this.articlesInCart = this._shoppingCartService.currentShoppingCart;
        this.carName = this._selectedCarService.getSelectedCarName();
        // Update view when user removed an item from the wish list
        this.shoppingCartChangedSubscription = this._shoppingCartService.shoppingCartChanged.subscribe((response: ShoppingCartData) => {
            this.articlesInCart = response;
        });
    }

    /**
     * Builds the path for the category where the given article is in
     * @param article Shopping cart item for which an alternative must be found
     */
    goToCategory(article: Article): string {
        if (!this._selectedCarService.carLine) {
            return 'products/' + this._groupMapperService.getGroupUrlNameFromGroupId(article.groupIdPath[0]);
        }

        let groupName = this._groupMapperService.getGroupUrlNameFromGroupId(article.groupIdPath[0]);
        if (groupName) {
            const subGroupName = this._groupMapperService.getGroupUrlNameFromGroupId(article.groupIdPath[1]);
            if (subGroupName) {
                groupName = `${groupName}/${subGroupName}`
            }
        }
        const selectedCar: string = this._selectedCarService.carLine.urlName;
        // After a new car is logged in there is a possibility that the group the unfitting article belongs to does not exist for the new car
        return groupName ? `/${selectedCar}/${groupName}` : `/${selectedCar}`;
    }

    /**
     * Callback when the modal gets closed.
     */
    closeComponent() {
        if (this.shoppingCartChangedSubscription) {
            this.shoppingCartChangedSubscription.unsubscribe();
        }

        // Remove all shopping cart items which are not fitting
        this._shoppingCartService.removeNotFittingItems();
    }

    close(): void {
        this._modalService.close('wishlist-compatibility-feedback');
    }
}
