// ng
import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from "@angular/router";
// services
import { ProductListService } from '@shared/shared-services/product-list/product-list.service';
import { SelectedCarService } from '@shared/shared-services/selected-car/selected-car.service';
import { GoogleAnalyticsService } from '@shared/shared-services/google-analytics/google-analytics.service';
import { ProductGroupsService } from "@shared/shared-services/product-groups/product-groups.service";
import { TranslationService } from "@shared/shared-services/translate/translation.service";
// models
import { Article } from '@shared/global-models/article/article.model';
import { ArticleCategory } from '@shared/global-models/article/articleCategory.model';
import { ProductGroup, ViewportService } from "@mbcs/mbcs-lib";

@Component({
    selector: 'zk-product-list',
    templateUrl: 'product-list.component.html',
    styleUrls: ['./product-list.component.scss']
})
export class ProductListComponent implements OnInit, OnDestroy {
    /**
     * User changes the main category - reload according articles
     * @param val
     */
    @Input()
    set mainCategoryId(val: string) {
        if (val && val !== this._mainCategoryId) {
            this._mainCategoryId = val;
            this.activeProductGroup = this._productGroupsService.groups.find((x: ProductGroup) => x.groupId === val);
            this.setupPage();
        }
    }

    productSubGroups: ArticleCategory[] = [];
    isLoading: boolean = false;
    errorCode: number;
    activeProductGroup: ProductGroup;
    typoTeaserText = this._translationService.translate('LIFESTYLE.PDL.SEEALLPRODUCTS');

    private _articlesAmountInViewport = this._viewPortService.getCurrentViewPort() === 'mq1' ? 4 : 8;
    private _mainCategoryId: string;
    private _carSelectionSubscription: Subscription;
    private _viewPortSubscription: Subscription;

    constructor(
        private _productListService: ProductListService,
        private _productGroupsService: ProductGroupsService,
        private _selectedCarService: SelectedCarService,
        private _gaService: GoogleAnalyticsService,
        private _translationService: TranslationService,
        private _router: Router,
        private _activatedRoute: ActivatedRoute,
        private _viewPortService: ViewportService
    ) {}

    ngOnInit() {
        // User selects first time or changes the car - reload the articles for the new car
        this._carSelectionSubscription = this._selectedCarService.carLineSubscriber.subscribe((newCarLine) => {
            if (newCarLine) {
                this.setupPage();
            }
        });

        // Load different article amount for mobile and desktop
        this._viewPortSubscription = this._viewPortService.getViewportChangedObserver().subscribe((newViewPort) => {
            if (this._articlesAmountInViewport === 8 && newViewPort === 'mq1' || this._articlesAmountInViewport === 4 && newViewPort !== 'mq1') {
                this._articlesAmountInViewport = newViewPort === 'mq1' ? 4 : 8;
                this.getArticlesFromCategory();
            }
        });
    }

    ngOnDestroy(): void {
        this._carSelectionSubscription.unsubscribe();
        this._viewPortSubscription.unsubscribe();
    }

    scrollToCategory(groupId: string): void {
        const el = document.querySelector('#' + groupId);
        el.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
    }

    routeToSubcategoryPage(groupUrlName: string): void {
        this._router.navigate([groupUrlName], { relativeTo: this._activatedRoute });
    }

    private setupPage() {
        // empty page on category change
        this.isLoading = true;
        this.productSubGroups = [];
        this.getArticlesFromCategory();
        window.scrollTo(0, 0);
    }

    private getArticlesFromCategory() {
        const vehicleId = this._selectedCarService.carLine ? this._selectedCarService.carLine.carLineId : this._selectedCarService.vehicleType.vehicleTypeId;
        const sub = this._productListService
            .getArticlesFromMainCategory(this._mainCategoryId, vehicleId, this._articlesAmountInViewport, true)
            .subscribe(
                (response) => {
                    sub.unsubscribe();
                    this.isLoading = false;
                    this.productSubGroups = response;
                    this.trackArticlesForAnalytics();
                },
                (error) => {
                    sub.unsubscribe();
                    this.isLoading = false;
                    this.errorCode = error.status;                }
            );
    }

    private trackArticlesForAnalytics() {
        let visibleArticles: Article[] = [];
        this.productSubGroups.forEach( (cat: ArticleCategory) => {
            visibleArticles = visibleArticles.concat(cat.articles);
        });
        this._gaService.trackProductImpressions(visibleArticles);

    }
}
