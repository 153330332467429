<div *ngIf="filterOptions && filterOptions.length > 1"
     class="wheel-filter">
    <wb-text size="l" zkTranslate="WHEELS.WHEELDIAMETER"></wb-text>
    <mba-multiselect class="wheel-filter_options"
                     [allSelectedEqualsNoSelection]="true"
                     (clicked)="toggleFilterOption($event)">
        <mba-multiselect-item class="wheel-filter_options_item"
                              *ngFor="let option of filterOptions"
                              [title]="option.diameterText"
                              [id]="option.diameterId"
                              [isSelected]="option.active">
        </mba-multiselect-item>
    </mba-multiselect>
</div>
