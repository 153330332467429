<div #element
     class="vin-lock_wrapper"
     [ngClass]="{'open-to-bottom': openToBottom,
                 'open': state === 'OPEN'}">

    <div class="vin-lock_wrapper_container">
        <wb-icon name="bds/close/24" style="--size: 34px" class="vin-lock_close" (click)="close()"></wb-icon>

        <div class="vin-lock_form">
            <wb-text size="l" static zkTranslate="VINLOCK.DESCRIPTION"></wb-text>

            <div class="vin-lock_form_input-box spacing-xs">
                <div class="vin-lock_form_input-box_input-line">
                    <input class="vin-lock_input-vin"
                           [ngClass]="{ 'vin-lock-failed': isVinLockUnsuccessful }"
                           maxlength="17"
                           [placeholder]="placeholder"
                           zkOnlyAlphanumeric
                           (keyup.enter)="onLockVin(vinInput.value, $event)"
                           #vinInput>
                </div>
                <wb-button *ngIf="!showLoadingSpinner"
                           variant="primary"
                           size="s"
                           [disabled]="vinInput.value?.trim().length === 0"
                           (click)="onLockVin(vinInput.value, $event)"
                           zkTrackClick="fin-entered">
                    <span zkTranslate="VINLOCK.SEND"></span>
                </wb-button>
            </div>
            <wb-text size="m" *ngIf="isVinLockUnsuccessful"
                  class="is-error vin-lock_error-message">{{errorMessage}}
            </wb-text>
        </div>
        <div *ngIf="showLoadingSpinner"
             class="vin-lock_loading-spinner">
            <mba-loading-spinner [showLoadingPercent]="true"
                                 [loadingPercent]="loadingSpinnerText"
                                 [leftFiftyPercent]="false"
                                 [positionAbsolute]="false">
            </mba-loading-spinner>
            <wb-text size="m">{{progressFeedbackText}}</wb-text>
        </div>
        <div *ngIf="showDescription"
             class="vin-lock_help">
            <img src="ui/assets/img/technicalDataDetailExample.jpg"
                 alt="VIN example image">
            <wb-text size="m" static zkTranslate="VINLOCK.FURTHERINFORMATION"></wb-text>
        </div>
    </div>
</div>
