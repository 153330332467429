<div class="car-lines">

    <wb-text size="l" strong class="car-lines_column car-lines_label"
             zkTranslate="CARCHOOSER.CARLINE">
    </wb-text>

    <ul class="car-lines_list">
        <li class="car-lines_list-item"
            *ngFor="let carLine of carLines"
            [ngClass]="{'is-active' : carLine.isActive}">
            <a class="car-lines_link"
               (click)="activateCarLine(carLine)">
                <wb-text size="l">{{carLine.name}}</wb-text>
            </a>
        </li>
    </ul>
</div>
