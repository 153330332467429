// ng
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';

// modules
import { SharedModule } from '@shared/shared.module';
import { LifestyleRoutingModule } from './lifestyle-routing.module';

// components
import { CollectionComponent } from './collection/collection.component';
import { LifestyleComponent } from './lifestyle.component';
import { CollectionStartPageComponent } from './collection/collection-start-page/collection-start-page.component';
import { CollectionProductListPageComponent } from './collection/collection-product-list-page/collection-product-list-page.component';
import { CollectionSubCategoryPageComponent } from './collection/collection-sub-category-page/collection-sub-category-page.component';
import { CollectionSubSubCategoryPageComponent } from './collection/collection-sub-sub-category-page/collection-sub-sub-category-page.component';
import { CollectionProductDetailsPageComponent } from './collection/collection-product-details-page/collection-product-details-page.component';

@NgModule({
    declarations: [
        // Router outlets
        LifestyleComponent,
        CollectionComponent,
        // pages
        CollectionStartPageComponent,
        CollectionProductListPageComponent,
        CollectionSubCategoryPageComponent,
        CollectionSubSubCategoryPageComponent,
        CollectionProductDetailsPageComponent
    ],
    imports: [
        CommonModule,
        BrowserModule,
        RouterModule,
        LifestyleRoutingModule,
        SharedModule
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class LifestyleModule {}
