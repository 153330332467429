import { Component, Input } from '@angular/core';

// own
import { TeaserItem } from '@shared/components/campaign/teaser-item/teaser-item.model';

@Component({
	selector: 'zk-product-teaser-medium-campaign[class=campaign-component product-teaser-medium]',
	templateUrl: './product-teaser-medium-campaign.component.html',
	styleUrls: ['./product-teaser-medium-campaign.component.scss']
})
export class ProductTeaserMediumCampaign {
	@Input() data: TeaserItem[];
}
