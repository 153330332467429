import { Component, Input } from '@angular/core';

// own
import { TeaserItem } from '@shared/components/campaign/teaser-item/teaser-item.model';

@Component({
	selector: 'zk-product-teaser-carousel[class=campaign-component]',
	templateUrl: './product-teaser-carousel.component.html',
	styleUrls: ['./product-teaser-carousel.component.scss']
})
export class ProductTeaserCarouselComponent {

	@Input() data: TeaserItem[];

	hasGreyBackground: boolean = false;
}
