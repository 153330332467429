// ng
import {
    AfterViewChecked,
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import { Router } from '@angular/router';

// Service
import { SelectedCarService } from '@shared/shared-services/selected-car/selected-car.service';
import { CarChooserHelperService } from '@shared/components/car-chooser/car-chooser-helper.service';
import { PopupModalService } from "@shared/modals/popup-modal/popup-modal.service";

// models
import {BodyType, CarLine} from '@shared/components/car-chooser/models';

@Component({
    selector: 'zk-body-type',
    templateUrl: 'body-type.component.html',
    styleUrls: ['./body-type.component.scss', './body-type.component.header.scss']
})
export class BodyTypeComponent implements OnInit, AfterViewInit, AfterViewChecked {
    @ViewChild('bodyType', { static: true })
    bodyType: ElementRef;

    @Input()
    bodyTypes: BodyType[];
    @Input()
    isInHeader: boolean = false;

    @Output()
    hoverBodyType: EventEmitter<BodyType> = new EventEmitter<BodyType>();
    @Output()
    bodyTypeClicked: EventEmitter<BodyType> = new EventEmitter<BodyType>();
    @Output()
    dimensionsChanged: EventEmitter<any> = new EventEmitter<any>();

    height: number = 0;
    width: number = 0;

    constructor(
        private selectedCarService: SelectedCarService,
        private router: Router,
        private carChooserHelperService: CarChooserHelperService,
		private _popupModalService: PopupModalService
    ) {}


    ngOnInit() {}

    ngAfterViewInit() {
        this.setElementHeightAndWidth();
    }

    ngAfterViewChecked() {
        this.setElementHeightAndWidth();
    }

    emitHover(bodyType: BodyType) {
		this.hoverBodyType.emit(bodyType);
    }

    /**
     * Activate the body type
     * @param bodyType Body type to activate
     */
    clickBodyType(bodyType: BodyType): void {
        if (bodyType.isActive && this.isInHeader) {
            return;
        }

        // case: car chooser on (start) page - logs in the car
        if (!this.isInHeader) {
	        this.selectedCarService.clearVin();
	        this.selectedCarService.bodyType = bodyType;

            // amg cars have an additional model design layer
			if (this.selectedCarService.vehicleType.vehicleTypeId === 'amg' && bodyType.modelDesigns.length > 0) {
				this._popupModalService.open('car-chooser-modal');
			} else {
				this.routeToModelStartPage(bodyType);
			}
        // case: car chooser in header flyout - loads new car line tree - car login is via submit button
        } else {
            this.setBodyTypeActive(bodyType);
            this.bodyTypeClicked.emit(bodyType);
        }
    }

    /**
     * Set the body type to active and reset every other body type
     * @param bodyType Body type to set to active
     */
    private setBodyTypeActive(bodyType: BodyType) {
        for (const bt of this.bodyTypes) {
            bt.isActive = bt.bodyTypeId === bodyType.bodyTypeId;
        }
    }

    /**
     * Use the injected router to navigate to the model start page
     * @param bodyType Body type to indicate which vehicle must be used
     */
    private routeToModelStartPage(bodyType: BodyType) {
        if (bodyType.carLines && bodyType.carLines.length > 0) {
            this.selectedCarService.clearVin();
            const activeCarLine: CarLine = bodyType.carLines[0];
            this.router.navigate([this.carChooserHelperService.updateCurrentRoute(activeCarLine)], { queryParamsHandling: 'preserve' });
        }
    }

    private setElementHeightAndWidth() {
        // After the view is initialized we have the height and width of our objects
        if (this.bodyType) {
            // But to be sure we check if it really is available
            // And afterwards we set our height and width
            const bodyTypeElement: any = this.bodyType.nativeElement;
            if (bodyTypeElement.offsetHeight !== this.height || bodyTypeElement.offsetWidth !== this.width) {
                this.height = bodyTypeElement.offsetHeight;
                this.width = bodyTypeElement.offsetWidth;
                this.dimensionsChanged.emit({
                    height: this.height,
                    width: this.width
                });
            }
        }
    }
}
