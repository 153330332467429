import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'zk-teaser-banner',
  templateUrl: './teaser-banner.component.html',
  styleUrls: ['./teaser-banner.component.scss']
})
export class TeaserBannerComponent implements OnInit {
    @Input()
    text: string = '';

    @Input()
    wbIconName: string;

    @Input()
    iconSrc: string;

    constructor() { }

    ngOnInit(): void {}
}
