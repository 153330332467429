<article class="pdp">
      <div class="page-wrapper pdp-wrapper"
           *ngIf="articleExists; else articleNotFound">
          <wb-spinner *ngIf="showPageLoadingSpinner" class="loading-spinner" theme="light"></wb-spinner>

          <!-- Left Detail Part -->
            <div class="pdp-left">
                <wb-link (click)="goBack()" variant="standalone">
                    <wb-icon name="bds/chevron-left/24" slot="icon" style="--size: 20px"></wb-icon>
                    <span zkTranslate="PRODUCTDETAILS.BACK"></span>
                    <span>&nbsp;{{mainCategoryName}}</span>
                </wb-link>

                <wb-heading tag="h1" size="l" class="pdp_headline">{{article?.headline}}</wb-heading>
                <!-- If the article has no variants we show the headline2 as description. Otherwise a dropdown to select the variant of an article -->
                <wb-text tag="span" size="l"
                      *ngIf="!article?.variants || article?.variants === 0">{{article?.headline2}}
                </wb-text>

                <wb-select class="pdp_dropdown" *ngIf="article?.variants > 0" [label]="dropdownLabel">
                    <select (change)="switchVariant($event)">
                        <option selected [value]="article.headline2">{{article.headline2}}</option>
                        <option *ngFor="let variant of article.variantArticles"
                                [value]="variant.articleId">
                            {{variant.headline2}}
                        </option>
                    </select>
                </wb-select>

                <div *ngIf="marketData.showPrice"
                     class="pdp-left_price">
                    <wb-heading tag="span" size="m" *ngIf="pricePrefix"
                          class="pdp-left_price_prefix">{{pricePrefix}}
                    </wb-heading>
                    <wb-heading tag="span" size="m">{{article?.grossPriceText}}</wb-heading>

                    <mba-content-volume-info *ngIf="article?.contentsVolumeInfo"
                                             [volumeInfo]="article.contentsVolumeInfo">
                    </mba-content-volume-info>
                </div>

                <wb-button variant="primary" class="wb-button wb-button--primary wb-button--large pdp-left_btn"
                        (click)="onClickAddToCart(article, isCompleteWheels)"
                        [disabled]="addToCartButtonDisabled || article?.fit === false || showLoadingSpinner || showPageLoadingSpinner">
                    <wb-icon src="ui/assets/icons/misc/star-filled.svg" *ngIf="wasAddedToCart" class="wb-button__icon"></wb-icon>
                    <wb-icon src="ui/assets/icons/misc/star-outline.svg" *ngIf="!wasAddedToCart" class="wb-button__icon"></wb-icon>
                    <span zkTranslate="GENERAL.ADDTOWISHLIST"></span>
                </wb-button>

                <!-- Hazard warning for care products - legal requirement to be near CTA button -->
                <div *ngIf="article?.productType === 'CARE_PRODUCT' && article.hazardSymbols && article.hazardSymbols.length > 0"
                     class="pdp-left_pictogram">
                    <img *ngFor="let pictogram of article.hazardSymbols"
                         class="pdp-left_pictogram_icon"
                         alt="hazardSymbols"
                         [src]="pictogram.symbolUrl">
                </div>

                <wb-text size="m" *ngIf="displayErrorMessage"
                        class="is-error pdp-left_feedback"
                        zkTranslate="ERRORMESSAGE.SERVICEUNAVAILABLE">
                </wb-text>
                <wb-text size="m" *ngIf="statusMessage"
                        class="pdp-left_feedback">
                        {{statusMessage}}
                </wb-text>

                <div *ngIf="!isMobile">
                    <ng-container *ngTemplateOutlet="buttons"></ng-container>
                </div>

                <zk-shop-link *ngIf="!isMobile"></zk-shop-link>
                <zk-dealer-locator-link *ngIf="!isMobile"></zk-dealer-locator-link>
            </div>

            <!-- Right Detail Part -->
            <div class="pdp-right">
                <mba-image-article *ngIf="article?.image"
                                  [imageObject]="article"
                                  [imageAltText]="article.headline2 ? article?.headline +' '+ article?.headline2 : article?.headline">
                </mba-image-article>

                <div *ngIf="isMobile"
                     [class]="marketHasShopLink ? 'link-wrapper' : ''">
                    <zk-shop-link (isValid)="marketHasShopLink = $event" class="link-btn"></zk-shop-link>
                    <zk-dealer-locator-link class="link-btn"></zk-dealer-locator-link>
                </div>

                <wb-heading size="s" class="pdp-right_details"
                        zkTranslate="PRODUCTDETAILS.PRODUCTDETAILS"></wb-heading>
                  <div class="pdp-right_inlinetext">
                      <wb-text size="l" zkTranslate="PRODUCTDETAILS.ARTICLENUMBER"></wb-text>
                      <wb-text size="l">: {{article?.articleNumber}}</wb-text>
                  </div>
                  <wb-text size="l" class="pdp-right_description"
                        [innerHTML]="article?.description">
                  </wb-text>

                <!-- Show hazard warning pictograms only for Care Products -->
                <div *ngIf="article?.productType === 'CARE_PRODUCT' && article.hazardWarnings && article.hazardWarnings.length > 0"
                     class="pdp-right_pictograms">
                    <wb-text size="l" strong *ngFor="let headline of article?.hazardHeadlines" class="pdp-right_pictograms_headline">
                        {{headline + "! "}}
                    </wb-text>
                    <wb-text size="l" *ngFor="let warning of article.hazardWarnings">{{warning.designation}}</wb-text>
                    <div *ngIf="article.hazardSymbols && article.hazardSymbols.length > 0"
                         class="pdp-right_pictograms_list">
                        <img *ngFor="let pictogram of article.hazardSymbols"
                             class="pdp-right_pictograms_list_icon"
                             alt="hazardSymbols"
                             [src]="pictogram.symbolUrl">
                    </div>
                    <wb-text size="l" class="pdp-right_pictograms_info" zkTranslate="PRODUCTDETAILS.PICTOGRAMHINT"></wb-text>
                </div>

                <!-- Safety infos for care products -->
                <ul *ngIf="article?.productType === 'CARE_PRODUCT' && article?.noticeLinks"
                    class="pdp-right_notice-links">
                    <li *ngFor="let link of article.noticeLinks; let i = index">
                        <a href="{{link}}"
                           target="_blank"
                           rel="noopener"
                           class="ui-standalone-link">
                            <mba-icon icon="arrow-thick-right"
                                      iconClass="icon_text icon_pointer">
                            </mba-icon>
                            <!-- Show the index only if there is more than one link -->
                            <wb-text tag="span" size="l"
                                  [zkTranslate]="'PRODUCTDETAILS.SAFETYDATASHEET'">
                            </wb-text>
                            <wb-text tag="span" size="l" *ngIf="article.noticeLinks.length > 1"> {{i + 1}}</wb-text>
                        </a>
                    </li>
                </ul>

                <!--Complete-wheels special -->
                <div *ngIf="isCompleteWheels">
                    <wb-button variant="secondary"
                               class="pdp-left_btn"
                               [disabled]="addToCartButtonDisabled || article?.fit === false"
                               (click)="onClickAddToCart(article, isCompleteWheels)">
                        <span zkTranslate="WHEELS.TOWISHLIST"></span>
                    </wb-button>

                    <zk-complete-wheels-info [completeWheelData]="article">
                    </zk-complete-wheels-info>
                </div>

                <wb-heading size="s" class="pdp-right_specs"
                    zkTranslate="PRODUCTDETAILS.VEHICLECOMPATIBILITY"></wb-heading>

                <div class="pdp-right_compatibilty">
                    <!-- Active or inactive icon -->
                    <mba-icon icon="service"
                              [iconClass]="{'icon_small icon_hover' : true,
                                            'icon_disabled-inactive' : article?.fit != undefined }"
                              (click)="article?.fit === undefined && onClickCompatibilityCheck()">
                    </mba-icon>
                    <div class="pdp-right_compatibilty_text">
                          <!-- Info and Feedback text depending on if check was done or not -->
                          <wb-text tag="span" size="l"
                                   [zkTranslate]="article?.fit === undefined ? 'PRODUCTDETAILS.VEHICLECOMPATIBILITYINFO'
                                    : (article?.fit === false ? 'PRODUCTDETAILS.INCOMPATIBLEINFO' : 'PRODUCTDETAILS.COMPATIBLEINFO')">
                          </wb-text>
                          <wb-text tag="span" size="l" (click)="article?.fit === undefined && onClickCompatibilityCheck()">
                                <!-- Case: No compatibility check done -->
                                <wb-link *ngIf="article?.fit === undefined" variant="standalone" zkTranslate="PRODUCTDETAILS.COMPATIBILITYCHECK"></wb-link>
                                <!-- Case: Compatibility check done but article is not fitting -->
                                <wb-link *ngIf="article?.fit === false" variant="standalone" zkTranslate="GENERAL.SEARCHALTERNATIVE" class="spacing-xxs"
                                         [routerLink]="goToCategory(article)">
                                </wb-link>
                          </wb-text>
                    </div>
                </div>
            </div>

            <!-- Design wants that the same icons are at the bottom for MQ1 + MQ2 -->
          <div *ngIf="isMobile">
              <ng-container *ngTemplateOutlet="buttons"></ng-container>
          </div>
      </div>

      <!-- Error Feedback -->
      <ng-template #articleNotFound>
          <div class="wb-grid-container page-wrapper">
              <wb-heading tag="h1" size="l" zkTranslate="PRODUCTDETAILS.DOESNTEXISTHEADLINE"></wb-heading>
              <wb-text size="l" class="spacing-s">
                  <span zkTranslate="PRODUCTDETAILS.DOESNTEXISTINFO"></span>
                  <small>&nbsp;</small>
                  <wb-link (click)="routeToStartOrModelPage()" variant="standalone" zkTranslate="GENERAL.PAGES.STARTPAGE"></wb-link>
              </wb-text>
          </div>
      </ng-template>

      <!-- Buttons template -->
      <ng-template #buttons>
          <div class="pdp_buttons">
              <mba-button *ngIf="article?.fit === undefined"
                          icon="car-xs"
                          [isLink]="true"
                          [hasIconOnMobile]="true"
                          [hasBiggerIcon]="true"
                          (clicked)="onClickCompatibilityCheck()"
                          zkTrackClick="compatibility-check-start"
                          zkTrackClickPosition="product">
                  <span class="label" zkTranslate="PRODUCTDETAILS.FITSCAR"></span>
              </mba-button>

              <!-- CC Feedback replaces CC button -->
              <wb-tag *ngIf="article?.fit" zkTranslate="PRODUCTDETAILS.COMPATIBLE"></wb-tag>
              <wb-tag *ngIf="article?.fit === false" class="is-red" zkTranslate="PRODUCTDETAILS.INCOMPATIBLE"></wb-tag>

              <mba-button class="distance"
                          icon="downloadprint-xs"
                          [isLink]="true"
                          [hasIconOnMobile]="true"
                          [hasBiggerIcon]="true"
                          (clicked)="printPDF()"
                          zkTrackClick="pdf-download"
                          zkTrackClickPosition="product">
                  <span class="label" zkTranslate="PRODUCTDETAILS.PDFDOWNLOAD"></span>
              </mba-button>
          </div>
      </ng-template>
</article>

<section *ngIf="recommendedArticles?.length > 0" class="page-wrapper recent_articles">
    <div class="wb-grid-container">
        <wb-heading size="m" zkTranslate="PRODUCTDETAILS.CAROUSELHEADLINE"></wb-heading>
        <wb-slider class="recent_articles_slider"
                   scroll-snap grid-gutter show-dot-navigation show-arrow-navigation>
            <wb-slider-item *ngFor="let article of recommendedArticles" mq1="6" mq3="4" mq5="3">
                <zk-product-teaser-card [article]="article"
                                        [isCollection]="false"
                                        [limitWidth]="true">
                </zk-product-teaser-card>
            </wb-slider-item>
        </wb-slider>
    </div>
</section>
