// Ng
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

// Services
import { AppService } from '../../../app.service';
import { TranslationService } from '@shared/shared-services/translate/translation.service';

// Model
import { MODE, SUBMODE } from "@shared/global-models/mode.enum";

@Injectable()
export class CanActivateCollectionGuard implements CanActivate {
	constructor(private _appService: AppService,
	            private _router: Router,
	            private _translationService: TranslationService) {}

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean|UrlTree>|Promise<boolean|UrlTree>|boolean|UrlTree {
        if (!this._appService.currentMarket) {
			return this._appService.marketsLoaded.pipe(map( () => {
			   return this.redirectToAccessories();
			}));
		} else {
            return this.redirectToAccessories();
        }
	}

    /**
     * Redirect to Accessories if market has no Collection mode support / configuration.
     * Assortment splash page can´t be shown.
     * @private
     */
	private redirectToAccessories(): boolean {
        if (!this._appService.currentMarket.collectionMarket) {
            console.log('Collection mode not supported for market - redirect to Accessories');
            let url = this._translationService.currentLang + MODE.ACCESSORIES;
            // US market has only vans enabled
            url += this._appService.currentMarket.marketCode === 'US' ? SUBMODE.VANS : SUBMODE.PASSENGERCARS

            this._router.navigate([url]).then(() => { return false });
        } else {
            return true;
        }
	}
}
