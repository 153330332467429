export enum RimAxleType {
    FRONT = 'front',
    REAR = 'rear',
    LEFT = 'left',
    RIGHT = 'right',
    FAL = 'frontleft',
    FAR = 'frontright',
    RAL = 'rearleft',
    RAR = 'rearright',
    ALL = 'all',
    AAAA = 'aaaa', // same rim for all 4 wheels
    AACC = 'aacc', //  rim 2 front wheels, other rim 2 back wheels
    ABAB = 'abab', // 2 left wheels and 2 right wheels
    ABCD = 'abcd' // the rim has 4 different wheels
}
