// NG
import { Component, Input, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

// Services
import { AppService } from '../../../app.service';
import { TranslationService } from '@shared/shared-services/translate/translation.service';
import { PopupModalService } from "@shared/modals/popup-modal/popup-modal.service";
import { ShoppingCartService } from '@shared/shared-services/shopping-cart/shopping-cart.service';
import { GoogleAnalyticsService } from '@shared/shared-services/google-analytics/google-analytics.service';

// Model
import { Article } from '@shared/global-models/article/article.model';
import { RimAxleType } from '../../global-models/rimAxleType.model';

@Component({
    selector: 'zk-rim-set-quantity-modal',
    templateUrl: './rim-set-quantity-modal.component.html',
    styleUrls: ['./rim-set-quantity-modal.component.scss']
})
export class RimSetQuantityModalComponent implements OnInit {
    /**
     * Defines if the modal shows more or less options for the user
     */
    @Input()
    showAsCompleteSet: boolean;

    /**
     * Default state is false
     * If we add completeWheelsSpecial Set - we do not allow the user to remove item from the set - inform card-item to hide delete icon
     */
    @Input()
    hideDeleteIcon: boolean = false;

    /**
     * Article list to display in the modal
     * @param value
     */
    @Input()
    set rimArticle(value: Article[]) {
        if (value && value.length > 0) {
            this._rimArticle = value;
            this.setStartState();
        }
    }

    /**
     * Article set that is displayed depending on the current state
     */
    shownSet: Article[] = [];

    // Defines the options for the user to manipulate and build the shown set
    frontAxleAdded: boolean;
    rearAxleAdded: boolean;
    setQuantityOptimal: boolean;

    modalHeadline: string;

    /**
     * Pool to build the shown set from
     */
    private _rimArticle: Article[];

    constructor(
        private _translationService: TranslationService,
        private _popupModalService: PopupModalService,
        private _shoppingCartService: ShoppingCartService,
        private _gaService: GoogleAnalyticsService,
		private _appService: AppService
    ) {}

    ngOnInit() {}

    /**
     * Adds the shown articles to the shopping cart and closes the modal
     */
    onAddToCart() {
        this._shoppingCartService.addItemsToShoppingCart(this.shownSet, null, true);
        this.closeModal();
    }

    /**
     * Callback handler when an axle partner (article) is removed for 2x2 axle types
     * @param article
     */
    onDelete(article: Article) {
        if (article.axle === RimAxleType.FRONT) {
            this.shownSet = this._rimArticle.filter((item) => item.axle === RimAxleType.REAR);
            this.frontAxleAdded = false;
        } else if (article.axle === RimAxleType.REAR) {
            this.shownSet = this._rimArticle.filter((item) => item.axle === RimAxleType.FRONT);
            this.rearAxleAdded = false;
        }

        // reset eventual previously assigned quantity
        if (article.quantity > 1) {
            article.quantity = this.showAsCompleteSet ? 2 : 1;
            this.getTotalPriceForItem(article);
        }
        this.setQuantityOptimal = false;
        // at least one item should always stay in the modal
        this.setItemsNotRemovable(true);

        this.updateTracking();
    }

    /**
     * Callback handler when quantity was changed via the dropdown handler
     * @param article
     */
    onQuantityChanged(article: Article) {
        this.checkForOptimalQuantity();
    }

    /**
     * Closes the modal
     */
    closeModal() {
        this._popupModalService.close('rim-set-choose-quantity-modal');
    }

    /**
     * Adds the missing axle partner for 2x2 axle types
     * @param isRearAxle
     */
    addAxlePartner(isRearAxle: boolean) {
        this.shownSet = this._rimArticle.filter((item) => item.axle === RimAxleType.FRONT || item.axle === RimAxleType.REAR);

        if (isRearAxle) {
            this.rearAxleAdded = true;
        } else {
            this.frontAxleAdded = true;
        }

        this.setItemsNotRemovable(false);
        this.checkForOptimalQuantity();
        this.updateTracking();
    }

    /**
     * Sets the item quantity automatically depending on the set type and retrieves new total prices from the API
     */
    adjustQuantityToCompleteSet() {
        this.shownSet = this._rimArticle;
        this.shownSet.forEach((item: Article) => {
            if (item.axle === RimAxleType.ALL) {
                item.quantity = 4;
            } else if (item.axle === RimAxleType.FRONT || item.axle === RimAxleType.REAR || item.axle === RimAxleType.LEFT || item.axle === RimAxleType.RIGHT) {
                item.quantity = 2;
            } else {
                item.quantity = 1;
            }

            this.getTotalPriceForItem(item);
        });

        this.frontAxleAdded = true;
        this.rearAxleAdded = true;
        this.setQuantityOptimal = true;
        this.setItemsNotRemovable(false);
    }

    /**
     * Requests the total price of an article from the API depending on its quality
     * @param item
     */
    private getTotalPriceForItem(item: Article) {
        const sub: Subscription = this._shoppingCartService.updateSingleItem(item).subscribe(
            (response) => {
                sub.unsubscribe();
                item.totalGrossPriceText = response.totalGrossPriceText;
            },
            (error) => {
                sub.unsubscribe();
                console.log('Error updating single item total price due to quantity change:', error);
            }
        );
    }

    /**
     * Checks if an item of the set is allowed to be removed
     * @param notRemovable | Defines if the items should be removable or not via the trash can icon
     */
    private setItemsNotRemovable(notRemovable: boolean) {
        // one item/complete set can never be removed from modal
        if (this.shownSet.length === 1) {
            this.shownSet[0].notRemovable = true;
        } else {
            this.shownSet.forEach((item: Article) => (item.notRemovable = notRemovable));
        }
    }

    /**
     *  Checks if one of the articles has not the optimal quantity to build a set
     */
    private checkForOptimalQuantity() {
        if (!this.frontAxleAdded || !this.rearAxleAdded) {
            this.setQuantityOptimal = false;

            return;
        }

        this.setQuantityOptimal = true;
        this.shownSet.forEach((x: Article) => {
            if (
                (x.axle === RimAxleType.ALL && x.quantity !== 4) ||
                ((x.axle === RimAxleType.FRONT || x.axle === RimAxleType.REAR || x.axle === RimAxleType.LEFT || x.axle === RimAxleType.RIGHT) && x.quantity !== 2) ||
                ((x.axle === RimAxleType.FAL || x.axle === RimAxleType.FAR || x.axle === RimAxleType.RAL || x.axle === RimAxleType.RAR) && x.quantity !== 1)
            ) {
                this.setQuantityOptimal = false;
            }
        });
    }

    /**
     * Adjusts the shown set of articles depending on the start state and the rim type
     */
    private setStartState() {
        // reset previous changes
        this.rearAxleAdded = false;
        this.frontAxleAdded = false;
        this.setQuantityOptimal = false;

        if (this.showAsCompleteSet) {
            // case: complete set wanted
            this.adjustQuantityToCompleteSet();
        } else if (this._rimArticle.length > 1) {
            // case: different axles - two articles - start with front axle
            this.shownSet = this._rimArticle.filter((item) => item.axle === RimAxleType.FRONT);
            this.frontAxleAdded = true;
            this.setItemsNotRemovable(true);
        } else {
            // case: same axles - one article
            this.shownSet = this._rimArticle;
            this.frontAxleAdded = true;
            this.rearAxleAdded = true;
            this.setItemsNotRemovable(true);
        }

        // Adjusts the headline of the modal from singular to plural
        const key: string = this.shownSet.length > 1 ? 'WHEELS.QUANTITIESHEADLINE' : 'WHEELS.QUANTITYHEADLINE';
        this.modalHeadline = this._translationService.translate(key);

        this.updateTracking();
    }

    private updateTracking() {
        this._gaService.trackProductImpressions(this.shownSet);
    }
}
