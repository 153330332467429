import { Component } from '@angular/core';

@Component({
    selector: 'zk-accessories',
    templateUrl: './accessories.component.html',
    styleUrls: ['./accessories.component.scss']
})
export class AccessoriesComponent {

}
