// ng
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

// models
import { WheelFilterDiameter } from './wheel-filterbar.model';
import { MultiselectItemComponent } from '@mbcs/mbcs-lib';

@Component({
    selector: 'zk-wheel-filter',
    templateUrl: './wheel-filter.component.html',
    styleUrls: ['./wheel-filter.component.scss']
})
export class WheelFilterComponent implements OnInit {
    /**
     * Diameter filters
     */
    @Input()
    filterOptions: WheelFilterDiameter[];

    /**
     * Emits event with the selected filter IDs as comma separated string
     */
    @Output()
    activeFilter: EventEmitter<string> = new EventEmitter<string>();

    constructor() {}

    ngOnInit() {}

    /**
     * Filter selection handler
     * @param selected MultiselectItemComponent[]
     */
    toggleFilterOption(selected: MultiselectItemComponent[]) {
       const active: Array<string> = selected.map(filter => filter.id);
       this.activeFilter.emit(active.toString());
    }
}
