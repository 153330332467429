// NG
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject, Subscription } from 'rxjs';

// Services
import { LocalStorageService } from '@shared/shared-services/storage/local-storage.service';
import { SelectedCarService } from '@shared/shared-services/selected-car/selected-car.service';
import { TranslationService } from '@shared/shared-services/translate/translation.service';
import { NotificationService } from '@shared/components/notification/notification.service';
import { AppService } from "../../../app.service";

// Models
import { Article } from '@shared/global-models/article/article.model';
import { ShoppingCartPost, ShoppingCartPostItem } from '@shared/shared-services/shopping-cart/model/shopping-cart-post.model';
import { ShoppingCartData } from '@shared/shared-services/shopping-cart/model/shopping-cart-data.model';
import { ShoppingCartItemModalInfoModel } from '@shared/shared-services/shopping-cart/model/shopping-cart-item-modal-info.model';
import { ShoppingCartItemAction } from '@shared/shared-services/shopping-cart/model/shopping-cart-item-action.model';
import { ShoppingCartDownloadPDFData } from './model/shopping-cart-dowload-pdf-data.model';
import { WheelDetail } from '@shared/global-models/wheelDetail.model';
import { RimAxleType } from '../../global-models/rimAxleType.model';
import { ShoppingCartItemMaxAmountReachedInfo } from '@shared/shared-services/shopping-cart/model/shopping-cart-item-max-amount-reached-info.model';
import { StorageEnum } from '@shared/shared-services/storage/storage.enum';
import { Notification, NotificationIcons, NotificationTypes } from '@shared/components/notification/notification.model';

@Injectable()
export class ShoppingCartService {
    private readonly MAX_QUANTITY: number = 10;
	private lastDeletedArticle: Article;

    private shoppingCartChangedSubject: Subject<ShoppingCartData> = new Subject<ShoppingCartData>();
    private shoppingCartItemInfoSubject: Subject<ShoppingCartItemModalInfoModel> = new Subject<ShoppingCartItemModalInfoModel>();
    private shoppingCartChangedErrorSubject: Subject<any> = new Subject<any>();
    private shoppingCartPDFGenerationCompleteSubject: Subject<boolean> = new Subject<boolean>();
    private shoppingCartItemMaxAmountInfoSubject: Subject<ShoppingCartItemMaxAmountReachedInfo> = new Subject<ShoppingCartItemMaxAmountReachedInfo>();
	private shoppingCartRemovedItemIdSubject: Subject<String> = new Subject<String>();
	public undoArticleRemove: Subject<any> = new Subject<any>();

    constructor(
        private _translationService: TranslationService,
        private selectedCarService: SelectedCarService,
        private httpClient: HttpClient,
		private _localStorageService: LocalStorageService,
		private _notificationService: NotificationService,
        private _appService: AppService
    ) {}

    get isAmg(): boolean {
        return this.selectedCarService.vehicleType?.vehicleTypeId === 'amg';
    }

    /**
     * Will return the current shopping cart or null
     * @returns shopping cart out of the local storage
     */
    get currentShoppingCart(): ShoppingCartData {
        const localStorageCart: ShoppingCartData = this._localStorageService.getItem(StorageEnum.SHOPPING_CART);

        if (localStorageCart && localStorageCart.items) {
			return localStorageCart;
        }
    }

    /**
     * Get informed when an article is added or removed from cart.
     * @returns {Subject<ShoppingCartItemModalInfoModel>}
     */
    get shoppingCartItemAddedOrRemovedInfo(): Subject<ShoppingCartItemModalInfoModel> {
        return this.shoppingCartItemInfoSubject;
    }

    /**
     * Get the latest news of the cart changed by subscribing to this subject!
     */
    get shoppingCartChanged(): Subject<ShoppingCartData> {
        return this.shoppingCartChangedSubject;
    }

    /**
     * Get if there is an error happening when the shopping cart changed
     */
    get shoppingCartChangedError(): Subject<any> {
        return this.shoppingCartChangedErrorSubject;
    }

    /**
     * Get informed if max article amount was reached or not to give a feedback to the user.
     */
    get shoppingCartStatusMessage(): Subject<ShoppingCartItemMaxAmountReachedInfo> {
        return this.shoppingCartItemMaxAmountInfoSubject;
    }

    /**
     * Get informed when PDF generation was finished
     */
    get shoppingCartPDFGenerationComplete(): Subject<boolean> {
        return this.shoppingCartPDFGenerationCompleteSubject;
    }

	/**
	 * Save last deleted Article in order to be reverted
	 */
	set deletedArticle(value: Article) {
		this.lastDeletedArticle = value;
	}

	get deletedArticle(): Article {
		return this.lastDeletedArticle;
	}

    /**
     * Adds the given item to the shopping cart. Or raises it´s quantity if the maximum quantity would not be reached.
     * @param itemsToAdd Articles to add to the shopping cart
     * @param quantity | optional | Article amount to add to the shopping cart. If nothing is passed then the amount that the article itself has will be used
     * @param notificationOnSuccess | optional | If a notification should be triggered when the cart update was successful
     */
    addItemsToShoppingCart(itemsToAdd: Article[], quantity: number = null, notificationOnSuccess: boolean = false) {
        // fail safe
        if (!itemsToAdd || itemsToAdd.length === 0) {
            return;
        }

        let currentLocalStorageCart: ShoppingCartData = this.currentShoppingCart;
        const shoppingCartItems: Article[] = [];

        // check if shopping cart exists
        if (!currentLocalStorageCart) {
            // create new shopping cart if it is the first time
            currentLocalStorageCart = {
                currency: '',
                items: [],
                totalGrossPrice: 0,
                totalGrossPriceText: '0'
            };
        }

        let itemAction: ShoppingCartItemAction;
        const useItemQuantity: boolean = quantity === null;

        for (const item of itemsToAdd) {
            // wheel sets for instance define the quantity by the object itself
            if (useItemQuantity) {
                quantity = item.quantity;
            }

            let shoppingCartItem: Article = null;
            let info: ShoppingCartItemMaxAmountReachedInfo;

            // check if the item that should be added is already in the shopping cart (to know if it´s a quantity raise or not)
            if (currentLocalStorageCart.items.length > 0) {
                shoppingCartItem = currentLocalStorageCart.items.find((singleCartItem) => {
                    return singleCartItem.articleId === item.articleId;
                });
            }

            // check if raising the quantity would exceed the threshold and quit the process and inform the user about that
            if (shoppingCartItem && shoppingCartItem.quantity + quantity > this.MAX_QUANTITY) {
                info = { articleId: shoppingCartItem.articleId, maxReached: true, quantity: shoppingCartItem.quantity };
                this.shoppingCartItemMaxAmountInfoSubject.next(info);

                return;
            }
            // raise quantity if article already exists
            if (shoppingCartItem) {
                shoppingCartItem.quantity = shoppingCartItem.quantity + quantity;
                shoppingCartItem.quantityAdded = quantity;
                itemAction = ShoppingCartItemAction.ITEM_QUANTITY_RAISED;
            } else {
                // or add new to cart (note: new object/deep copy needed!)
                shoppingCartItem = {
                    ...item,
                    appMode: item.appMode ? item.appMode : this._appService.appMode,
                    appSubMode: item.appSubMode ? item.appSubMode : this._appService.appSubMode,
                    dateAdded: item.dateAdded ? item.dateAdded : Date.now(),
                    groupIdPath: [...item.groupIdPath],
                    groupUrlNamePath: item.groupUrlNamePath ? [...item.groupUrlNamePath] : undefined,
                    images: item.images ? [...item.images] : undefined,
                    totalGrossPrice: item.grossPrice,
                    quantity: quantity,
                    quantityAdded: quantity,
					notRemovable: undefined
                };

                currentLocalStorageCart.items.push(shoppingCartItem);
                itemAction = ShoppingCartItemAction.ITEM_ADD;
            }

            shoppingCartItems.push(shoppingCartItem);

            // inform
            info = {
                articleId: shoppingCartItem.articleId,
                maxReached: shoppingCartItem.quantity >= this.MAX_QUANTITY,
                quantity: shoppingCartItem.quantity
            };

            this.shoppingCartItemMaxAmountInfoSubject.next(info);
        }

        // save and update shopping cart
        this.postAndHandleShoppingCart(currentLocalStorageCart, itemAction, shoppingCartItems, notificationOnSuccess);
    }

    /**
     * Helper function to build two article sets or one complete set from the main wheel article.
     * Used to display and build rim sets for displaying in modal and for adding them to the shopping cart.
     *
     * @param wheel | required | Needed wheel data from article object containing information about all sides of the rim set
     * @param previewAsSet | optional | default is true: If a set is built with quantity and total costs matching the set
     */
    buildRimSetFromWheelArticle(wheel: WheelDetail, previewAsSet: boolean = true): Article[] {
        // failsafe
        if (!wheel) {
            return [];
        }

        // check if rim set is for all axles
        if (wheel.wheelSetConfiguration === RimAxleType.AAAA) {
            const rimSet: Article = wheel;
            rimSet.quantity = previewAsSet ? 4 : 1;
            // single or total price for front and rear
            rimSet.totalGrossPriceText = previewAsSet ? wheel.setGrossPriceText : wheel.grossPriceText;
            rimSet.axle = RimAxleType.ALL;

            return [rimSet];
        }

        // or if separated for rear and front
        if (wheel.wheelSetConfiguration === RimAxleType.AACC) {
            // build pairs for front and rear
            const frontAxelRim: Article = wheel.leftFront;
            frontAxelRim.quantity = previewAsSet ? 2 : 1;
            // single or total price for front (left and right)
            frontAxelRim.totalGrossPriceText = previewAsSet ? wheel.frontGrossPriceText : wheel.leftFront.grossPriceText;
            frontAxelRim.axle = RimAxleType.FRONT;
            frontAxelRim.otherAxleUrlName = wheel.leftRear.urlName;

            const rearAxelRim: Article = wheel.leftRear;
            rearAxelRim.quantity = previewAsSet ? 2 : 1;
            // single or total price for rear (left and right)
            rearAxelRim.totalGrossPriceText = previewAsSet ? wheel.rearGrossPriceText : wheel.leftRear.grossPriceText;
            rearAxelRim.axle = RimAxleType.REAR;
            rearAxelRim.otherAxleUrlName = wheel.leftFront.urlName;

            return [frontAxelRim, rearAxelRim];
        }

        // or if separated for left and right
        if (wheel.wheelSetConfiguration === RimAxleType.ABAB) {
            // build pairs for front and rear
            const leftAxelRim: Article = wheel.leftFront;
            leftAxelRim.quantity = previewAsSet ? 2 : 1;
            // single or total price for front (left and right)
            leftAxelRim.totalGrossPriceText = previewAsSet ? wheel.frontGrossPriceText : wheel.leftFront.grossPriceText;
            leftAxelRim.axle = RimAxleType.LEFT;
            leftAxelRim.otherAxleUrlName = wheel.leftFront.urlName;

            const rightAxelRim: Article = wheel.rightFront;
            rightAxelRim.quantity = previewAsSet ? 2 : 1;
            // single or total price for rear (left and right)
            rightAxelRim.totalGrossPriceText = previewAsSet ? wheel.rearGrossPriceText : wheel.rightFront.grossPriceText;
            rightAxelRim.axle = RimAxleType.RIGHT;
            rightAxelRim.otherAxleUrlName = wheel.rightFront.urlName;

            return [leftAxelRim, rightAxelRim];
        }

        // or all 4 wheels are different
        if (wheel.wheelSetConfiguration === RimAxleType.ABCD) {
            // build pairs for left and front
            const frontAxelRimLeft: Article = wheel.leftFront;
            frontAxelRimLeft.quantity = 1;
            // single or total price for front (left and right)
            frontAxelRimLeft.totalGrossPriceText = previewAsSet ? wheel.frontGrossPriceText : wheel.leftFront.grossPriceText;
            // frontAxelRimLeft.axle = RimAxleType.FRONT + RimAxleType.LEFT;
            frontAxelRimLeft.axle = RimAxleType.FAL;
            frontAxelRimLeft.otherAxleUrlName = wheel.leftFront.urlName;

            // build pairs for right and front
            const frontAxelRimRight: Article = wheel.rightFront;
            frontAxelRimRight.quantity = 1;
            // single or total price for front (left and right)
            frontAxelRimRight.totalGrossPriceText = previewAsSet ? wheel.frontGrossPriceText : wheel.rightFront.grossPriceText;
            // frontAxelRimRight.axle = RimAxleType.FRONT + RimAxleType.RIGHT;
            frontAxelRimRight.axle = RimAxleType.FAR;
            frontAxelRimRight.otherAxleUrlName = wheel.rightFront.urlName;

            // build pairs for left and rear
            const rearAxelRimLeft: Article = wheel.leftRear;
            rearAxelRimLeft.quantity = 1;
            // single or total price for front (left and right)
            rearAxelRimLeft.totalGrossPriceText = previewAsSet ? wheel.rearGrossPriceText : wheel.leftRear.grossPriceText;
            // rearAxelRimLeft.axle = RimAxleType.REAR + RimAxleType.LEFT;
            rearAxelRimLeft.axle = RimAxleType.RAL;
            rearAxelRimLeft.otherAxleUrlName = wheel.leftRear.urlName;

            // build pairs for right and rear
            const rearAxelRimRight: Article = wheel.rightRear;
            rearAxelRimRight.quantity = 1;
            // single or total price for front (left and right)
            rearAxelRimRight.totalGrossPriceText = previewAsSet ? wheel.frontGrossPriceText : wheel.rightRear.grossPriceText;
            // rearAxelRimRight.axle = RimAxleType.REAR + RimAxleType.RIGHT;
            rearAxelRimRight.axle = RimAxleType.RAR;
            rearAxelRimRight.otherAxleUrlName = wheel.rightRear.urlName;

            return [frontAxelRimLeft, frontAxelRimRight, rearAxelRimLeft, rearAxelRimRight];
        }

        console.error('UNHANDLED CASE: Could not find any match for the wheel set configuration: ', wheel.wheelSetConfiguration);

        return [];
    }

    /**
     * Remove the article with the given articleId from the shopping cart
     * @param articleIds Article ids of the articles which needs to be removed
     * @param notificationOnSuccess | optional | If a notification should be triggered when the cart update was successful
     */
    removeItemFromShoppingCart(articleIds: string[], notificationOnSuccess: boolean = false) {
        const currentLocalStorageCart: ShoppingCartData = this.currentShoppingCart;

        if (!currentLocalStorageCart || currentLocalStorageCart.items.length === 0 || !articleIds) {
            return;
        }

        const actualRemovedArticles = this.removeArticleFromLocalStorage(currentLocalStorageCart, articleIds);

        // removes the eventual shown message from the PDP or wheel special page that the max quantity for the article was reached
        for (const item of actualRemovedArticles) {
            const info: ShoppingCartItemMaxAmountReachedInfo = {
                articleId: item.articleId,
                maxReached: false,
                quantity: 0
            };
            this.shoppingCartItemMaxAmountInfoSubject.next(info);
        }

        // save and update cart
        this.postAndHandleShoppingCart(
            currentLocalStorageCart,
            ShoppingCartItemAction.ITEM_REMOVE,
            actualRemovedArticles,
            notificationOnSuccess
        );

		this.shoppingCartRemovedItemIdSubject.next(articleIds[0]);
    }

    /**
     * Update the item quantity of an article
     * @param articleId Article id of the article which needs to be updated
     * @param quantity Quantity to set the article to
     * @param notificationOnSuccess | optional | If a notification should be triggered when the cart update was successful
     */
    updateItemFromShoppingCart(articleId: string, quantity: number, notificationOnSuccess: boolean = false) {
        if (!articleId || !quantity) {
            return;
        }

        const currentLocalStorageCart: ShoppingCartData = this.currentShoppingCart;
        const singleItem: Article = currentLocalStorageCart.items.find((value) => {
            return value.articleId === articleId;
        });

        // update quantity of existing article in the shopping cart
        if (singleItem) {
            const itemAction: ShoppingCartItemAction =
                quantity > singleItem.quantity ? ShoppingCartItemAction.ITEM_QUANTITY_RAISED : ShoppingCartItemAction.ITEM_QUANTITY_REDUCED;

            // update ref
            singleItem.quantity = quantity;
            singleItem.quantityAdded = quantity;

            // removes the eventual shown message from the product detail or wheel special page that the max quantity for the article was reached
            const info: ShoppingCartItemMaxAmountReachedInfo = {
                articleId: singleItem.articleId,
                maxReached: quantity >= this.MAX_QUANTITY,
                quantity: singleItem.quantity
            };
            this.shoppingCartItemMaxAmountInfoSubject.next(info);

            // save
            this.postAndHandleShoppingCart(currentLocalStorageCart, itemAction, [singleItem], notificationOnSuccess);
        }
    }

    /**
     * Sends the articleId and quantity through the API and receives the updated prices
     * @param cartItem CartItem to update
     */
    updateSingleItem(cartItem: Article): Observable<Article> {
        const itemToUpdate: ShoppingCartPostItem = {
            articleId: cartItem.articleId,
            quantity: cartItem.quantity,
			sizeSelected: !!cartItem.sizeSelected
        };

        const url = `api/cart/calc/${this._translationService.currentLang}`;

        return this.httpClient.post<Article>(url, itemToUpdate);
    }

    /**
     * This will remove cart information from local storage
     */
    clearShoppingCart() {
        this._localStorageService.removeItem(StorageEnum.SHOPPING_CART);
        this.shoppingCartChangedSubject.next({
            totalGrossPriceText: null,
            totalGrossPrice: null,
            currency: null,
            items: []
        });
    }

    /**
     * Removes all not fitting items from the shopping cart that have been checked already
     */
    removeNotFittingItems() {
        if (this.currentShoppingCart && this.currentShoppingCart.items && this.currentShoppingCart.items.length > 0) {
            // note: the property 'fit' is undefined as long as no check has happened yet
            const unfitting: string[] = this.currentShoppingCart.items
                .filter((singleItem) => singleItem.fit !== undefined && !singleItem.fit)
                .map((found) => found.articleId);

            if (unfitting && unfitting.length > 0) {
                this.removeItemFromShoppingCart(unfitting);
            }
        }
    }

    /**
     * Recalculate the total gross price with only the fitting items
     */
    calculateFittingProducts() {
        if (!this.currentShoppingCart) {
            const placeholder: Subject<Object> = new Subject<Object>();
            placeholder.complete();

            return placeholder.asObservable();
        }

        const shoppingCartPostData: ShoppingCartPost = {
            items: [],
            checkout: false,
            vin: this.selectedCarService.VIN
        };

        for (const singleItem of this.currentShoppingCart.items) {
            if (singleItem.fit) {
                shoppingCartPostData.items.push({
                    articleId: singleItem.articleId,
                    quantity: singleItem.quantity
                });
            }
        }

        const url = `api/cart/${this._translationService.currentLang}`;

        return this.httpClient.post(url, shoppingCartPostData);
    }

    /**
     * Prints wishlist content or PDF of single article.
     */
    proceedToDownloadPDF(singleArticle?: Article): void {
        const downloadPDFData: ShoppingCartDownloadPDFData = {
            items: []
        };

        // Print single article from PDP or WSP
        if (singleArticle) {
            downloadPDFData.items[0] = {
                articleId: singleArticle.articleId,
                quantity: 1
            };
        } else {
            // Print Wishlist
            const articles: Article[] = this._localStorageService.getItem(StorageEnum.SHOPPING_CART).items;

            articles.forEach((x: Article) => {
                downloadPDFData.items.push({
                    articleId: x.articleId,
                    quantity: x.quantity
                });
            });
        }

        const sub: Subscription = this.requestDownloadPDF(downloadPDFData).subscribe(
            (response: any) => {
                // pdf filename shall be different for wishlist or single article
                const fileName: string = singleArticle
                    ? singleArticle.headline + ' - Mercedes Benz'
                    : this._translationService.translate('PDF.PRODUCTDATASHEETFILENAME');

				// create "link" to imitate download and save functionality
				const link = document.createElement('a');
				link.href = window.URL.createObjectURL(response.body);
				link.download = fileName.replace(/["'\\/:*?<>|.]/g, '').trim();
				// FF workaround: a[click] property only works in Chrome - so we dispatch the click event in this way
				link.dispatchEvent(new MouseEvent(`click`, { bubbles: true, cancelable: true, view: window }));
            },
            (error) => {
                console.log('Error request of downloadPDF', error);
				this._notificationService.triggerNotification(
					new Notification(NotificationTypes.ERROR, NotificationIcons.WARNING, this._translationService.translate('ERRORMESSAGE.SERVICEUNAVAILABLE')));
            },
            () => {
                if (sub) {
                    sub.unsubscribe();
                }
                // inform
                this.shoppingCartPDFGenerationCompleteSubject.next(true);
            }
        );
    }

    /**
     * Returns PDF file to download
     * @param downloadPDFData articles data to construct PDF
     */
    private requestDownloadPDF(downloadPDFData: ShoppingCartDownloadPDFData) {
        // add vin if available to body
        downloadPDFData.vin = this.selectedCarService.VIN;
        // add full car name or VIN number to body
        downloadPDFData.selectedCar = this.selectedCarService.carLine ? this.selectedCarService.getSelectedCarName() : '';

        const url = `api/cart/print/${this._translationService.currentLang}`;

        return this.httpClient.post(url, downloadPDFData, { observe: 'response', responseType: 'blob' });
    }

    /**
     * Returns the article with the given article id if it was found in the shopping cart. Otherwise, null.
     * @param {string} articleId
     * @returns Article | null
     */
    getArticleFromCartById(articleId: string): Article {
        const cart: ShoppingCartData = this.currentShoppingCart;
        if (cart && cart.items) {
            const searchedItem: Article = cart.items.find((item) => {
                return item.articleId === articleId;
            });

            if (searchedItem) {
                return searchedItem;
            }
        }

        return null;
    }

    resetCompatibilityStatus(): void {
        const cart: ShoppingCartData = this.currentShoppingCart;
        if (cart && cart.items.length > 0) {
            cart.items.forEach((x: Article) => {
                x.fit = undefined;
            });

            // update shopping cart ref in local storage
			this._localStorageService.setItem(StorageEnum.SHOPPING_CART, cart);
        }
    }

    markArticlesAsNotFitting(articleIds: string[]): void {
        const cart: ShoppingCartData = this.currentShoppingCart;
        articleIds.forEach((x: string) => {
            const searchedItem: Article = cart.items.find((item) => {
                return item.articleId === x;
            });

            if (searchedItem) {
                searchedItem.fit = false;
            }
        });

        // update shopping cart ref in local storage
		this._localStorageService.setItem(StorageEnum.SHOPPING_CART, cart);
    }

    /**
     * Remove the given article by their id from local storage
     * @param currentLocalStorageCart Current shopping cart data
     * @param articleIds Articles to remove from local storage
     * @returns Array of removed articles
     */
	 removeArticleFromLocalStorage(currentLocalStorageCart: ShoppingCartData, articleIds: string[]): Article[] {
        const actualRemovedArticles: Article[] = [];
        for (const articleId of articleIds) {
			const indexToRemove: number = currentLocalStorageCart.items.findIndex(value => value.articleId === articleId);

            if (indexToRemove > -1) {
                const removedArticles: Article[] = currentLocalStorageCart.items.splice(indexToRemove, 1);
                actualRemovedArticles.push(removedArticles[0]);
            }
        }

        if (actualRemovedArticles.length > 0) {
			this._localStorageService.setItem(StorageEnum.SHOPPING_CART, currentLocalStorageCart);
        }

        return actualRemovedArticles;
    }

    /**
     * Use this method to handle the default post and handling of the shopping cart to the backend
     * @param currentLocalStorageCart Current ShoppingCartData from local storage
     * @param itemAction True if item was added, false if item was removed
     * @param articles ShoppingCartItems that were added / removed / quantity changed
     * @param notificationOnSuccess | optional | If a notification should be triggered when the cart update was successful
     */
    private postAndHandleShoppingCart(
		currentLocalStorageCart: ShoppingCartData,
        itemAction: ShoppingCartItemAction,
        articles: Article[],
        notificationOnSuccess: boolean = false
    ) {
        const sub = this.postShoppingCart(currentLocalStorageCart).subscribe(
            (response: ShoppingCartData) => {
                sub.unsubscribe();

                currentLocalStorageCart.totalGrossPriceText = response.totalGrossPriceText;
                currentLocalStorageCart.totalGrossPrice = response.totalGrossPrice;
                currentLocalStorageCart.currency = response.currency;

                currentLocalStorageCart.items.map((value, index) => {
                    if (value.articleId === response.items[index].articleId) {
                        value.grossPriceText = response.items[index].grossPriceText;
                        value.totalGrossPriceText = response.items[index].totalGrossPriceText;
                    }
                });

                if (currentLocalStorageCart) {
                    // update shopping cart
					this._localStorageService.setItem(StorageEnum.SHOPPING_CART, currentLocalStorageCart);
                    // inform about change of whole cart
                    this.shoppingCartChangedSubject.next(currentLocalStorageCart);

                    // optional: inform about add/raise or remove/reduce of an item separately
                    if (notificationOnSuccess && itemAction && articles) {
                        const data: ShoppingCartItemModalInfoModel = {
                            action: itemAction,
                            items: articles
                        };

                        this.shoppingCartItemInfoSubject.next(data);
                    }
                }
            },
            (error) => {
                this.shoppingCartChangedErrorSubject.next(error);
				this._notificationService.triggerNotification(
					new Notification(NotificationTypes.ERROR, NotificationIcons.WARNING,  this._translationService.translate('ERRORMESSAGE.SERVICEUNAVAILABLE')));
            },
            () => {
                // case: last item from wishlist was removed
                if (notificationOnSuccess && itemAction && articles) {
                    const data: ShoppingCartItemModalInfoModel = {
                        action: itemAction,
                        items: articles
                    };

                    this.shoppingCartItemInfoSubject.next(data);
                }
            }
        );
    }

    /**
     * Use this method to handle the response yourself. It will only forward the given shopping cart to the Backend
     * @param shoppingCartData Current ShoppingCartData to forward to the Backend
     * @param vin | string. Optional. Adds vin to the shopping cart
     * @param checkout | boolean. Optional: Defines if we are at the checkout stage
     * @returns Observable to subscribe with the response type ShoppingCartData
     */
    private postShoppingCart(shoppingCartData: ShoppingCartData, vin: string = null, checkout: boolean = false): Observable<any> {
        const shoppingCartPostData: ShoppingCartPost = {
            items: []
        };

        if (vin && checkout) {
            shoppingCartPostData.checkout = checkout;
            shoppingCartPostData.vin = vin;
        }

        let itemsFromShoppingCart: Article[] = shoppingCartData.items;
        if (checkout) {
            // at this point we do not need to check for undefined since we are at the checkout stage
            const notFittingItemIds: string[] = itemsFromShoppingCart.filter((single) => !single.fit).map((s) => s.articleId);
            itemsFromShoppingCart = itemsFromShoppingCart.filter((single) => single.fit);
            this.removeArticleFromLocalStorage(this.currentShoppingCart, notFittingItemIds);
        }

        itemsFromShoppingCart.forEach((singleArticle: Article) => {
            shoppingCartPostData.items.push({
                articleId: singleArticle.articleId,
                quantity: singleArticle.quantity,
				sizeSelected: !!singleArticle.sizeSelected
            });
        });

        if (shoppingCartPostData.items.length > 0) {
            const url = `api/cart/${this._translationService.currentLang}`;

            return this.httpClient.post(url, shoppingCartPostData);
        }

        // case: last item from wishlist was removed
        const placeholder: Subject<any> = new Subject<any>();
        placeholder.complete();

		this.clearShoppingCart();

        return placeholder;
    }
}
