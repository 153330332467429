// ng
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';

// modules
import { SharedModule } from '@shared/shared.module';
import { AccessoriesRoutingModule } from './accessories-routing.module';
import { ProductDetailsModule } from './pages/product-details-page/product-details.module';
import { WheelCategoriesModule } from './pages/sub-category-page/wheel-category/wheel-categories.module';

// router outlet components
import { AccessoriesComponent } from './accessories.component';
import { PassengercarsComponent } from './passengercars/passengercars.component';
import { VansComponent } from './vans/vans.component';
import { AmgComponent } from './amg/amg.component';
// shared components
import { StartPageComponent } from './pages/start-page/start-page.component';
import { ModelStartPageComponent } from './pages/model-start-page/model-start-page.component';
import { CategoryPageComponent } from './pages/category-page/category-page.component';
import { SubCategoryPageComponent } from './pages/sub-category-page/sub-category-page.component';
import { ProductListComponent } from './pages/category-page/product-list/product-list.component';
import { ProductListSubcategoryComponent } from './pages/sub-category-page/product-list-subcategory/product-list-subcategory.component';

@NgModule({
  declarations: [
    // Router outlets
    AccessoriesComponent,
    PassengercarsComponent,
    VansComponent,
    AmgComponent,
    // Shared page components by passenger cars and vans
    StartPageComponent,
    ModelStartPageComponent,
    CategoryPageComponent,
    SubCategoryPageComponent,
    ProductListComponent,
    ProductListSubcategoryComponent,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule,
    AccessoriesRoutingModule,
    WheelCategoriesModule,
    ProductDetailsModule,
    SharedModule
  ]
})
export class AccessoriesModule { }
