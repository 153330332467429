<div class="model-design">
    <wb-text size="l" strong class="model-design_column model-design_label"
             zkTranslate="CARCHOOSER.MODELDESIGN">
    </wb-text>

    <ul class="model-design_list">
        <li class="model-design_list-item"
            *ngFor="let modelDesign of modelDesigns"
            [ngClass]="{'is-active' : modelDesign.isActive}">
            <a class="model-design_link"
               (click)="activateModelDesign(modelDesign)">
                <wb-text size="l">{{modelDesign.name}}</wb-text>
            </a>
        </li>
    </ul>
</div>
