<div class="campaign">
    <div class="campaign-stage">
        <ng-template header></ng-template>
    </div>
    
    <main class="campaign-content">
        <ng-template body></ng-template>
    </main>
    
    <div class="campaign-footer">
        <ng-template footer></ng-template>
    </div>
</div>
