// Ng
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Subscription } from 'rxjs';

// Services
import { ProductGroupsService } from '@shared/shared-services/product-groups/product-groups.service';
import { GroupMapperService } from '@shared/shared-services/group-mapper/group-mapper.service';
import { SeoService } from '@shared/shared-services/seo/seo.service';

// Models
import { ProductGroup } from '@mbcs/mbcs-lib';

@Component({
    selector: 'zk-collection-sub-subcategory-page',
    templateUrl: './collection-sub-sub-category-page.component.html',
    styleUrls: ['./collection-sub-sub-category-page.component.scss']
})
export class CollectionSubSubCategoryPageComponent implements OnInit {
    isProductUrl: boolean;

	private _activeProductGroup: ProductGroup;
	private _activeProductSubGroup: ProductGroup;
	private _activeProductSubSubGroup: ProductGroup;

    private _sub: Subscription = new Subscription();

    constructor(private _groupsService: ProductGroupsService,
                private _groupsMapperService: GroupMapperService,
                private _activatedRoute: ActivatedRoute,
                private _router: Router,
				private _seoService: SeoService) {}

    ngOnInit(): void {
        this._sub = this._activatedRoute.params.subscribe((params: Params) => {
            this._sub.unsubscribe();
            this.checkUrl(params);
        });
    }

    /**
	 * Checks if url ends with a valid collection article id (2nd level group)
	 * or ends with a valid collection sub-sub-group name (3rd level group).
	 * @param params
	 */
    private checkUrl(params: Params): void {
        // 2nd level
		this._activeProductGroup = this._groupsService.groups.find((x: ProductGroup) => x.urlName === params['category']);
		this._activeProductSubGroup = this._activeProductGroup.subGroups.find((x: ProductGroup) => x.urlName === params['subcategory']);
        if (this._activeProductSubGroup.subGroups === undefined) {
            this.articleIdIsValid(params['subsubcategory']);

            return;
        }

        // 3rd level
        this._activeProductSubSubGroup = this._activeProductSubGroup.subGroups.find((x: ProductGroup) => x.urlName === params['subsubcategory']);
        if (this._activeProductSubSubGroup) {
            this.isProductUrl = false;

			this.setSeoInfo();

            return;
        }

        this.articleIdIsValid(params['subsubcategory']);
    }

    /**
     * Checks if param matches a valid article ID to render PDP or redirect to 404 page.
     * @param urlPart
     */
    private articleIdIsValid(urlPart: string): void {
        const id = urlPart.substring(urlPart.lastIndexOf('-') + 1);
        if (id.match('[A-Za-z]\\d{7}.*')) {
            this.isProductUrl = true;
        } else {
            this._router.navigate(['lifestyle/404']);
        }
    }

	private setSeoInfo() {
		const title: string = this._seoService.getTranslation('ACCESSORIES.FLYOUT.TEASER.TITEL');

		this._seoService.updatePageTitle(
			`${this._activeProductSubSubGroup.name} | ${this._activeProductSubGroup.name} | ${this._activeProductGroup.name} | ${title}`);
		this._seoService.updateMetaDescription(
			this._seoService.getTranslation('LIFESTYLE.SEO.METADESCRIPTION.PRODUCTLISTSUBSUBCATPAGE')
					+ `: ${this._activeProductSubSubGroup.name} | ${this._activeProductSubGroup.name} | ${this._activeProductGroup.name}`);
	}
}
