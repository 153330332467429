// ng
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
// Service
import { TranslationService } from '@shared/shared-services/translate/translation.service';
import { PopupModalService } from "@shared/modals/popup-modal/popup-modal.service";

@Component({
    selector: 'zk-category-not-available-modal',
    templateUrl: 'category-not-available-modal.component.html',
    styleUrls: ['./category-not-available-modal.component.scss']
})
export class CategoryNotAvailableModalComponent implements OnInit {
    @Output()
    onModalClosed: EventEmitter<any> = new EventEmitter();

    modalHeadline = this.translationService.translate('PRODUCTLIST.INFO.HEADLINE');

    constructor(private translationService: TranslationService, private modalService: PopupModalService) {}

    ngOnInit() {}

    onContinue() {
        this.modalService.close('category-not-available-modal');
    }

    informAboutClosed() {
        this.onModalClosed.emit();
    }
}
