// ng
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
// services
import { SelectedCarService } from '@shared/shared-services/selected-car/selected-car.service';

@Component({
    selector: 'zk-error-message',
    templateUrl: 'error-message.component.html',
    styleUrls: ['./error-message.component.scss']
})
export class ErrorMessageComponent implements OnInit, OnDestroy {
    @Input()
    lightBackground: boolean = false;

    /**
     * Error code defines different error messages
     * @param code
     */
    @Input() set errorCode(code: number) {
        switch (code) {
            case 503: this.translationKey = 'ERRORMESSAGE.PRODUCTLISTDCP'; break;
            default:  this.translationKey = 'ERRORMESSAGE.PRODUCTLIST';
        }

        this._errorCode = code;
    }

    /**
     * Defines if a link to the model or start page is displayed after the text.
     * Default is true.
     */
    @Input()
    showLink: boolean = true;

    isCarLockedIn: boolean = false;
    errorMessageLink: string;
    errorMessageLinkText: string = 'GENERAL.PAGES.STARTPAGE';
    translationKey: string = 'NO_KEY_GIVEN';

    private carLineSubscription: Subscription = null;
    private _errorCode: number;

    constructor(private selectedCarService: SelectedCarService) {}

    ngOnInit() {
        this.errorMessageLink = '/';

        if (this.selectedCarService.carClass !== null && typeof this.selectedCarService.carClass !== 'undefined') {
            this.isCarLockedIn = true;
            this.buildRouteToModelStartPage();
        }

        this.carLineSubscription = this.selectedCarService.carLineSubscriber.subscribe((resp) => {
            this.isCarLockedIn = resp !== null && typeof resp !== 'undefined';

            if (this.isCarLockedIn) {
                this.buildRouteToModelStartPage();
            }
        });
    }

    ngOnDestroy() {
        if (this.carLineSubscription) {
            this.carLineSubscription.unsubscribe();
        }
    }

    private buildRouteToModelStartPage() {
        // If retailer is not known by DCP we can only route to the start page. Everything else makes no sense.
        if (this._errorCode && this._errorCode === 501) {
            return;
        }

        this.errorMessageLink =  `/${this.selectedCarService.carLine.urlName}`;
        this.errorMessageLinkText = 'GENERAL.PAGES.MODELSTARTPAGE';
    }
}
