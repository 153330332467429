// ng
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
// services
import { PopupModalService } from "@shared/modals/popup-modal/popup-modal.service";
import { SelectedCarService } from '@shared/shared-services/selected-car/selected-car.service';
import { GroupMapperService } from '@shared/shared-services/group-mapper/group-mapper.service';
import { SeoService } from '@shared/shared-services/seo/seo.service';
import { ProductGroupsService } from '@shared/shared-services/product-groups/product-groups.service';
import { TeaserService } from '@shared/shared-services/teaser/teaser.service';
import { GoogleAnalyticsService } from '@shared/shared-services/google-analytics/google-analytics.service';
// model
import { Image } from '@mbcs/mbcs-lib';
import { ProductGroup } from '@mbcs/mbcs-lib';
import { PageName } from '@shared/shared-services/google-analytics/google-analytics.model';
import { ResponsiveImage } from "@shared/global-models/responsiveImages.model";

@Component({
    selector: 'zk-sub-category-page',
    templateUrl: './sub-category-page.component.html',
    styleUrls: ['./sub-category-page.component.scss']
})
export class SubCategoryPageComponent implements OnInit, OnDestroy {
    subGroupType = '';
    subGroupId = '';
    subGroupName = '';
    isGroupTypeWheels = false;
    image: ResponsiveImage;
    headline = '';
    wheelProductGroup: ProductGroup;

    private _carLogged = false;
    private _mainGroupName = '';
    private _mainGroupId = '';
    private _productGroups: ProductGroup[];
    private _routerSubscription: Subscription;
    private _groupsChangedSubscription: Subscription;

    constructor(
        private _popupModalService: PopupModalService,
        private _router: Router,
        private _activatedRoute: ActivatedRoute,
        private _selectedCarService: SelectedCarService,
        private _groupMapperService: GroupMapperService,
        private _seoService: SeoService,
        private _productGroupsService: ProductGroupsService,
        private _teaserService: TeaserService,
        private _gaService: GoogleAnalyticsService
    ) {}

    ngOnInit(): void {
        this._carLogged = this._activatedRoute.snapshot.params.carclass !== 'products';
        this._productGroups = this._productGroupsService.groups;
        this.parseCategory();

        if (this._carLogged && !this.checkIfGroupExistsForCar()) {
                return;
        }

        if (this.isGroupTypeWheels) {
            this.initStageTeaser();
        }

        this.setSeoInfo();
        this.listenToChanges();
    }

    ngOnDestroy(): void {
        if (this._routerSubscription) {
            this._routerSubscription.unsubscribe();
        }

        if (this._groupsChangedSubscription) {
            this._groupsChangedSubscription.unsubscribe();
        }
    }

    private parseCategory(): void {
        this._mainGroupId = this._groupMapperService.getGroupIdFromGroupUrlName(this._activatedRoute.snapshot.params.category);
        const mainGroupType = this._groupMapperService.getGroupTypeById(this._mainGroupId);
        this._mainGroupName = this._groupMapperService.getGroupNameFromUrlName(this._activatedRoute.snapshot.params.category);

        this.subGroupName = this._groupMapperService.getGroupNameFromUrlName(
            this._activatedRoute.snapshot.params.category,
            this._activatedRoute.snapshot.params.subcategory
        );
        this.subGroupId = this._groupMapperService.getGroupIdFromGroupUrlName(
            this._activatedRoute.snapshot.params.category,
            this._activatedRoute.snapshot.params.subcategory
        );
        this.subGroupType = this._groupMapperService.getGroupTypeById(this.subGroupId);

        this.isGroupTypeWheels = mainGroupType === 'wheels' || mainGroupType === 'amg-wheels';
        this.wheelProductGroup = this._productGroups.find((x) => x.type === mainGroupType);
    }

    /**
     * Checks if the current active sub category exists for the new selected car.
     * If not it redirects the user to the model start page.
     */
    private checkIfGroupExistsForCar(): boolean {
        // case: current main category is not available for the new car
        const currentMainGroup: ProductGroup = this._productGroups.find((x) => x.groupId === this._mainGroupId);
        if (!currentMainGroup) {
            this._popupModalService.open('category-not-available-modal');

            return false;
        }

        // case: new car has the current main category but not the current sub category
        const currentSubGroup: ProductGroup = currentMainGroup.subGroups.find((x) => x.groupId === this.subGroupId);
        if (!currentSubGroup) {
            this._popupModalService.open('category-not-available-modal');

            return false;
        }

        return true;
    }

    private setSeoInfo(): void {
        this._gaService.trackPageView(PageName.SUBCATEGORY_PAGE);

        const title = this._seoService.getTranslation('GENERAL.PAGES.STARTPAGE_STATICTITLE');
        const metaTitle = this._seoService.getTranslation('SEO.METADESCRIPTION.PRODUCTSUBLISTPAGE');

        // with car: <SubCat> ["|"] <Cat> ["|"] <CarBodyLine> ["| Mercedes-Benz Genuine Accessories"]
        if (this._carLogged && this._selectedCarService.carLine) {
            this._seoService.updatePageTitle(
                this.subGroupName + ' | ' +
                this._mainGroupName + ' | ' +
                this._selectedCarService.bodyType.name + ' ' +
                this._selectedCarService.carLine.name + ' | ' +
                title);
        } else {
            // without a car: <SubCat> ["|"] <Cat> ["| Mercedes-Benz Genuine Accessories"]
            this._seoService.updatePageTitle(this.subGroupName + ' | ' + this._mainGroupName + ' | ' + title);
        }

        // set MetaDescription text: [StaticTxt] <SubCat> | <Cat>
        this._seoService.updateMetaDescription(metaTitle + ' ' + this.subGroupName + ' | ' + this._mainGroupName);
    }

    private listenToChanges(): void {
        // detect if the sub category has changed. Re-init of the component needed to display the new sub category
        this._routerSubscription = this._router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                this.ngOnDestroy();
                this.ngOnInit();
            }
        });

        // react to car log in / change. Re-init of the component needed to check if subcategory is available for the new car
        this._groupsChangedSubscription = this._productGroupsService.groupsChangedInfo.subscribe(() => {
            this.ngOnDestroy();
            this.ngOnInit();
        });
    }

    private initStageTeaser(): void {
        this._teaserService.getMainCategoryImage(this._mainGroupId).subscribe(
            (response: Image) => this.image = response
        );
        this.headline = this._teaserService.getHeadlineForSingleStageWide(this._mainGroupName);
    }
}
