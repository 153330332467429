// ng
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
// services
import { SelectedCarService } from '@shared/shared-services/selected-car/selected-car.service';
import { TranslationService } from '@shared/shared-services/translate/translation.service';
import { SeoService } from '@shared/shared-services/seo/seo.service';
import { GroupMapperService } from '@shared/shared-services/group-mapper/group-mapper.service';
// models
import { Article } from '@shared/global-models/article/article.model';

@Injectable()
export class ProductDetailsService {
    constructor(
        private httpClient: HttpClient,
        private selectedCarService: SelectedCarService,
        private _translationService: TranslationService,
        private seoService: SeoService,
        private _groupMapperService: GroupMapperService
    ) {}

    /**
     * Get the recommended articles for the product teaser carousel for accessory articles.
     * @param currentArticleId Identification number of the current article
     */
    getRecommendedArticles(currentArticleId: string) {
        const currentLang: string = this._translationService.currentLang;
        let carLineIdOrType: string = this.selectedCarService.carLine ?
            this.selectedCarService.carLine.carLineId : this.selectedCarService.vehicleType.vehicleTypeId;

        if (this.selectedCarService.vehicleType?.vehicleTypeId === 'amg' && this.selectedCarService?.modelDesign?.modelDesignId ) {
            carLineIdOrType = `amg/${this.selectedCarService.carLine.carLineId}/${this.selectedCarService.modelDesign.modelDesignId}`;
        }

        return this.httpClient.get(`api/highlights/all-articles/${currentLang}/${carLineIdOrType}?articleId=${currentArticleId}`);
    }

    /**
     * Update the title according to the given article
     * @param article Current article
     * @param isCarLoggedIn boolean
     */
    setSeoInfo(article: Article, isCarLoggedIn: boolean) {
        const groupName: string = this._groupMapperService.getGroupUrlNameFromGroupId(article.groupIdPath[0]);
        const categoryName: string = this._groupMapperService.getGroupNameFromUrlName(groupName);

        const subGroupName: string = this._groupMapperService.getGroupUrlNameFromGroupId(article.groupIdPath[1]);
        const subCategoryName: string = this._groupMapperService.getGroupNameFromUrlName(groupName, subGroupName);

        let title: string = article.headline;

        // There are some articles that doesn't have headline2
        let headline2: string = article.headline2;
        if (headline2) {
            headline2 = ' (' + article.headline2 + ') | ';
        } else {
            headline2 = ' | ';
        }

        // with car: <Headline> ["("] <SubHeadline> [") |"] <SubCat> ["|"] <Cat> ["|"] <CarBodyLine> ["| Mercedes-Benz Genuine Accessories"]
        if (isCarLoggedIn && this.selectedCarService.carLine) {
            title +=
                headline2 +
                subCategoryName +
                ' | ' +
                categoryName +
                ' | ' +
                this.selectedCarService.bodyType.name +
                ' ' +
                this.selectedCarService.carLine.name +
                ' | ' +
				this.seoService.getTranslation('GENERAL.PAGES.STARTPAGE_STATICTITLE');
        } else if (!this.selectedCarService.carLine) {
            // without a car: <Headline> ["("] <SubHeadline> [") |"] <SubCat> ["|"] <Cat> ["| Mercedes-Benz Genuine Accessories"]
            title +=
                headline2 +
                subCategoryName +
                ' | ' +
                categoryName +
                ' | ' +
				this.seoService.getTranslation('GENERAL.PAGES.STARTPAGE_STATICTITLE');
        }

        this.seoService.updatePageTitle(title);

        // case: there are some articles that don't have description
        if (article.description) {
            const withoutHtmlTags: string = this.replaceHtmlTagFromString('<br>', article.description, '');
            this.seoService.updateMetaDescription(withoutHtmlTags);
        }
    }

    /**
     * Get the article ID from the given URL
     * @param articleUrl Article url
     */
    getArticleIdFromUrl(articleUrl: string): string {
        const pos: number = articleUrl.lastIndexOf('-');
        if (pos === -1) {
            return articleUrl;
        }

        return articleUrl.substring(pos + 1);
    }

    /**
     * Adds the missing translated text parts for articles of type CARE_PRODUCTS since not delivered by backend.
     * This info is displayed only product teasers and on the PDP.
     * @param article
     */
    addContentVolumeInfoTranslation(article: Article): Article {
        if (article.contentsVolumeInfo) {
            article.contentsVolumeInfo.contentText = this._translationService.translate('CONTENTVOLUME.CONTENT');
            article.contentsVolumeInfo.contentMilliliterText = this._translationService.translate('CONTENTVOLUME.ML');
            article.contentsVolumeInfo.contentProLiterText = this._translationService.translate((article.contentsVolumeInfo.priceVolumeUnit === 'ml') ? 'CONTENTVOLUME.PROML' : 'CONTENTVOLUME.L');
        }

        return article;
    }

    /**
     * Replaces the given characters globally from the string. Can be used to replace/escape html tags for instance.
     * @param htmlTag - search value
     * @param str - given string
     * @param replacer - value to replace with
     */
    replaceHtmlTagFromString(htmlTag: string, str: string, replacer: string): string {
        const re = new RegExp(htmlTag, 'g');

        return str.replace(re, replacer);
    }
}
