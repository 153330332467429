<div #appWrapper *ngIf="loaded; else loading">
    <!-- App -->
    <zk-header-base></zk-header-base>
    <router-outlet></router-outlet>
    <zk-product-categories *ngIf="!isCampaignPage && !isLegalPage && !isSplashPage"></zk-product-categories>
    <zk-footer-base [showPriceNotification]="showPriceNotification"></zk-footer-base>

    <!-- Modals -->
    <zk-notification></zk-notification>

    <zk-rim-set-quantity-modal [showAsCompleteSet]="previewAsCompleteSet"
                               [rimArticle]="rimSetPreview"
                               hideDeleteIcon="true">
    </zk-rim-set-quantity-modal>
    <zk-shopping-cart-modal [article]="articleInfo"></zk-shopping-cart-modal>
    <zk-compatibility-check-modal></zk-compatibility-check-modal>
    <zk-wishlist-compatibility-feedback-modal></zk-wishlist-compatibility-feedback-modal>
    <zk-reset-warning-modal></zk-reset-warning-modal>
    <zk-model-design-chooser-modal></zk-model-design-chooser-modal>
    <zk-category-not-available-modal (onModalClosed)="routeToStartOrModelPage()"></zk-category-not-available-modal>
    <zk-dealer-locator-modal></zk-dealer-locator-modal>
</div>

<ng-template #loading>
    <wb-spinner class="loading-spinner" theme="light"></wb-spinner>
</ng-template>
