import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'zk-dealer-locator-modal',
  templateUrl: './dealer-locator-modal.component.html',
  styleUrls: ['./dealer-locator-modal.component.scss']
})
export class DealerLocatorModalComponent implements OnInit {

    initComponent = false;

    constructor() {}

    ngOnInit(): void {}
}
