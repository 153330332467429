<zk-popup-modal id="compatibility-check"
                 [headline]="headline"
                 [closeOnClickOutside]="false"
                 (onClose)="closeComponent()"
                 (onOpen)="initComponent()">

    <div modalContent *ngIf="isOpen">
        <wb-text size="l" class="intro-text" zkTranslate="COMPATIBILITYCHECK.INTRO"></wb-text>
        <wb-text size="l" class="compatibility-check_check-info">
            <mba-icon icon="info" iconClass="icon_text icon_align-middle"></mba-icon>
            <span class="compatibility-check_check-info-text" zkTranslate="COMPATIBILITYCHECK.CHECKINFOWISHLIST"></span>
        </wb-text>

        <div class="compatibility-check_vin-wrapper">
            <!-- VIN Input -->
            <form>
                <input class="ui-input compatibility-check_vin-field"
                       [ngClass]="{'has-err-msg' : displayVINErrorMessage}"
                       value=""
                       [placeholder]="placeholder"
                       name="vin"
                       maxlength="17"
                       [(ngModel)]="vinNumber"
                       required
                       zkOnlyAlphanumeric>

                <wb-button [variant]="compatibilityChecked && vehicleFound ? 'secondary' : 'primary'"
                           class="compatibility-check_btn-check"
                           size="s"
                           [disabled]="showLoadingSpinner || !vinNumber || vinNumber.length === 0"
                           (click)="checkCompatibility()"
                           zkTrackClick="manual-compatibility-check"
                           zkTrackClickPosition="product">
                    <span zkTranslate="COMPATIBILITYCHECK.CHECKCOMPATIBILITY"></span>
                </wb-button>
            </form>

            <!-- Feedback during VIN check -->
            <mba-loading-spinner *ngIf="showLoadingSpinner"
                                class="compatibility-check_loading-spinner"
                                [showLoadingPercent]="true"
                                [loadingPercent]="loadingSpinnerText"
                                [topFiftyPercent]="false"
                                [leftFiftyPercent]="false"
                                [positionAbsolute]="false">
            </mba-loading-spinner>

            <div *ngIf="!displayVINErrorMessage && vinVehicleIdentificationText && !showLoadingSpinner"
                 class="compatibility-check_vehicle">
                <wb-text tag="span" size="l" zkTranslate="COMPATIBILITYCHECK.YOURCAR"></wb-text>
                <wb-text tag="span" size="l" strong class="compatibility-check_gap">{{vinVehicleIdentificationText}}</wb-text>
            </div>

            <wb-text size="l" *ngIf="showLoadingSpinner">{{progressFeedbackText}}</wb-text>
            <wb-text *ngIf="displayVINErrorMessage" size="m" class="compatibility-check_vehicle is-error">{{vinVehicleIdentificationText}}</wb-text>
        </div>

        <!-- Where to find the VIN info drop down -->
        <div *ngIf="showDescription"
             class="compatibility-check_vin-help">
             <mba-button [icon]="toggledVINDescription ? 'arrow-thin-up-new' : 'arrow-thin-down-new'"
                         [isLink]="true"
                         [iconRight]="true"
                         [hasIconOnMobile]="true"
                         (clicked)="toggledVINDescription = !toggledVINDescription">
                         <wb-text size="l" zkTranslate="COMPATIBILITYCHECK.FINDVIN"></wb-text>
             </mba-button>

            <div class="compatibility-check_vin-description"
                 [ngClass]="{'is-closed': !toggledVINDescription}">
                <img class="compatibility-check_vin-image"
                     alt="VIN example image"
                     src="ui/assets/img/technicalDataDetailExample.jpg">
                <wb-text size="m" class="compatibility-check_vin-description_info is-light-grey"
                   zkTranslate="VINLOCK.FURTHERINFORMATION"></wb-text>
            </div>
        </div>

        <!-- Current articles that haven´t been added to the shopping cart -->
        <div *ngIf="currentArticles && currentArticles.length > 0" class="compatibility-check_detail-page">
            <wb-text size="l" strong class="headline" zkTranslate="COMPATIBILITYCHECK.DETAILARTICLE"></wb-text>

            <div *ngFor="let item of currentArticles">
                <zk-cart-item *ngIf="item.fit || !compatibilityChecked"
                              [cartItem]="item"
                              [isCompatibilityCheck]="true"
                              [isDetailItem]="true"
                              [isInModal]="true"
                              [hideDeleteIcon]="true">
                </zk-cart-item>
            </div>

            <div *ngFor="let item of currentArticles">
                <div class="compatibility-check_not-orderable_wrapper"
                     *ngIf="item.fit === false && compatibilityChecked">
                    <img class="compatibility-check_not-orderable_image"
                         [alt]="item.headline"
                         [src]="item.image">
                    <div class="compatibility-check_not-orderable_description">
                        <wb-text size="l" strong zkTranslate="COMPATIBILITYCHECK.ARTICLENOTORDERABLE"></wb-text>
                        <wb-text size="l" zkTranslate="COMPATIBILITYCHECK.NOTORDERABLEREASON"></wb-text>
                        <wb-link variant="standalone" class="compatibility-check_link"
                                 [routerLink]="getMaincategoryURL(item)"
                                 (click)="abortCompatibilityCheck()">
                            <span zkTranslate="COMPATIBILITYCHECK.SEARCHSUITABLEALT"></span>
                        </wb-link>
                    </div>
                </div>
            </div>

            <mba-separator separatorClass="separator_is-light-grey"></mba-separator>

            <div *ngIf="compatibilityChecked && areCurrentArticlesNotFitting()"
                 class="compatibility-check_alternative-info">
                <wb-text size="l" tag="span" zkTranslate="COMPATIBILITYCHECK.CONTINUESHOPPINGINFO"></wb-text>
                <wb-text size="l" tag="span" strong>&nbsp;{{vinVehicleIdentificationText}}</wb-text>
            </div>

            <wb-button variant="primary"
                       class="compatibility-check_add-cart"
                       (click)="addToCart()"
                       *ngIf="canAddToCart && compatibilityChecked">
                <span zkTranslate="PRODUCTDETAILS.ADDTOWISHLIST"></span>
            </wb-button>
            <wb-button variant="primary"
                       class="compatibility-check_add-cart"
                       (click)="navigateToMainCategory()"
                       *ngIf="compatibilityChecked && areCurrentArticlesNotFitting()">
                <span zkTranslate="GENERAL.SEARCHALTERNATIVE"></span>
            </wb-button>
        </div>

        <!-- Shopping Cart -->
        <div class="compatibility-check_cart-articles"
             *ngIf="articlesInCart && articlesInCart.items && articlesInCart.items.length > 0">
            <wb-text tag="h2" size="l" strong class="headline"
                zkTranslate="COMPATIBILITYCHECK.ARTICLEINWISHLIST"></wb-text>
            <ul class="compatibility-check_unordered-list">
                <ng-container *ngFor="let article of articlesInCart.items">
                    <li class="compatibility-check_unordered-list"
                        *ngIf="article.appMode === appMode.ACCESSORIES">

                        <!-- STATE: Available for car or unchecked -->
                        <zk-cart-item [cartItem]="article"
                                      [isInModal]="true"
                                      *ngIf="article.fit || !compatibilityChecked"
                                      [isCompatibilityCheck]="true">
                        </zk-cart-item>

                        <!-- STATE: Not available for car -->
                        <div class="compatibility-check_not-orderable_wrapper"
                             *ngIf="article.fit === false && compatibilityChecked">
                            <img class="compatibility-check_not-orderable_image"
                                 [src]="article.image"
                                 [alt]="article.headline">
                            <div class="compatibility-check_not-orderable_description">
                            <wb-text size="l" strong zkTranslate="COMPATIBILITYCHECK.ARTICLEREMOVEDWISHLIST"></wb-text>
                                <wb-text size="l" zkTranslate="COMPATIBILITYCHECK.NOTORDERABLEREASON"></wb-text>
                                <wb-link variant="standalone" class="compatibility-check_link"
                                         [routerLink]="goToCategory(article)"
                                         (click)="abortCompatibilityCheck()">
                                    <span zkTranslate="GENERAL.SEARCHALTERNATIVE"></span>
                                </wb-link>
                            </div>
                        </div>
                    </li>
                </ng-container>
            </ul>
        </div>

        <wb-text size="l" *ngIf="displayServiceErrorMessage" class="spacing-s is-error" zkTranslate="ERRORMESSAGE.SERVICEUNAVAILABLE"></wb-text>

        <!-- Gross Price -->
        <div class="compatibility-check_total-price"
             *ngIf="showTotalGrossPrice() && totalGrossPrice !== ''">
            <span class="ui-sub-heading-xxs is-dark"
                  zkTranslate="SHOPPINGCART.SUMINCVAT"></span>
            <span class="ui-sub-heading-xxs is-dark">
                {{totalGrossPrice}}
            </span>
        </div>

        <div class="compatibility-check_footer">
            <!-- Cancel and checkout button -->
            <div class="compatibility-check_footer_right-column">
                <wb-button variant="secondary"
                           class="compatibility-check_footer_cancel-btn"
                           [ngClass]="{'has-margin-right': isCheckoutBtnAvailable }"
                           (click)="abortCompatibilityCheck()">
                    <span zkTranslate="GENERAL.ABORT"></span>
                </wb-button>

                <wb-button *ngIf="isCheckoutBtnAvailable"
                           variant="primary"
                           class="compatibility-check_checkout-btn"
                           (click)="printPdfAndCloseModal()"
                           [disabled]="showLoadingSpinnerPDF"
                           [loading]="showLoadingSpinnerPDF"
                           zkTrackClick="pdf-download"
                           zkTrackClickPosition="product">
                    <wb-icon class="wb-button__icon" name="bds/download-print/24"></wb-icon>
                    <span zkTranslate="SHOPPINGCART.DOWNLOADPDF"></span>
                </wb-button>
            </div>
        </div>
    </div>
</zk-popup-modal>
