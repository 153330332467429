// ng
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
// services
import { TranslationService } from '@shared/shared-services/translate/translation.service';
// models
import { Wheel } from '@shared/global-models/wheel.model';
import { Article } from '@shared/global-models/article/article.model';

@Injectable()
export class WheelSpecialService {
    openWheelDetails = new Subject<Wheel>();

    private previewArticles: Article[] = [];
    private showQuantityLayer: Subject<Article[]> = new Subject<Article[]>();
    private previewAsCompleteSet: boolean = false;

    constructor(private _httpClient: HttpClient, private _translationService: TranslationService) {}

    /**
     * Sets the article list that is shown in the quantity change preview modal and triggers a notification to show the modal
     */
    set wheelPreviewArticles(articles: Article[]) {
        if (articles && articles.length > 0 && articles !== this.previewArticles) {
            this.previewArticles = articles;
            this.showQuantityLayer.next(this.previewArticles);
        }
    }

    /**
     * Returns if single wheel or wheel set should be shown in the quantity change preview modal
     */
    get wheelPreviewAsSet(): boolean {
        return this.previewAsCompleteSet;
    }

    /**
     * Sets if single wheel or wheel set should be shown in the quantity change preview modal
     */
    set wheelPreviewAsSet(val: boolean) {
        this.previewAsCompleteSet = val;
    }

    /**
     * Subscribe to get informed when the quantity change preview modal should be shown
     */
    get showQuantityLayerInfo(): Observable<Article[]> {
        return this.showQuantityLayer;
    }

    /**
     * Get informed if the wheel component shows it´s more info layer
     */
    get openWheelDetailsInfo() {
        return this.openWheelDetails.asObservable();
    }

    /**
     * triggers the notification that the wheel component opens it´s more info layer
     * @param wheelToOpen - The wheel that opens it´s detail layer
     */
    triggerOpenWheelDetailsInfo(wheelToOpen: Wheel) {
        this.openWheelDetails.next(wheelToOpen);
    }
}
