<zk-popup-modal id="rim-set-choose-quantity-modal"
                 class="rim-info-modal"
                 [headline]="modalHeadline">

    <div modalContent
         class="content">
        <!-- Rim articles -->
        <div *ngFor="let cartItem of shownSet">
            <zk-cart-item [cartItem]="cartItem"
                          [isRimSetPreviewLayer]="true"
                          [isInModal]="true"
                          [hideDeleteIcon]="hideDeleteIcon"
                          (onDeleteEvent)="onDelete($event)"
                          (onQuantityChangedEvent)="onQuantityChanged($event)">
            </zk-cart-item>
        </div>
        <mba-separator separatorClass="separator_is-light-grey"></mba-separator>
        <!-- Single rim options -->
        <div *ngIf="!rearAxleAdded || !frontAxleAdded || !setQuantityOptimal"
             class="actionList">
            <wb-text size="l" *ngIf="!rearAxleAdded"
                  class="interactive"
                  zkTranslate="WHEELS.ADDREARAXLE"
                  (click)="addAxlePartner(true)">
            </wb-text>
            <wb-text size="l" *ngIf="!frontAxleAdded"
                  class="interactive"
                  zkTranslate="WHEELS.ADDFRONTAXLE"
                  (click)="addAxlePartner(false)">
            </wb-text>
            <wb-text size="l" *ngIf="!setQuantityOptimal"
                  class="interactive"
                  zkTranslate="WHEELS.SELECTFULLSET"
                  (click)="adjustQuantityToCompleteSet()">
            </wb-text>
        </div>

        <mba-separator *ngIf="!setQuantityOptimal"
                      separatorClass="separator_is-light-grey">
        </mba-separator>
    </div>

    <div modalFooter
         class="modal-footer">
        <wb-button variant="secondary"
                   (click)="closeModal()">
            <span zkTranslate="GENERAL.ABORT"></span>
        </wb-button>
        <wb-button variant="primary"
                   class="last"
                   (click)="onAddToCart()">
            <span zkTranslate="PRODUCTDETAILS.ADDTOWISHLIST"></span>
        </wb-button>
    </div>
</zk-popup-modal>
