<div class="page-wrapper">
    <wb-heading tag="h1" size="l">{{headline}}</wb-heading>

    <!-- Tabs for ACC only and if results exits in both article categories -->

    <wb-tabs *ngIf="isAccessoriesMode && wheelArticles?.articleInfo?.items > 0 && accArticles?.articleInfo?.items > 0"
             class="spacing-l" fitted [selected]="activeTab">
        <wb-tab name="acc" (click)="activeTab = 'acc'">
            {{tabHeadlineAcc}}
            <wb-counter style="margin-left: 10px" [value]="accArticles.articleInfo.items" variant="secondary" max="99999"></wb-counter>
        </wb-tab>

        <wb-tab name="wheel" (click)="activeTab = 'wheel'">
            {{tabHeadlineWheels}}
            <wb-counter style="margin-left: 10px" [value]="wheelArticles.articleInfo.items" variant="secondary" max="99999"></wb-counter>
        </wb-tab>
    </wb-tabs>

    <!-- ACC & COLL -->

    <div *ngIf="isAccessoriesMode && activeTab === 'acc' || !isAccessoriesMode" class="spacing-l">
        <zk-product-teaser-cards>
            <zk-product-teaser-card *ngFor="let article of accArticles?.articles"
                                    [article]="article"
                                    [isCollection]="!isAccessoriesMode">
            </zk-product-teaser-card>
        </zk-product-teaser-cards>
    </div>

    <wb-pagination *ngIf="activeTab === 'acc' && accArticles?.articleInfo?.pages > 1"
                   class="pagination spacing-s"
                   zkPagination
                   compact="{{isMobile}}"
                   pages="{{accArticles.articleInfo.pages}}"
                   [currentPage]="accArticles.articleInfo.page + 1"
                   (currentPageEmitter)="handlePagination($event, false)">
    </wb-pagination>

    <!-- ACC - WHEELS - if "light-alloy-wheel" articles exist -->

    <div *ngIf="isAccessoriesMode && activeTab === 'wheel'" class="spacing-l">
        <div *ngFor="let wheel of wheelArticles.articles" class="wheel">
            <zk-wheel [wheelData]="wheel"></zk-wheel>
        </div>
    </div>

    <wb-pagination *ngIf="isAccessoriesMode && activeTab === 'wheel' && wheelArticles?.articleInfo?.pages > 1"
                   class="pagination spacing-s"
                   zkPagination
                   compact="{{isMobile}}"
                   pages="{{wheelArticles.articleInfo.pages}}"
                   [currentPage]="wheelArticles.articleInfo.page + 1"
                   (currentPageEmitter)="handlePagination($event, true)">
    </wb-pagination>

    <!-- Eventual results in other mode -->
    <zk-teaser-banner *ngIf="isAccessoriesMode && searchResult?.numberOfCollectionArticles > 0"
                      (click)="searchInOtherMode()"
                      class="spacing-l"
                      [text]="teaserBannerText"
                      iconSrc="ui/assets/icons/misc/search_collection.svg">
    </zk-teaser-banner>
    <zk-teaser-banner *ngIf="!isAccessoriesMode && searchResult?.numberOfAccessoryArticles > 0"
                      (click)="searchInOtherMode()"
                      class="spacing-l"
                      [text]="teaserBannerText"
                      wbIconName="bds/vehicle-search/24">
    </zk-teaser-banner>
</div>
