<!-- Shopping Cart article added feedback modal -->
<zk-popup-modal id="shopping-cart-info-modal"
                 class="cart-info-modal"
                 (onOpen)="modalOpens()"
                 [headline]="modalHeadline">

    <div modalContent class="content">
        <div class="border">
            <zk-cart-item *ngFor="let cartItem of article?.items"
                          [cartItem]="cartItem"
                          [isAddedToCartFeedback]="true">
            </zk-cart-item>
        </div>

        <wb-text *ngIf="!compatibilityChecked" size="l" class="info">
            <span>{{compatibilityPre}}</span>
            <wb-link (click)="openCompatibilityCheck()" variant="standalone">{{compatibilityLink}}</wb-link>
            <span>{{compatibilityPost}}</span>
        </wb-text>
    </div>

    <div modalFooter class="modal-footer">
        <wb-button variant="secondary"
                   (click)="closeModal()">
            <span zkTranslate="SHOPPINGCART.CONTINUESHOPPING"></span>
        </wb-button>

        <wb-button variant="primary" class="last"
                   (click)="printPDF()"
                   zkTrackClick="pdf-download"
                   zkTrackClickPosition="cart">
            <wb-icon class="wb-button__icon" name="bds/download-print/24"></wb-icon>
            <span zkTranslate="SHOPPINGCART.DOWNLOADPDF"></span>
        </wb-button>
    </div>
</zk-popup-modal>
