<!-- Start Page Warning Modal -->
<zk-popup-modal id="reset-warning-modal"
                 class="warning-modal"
                 [headline]="modalHeadline"
                 (onOpen)="initComponent()">

    <div modalContent class="content">
        <wb-text size="l">{{modalContentText}}</wb-text>
    </div>

    <div modalFooter class="modal-footer">
        <wb-button variant="secondary"
                   (click)="onCancel()">
            <span zkTranslate="GENERAL.ABORT"></span>
        </wb-button>

        <wb-button variant="primary" class="last"
                   (click)="onContinue()">
            <span zkTranslate="GENERAL.CONTINUE"></span>
        </wb-button>
    </div>
</zk-popup-modal>
