import { Component, OnInit } from '@angular/core';
import { SeoService } from "@shared/shared-services/seo/seo.service";

@Component({
    selector: 'zk-splash-page',
    templateUrl: './splash-page.component.html',
    styleUrls: ['./splash-page.component.scss']
})
export class SplashPageComponent implements OnInit {

    constructor(
        private _seoService: SeoService,
    ) {
    }

    ngOnInit(): void {
        this.setSeoInfo();
    }

    private setSeoInfo() {
        const title: string = this._seoService.getTranslation('SPLASHPAGE.PAGE_TITLE');
        this._seoService.updatePageTitle(title);
        this._seoService.updateMetaDescription(title);
    }
}
