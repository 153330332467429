// ng
import { Component, Input, OnInit } from '@angular/core';

// model
import { ResponsiveImage } from '@shared/global-models/responsiveImages.model';

@Component({
    selector: 'zk-single-image-stage',
    templateUrl: './single-image-stage.component.html',
    styleUrls: ['./single-image-stage.component.scss']
})
export class SingleImageStageComponent implements OnInit {
    @Input()
    image: ResponsiveImage;

    @Input()
    headline: string = '';

    constructor() {}

    ngOnInit(): void {
    }
}
