import { Directive, HostListener, Output, EventEmitter } from '@angular/core';

@Directive({
	selector: '[imgLoaded]',
})
export class ImgLoadedDirective {
	@Output() loaded = new EventEmitter<boolean>();

	@HostListener('load')
	imageLoaded() {
		this.loaded.emit(true);
		this.loaded.complete();
	}

	@HostListener('error')
	imageNotLoaded() {
        this.loaded.emit(false);
        this.loaded.complete();
	}
}
