// ng
import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

// services
import { TeaserService } from '@shared/shared-services/teaser/teaser.service';
import { SeoService } from '@shared/shared-services/seo/seo.service';
import { ProductGroupsService } from '@shared/shared-services/product-groups/product-groups.service';

// models
import { Slider } from '@shared/global-models/wb-models/wb-slider.model';
import { ProductTeaserBig } from '@shared/global-models/wb-models/wb-product-teaser-big.model';
import { TextTeaser } from '@shared/global-models/textTeaser.model';

@Component({
    selector: 'zk-collection-start-page',
    templateUrl: './collection-start-page.component.html',
    styleUrls: ['./collection-start-page.component.scss']
})
export class CollectionStartPageComponent implements OnInit {
    slides: Slider[];
    textTeaser: TextTeaser;
    productTeaserBig: ProductTeaserBig;
	firstSlideHeadline: string;

    constructor(private _teaserService: TeaserService,
				private _seoService: SeoService,
				private _productGroupsService: ProductGroupsService) {}

    ngOnInit(): void {
        this.getSliderImages();
        this.getTextTeaser();
        this.getProductTeaserBig();
		this.getAllProductGroups();
    }

    private getSliderImages(): void {
        const sub: Subscription = this._teaserService.getSlidesForCollectionStartPage().subscribe(
            (response: any) => {
                sub.unsubscribe();

                if (!response) {
                    console.error('Error on slides retrieved from CMS: Response is null');
                    return;
                }

                this.slides = response.slides;
				this.firstSlideHeadline = response.slides[0].headline

				this.setSeoInfo();
            },
            (error) => {
                sub.unsubscribe();
                console.log('Error: ', error);
            }
        );
    }

    private getTextTeaser(): void {
        const sub: Subscription = this._teaserService.getCmsTextForTextTeaser().subscribe(
            (response: TextTeaser) => {
                sub.unsubscribe();
                this.textTeaser = response;
            },
            (error) => {
                sub.unsubscribe();
                console.log('Error: ', error);
            }
        );
    }

    private getProductTeaserBig(): void {
        const sub: Subscription = this._teaserService.getCmsDataForProductTeaserBig().subscribe(
            (response: ProductTeaserBig) => {
                sub.unsubscribe();
                this.productTeaserBig = response;
            },
            (error) => {
                sub.unsubscribe();
                console.log('Error: ', error);
            }
        );
    }

	private getAllProductGroups() {
        const sub: Subscription = this._productGroupsService.getAllProductGroups().subscribe(() => sub.unsubscribe());
	}

	private setSeoInfo() {
		const title: string = this._seoService.getTranslation('ACCESSORIES.FLYOUT.TEASER.TITEL');
		this._seoService.updatePageTitle(title + ' | ' + this.firstSlideHeadline);
		this._seoService.updateMetaDescription(this._seoService.getTranslation('LIFESTYLE.SEO.METADESCRIPTION.STARTPAGE'));
	}
}
