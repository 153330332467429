// ng
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

// Models
import { Notification, NotificationTypes } from './notification.model';

@Injectable({
	providedIn: 'root'
})
export class NotificationService {
	notificationTriggered: Subject<Notification> = new Subject<Notification>();

	constructor() {
	}

	triggerNotification(notification: Notification) {
		if (notification.type != NotificationTypes.ERROR) {
			setTimeout(() => {
				notification.isActive = false;
			}, notification.lifeTime);
		}

		this.notificationTriggered.next(notification);
	}
}
