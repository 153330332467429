<wb-stage *ngIf="data.images || data.video" theme="{{data.dark ? 'dark' : 'light'}}">

    <wb-aspect-ratio slot="media" ratio="9x4" *ngIf="data.images && !data.video">
        <picture>
            <source [srcset]="data.images.image768" media="(max-width:767px)">
            <source [srcset]="data.images.image1280" media="(max-width:1279px)">
            <img [src]="data.images.image1680" alt="{{data.imageDescription}}">
        </picture>
    </wb-aspect-ratio>

    <wb-aspect-ratio slot="media" ratio="9x4" *ngIf="data.video">
        <video autoplay muted loop [src]="data.video"></video>
    </wb-aspect-ratio>

    <wb-heading tag="h1" size="xl">{{ data.headline }}</wb-heading>
    <wb-text tag="p" size="l" class="wb-margin-top-s">{{ data.marketingText }}</wb-text>
</wb-stage>
