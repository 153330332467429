import { Directive, HostListener } from '@angular/core';

@Directive({
    selector: '[zkOnlyAlphanumeric]'
})
export class OnlyAlphanumericDirective {
    regex = '^[a-zA-Z0-9 ]*$';

    constructor() {}

    @HostListener('keydown', ['$event'])
    onKeyDown(event) {
        const e = <KeyboardEvent>event;

        if (
            [46, 8, 9, 27, 13, 110].indexOf(e.keyCode) !== -1 ||
            // Ctrl+A
            (e.keyCode === 65 && e.ctrlKey === true) ||
            // Ctrl+C
            (e.keyCode === 67 && e.ctrlKey === true) ||
            // Ctrl+V
            (e.keyCode === 86 && e.ctrlKey === true) ||
            // Ctrl+X
            (e.keyCode === 88 && e.ctrlKey === true) ||
            // home, end, left, right
            (e.keyCode >= 35 && e.keyCode <= 39)
        ) {
            return;
        }

        // numbers on top of keyboard ( 0 - 9 ) : 48 - 57
        // numbers on numeric keypad ( 0 - 9 ) : 96 - 105

        const char = e.key;
        const regEx = new RegExp(this.regex);

        if (
            regEx.test(char) &&
            !(e.shiftKey === true && ((e.keyCode >= 48 && e.keyCode <= 57) || (e.keyCode >= 96 && e.keyCode <= 105)))
        ) {
            return;
        } else {
            e.preventDefault();
            e.returnValue = false;
            e.stopPropagation();
        }
    }
}
