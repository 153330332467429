// ng
import { Component, OnInit } from '@angular/core';

// services
import { SelectedCarService } from '@shared/shared-services/selected-car/selected-car.service';

@Component({
    selector: 'zk-passengercars',
    templateUrl: './passengercars.component.html',
    styleUrls: ['./passengercars.component.scss']
})
export class PassengercarsComponent implements OnInit {
    constructor(private _selectedCarService: SelectedCarService) {}

    ngOnInit(): void {
       	// react to browser back button action (ZKDEV-1658)
        if (this._selectedCarService.vehicleType?.vehicleTypeId !== 'car') {
            this._selectedCarService.vehicleType = this._selectedCarService.vehicleTypeCar;
        }
    }
}
