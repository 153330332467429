<div class="teaser-large" [ngClass]="{'is-stacked': isStacked, 'is-article': data.articleNumber}">
    <div class="image-wrapper">
        <ng-container *ngIf="!data.articleNumber; else articleData">
            <picture>
                <source [srcset]="data.images.image768" media="(max-width:767px)">
                <img [src]="data.images.image1440" alt="{{data.imageDescription}}">
            </picture>

            <picture class="nested_image" *ngIf="isStacked">
                <img [src]="data.imageForeground?.image" alt="">
            </picture>
        </ng-container>

        <ng-template #articleData>
            <picture>
                <img [src]="data.images.image" alt="{{data.imageDescription}}">
            </picture>
        </ng-template>
    </div>

    <h3 class="teaser-large_headline">{{ data.headline }}</h3>
    <p class="teaser-large_description" *ngIf="data.marketingText">{{ data.marketingText }}</p>
    <p class="teaser-large_price" *ngIf="data.price">{{ data.price }}</p>
    <wb-button variant="secondary" *ngIf="data.articleNumber"
               zkTranslate="CAMPAIGN.BUY_NOW"
               (click)="routeToArticle()">
    </wb-button>
</div>
