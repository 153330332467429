// ng
import { Component, Input, Output, EventEmitter } from '@angular/core';

// model
import { Article } from '@shared/global-models/article/article.model';

@Component({
    selector: 'zk-product-teaser-big-with-price',
    templateUrl: './product-teaser-big-with-price.component.html',
    styleUrls: ['./product-teaser-big-with-price.component.scss']
})
export class ProductTeaserBigWithPriceComponent {
    /**
     * Defines the label of the headline.
     */
    @Input() mainHeadline: string;

    /**
     * Article that should be displayed.
     */
    @Input() article: Article;

    /**
     * Defines the [routerLink] property of the headline
     */
    @Input() headlineLink: string;

    /**
     * Event that is emitted when a click on the component happens.
     * Emits the clicked article object. Can be used for routing for instance.
     */
    @Output() onProductClick = new EventEmitter<Article>();

    /**
     * Defines the label of the bottom link.
     */
    @Input() buttonLinkLabel: string = '';

    constructor() {}

    /**
     * Emits an event with the clicked article object. Application can decide what to do.
     * For instance route to the product detail page of the article
     * @param article | Article
     */
    onClickPicture(article: Article) {
        this.onProductClick.emit(article);
    }
}
