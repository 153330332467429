<div class="wish-list page-wrapper">
    <wb-heading size="m" zkTranslate="SHOPPINGCART.WISHLIST"></wb-heading>

    <div *ngIf="cartData && cartData.items.length > 0 && !displayErrorMessage"
         class="wish-list_items">

        <ul *ngIf="collectionArticles && collectionArticles.length > 0"
            class="wish-list_items_list">
            <p class="cart-type-headline" zkTranslate="LIFESTYLE.GENERAL.COLLECTION"></p>

            <li *ngFor="let cartItem of collectionArticles;"
                class="wish-list_items_list_cart-item">

                <zk-cart-item [cartItem]="cartItem"></zk-cart-item>

            </li>
        </ul>

        <ul *ngIf="accessoriesArticles && accessoriesArticles.length > 0"
            class="wish-list_items_list">
            <p class="cart-type-headline">
                <span zkTranslate="LIFESTYLE.GENERAL.ACCESSORIES"></span> {{ selectedCar }}
                <span class="cart-type-headline_vin" *ngIf="selectedVIN">({{ selectedVIN }})</span>
            </p>

            <li *ngFor="let cartItem of accessoriesArticles"
                class="wish-list_items_list_cart-item">

                <zk-cart-item [cartItem]="cartItem"></zk-cart-item>

            </li>
        </ul>

        <div class="wish-list_bottom-container">
            <div class="wish-list_bottom-container_left-column">
                <ng-container *ngIf="!isSmallScreen">
                    <div class="compatibility-check"
                         *ngIf="!compatibilityChecked && accessoriesArticles.length > 0">
                        <span zkTranslate="SHOPPINGCART.COMPATIBILITYCHECK.PRE"></span>
                        <span class="wish-list_link"
                              (click)="openCompatibilityCheck()"
                              zkTranslate="PRODUCTDETAILS.COMPATIBILITYCHECK">
                        </span>
                        <span zkTranslate="SHOPPINGCART.COMPATIBILITYCHECK.POST"></span>
                    </div>
                </ng-container>
            </div>
            <div class="wish-list_bottom-container_right-column">
                <div *ngIf="showPrice"
                     class="wish-list_total-price">
                        <span class="price-text">
                            <span zkTranslate="LIFESTYLE.CART.TOTALSUM"></span>:
                        </span>
                    <span class="price-text">
                            {{collectionArticleHasVariousPrices ? translationService.translate('LIFESTYLE.GENERAL.FROM') + ' ' : ''}} {{cartData.totalGrossPriceText}}
                        </span>
                </div>

                <span *ngIf="showPrice" class="wish-list_total-price_incl">
                        *<span zkTranslate="LIFESTYLE.CART.VAT"></span>
                    </span>

                <ng-container *ngIf="isSmallScreen">
                    <div class="compatibility-check"
                         *ngIf="!compatibilityChecked && accessoriesArticles.length > 0">
                        <span zkTranslate="SHOPPINGCART.COMPATIBILITYCHECK.PRE"></span>
                        <span class="wish-list_link"
                              (click)="openCompatibilityCheck()"
                              zkTranslate="PRODUCTDETAILS.COMPATIBILITYCHECK">
                        </span>
                        <span zkTranslate="SHOPPINGCART.COMPATIBILITYCHECK.POST"></span>
                    </div>
                </ng-container>

                <div class="wish-list_buttons">
                    <wb-button variant="primary"
                               class="wb-button wb-button--primary wb-button--large wish-list_checkout-btn"
                               [disabled]="showLoadingSpinner"
                               (click)="printPDF()"
                               zkTrackClick="pdf-download"
                               zkTrackClickPosition="cart">
                        <wb-icon *ngIf="!showLoadingSpinner" class="wb-button__icon"
                                 name="bds/download-print/24"></wb-icon>
                        <wb-spinner *ngIf="showLoadingSpinner" class="loading-spinner" theme="light"></wb-spinner>
                        <span zkTranslate="SHOPPINGCART.DOWNLOADPDF"></span>
                    </wb-button>
                </div>
            </div>
        </div>
    </div>
    <wb-text size="l" *ngIf="(!cartData || cartData.items.length === 0) && !displayErrorMessage"
         class="placeholder-text"
         zkTranslate="SHOPPINGCART.EMPTYWISHLIST">
    </wb-text>

    <wb-text size="l" class="placeholder-text is-error"
         *ngIf="displayErrorMessage"
         zkTranslate="ERRORMESSAGE.SERVICEUNAVAILABLE">
    </wb-text>
</div>
