// ng
import {
	Component,
	ElementRef,
	EventEmitter,
	HostListener,
	Input,
	OnDestroy,
	OnInit,
	Output,
	Renderer2,
	ViewChild
} from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { environment } from 'environments/environment';
// Services
import { CarChooserService } from './car-chooser.service';
import { SelectedCarService } from '@shared/shared-services/selected-car/selected-car.service';
import { ViewportService } from '@mbcs/mbcs-lib';
import { CarChooserHelperService } from '@shared/components/car-chooser/car-chooser-helper.service';
import { ResetWarningModalService } from '@shared/modals/reset-warning-modal/reset-warning-modal.service';
import { TranslationService } from "@shared/shared-services/translate/translation.service";
import { AppService } from "../../../app.service";
// Components
import { VinLockComponent } from '@shared/components/vin-lock/vin-lock.component';
import { BodyTypeComponent } from '@shared/components/car-chooser/body-type/body-type.component';
// Models
import { BodyType, CarClass, CarLine, ModelDesign } from './models';

@Component({
    selector: 'zk-car-chooser',
    templateUrl: 'car-chooser.component.html',
    styleUrls: ['./car-chooser.header.component.scss', './car-chooser.component.scss'],
    animations: [
        trigger('detailsAnimation', [
            // desktop
            state(
                'open',
                style({
                    top: '{{topValue}}',
                    height: '{{heightValue}}'
                }),
                {
                    params: {
                        topValue: 'unset',
                        heightValue: 'unset'
                    }
                }
            ),
            // mobile
            state(
                'idle',
                style({
                    top: '{{topValue}}',
                    height: '{{heightValue}}'
                }),
                {
                    params: {
                        topValue: 'unset',
                        heightValue: 'unset'
                    }
                }
            ),
            state('closed', style({ top: '{{closedTop}}', height: 0 }), {
                params: {
                    closedTop: '0'
                }
            }),
            state('closeFast', style({ top: '{{closedTop}}', height: 0 }), {
                params: {
                    closedTop: '0'
                }
            }),
            transition('void => open', [animate('500ms ease-in-out')]),
            transition('* => closed', [animate('500ms ease-in-out')]),
            transition('* => closeFast', [animate('1ms')])
        ])
    ]
})
export class CarChooserComponent implements OnInit, OnDestroy {
    // The element references to calculate the super cool design
    @ViewChild('wholeCarChooser', { static: true })
    private wholeCarChooser: ElementRef;
    @ViewChild('vinLock')
    private vinLockComponent: VinLockComponent;
    @ViewChild('bodyTypes')
    private bodyTypes: BodyTypeComponent;
    @ViewChild('overscrollIndicator')
    private overscrollIndicator: ElementRef;

    // car chooser variants
    @Input()
    isInHeader = false;
    @Input()
    canBeSticky = false;
    @Input()
    isOnStartPage = false;
    @Output()
    offsetHeightWhenSticky: EventEmitter<number> = new EventEmitter<number>();
    @Output()
    selectedCarClas: EventEmitter<string> = new EventEmitter<string>();

    currentCarClass: CarClass = null;
    currentBodyType: BodyType = null;
    currentModelDesign: ModelDesign = null;
    currentCarLine: CarLine = null;
    classPaddingBottom = '0';
    classZIndex = 'auto';
    chooseCarText = 'CARCHOOSER.CHOOSECAR';
    carClasses: CarClass[];
    mostRecentCarLine: CarLine | ModelDesign;
    isMobile = false;
    openStickyCarChooser = false;
    vinLayerIsActive = false;
    // animation variables
    currentState = 'closed';
    currentTop: string;
    closedTop: string;
    currentHeight: string;
    isFixed = false;

    environment = environment;
	carIsLogged = false;

    private viewportSubscriber: Subscription;
    private _clickOutsideListener: () => void;

	get isAmg(): boolean {
		return this.selectedCarService.vehicleType?.vehicleTypeId === 'amg';
	}

    constructor(
        private carChooserService: CarChooserService,
        private selectedCarService: SelectedCarService,
        private viewportService: ViewportService,
        private componentReference: ElementRef,
        private activatedRoute: ActivatedRoute,
        private carChooserHelperService: CarChooserHelperService,
        private resetWarningService: ResetWarningModalService,
        private _translationService: TranslationService,
        private _appService: AppService,
        private _renderer: Renderer2
    ) {}

    ngOnInit() {
        this.handleSubscriptions();

        // two different labels whether we are on the start page or not
        if (!this.isOnStartPage) {
            this.chooseCarText = this.activatedRoute.snapshot.params.product
                ? 'CARCHOOSER.FOOTER.PRODUCTFIT'
                : 'CARCHOOSER.FOOTER.CHOOSECAR';
        }

        const vp: string = this.viewportService.getCurrentViewPort();
        this.isMobile = vp === 'mq1' || vp === 'mq2';
    }

    ngOnDestroy() {
        if (this.viewportSubscriber) {
            this.viewportSubscriber.unsubscribe();
        }

        if (this._clickOutsideListener) {
	        this._clickOutsideListener();
        }
    }

    /**
     * Handles the click on the sticky car chooser for mobile and tablet
     */
    clickStickyCarChooser() {
        this.openStickyCarChooser = !this.openStickyCarChooser;

        if (!this.openStickyCarChooser) {
            this.currentState = 'closeFast';
            this.setActiveCarClass(null);
        }
    }

    /**
     * Handle the hover over a car class ONLY for car chooser on page and mobile version.
     * @param carClass Car class which is being hover
     * @param cmpRef Component reference which is being hovered
     */
    carClassHover(carClass: CarClass, cmpRef: any) {
        if (!this.isInHeader) {
			this.currentCarClass = carClass;
            this.classZIndex = '2';
            this.setActiveCarClass(carClass);

            // set preview silhouette image
            this.setCarLinePreviewImage(this.isAmg ? carClass.bodyTypes[0].modelDesigns[0] : carClass.bodyTypes[0].carLines[0]);

            this.animateDetails();
            this.setDetailsOffsetAndHeight(cmpRef);
        }
    }

    /**
     * Click handler on car class in header. Activates the given class and resets the former body types and model designs for AMG.
     * @param cc
     */
    clickCarClass(cc: CarClass) {
        if (cc.isActive || !this.isInHeader) {
            return;
        }

		this.currentCarClass = cc;
		this.setActiveCarClass(cc);

		if (this.currentBodyType) {
			if (this.isAmg) {
				this.currentModelDesign = null;
				this.setActiveModelDesign(null);
			}

			this.currentBodyType = null;
			this.setActiveBodyType(null);
		}
    }

	/**
	 * Click handler on body type in header. Activates the given body type and resets former car lines and model designs from AMG.
	 * @param bt
	 */
	clickBodyType(bt: BodyType) {
		this.currentBodyType = bt;

		if (this.isAmg) {
			this.currentModelDesign = null;
			this.setActiveModelDesign(null);
		} else {
			this.currentCarLine = null;
			this.setActiveCarLine(null);
		}
	}

	/**
	 * Click handler on model design in header. Activates the given model design and resets former car lines.
	 * @param md
	 */
	clickModelDesign(md: ModelDesign) {
		this.currentModelDesign = md;

		this.currentCarLine = null;
		this.setActiveCarLine(null);
	}

	/**
	 * Click handler on car line in header. Saves the reference ofr car line to be activated.
	 * @param cl
	 */
	clickCarLine(cl: CarLine) {
		this.currentCarLine = cl;
	}

	/**
	 * Updates the silhouette image for the car chooser on page.
	 * @param bt
	 */
    bodyTypeHover(bt: BodyType) {
	    if (this.selectedCarService.vehicleType.vehicleTypeId === 'amg') {
		    this.setCarLinePreviewImage(bt.modelDesigns[0]);
	    } else {
		    this.setCarLinePreviewImage(bt.carLines[0]);
	    }
    }

	/**
	 * Defines the Car Login button state in header.
	 * Disables the button if not complete tree was selected or the selection is the same as the currently logged in car line.
	 */
	get carLoginDisabled(): boolean {
		// TODO: Ask Backend why car line ids are not always unique for amg - programmatic mistake?
		//  problem: edge case handling for AMG here via IF: Some AMG cars have the same car line ids for different model design types.
		//  example: see GLA -> SUV -> same carLine IDs for different model types. why?
		if (this.isAmg) {
			return !this.currentModelDesign || !this.currentCarLine ||
			(this.currentModelDesign.modelDesignId === this.selectedCarService.modelDesign?.modelDesignId && this.currentCarLine.carLineId === this.selectedCarService.carLine?.carLineId);
		} else {
			return !this.currentCarLine || this.currentCarLine.carLineId === this.selectedCarService.carLine?.carLineId;
		}
	}

    /**
     * Set the most recent car line silhouette image
     * @param carLine Car line or model design (amg)
     */
    setCarLinePreviewImage(carLine: CarLine | ModelDesign) {
        this.mostRecentCarLine = carLine;
    }

    /**
     * Handle the dimension change of the body types when changing the car class on the mobile car chooser variant.
     * @param newDimensions New height and width of the body types
     */
    bodyTypeDimensionsChanged(newDimensions: any) {
        if (newDimensions) {
            this.adjustPaddingBottom(newDimensions.height, newDimensions.width);
        }
    }

    /**
     * Open the VIN lock layer
     */
    openVinLockOverlay() {
        this.vinLockComponent.open();
        this.closeCarChooser();
    }

    /**
     * Output Event Handler from Vin Lock Layer - Removes or adds the main car chooser <div> from DOM
     * @param isOpen
     */
    vinLockLayerIsOpen(isOpen: boolean) {
        this.vinLayerIsActive = isOpen;

        // prevent body types artifacts to fly into view on mobile/tablet
        if (!isOpen) {
            this.currentState = 'closeFast';
        }
    }

	/**
	 * Click listener to close "desktop car chooser variant" on click outside the container.
	 */
    private setupClickOutsideListener() {
        this._clickOutsideListener = this._renderer.listen('document', 'click', event => {
	        if (!this.componentReference.nativeElement.contains(event.target)) {
		        // Desktop flyout should close with animation
		        if (this.currentState === 'open') {
			        this.closeCarChooser();
		        }
		        // Mobile / Tablet Body Types should close immediately and not fly over car classes
		        else if (this.currentState === 'idle') {
			        this.currentState = 'closeFast';
		        }

		        // always close sticky car chooser menu - but respect eventual open detail flyout animation on tablet to finish before closing
		        if (this.viewportService.getCurrentViewPort() === 'mq3' && this.openStickyCarChooser && this.currentCarClass?.isActive) {
			        setTimeout(() => {
				        this.openStickyCarChooser = false;
			        }, 500);
		        } else {
			        this.openStickyCarChooser = false;
		        }
	        }
        });
    }

	/**
	 * Resets the complete car class tree to inactive
	 */
	private resetAllActiveStates() {
		this.carClasses.forEach(cc => {
			cc.isActive = false;
			cc.bodyTypes.forEach(bt => {
				bt.isActive = false;
				if (bt.modelDesigns) {
					bt.modelDesigns.forEach(md => {
						md.isActive = false;
						md.carLines.forEach(cl => {
							cl.isActive = false;
						});
					});
				} else {
					bt.carLines.forEach(cl => {
						cl.isActive = false;
					});
				}
			});
		});
    }

    /**
     * Set the given car class to active and reset every other car class.
     * Or reset all car classes to inactive.
     * @param carClass Car class to set active. Pass null to reset all.
     */
    private setActiveCarClass(carClass: CarClass) {
        this.carClasses.forEach( cc => {
            carClass ? cc.isActive = cc.carClassId === carClass.carClassId : cc.isActive = false;
        });
    }

	/**
	 * Set the given body type to active and reset every other body type.
	 * Or reset all body type to inactive.
	 * @param bodyType Body type to set active. Pass null to reset all.
	 */
    private setActiveBodyType(bodyType: BodyType) {
		this.currentCarClass.bodyTypes.forEach( bt => {
			bodyType ? bt.isActive = bt.bodyTypeId === bodyType.bodyTypeId : bt.isActive = false;
		});
    }

    private setActiveModelDesign(modelDesign: ModelDesign) {
        this.currentBodyType.modelDesigns.forEach( md => {
            modelDesign ? md.isActive = md.modelDesignId === modelDesign.modelDesignId : md.isActive = false;
        });
    }

	/**
	 * Set the given car line type to active and reset every other car line type.
	 * Or reset all car line type to inactive.
	 * @param carLine Car line to set active. Pass null to reset all.
	 */
    private setActiveCarLine(carLine: CarLine) {
	    if (this.isAmg) {
	        this.currentModelDesign.carLines.forEach( cl => {
		        carLine ? cl.isActive = cl.carLineId === carLine.carLineId : cl.isActive = false;
	        });
	    } else {
		    this.currentBodyType.carLines.forEach( cl => {
		        carLine ? cl.isActive = cl.carLineId === carLine.carLineId : cl.isActive = false;
		    });
	    }
    }

    /**
     * Set the animation properties to trigger the NG-Animation for desktop or mobile / tablet
     */
    private animateDetails() {
        this.currentState = this.isMobile ? 'idle' : 'open';
    }

    /**
     * Calculate the padding bottom for the car classes
     * @param height Height of the body type
     * @param width Width of all body types
     */
    private adjustPaddingBottom(height: number, width: number) {
        if (this.isMobile && !this.isInHeader) {
            let heightToAdjust: number = height;

            // If the width of our body types exceeds the car chooser width
            // we need more space
            if (width > this.wholeCarChooser.nativeElement.offsetWidth) {
                heightToAdjust *= 2;
            }

            // Timeout is needed since the event is triggered in "AfterViewChecked"
            // So we need to wait for the next turn to set it in our template
            setTimeout(() => {
                this.classPaddingBottom = `${heightToAdjust}px`;
                this.currentHeight = `${heightToAdjust}px`;
                this.classZIndex = 'auto';
            }, 0);
        } else {
            // Same as the comment for the other timeout
            setTimeout(() => {
                this.classPaddingBottom = '0';
            }, 0);
        }
    }

    /**
     * Set the detailed fly out height and offset for all view ports
     * @param cmpRef Component reference of the current car class
     */
    private setDetailsOffsetAndHeight(cmpRef: any = null) {
        // MQ 1 + MQ 2 Layout
        if (cmpRef) {
            // Get the numbers which start from the first character (^ = indicate the start; \d = any number)
            // with any amount (+ = as many characters as there are)
            const currentPaddingBottom: number = parseInt(/^\d+/.exec(this.classPaddingBottom)[0], 10);

            // Remember the offset from the component reference so we do not have to look it up everytime
            const cmpOffsetTop: number = cmpRef.offsetTop;

            // Check whether we need to use the padding bottom to calculate our top position
            const offsetWithoutPadding: number = cmpOffsetTop - currentPaddingBottom;
            const offsetToCheck: number = offsetWithoutPadding > 25 ? offsetWithoutPadding : cmpOffsetTop;

            // Magic numbers
            // These numbers are based on the height of the text;
            // Kind of a hack to see in which line we are since we are using wrapping elements
            // and do not know which element is in which line
            if (offsetToCheck > 80) {
                this.currentTop = '120px';
            } else if (offsetToCheck > 40) {
                this.currentTop = '80px';
            } else {
                this.currentTop = '40px';
            }
        }

        if (this.isMobile) {
            return;
        }

        // Desktop Layout > Details flyout to top or bottom
        const carChooserTop = this.wholeCarChooser.nativeElement.offsetHeight + 'px';
        const vp = this.viewportService.getCurrentViewPort();
        switch (vp) {
            case 'mq3':
                this.currentHeight = '240px';
                this.currentTop = this.isFixed ? carChooserTop : '-240px';

                break;
            case 'mq4':
                this.currentHeight = '185px';
                this.currentTop = this.isFixed ? carChooserTop : '-185px';

                break;
            default:
                this.currentHeight = '280px';
                this.currentTop = this.isFixed ? carChooserTop : '-280px';

                break;
        }
    }

    /**
     * Handle the change of viewports
     * @param vp New viewport (mq1, mq2, ...)
     */
    private viewportChanged(vp: string) {
        this.closeCarChooser();
        this.isMobile = vp === 'mq1' || vp === 'mq2';
    }

    private closeCarChooser() {
        this.currentState = 'closed';
        this.classPaddingBottom = '0';
	    // reset shown "body type boxes" on mobile car chooser after closing the vin login layer
        this.setActiveCarClass(null);
        this.setDetailsOffsetAndHeight();
    }

    /**
     * Handle the positioning (fixed at the top or not) of the car chooser in case of over scrolling it.
     * Info: Not the best attempt cause can cause visual jitters but quite difficult to refactor it to a css "position:sticky" approach.
     * https://firefox-source-docs.mozilla.org/performance/scroll-linked_effects.html
     */
    @HostListener('document:scroll')
    private handleScrollEvent() {
        if (this.canBeSticky) {
            // get the current scrolling positions
            const scrollY: number = window.scrollY;
            const carChooserY: number = this.overscrollIndicator.nativeElement.getBoundingClientRect().top + window.scrollY;

            // check for over scrolled once
            if (!this.isFixed && scrollY > carChooserY) {
                this.isFixed = true;

                // component height changes for sticky version for MQ1-MQ2, MQ3
                let height = this.wholeCarChooser.nativeElement.offsetHeight;
                const vp: string = this.viewportService.getCurrentViewPort();
                if (vp === 'mq1' || vp === 'mq2') {
                    height = 42;
                } else if (vp === 'mq3') {
                    height = 55;
                }
                // inform parent to adjust position to prevent layout jump - See ZKDEV-1592
                this.offsetHeightWhenSticky.emit(height);

                // always reset open state
                this.currentState = 'closeFast';
                this.setActiveCarClass(null);
                // also for evtl. open sticky for mobile and tablet
                this.openStickyCarChooser = false;

                // adjust start and end pos for details flyout to the bottom
                this.currentTop = this.closedTop = this.wholeCarChooser.nativeElement.offsetHeight + 'px';
            }
            // check for release when scrolling back
            else if (this.isFixed && scrollY < carChooserY) {
                this.isFixed = false;

                // always reset open state
                this.currentState = 'closeFast';
                this.setActiveCarClass(null);

                // adjust start and end pos for details flyout to the top
                this.currentTop = this.closedTop = '0';
            }
        }
    }

    /**
     * Get the available car classes tree from the API
     */
    private getAvailableCarClasses() {
        this.selectedCarService.getAvailableClasses().subscribe(
        (response: CarClass[]) => {
                this.carClasses = response;

                if (this.isInHeader) {
                    // needed to not overlap car line on body types
                    this.currentState = 'open';
					// set tree as active for car chooser header flyout
                    if (this.selectedCarService.carClass) {
                        this.currentCarClass = this.selectedCarService.carClass;
                        this.currentBodyType = this.selectedCarService.bodyType;
                        this.currentModelDesign = this.selectedCarService.modelDesign;
                        this.currentCarLine = this.selectedCarService.carLine;

                        // mark logged in as active
                        this.setActiveCarClass(this.currentCarClass);
                        this.setActiveBodyType(this.currentBodyType);
                        // amg mode
                        if (this.currentModelDesign) {
                            this.setActiveModelDesign(this.currentModelDesign);
                        }
                        this.setActiveCarLine(this.currentCarLine);

						// offer car logout button
                        this.carIsLogged = true;
                    } else {
                        // reset active state of whole tree when selection on car chooser happened and then closed and reopened
	                    setTimeout(() => this.resetAllActiveStates());
                    }
                }
            },
            (error) => {
                console.log('car-chooser.getAvailableCarClasses ERROR:', error);
            }
        );
    }

    /**
     * Subscribe to all necessary services and observables
     */
    private handleSubscriptions() {
        this.getAvailableCarClasses();
        this.viewportSubscriber = this.viewportService.getViewportChangedObserver().subscribe(this.viewportChanged.bind(this));
        if (!this.isInHeader) {
            this.setupClickOutsideListener();
        }
    }

	/**
	 * Removes saved car data, adjusts URL and performs page reload to stay on same page
	 */
	removeCar() {
		this.selectedCarService.resetLoggedCarData();
		const url = this._translationService.currentLang + this._appService.currentAppMode + this.carChooserHelperService.updateCurrentRoute(null);
		window.location.replace(url);
	}

	/**
	 * Logs in the new selected car and adjusts the URL with car line ID.
	 */
	loginCar() {
		this.selectedCarService.clearVin();
		this.selectedCarService.carClass = this.currentCarClass;
		this.selectedCarService.bodyType = this.currentBodyType;
		this.selectedCarService.modelDesign = this.currentModelDesign;
		this.selectedCarService.carLine = this.currentCarLine;

		const route = this.carChooserHelperService.updateCurrentRoute(this.currentCarLine);
		this.resetWarningService.openDialog(route, false);
	}
}
