import { Component, OnInit } from '@angular/core';

// Services
import { SelectedCarService } from '@shared/shared-services/selected-car/selected-car.service';
import { Co2Service } from '@shared/shared-services/co2-label/co2.service';

@Component({
    selector: 'zk-amg',
    templateUrl: './amg.component.html',
    styleUrls: ['./amg.component.scss']
})
export class AmgComponent implements OnInit {
    constructor(private _selectedCarService: SelectedCarService,
				private _co2Service: Co2Service) {}

    ngOnInit(): void {
        // react to browser back button action (ZKDEV-1658)
        if (this._selectedCarService.vehicleType?.vehicleTypeId !== 'amg') {
            this._selectedCarService.vehicleType = this._selectedCarService.vehicleTypeAmg;
        }

		this._co2Service.loadCO2Data();
	}
}
