// Ng
import { AfterViewChecked, Component, ElementRef, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { ViewportService } from '@mbcs/mbcs-lib';
import { forkJoin, Subscription } from 'rxjs';

// Models
import { Article, VariantType } from '@shared/global-models/article/article.model';
import { VariantPropertyCollection } from '@shared/global-models/article/articleVariantCollection.model';
import { Breadcrumbs } from '@shared/global-models/breadcrumbs.model';
import { Notification, NotificationIcons, NotificationTypes } from '@shared/components/notification/notification.model';
import { ShoppingCartItemAction } from '@shared/shared-services/shopping-cart/model/shopping-cart-item-action.model';
import { ShoppingCartItemModalInfoModel } from '@shared/shared-services/shopping-cart/model/shopping-cart-item-modal-info.model';

// Services
import { AppService } from '../../../../app.service';
import { ProductDetailsService } from '../../../accessories/pages/product-details-page/product-details.service';
import { NotificationService } from '@shared/components/notification/notification.service';
import { ShoppingCartService } from '@shared/shared-services/shopping-cart/shopping-cart.service';
import { GroupMapperService } from '@shared/shared-services/group-mapper/group-mapper.service';
import { ProductListService } from '@shared/shared-services/product-list/product-list.service';
import { TranslationService } from '@shared/shared-services/translate/translation.service';
import { ImgLoadedDirective } from '@shared/directives/pagination/ImgLoaded.directive';
import { SeoService } from '@shared/shared-services/seo/seo.service';

@Component({
	selector: 'zk-collection-product-details-page',
	templateUrl: './collection-product-details-page.component.html',
	styleUrls: ['./collection-product-details-page.component.scss']
})
export class CollectionProductDetailsPageComponent implements OnInit, AfterViewChecked {
	@ViewChildren(ImgLoadedDirective) images: QueryList<ImgLoadedDirective>;
	@ViewChild('galleryEl') galleryEl: ElementRef;

	article: Article;
	articleVariant: Article;
	activeArticle: Article;
	breadcrumbs: Breadcrumbs[] = [];
	pdfDownloadInProgress: boolean = false;
	articlePrice: string;
	wasAddedToCart = false;
	galleryLoaded: boolean = false;
	galleryMinHeightMobile: string;
    marketHasShopLink: boolean = false;

	mq1: boolean = false;
	mq2: boolean = false;
	mq3: boolean = false;

	get variantType() {
		return VariantType;
	}

	private _subscriptions = new Subscription();

	constructor(private _viewPortService: ViewportService,
				private _activatedRoute: ActivatedRoute,
				private _pdpService: ProductDetailsService,
				private _pdlService: ProductListService,
				private _groupMapperService: GroupMapperService,
				private _router: Router,
				private _shoppingCartService: ShoppingCartService,
				private _appService: AppService,
				private _notificationService: NotificationService,
				private _translateService: TranslationService,
				private _seoService: SeoService) {
	}

	ngOnInit(): void {
		this.requestArticleData();

		this.initListeners();
	}

	// TODO Improve relation size - color
	getVariantArticleData(variant: VariantPropertyCollection, variantType: VariantType) {

		this.galleryLoaded = this.activeArticle.articleId === variant.articleId;

		this.articleVariant = this.article.variantArticles.find((article: Article) => {
			return article.articleId === variant.articleId;
		});

		this.activeArticle = this.articleVariant ? this.articleVariant : this.article;

		// If clicking on initial item ('master') - not variant
		// set it again as active
		if (this.article.articleId === variant.articleId) {

			if (variantType === this.variantType.COLOR) {
				this.article.variantPropertiesCollection.colors.map(color => {
					color.isActive = color.articleId === variant.articleId;
				})
			} else {
				this.article.variantPropertiesCollection?.sizes.map(size => {
					size.isActive = size.articleId === variant.articleId;
				})
			}
		}

		// Set selected variant as active
		if (variantType === this.variantType.COLOR) {
			this.article.variantPropertiesCollection.colors.map(color => {
				color.isActive = color.articleId === variant.articleId;
			})

			// and reset size if color is changed
			this.article.sizeSelected = undefined;
			this.activeArticle.variantPropertiesCollection?.sizes?.map(size => {
				size.isActive = false;
			})

		} else {
			this.articleVariant?.variantPropertiesCollection.sizes.map(size => {
				size.isActive = size.articleId === variant.articleId;
			})

			this.article.sizeSelected = variant.name;
		}

		this.wasAddedToCart = !!this._shoppingCartService.getArticleFromCartById(this.activeArticle.articleId);

		this.setArticlePrice();
		this.selectItemWithSingleSize();
		this.updateUrlOnVariantChange();
		this.setGalleryMinHeight();
	}


	setArticlePrice() {
		const activeColor = this.activeArticle.variantPropertiesCollection?.colors.find(color => { return color.hint === 'self'});

		if (activeColor && activeColor?.prices?.various && !this.article.sizeSelected) {
			this.articlePrice = this._translateService.translate('LIFESTYLE.GENERAL.FROM') + ' ' + activeColor.prices.minGrossText;
		} else {
			this.articlePrice = this.activeArticle.grossPriceText;
		}
	}

	addRemoveFromCart(): void {
		if (this.wasAddedToCart) {
			this._shoppingCartService.removeItemFromShoppingCart([this.activeArticle.articleId], true);
			this.wasAddedToCart = false;
			return;
		}

		// If variant Article is selected, we need to fetch that article again, because variantArticle doesn't have
		// it's variantArticles therefore switching variants in cart would not be possible
		const sub: Subscription = this._pdlService.getCollectionArticleDetails(this.activeArticle.articleId).subscribe(
			(response: Article) => {
				if (response) {
					let cartArticle: Article = response;

					cartArticle.sizeSelected = this.article.sizeSelected;
					cartArticle.colorSelected =  this.article.colorSelected;
					this.wasAddedToCart = true;

					this._shoppingCartService.addItemsToShoppingCart([cartArticle], 1, true);
				}

				sub.unsubscribe();
			},
			(error) => {
				sub.unsubscribe();
				console.log('Failed to add or remove an article: ', error);
				this._notificationService.triggerNotification(
					new Notification(NotificationTypes.ERROR, NotificationIcons.WARNING, this._translateService.translate('ERRORMESSAGE.SERVICEUNAVAILABLE')));
			}
		);
	}

	printPDF(): void {
		this._shoppingCartService.shoppingCartPDFGenerationComplete.subscribe((complete: boolean) => {
			this.pdfDownloadInProgress = !complete;
		});

		this.pdfDownloadInProgress = true;
		this._shoppingCartService.proceedToDownloadPDF(this.activeArticle);
	}

	ngAfterViewChecked() {
		// Use 'imgLoaded' directive on images to be informed when all of them are loaded
		forkJoin(this.images.map(img => img.loaded)).subscribe((result: [boolean]) => {
            this.galleryLoaded = true;
            // remove from DOM invalid image urls
            if (result.includes(false)) {
                this.activeArticle.images = this.activeArticle.images.filter(img => img.loaded);
            }
		});
	}

	private initListeners() {
		this._subscriptions.add(
			this._router.events.subscribe((event) => {
				if (event instanceof NavigationEnd) {

					// On article variant change, URL is updated but no new data needed
					// because they are loaded from variantArticle object.
					// However, new data is required if cart item is clicked that routes to PDP.
					if (this._pdpService.getArticleIdFromUrl(this._router.url) != this.activeArticle.articleId) {
						this.requestArticleData();
					}
				}
			})
		);

		this._subscriptions.add(
			this._shoppingCartService.shoppingCartItemAddedOrRemovedInfo.subscribe((item: ShoppingCartItemModalInfoModel) => {
					if (item.items && item.items.length > 0 && item.action === ShoppingCartItemAction.ITEM_REMOVE) {
						if (item.items[0].articleId === this.activeArticle.articleId) {
							this.wasAddedToCart = false;
						}
					}
				}
			)
		);

		this._subscriptions.add(
			this._shoppingCartService.undoArticleRemove.subscribe(() => {
				if (this._shoppingCartService.deletedArticle.articleId === this.activeArticle.articleId) {
					this.wasAddedToCart = true;
				}
			})
		);

		this._subscriptions.add(
			this._shoppingCartService.shoppingCartChanged.subscribe(() => {
				this.wasAddedToCart = !!this._shoppingCartService.getArticleFromCartById(this.activeArticle.articleId);
			})
		);

		this._subscriptions.add(
			this._viewPortService.getViewportChangedObserver().subscribe((newViewPort) => {
				this.setViewPort(newViewPort);
				this.setGalleryMinHeight();
			})
		);

		// Layout Handling
		this.setViewPort(this._viewPortService.getCurrentViewPort());
	}

	// param id will be only used for test purpose
	// on DEV, ID can be passed from template for easier testing
	private requestArticleData(id?: string) {
		const param = this._activatedRoute.snapshot.params.product ? this._activatedRoute.snapshot.params.product : this._activatedRoute.snapshot.params.subsubcategory;
		const articleId = this._pdpService.getArticleIdFromUrl(param);

		const sub: Subscription = this._pdlService.getCollectionArticleDetails(id ? id : articleId).subscribe(
			(response: Article) => {
				if (response) {
					this.article = response;
					this.activeArticle = response;
					this.articleVariant = null;
					this.setArticlePrice();

					this.setBreadcrumbs();

					if (this.article.variantPropertiesCollection?.colors) {
						this.sortVariantImages(this.article.variantPropertiesCollection.colors);
					}

					this.selectItemWithSingleSize();

					this.wasAddedToCart = !!this._shoppingCartService.getArticleFromCartById(this.activeArticle.articleId);

					if (id) { // test purpose only (DEV)
						const articlePath = this.article.groupUrlNamePath.join('/');
						this._router.navigate(['/products/' + articlePath + '/' + this.article.urlName]).then();
					}
				}

				sub.unsubscribe();

				window.scrollTo(0, 0);
			},
			(error) => {
				sub.unsubscribe();
				console.log('Failed to fetch article data: ', error);
				this._notificationService.triggerNotification(
					new Notification(NotificationTypes.ERROR, NotificationIcons.WARNING, this._translateService.translate('ERRORMESSAGE.SERVICEUNAVAILABLE')));
			}
		);
	}

	private setBreadcrumbs() {
		if (!this.article.groupIdPath) {
			return;
		}

		let url = '/products/';
		this.breadcrumbs = [];

		// Get readable Group Names
		const mainGroup = this._groupMapperService.getGroupNameFromUrlName(
			this._activatedRoute.snapshot.params.category
		);
		const subGroup = this._groupMapperService.getGroupNameFromUrlName(
			this._activatedRoute.snapshot.params.category,
			this._activatedRoute.snapshot.params.subcategory
		);
		const subSubGroup = this._groupMapperService.getGroupNameFromUrlName(
			this._activatedRoute.snapshot.params.category,
			this._activatedRoute.snapshot.params.subcategory,
			this._activatedRoute.snapshot.params.subsubcategory
		);

		const readableGroupNames: string[] = [mainGroup, subGroup, subSubGroup];

		this.article.groupIdPath.map((groupId: string, i) => {
			const singleGroup = this._groupMapperService.getGroupUrlNameFromGroupId(groupId);

			this.breadcrumbs.push({
				groupName: readableGroupNames[i],
				groupUrl: url += `${singleGroup}/`
			})
		});

		this.setSeoInfo();
	}

	private sortVariantImages(array: VariantPropertyCollection[]) {
		const selfColorIndex = array.findIndex(color => color.hint === 'self');
		let selfColorItem = array[selfColorIndex];

		// Reorder only if not already on 1st place
		if (selfColorIndex != 0) {
			selfColorItem = array.splice(selfColorIndex, 1)[0];
			array.splice(0, 0, selfColorItem);
		}

		// And set it as active (highlighted)
		selfColorItem.isActive = true;
	}

	private updateUrlOnVariantChange() {
		const currentUrl = this._router.url;
		const urlWithoutArticleName = currentUrl.slice(0, currentUrl.lastIndexOf('/'));
		const newUrl = urlWithoutArticleName + '/' + (this.articleVariant ? this.articleVariant.urlName : this.article.urlName);

		this._router.navigate([newUrl]).then();
	}

	private setViewPort(viewPort: string) {
		this.mq1 = viewPort === 'mq1';
		this.mq2 = viewPort === 'mq2';
		this.mq3 = viewPort === 'mq3';
	}

	private setSeoInfo() {
		const title: string = this._seoService.getTranslation('ACCESSORIES.FLYOUT.TEASER.TITEL');
		const articleHeadline = this.activeArticle.headline;
		const articleSubHeadline = this.activeArticle.headline2;
		const articleDescription: string = this._pdpService.replaceHtmlTagFromString('<br>', this.activeArticle.description, '');
		const categories = this.breadcrumbs.map((breadcrumb) => {
			return breadcrumb.groupName;
		});

		this._seoService.updatePageTitle(`${articleHeadline} (${articleSubHeadline}) | ${categories.reverse().join(' | ')} | ${title}`);
		this._seoService.updateMetaDescription(articleDescription);
	}

	/**
	 * UseCase: If article have one size only
	 * select that size in order to show its price in cart
	 * and not price 'Ab' if item would not be selected and have various prices
	 */
	private selectItemWithSingleSize(): void {
		if (this.activeArticle.variantPropertiesCollection?.sizes?.length === 1) {
			this.article.sizeSelected = this.activeArticle.variantPropertiesCollection.sizes[0].name;
			this.activeArticle.variantPropertiesCollection.sizes[0].isActive = true;
		}
	}

	// Prevent gallery jumps on small screen
	private setGalleryMinHeight() {
		if ((this.mq1 || this.mq2) && !this.galleryMinHeightMobile) {
			this.galleryMinHeightMobile = this.galleryEl?.nativeElement.offsetHeight;
		}
	}

	ngOnDestroy(): void {
		this._subscriptions.unsubscribe();
	}

	// USED FOR TEST PURPOSE ONLY - VISIBLE ON DEV ONLY
	getArticleById(event) {
		this.requestArticleData(event.target.value);
		event.target.value = '';
	}
}
