// ng
import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
// services
import { SelectedCarService } from '@shared/shared-services/selected-car/selected-car.service';
import { TranslationService } from '@shared/shared-services/translate/translation.service';
import { GroupMapperService } from '@shared/shared-services/group-mapper/group-mapper.service';
import { AppService } from '../../../app.service';
// model
import { Article } from '@shared/global-models/article/article.model';
import { MODE } from '@shared/global-models/mode.enum';

/**
 * Note: This service was introduced because of the issue we have when trying to call the current route params outside of the router outlet.
 */
@Injectable()
export class CurrentRouteService {
	isAccessoriesMode = true;

    private currentCategoryName: string;
    private currentSubcategoryName: string;
    private currentArticleName: string;

    constructor(private router: Router,
                private selectedCarService: SelectedCarService,
                private _translationService: TranslationService,
                private _groupMapperService: GroupMapperService,
                private _appService: AppService
    ) {
        // Initial fill
        this.fillValuesFromUrl(this.router.url);

        // update when routes change
        this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                this.fillValuesFromUrl(event.url);
            }
        });
    }

    get categoryName(): string {
        return this.currentCategoryName;
    }

    get subcategoryName(): string {
        return this.currentSubcategoryName;
    }

    get articleName(): string {
        return this.currentArticleName;
    }

    routeToProductDetails(article: Article): void {
		this.isAccessoriesMode = this._appService.appMode === MODE.ACCESSORIES;

        const car = (this.selectedCarService.carLine && this.isAccessoriesMode) ? this.selectedCarService.carLine.urlName : 'products';
        const mainCategory = this._groupMapperService.getGroupUrlNameFromGroupId(article.groupIdPath[0]);
        const subCategory = this._groupMapperService.getGroupUrlNameFromGroupId(article.groupIdPath[1]);
        const subSubCategory = article.groupIdPath[2] ? this._groupMapperService.getGroupUrlNameFromGroupId(article.groupIdPath[2]) + '/' : '';
        const url = car + '/' + mainCategory + '/' + subCategory + '/' + subSubCategory + article.urlName;

        this.router.navigate([url]).then();
    }

    /**
     * Checks if the current route is the start page of the application
     */
    checkIfStartPageIsShown(): boolean {
        let routeToCheck = this.router.url;

        // remove queryParams from the URL
        if (routeToCheck.indexOf('?') > -1) {
            routeToCheck = routeToCheck.slice(0, routeToCheck.indexOf('?'));
        }

        let url: string[] = routeToCheck.split('/');
        url.shift();

        return url.length === 3 && url[1] === 'accessories';
    }

    /**
     * Extracts the current categories and the article name from the current route
     * @param url
     */
    private fillValuesFromUrl(url: string) {
        const splitUrl: string[] = url.split('/');
        if (splitUrl.length > 5) {
            // remove queryParams from the CategoryName, SubcategoryName and ArticleName
            this.currentCategoryName = this.removeQueryParamsHelper(splitUrl, 5);
            this.currentSubcategoryName = this.removeQueryParamsHelper(splitUrl, 6);
            this.currentArticleName = this.removeQueryParamsHelper(splitUrl, 7);
        } else {
            // one case: when going back to the start page the saved categories need to be reset
            this.clear();
        }
    }

    /**
     * Helper function to remove queryParams from different URL parts
     * @param {string[]} splitUrl
     * @param {number} index
     */
    private removeQueryParamsHelper(splitUrl: string[], index: number): string {
        let splitUrlPart = splitUrl.length > index ? splitUrl[index] : null;

        if (splitUrlPart && splitUrlPart.indexOf('?') > -1) {
            const queryIndex: number = splitUrlPart.indexOf('?');
            splitUrlPart = splitUrlPart.slice(0, queryIndex);
        }

        return splitUrlPart;
    }

    /**
     * Resets the categories and article name
     */
    private clear() {
        this.currentCategoryName = null;
        this.currentSubcategoryName = null;
        this.currentArticleName = null;
    }
}
