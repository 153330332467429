<zk-single-image-stage *ngIf="isGroupTypeWheels"
                       [image]="image"
                       [headline]="headline">
</zk-single-image-stage>

<div class="product-list-subcategory page-wrapper {{isGroupTypeWheels ? 'no-top-padding' : ''}}">
    <zk-product-list-subcategory [wheelProductGroup]="wheelProductGroup"
                                 [isGroupTypeWheels]="isGroupTypeWheels"
                                 [subGroupType]="subGroupType"
                                 [subGroupName]="subGroupName"
                                 [subGroupId]="subGroupId">
    </zk-product-list-subcategory>
</div>

<!-- additional wheel category teaser link outs - on alloy-wheels page only -->
<zk-category-teaser-medium *ngIf="subGroupType === 'alloy-wheels'"></zk-category-teaser-medium>

