<div class="sticky-footer_wrapper"
     (click)="togglePriceInfo()">
    <div class="sticky-footer_headline">
            <wb-text size="l"
                  zkTranslate="STICKYFOOTER.HEADLINE">
            </wb-text>
        <mba-icon [icon]="isInfoOpen ? 'arrow-thin-down' : 'arrow-thin-up'"
                  iconClass="icon_text icon_white icon_pointer"
                  class="sticky-footer_icon">
        </mba-icon>
    </div>
    <div class="sticky-footer_content"
         [ngClass]="{'is-open': applyMargin}"
         [@stickyFooterAnimation]="isInfoOpen">
            <wb-text size="l" *ngIf="isAccessoriesMode" class="sticky-footer_content_defaultText"
                  zkTranslate="FOOTNOTES.UVP">
            </wb-text>

            <wb-text size="l"  *ngIf="!isAccessoriesMode" class="sticky-footer_content_defaultText"
                  zkTranslate="LIFESTYLE.STICKYFOOTER.CONTENT">
            </wb-text>
    </div>
</div>
