import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export class LocalStorageService {

	constructor() { }

	public setItem(key: string, value: any): void {
		localStorage.setItem(key, JSON.stringify(value));
	}

	public getItem(key: string): any {
		return JSON.parse(localStorage.getItem(key));
	}

	public removeItem(key: string): void {
		localStorage.removeItem(key);
	}

	public clear() {
		localStorage.clear();
	}
}
