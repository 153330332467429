// NG
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MODE } from '@shared/global-models/mode.enum';
import { Subscription } from 'rxjs';
// Models
import { Article } from '@shared/global-models/article/article.model';
import { CompatibilityCheck } from './models/compatibility-check.model';
import { ShoppingCartData } from '@shared/shared-services/shopping-cart/model/shopping-cart-data.model';
import { VIN } from '@shared/shared-services/compatibility/vin.model';
import { CompatibilityCheckProgressStepsModel } from '@shared/modals/compatibility-check-modal/models/compatibility-check-progress-steps.model';
import { ViewportService } from '@mbcs/mbcs-lib';
// Services
import { CompatibilityCheckService } from '@shared/shared-services/compatibility/compatibility.service';
import { PopupModalService } from "@shared/modals/popup-modal/popup-modal.service";
import { ShoppingCartService } from '@shared/shared-services/shopping-cart/shopping-cart.service';
import { SelectedCarService } from '@shared/shared-services/selected-car/selected-car.service';
import { TranslationService } from '@shared/shared-services/translate/translation.service';
import { GroupMapperService } from '@shared/shared-services/group-mapper/group-mapper.service';

@Component({
    selector: 'zk-compatibility-check-modal',
    templateUrl: 'compatibility-check-modal.component.html',
    styleUrls: ['./compatibility-check-modal.component.scss']
})
export class CompatibilityCheckModalComponent implements OnInit, OnDestroy {
    isOpen = false;
    articlesInCart: ShoppingCartData = null;
    headline: string = null;

    showLoadingSpinner = false;
    showLoadingSpinnerPDF = false;
    progressFeedbackText = '';
    loadingSpinnerText = '';

    displayServiceErrorMessage = false;
    displayVINErrorMessage = false;

    vehicleFound = false;
    compatibilityChecked = false;
    toggledVINDescription = false;
    vinNumber = '';
    vinVehicleIdentificationText = '';
    vinVehicle: VIN = null;
    showDescription = false;
    placeholder = 'z.B. WDD1690071J236589';
    currentArticles: Article[] = [];
    isCheckoutBtnAvailable = false;
    canAddToCart = false;
    totalGrossPrice: string;

	get appMode() {
		return MODE;
	}

    private shoppingCartChangedSubscription: Subscription = null;
    private shoppingCartChangedErrorSubscription: Subscription = null;
    private vinCheckSubscription: Subscription = null;
    private compatibilityFeedbackStepSubscription: Subscription = null;

    constructor(
        private compatibilityCheckService: CompatibilityCheckService,
        private modalService: PopupModalService,
        private shoppingCartService: ShoppingCartService,
        private selectedCarService: SelectedCarService,
        private translationService: TranslationService,
        private router: Router,
        private _groupMapperService: GroupMapperService,
        private _viewPortService: ViewportService
    ) {}

    ngOnInit() {
    }

    ngOnDestroy() {
        this.unsubscribeFromListeners();
    }

    /**
     * Builds the path for the category where the given article is in
     * @param article Shopping cart item for which an alternative must be found
     */
    goToCategory(article: Article): string {
        if (!this.selectedCarService.carLine) {
            return 'products/' + article.groupUrlNamePath[0];
        }

        let groupName = this._groupMapperService.getGroupUrlNameFromGroupId(article.groupIdPath[0]);
        if (groupName) {
            const subGroupName = this._groupMapperService.getGroupUrlNameFromGroupId(article.groupIdPath[1]);
            if (subGroupName) {
                groupName = `${groupName}/${subGroupName}`
            }
        }

        const selectedCar: string = this.selectedCarService.carLine.urlName;
        // After a new car is logged in there is a possibility that the group the unfitting article belongs to does not exist for the new car
        return groupName ? `/${selectedCar}/${groupName}` : `/${selectedCar}`;
    }

    /**
     * Add the current articles to the shopping cart and removes them from the view
     */
    addToCart() {
        this.shoppingCartService.addItemsToShoppingCart(this.currentArticles, null);
        this.clearCurrentArticles();
    }

    /**
     * Redirects to the main category. Case: When the CC failed for the current article after VIN/LP input.
     */
    navigateToMainCategory() {
        // failsafe
        if (!this.currentArticles || this.currentArticles.length === 0) {
            return;
        }

        // we can use the main category from the first element here because only wheel or rim sets
        // are more then one article but always belong to the same category
        const translatedUrl: string | any[] = this.getMaincategoryURL(this.currentArticles[0]);

        // close modal: resets all values/variables!
        this.abortCompatibilityCheck();

        // redirect to the main category. Possible case: If main category does not exist for the new logged in car then
        // a separate popup modal will inform the user that the category is not available and redirect to the model start page.
        this.router.navigate([translatedUrl], { queryParamsHandling: 'preserve' });
    }

    /**
     * Get the full main category URL
     */
    getMaincategoryURL(detailArticle: Article) {
        const mainGroup: string = detailArticle.groupUrlNamePath[0];

        if (!this.vinVehicle) {
            return `/products/${mainGroup}`;
        } else {
            return `/${this.vinVehicle.carClass.bodyTypes[0].carLines[0].urlName}/${mainGroup}`;
        }
    }

    /**
     * Check the compatibility with the given VIN number and current detail page article
     */
    checkCompatibility() {
        this.displayVINErrorMessage = false;

        // ZKDEV-1583: remove non-alphanumeric, whitespaces and underscore via copy&paste before request to API. Allow "Umlaute" for Licence plate.
        const regex = /[^\w)\u00C0-\u00FF]|[_]/g;
        const escapedVIN = this.vinNumber.replace(regex,'');

        if (escapedVIN.length === 0) {
            this.displayVINErrorMessage = true;
            this.vinVehicleIdentificationText = this.translationService.translate('COMPATIBILITYCHECK.MODAL.VEHICLENOTIDENTIFIABLE');

            return;
        }

        // listen to feedback of the loading/checking process
        this.compatibilityFeedbackStepSubscription = this.compatibilityCheckService.compatibilityStepFeedback.subscribe(
            (value: CompatibilityCheckProgressStepsModel) => {
                // check for feedback step
                if (value.step !== undefined) {
                    this.progressFeedbackText = this.translationService.translate('LOADINGSPINNER.PROGRESS.' + value.name.toUpperCase());
                    this.loadingSpinnerText = value.step + '/' + value.totalSteps;
                }
                // check for result
                if (value.articles !== undefined && value.vin !== undefined) {
                    // clean up
                    this.showLoadingSpinner = false;
                    this.compatibilityFeedbackStepSubscription.unsubscribe();
                    // evaluate test result
                    this.handelCompatibilityCheck(value);
                }
                // case: Entered licence plate/VIN could not be identified
                if (value.errorMessage) {
                    this.vinVehicleIdentificationText = value.errorMessage
                    this.vehicleFound = false;
                    this.compatibilityChecked = false;
                    this.displayVINErrorMessage = true;
                    // clean up
                    this.showLoadingSpinner = false;
                    this.compatibilityFeedbackStepSubscription.unsubscribe();
                }
            }
        );

        // trigger request
        this.showLoadingSpinner = true;
        this.compatibilityCheckService.checkCompatibility(escapedVIN);
    }

    /**
     * Close the compatibility check modal
     */
    abortCompatibilityCheck() {
        this.modalService.close('compatibility-check');
    }

    /**
     * Called when the modal gets closed.
     * Cancels all open compatibility check related requests (so that the user can abort this process if he wants)
     */
    closeComponent() {
        this.unsubscribeFromListeners();

        // Remove all shopping cart items which are not fitting
        this.shoppingCartService.removeNotFittingItems();

        // Reset everything
        this.showLoadingSpinner = false;
        this.vinNumber = null;
        this.vinVehicle = null;
        this.vinVehicleIdentificationText = '';
        this.displayVINErrorMessage = false;
        this.clearCurrentArticles();

        this.isOpen = false;
    }

    /**
     * Determine wether the total gross price should be shown or not
     */
    showTotalGrossPrice() {
        // Only when we have articles we can show the total gross price
        if (this.articlesInCart && this.articlesInCart.items && this.articlesInCart.items.length > 0) {
            // Also we only want to show it if the fitting item amount is bigger than 0
            const filtered: Article[] = this.articlesInCart.items.filter((x) => x.fit);

            return this.compatibilityChecked && filtered && filtered.length > 0 && this.totalGrossPrice;
        }

        // For every other case we do not want to show the total gross price
        return false;
    }

    /**
     * Helper function that checks if one of the current articles did not pass the compatibility check
     */
    areCurrentArticlesNotFitting(): boolean {
        if (!this.currentArticles || this.currentArticles.length === 0) {
            return false;
        }

        for (const item of this.currentArticles) {
            if (item.fit === false) {
                return true;
            }
        }

        return false;
    }

    initComponent() {
        this.vinNumber = this.selectedCarService.VIN;
        this.showVinDescriptionForMarket();
        this.articlesInCart = this.shoppingCartService.currentShoppingCart;
        this.headline = this.translationService.translate('COMPATIBILITYCHECK.MODAL.HEADLINE');
        this.compatibilityChecked = this.compatibilityCheckService.compatibilityChecked;
        this.currentArticles = this.compatibilityCheckService.currentArticles;
        this.isOpen = true;
        this.listenToChanges();
        // modal is so big that on mobile it gets placed on top instead of centered
        const vp: string = this._viewPortService.getCurrentViewPort();
        if (vp === 'mq1' || vp === 'mq2') {
            this._viewPortService.scrollTop(0);
        }
    }

    printPdfAndCloseModal() {
        // wait until PDF is generated
        this.shoppingCartService.shoppingCartPDFGenerationComplete.subscribe((complete) => {
            this.showLoadingSpinnerPDF = !complete;
            this.modalService.close('compatibility-check');
        });

        this.showLoadingSpinnerPDF = true;
        this.shoppingCartService.proceedToDownloadPDF();
    }

    /**
     * Checks if additional VIN description image is needed for current market
     */
    private showVinDescriptionForMarket() {
        switch (this.translationService.currentLang) {
            case 'de-DE' : this.showDescription = true; break;
            case 'de-MB' : this.showDescription = true; break;
            case 'en-MB' : this.showDescription = true; break;
            case 'de-CH' : this.showDescription = true; break;
            case 'fr-CH' : this.showDescription = true; break;
            case 'it-CH' : this.showDescription = true; break;
            case 'en-GB' : {
                this.showDescription = false;
                this.placeholder = 'e. g. W238 WK';
                break;
            }
            default: this.showDescription = false;
        }
    }

    private unsubscribeFromListeners() {
        if (this.shoppingCartChangedSubscription) {
            this.shoppingCartChangedSubscription.unsubscribe();
        }
        if (this.vinCheckSubscription) {
            this.vinCheckSubscription.unsubscribe();
        }
        if (this.shoppingCartChangedErrorSubscription) {
            this.shoppingCartChangedErrorSubscription.unsubscribe();
        }
        if (this.compatibilityFeedbackStepSubscription) {
            this.compatibilityFeedbackStepSubscription.unsubscribe();
        }
    }

    private clearCurrentArticles() {
        this.currentArticles = [];
        this.compatibilityCheckService.currentArticles = null;
    }

    /**
     * Handles the response of the compatibility check and updates the current article and eventual
     * existing articles in the shopping cart / wish list
     * @param response Checked articles
     */
    private handelCompatibilityCheck(response: CompatibilityCheck) {
        this.compatibilityChecked = true;
        this.displayVINErrorMessage = false;
        // update the current shopping cart with the result of the check
        const shoppingCart: ShoppingCartData = this.compatibilityCheckService.updateShoppingCart(response.articles);

        // update current articles that haven´t been added to the cart yet with the result of the check
        if (this.currentArticles && this.currentArticles.length > 0) {
            for (const current of this.currentArticles) {
                const tested = response.articles.find((article) => article.articleId === current.articleId);
                if (tested) {
                    current.fit = tested.fit;
                }
            }
        }

        // update internal ref of the cart
        this.articlesInCart = shoppingCart;

        // get new price
        if (shoppingCart && this.showTotalGrossPrice()) {
            this.recalculateCart();
        }

        // lock in the car
        this.getVehicleByVIN();

        // check if the current article which fits can be added to the cart now
        this.updateCanAddToCart();
        // check if user can see the CTA button now
        this.isCheckoutBtnAvailable = this.checkProcessAvailable();
    }

    private recalculateCart() {
        const subscription: Subscription = this.shoppingCartService.calculateFittingProducts().subscribe(
            (resp: ShoppingCartData) => {
                if (resp.totalGrossPrice > 0) {
                    this.totalGrossPrice = resp.totalGrossPriceText;
                }
            },
            (error) => {
                console.log('Error recalculating price', error);
            },
            () => {
                if (subscription) {
                    subscription.unsubscribe();
                }
            }
        );
    }

    /**
     * Uses the VIN to identify the vehicle type and log it and updates the VIN in the local storage.
     */
    private getVehicleByVIN() {
        this.showLoadingSpinner = true;
        this.vinCheckSubscription = this.compatibilityCheckService.getCarByVin(this.vinNumber).subscribe(
            (response: VIN) => {
                this.vinCheckSubscription.unsubscribe();
                this.showLoadingSpinner = false;

                this.selectedCarService.VIN = this.vinNumber;
                this.vinVehicle = response;
                this.vinVehicleIdentificationText = this.vinVehicle.identificationText;
                this.vehicleFound = true;

                this.selectedCarService.selectCarByVINResponse(response);
            },
            (error) => {
                this.vinCheckSubscription.unsubscribe();
                this.showLoadingSpinner = false;

                let errorMessage;
                switch (error.status) {
                    case 400: errorMessage = this.translationService.translate('COMPATIBILITYCHECK.MODAL.VEHICLENOTIDENTIFIABLE'); break;
                    case 500: errorMessage = error.error.detail; break;
                    default: errorMessage = this.translationService.translate('ERRORMESSAGE.SERVICEUNAVAILABLE');
                }

                this.vinVehicleIdentificationText = errorMessage;
                this.displayVINErrorMessage = true;
                this.vehicleFound = false;
            }
        );
    }

    /**
     * Checks if the CTA button in the compatibility modal is available.
     */
    private checkProcessAvailable(): boolean {
        // check was done but no articles in cart and current articles are also not fitting => cancel is the only option
        if (!this.articlesInCart && this.areCurrentArticlesNotFitting()) {
            return false;
        }

        // articles in cart exist and at least one article in the cart fits => allow print PDF
        // otherwise false is returned => cancel is the only option
        if (this.articlesInCart && this.articlesInCart.items) {
            return this.articlesInCart.items.filter((cartItem: Article) => cartItem.fit).length > 0;
        }
    }

    /**
     * Subscribe to every subject where we need information that things changed
     */
    private listenToChanges() {
        // React to change of the shopping cart after the compatibility check was done because some items might have been removed
        this.shoppingCartChangedSubscription = this.shoppingCartService.shoppingCartChanged.subscribe((response) => {
            this.articlesInCart = response;
            this.displayServiceErrorMessage = false;
            // If there are no items in the shopping cart we do not want to let the user checkout
            if (this.articlesInCart && this.articlesInCart.items && this.articlesInCart.items.length === 0) {
                this.isCheckoutBtnAvailable = false;
            }else {
                // case: fitting current article might have been added to the cart - calculate new total price needed
                if (this.showTotalGrossPrice()) {
                    this.recalculateCart();
                }
                // check if user can see the CTA button now
                this.isCheckoutBtnAvailable = this.checkProcessAvailable();
            }
        });

        // Handle DCP error when their service is not available
        this.shoppingCartChangedErrorSubscription = this.shoppingCartService.shoppingCartChangedError.subscribe(() => {
            this.displayServiceErrorMessage = true;
            this.showLoadingSpinner = false;
        });
    }

    /**
     * Wishlist mode: Check if the vinNumber is given and the current articles are fitting so they can be added to the wishlist.
     * Online sellers: Check if a dealer is selected and the vinNumber is given and the current articles are fitting so they can be added to the shopping cart.
     */
    private updateCanAddToCart() {
        this.canAddToCart =
            typeof this.vinNumber !== 'undefined' &&
            this.vinNumber !== null &&
            this.vinNumber.length > 0 &&
            !this.areCurrentArticlesNotFitting();
    }
}
