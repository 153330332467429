// ng
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';

// services
import { ShoppingCartService } from '@shared/shared-services/shopping-cart/shopping-cart.service';
import { WheelSpecialService } from '../wheel-special.service';
import { SelectedCarService } from '@shared/shared-services/selected-car/selected-car.service';
import { GoogleAnalyticsService } from '@shared/shared-services/google-analytics/google-analytics.service';
import { ProductListService } from "@shared/shared-services/product-list/product-list.service";

// models
import { Article } from '@shared/global-models/article/article.model';
import { Wheel } from '@shared/global-models/wheel.model';
import { WheelDetail } from '@shared/global-models/wheelDetail.model';
import { ShoppingCartItemMaxAmountReachedInfo } from '@shared/shared-services/shopping-cart/model/shopping-cart-item-max-amount-reached-info.model';
import { PageName } from '@shared/shared-services/google-analytics/google-analytics.model';

@Component({
    selector: 'zk-wheel',
    templateUrl: './wheel.component.html',
    styleUrls: ['./wheel.component.scss']
})
export class WheelComponent implements OnInit, OnDestroy {
    @Input()
    wheelData: Wheel;

    detailedWheelData: WheelDetail;
    carLogged: boolean = false;
    moreInfoIsOpen: boolean = false;
    showLoadingSpinner: boolean = false;
    articleAmountInfo: ShoppingCartItemMaxAmountReachedInfo = null;

    private _carLoggedSubscription: Subscription;
    private _wheelDetailsOpenSubscription: Subscription;
    private _shoppingCartMaxAmountReachedSubscription: Subscription;
    private _loggedInWithVINChangedSubscription: Subscription;

    constructor(
        private _shoppingCartService: ShoppingCartService,
        private _wheelSpecialService: WheelSpecialService,
        private _selectedCarService: SelectedCarService,
        private _activatedRoute: ActivatedRoute,
        private _gaService: GoogleAnalyticsService,
        private _pdlService: ProductListService
    ) {}

    ngOnInit() {
        this.listenToChanges();

        if (this.wheelData.isOpen) {
            this.toggleMoreInfo(this.wheelData);
        }

        // define the initial CTA button state
        this.carLogged = this._activatedRoute.snapshot.params.carclass !== 'products';
    }

    toggleMoreInfo(article: Article) {
        if (!this.moreInfoIsOpen) {
            this.getDetailInfo(article);
        } else {
            this.moreInfoIsOpen = false;
        }
    }

    addToCart(article: Article, asCompleteSet: boolean) {
        if (this.detailedWheelData) {
            this.showQuantityPreviewLayer(asCompleteSet);
        } else {
            // case: more info layer was not opened yet - request detailed wheel data from API is required first
            this.getDetailInfo(article, true, asCompleteSet);
        }
    }

    getArticleAmountFeedbackText(): string {
        let msg: string;
        if (!this.articleAmountInfo) {
            msg = '';
        } else if (this.articleAmountInfo.quantity === 10) {
            msg = 'SHOPPINGCART.MAXITEMSWISHLIST';
        } else {
            msg = 'SHOPPINGCART.MAXITEMSWOULDBEEXCEEDED';
        }

        return msg;
    }

    ngOnDestroy() {
        if (this._carLoggedSubscription) {
            this._carLoggedSubscription.unsubscribe();
        }

        if (this._wheelDetailsOpenSubscription) {
            this._wheelDetailsOpenSubscription.unsubscribe();
        }

        if (this._shoppingCartMaxAmountReachedSubscription) {
            this._shoppingCartMaxAmountReachedSubscription.unsubscribe();
        }

        if (this._loggedInWithVINChangedSubscription) {
            this._loggedInWithVINChangedSubscription.unsubscribe();
        }
    }

    private showQuantityPreviewLayer(previewAsSet: boolean) {
        // prepare single rim article / article set and notify service to show the preview modal
        const rimSetToPreview: Article[] = this._shoppingCartService.buildRimSetFromWheelArticle(this.detailedWheelData, previewAsSet);
        this._wheelSpecialService.wheelPreviewAsSet = previewAsSet;
        this._wheelSpecialService.wheelPreviewArticles = rimSetToPreview;
    }

    private listenToChanges() {
        // listen to car log - changes CTA button
        this._carLoggedSubscription = this._selectedCarService.carLineSubscriber.subscribe((newCarLine) => {
            this.carLogged = newCarLine ? true : false;
        });

        // listen if another wheel will be opened for detail view
        this._wheelDetailsOpenSubscription = this._wheelSpecialService.openWheelDetailsInfo.subscribe((wheelToOpen: Wheel) => {
            if (this.wheelData.isOpen && this.wheelData !== wheelToOpen) {
                this.wheelData.isOpen = false;
                this.moreInfoIsOpen = false;
            }
        });

        // check if maximum of article quantity 10 is / would be reached and give a feedback to the user
        this._shoppingCartMaxAmountReachedSubscription = this._shoppingCartService.shoppingCartStatusMessage.subscribe(
            (info: ShoppingCartItemMaxAmountReachedInfo) => {
                // disable for the instance not globally
                if (this.detailedWheelData && info.articleId === this.detailedWheelData.articleId) {
                    this.articleAmountInfo = info;
                }
            }
        );

        // Handles the case when a VIN was logged in previously and then the car is changed by the user via car chooser.
        // The previously given compatibility of the current articles is not valid any more.
        this._loggedInWithVINChangedSubscription = this._selectedCarService.LoggedInWithVINChanged.subscribe(
            (wasLoggedInWithVIN) => {
                if (!wasLoggedInWithVIN) {
                    this.wheelData.fit = undefined;
                }
        });
    }

    /**
     * Get the article details for the given article id and adds it to the shopping cart if wanted.
     * @param article | Article. Id of the article to get the details for
     * @param addToCart | boolean. Optional. If article should be added to the shopping cart. Complete data info needed therefore.
     * @param addToCartAsSet | boolean. Optional. If article should be added to the shopping cart as complete set. Complete data info needed therefore.
     */
    private getDetailInfo(article: Article, addToCart: boolean = false, addToCartAsSet: boolean = false) {
        this.showLoadingSpinner = true;
        const vehicleTypeId: string = this._selectedCarService.carLine ? this._selectedCarService.carLine.carLineId
                                                                       : this._selectedCarService.vehicleType.vehicleTypeId;

        const sub: Subscription = this._pdlService.getLightAlloyWheelDetails(article.articleId, vehicleTypeId).subscribe(
        (detailedInfo: WheelDetail) => {
            // clean up
            this.showLoadingSpinner = false;
            sub.unsubscribe();

            // update model
            this.detailedWheelData = detailedInfo;

            if (addToCart) {
                this.addToCart(article, addToCartAsSet);
            } else {
                // open wheel details
                this.wheelData.isOpen = true;
                this.moreInfoIsOpen = true;
                // inform to close other open wheel layer instances that might be opened
                this._wheelSpecialService.triggerOpenWheelDetailsInfo(this.wheelData);
                // update tracking info
                this._gaService.trackPageView(PageName.WHEEL_SPECIAL_DETAIL_PAGE);
                this._gaService.trackProductDetailView(article);
            }
        }, error  => {
            console.log('Error: ', error);
            // clean up
            this.showLoadingSpinner = false;
            sub.unsubscribe();
        });
    }
}
