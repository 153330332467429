import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

import { LegalPagesService } from '../legal-pages.service';
import { LegalPageModel, LegalPageType } from '../legal-page.model';

@Component({
    selector: 'zk-copyright',
    templateUrl: './copyright-page.component.html',
    styleUrls: ['./copyright-page.component.scss']
})
export class CopyrightPageComponent implements OnInit {
    cmsContent: LegalPageModel;

    constructor(private _legalPagesService: LegalPagesService) {}

    ngOnInit() {
        const sub: Subscription = this._legalPagesService.getLegalPageContentFromCMS(LegalPageType.COPYRIGHT).subscribe(
            (result: LegalPageModel) => {
                this.cmsContent = result;
                sub.unsubscribe();
                window.scrollTo(0, 0);
            },
            (error) => console.log('Error: No content defined for this market in CMS! ', error),
            () => sub.unsubscribe()
        );
    }
}
