// Ng
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';

// Models
import { ProductGroup, ViewportService } from '@mbcs/mbcs-lib';
import { ArticleCategory } from '@shared/global-models/article/articleCategory.model';

// Services
import { ProductGroupsService } from '@shared/shared-services/product-groups/product-groups.service';
import { TranslationService } from '@shared/shared-services/translate/translation.service';
import { ProductListService } from '@shared/shared-services/product-list/product-list.service';

@Component({
    selector: 'zk-collection-categories-with-products-list',
    templateUrl: './collection-categories-with-products-list.component.html',
    styleUrls: ['./collection-categories-with-products-list.component.scss']
})
export class CollectionCategoriesWithProductsListComponent implements OnInit, OnDestroy {
    @Input()
    isSecondLevel: boolean;

    activeProductGroup: ProductGroup;
    isLoading = false;
    productSubGroups: ArticleCategory[] = [];
	typoTeaserText = this._translationService.translate('LIFESTYLE.PDL.SEEALLPRODUCTS');

	private _articlesAmountInViewport: number;
    private _categoryChangeSubscription: Subscription;
	private _viewPortSubscription: Subscription;

    constructor(
        private _productGroupsService: ProductGroupsService,
        private _translationService: TranslationService,
        private _activatedRoute: ActivatedRoute,
        private _productListService: ProductListService,
        private _router: Router,
		private _viewPortService: ViewportService
    ) {}

    ngOnInit(): void {
		this.getArticlesAmountForCurrentViewport();

        this._categoryChangeSubscription = this._activatedRoute.params.subscribe((params) => {
            this.activeProductGroup = this._productGroupsService.groups.find((x: ProductGroup) => x.urlName === params['category']);
            if (this.isSecondLevel) {
                this.activeProductGroup = this.activeProductGroup.subGroups.find((x: ProductGroup) => x.urlName === params['subcategory']);
            }

            this.initSubgroupsWithArticles();
        });
    }

    ngOnDestroy(): void {
        this._categoryChangeSubscription.unsubscribe();
		this._viewPortSubscription.unsubscribe();
    }

    private initSubgroupsWithArticles(): void {
        // empty page on category change
        this.isLoading = true;
        this.productSubGroups = [];
        window.scrollTo(0, 0);

        const sub = this._productListService.getCollectionMainCategoryArticles(this.activeProductGroup.groupId, this._articlesAmountInViewport).subscribe(
            (response: ArticleCategory[]) => {
                sub.unsubscribe();
                this.isLoading = false;
                this.productSubGroups = response;
            },
            (error) => {
                sub.unsubscribe();
                console.log('Error: ', error);
            }
        );
    }

    routeToSubcategoryPage(groupUrlName: string): void {
        this._router.navigate([groupUrlName], { relativeTo: this._activatedRoute });
    }

    scrollToCategory(groupId: string): void {
        const el = document.querySelector('#' + groupId);
        el.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
    }

	// Get exact amount of articles depending on current viewport
	private getArticlesAmountForCurrentViewport() {
		const viewPortArticleAmountMap = (viewPort) => {
			const mq = { 'mq1': 4, 'mq6': 8, 'mq7': 10 }; // 'viewPort': articleAmount
			return mq[viewPort] ?? 6; // return article amount for current MQ or for not defined MQs, set amount of 6
		};

		this._articlesAmountInViewport = viewPortArticleAmountMap(this._viewPortService.getCurrentViewPort());

		this._viewPortSubscription = this._viewPortService.getViewportChangedObserver().subscribe((newViewPort) => {
			if (this._articlesAmountInViewport != viewPortArticleAmountMap(newViewPort)) {
				this._articlesAmountInViewport = viewPortArticleAmountMap(newViewPort);
				this.initSubgroupsWithArticles();
			}
		});
	}
}
