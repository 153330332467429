import { Component} from '@angular/core';

@Component({
    selector: 'zk-collection',
    templateUrl: './collection.component.html',
    styleUrls: ['./collection.component.scss']
})
export class CollectionComponent {

}
