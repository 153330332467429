<div *ngIf="!result && !isError" class="page-wrapper">
    <wb-spinner class="loading-spinner" theme="light"></wb-spinner>
</div>

<zk-search-results-list-page *ngIf="result?.articles?.articles.length > 0 || result?.wheels?.articles.length > 0"
                             [term]="term"
                             [searchResult]="result">
</zk-search-results-list-page>

<zk-search-not-found-page *ngIf="(result?.articles?.articles.length === 0 && result?.wheels?.articles.length === 0) || isError"
                          [term]="term"
                          [searchResult]="result"
                          [isError]="isError">
</zk-search-not-found-page>
