// ng
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
// services
import { TeaserService } from '@shared/shared-services/teaser/teaser.service';
import { TranslationService } from '@shared/shared-services/translate/translation.service';
// model
import { CategoryTeaserMedium } from '@shared/global-models/categoryTeaserMedium.model';


@Component({
    selector: 'zk-category-teaser-medium',
    templateUrl: 'category-teaser-medium.component.html',
    styleUrls: ['./category-teaser-medium.component.scss']
})
export class CategoryTeaserMediumComponent implements OnInit, OnDestroy {
    teasers: CategoryTeaserMedium[] = [];
    teaserHeadline = '';

    private _teaserSubscription: Subscription;

    constructor(
        private _teaserService: TeaserService,
        private _translationService: TranslationService) {
    }

    ngOnInit() {
        this.getCategoryTeaserData();
    }

    ngOnDestroy(): void {
        this._teaserSubscription.unsubscribe();
    }

    private getCategoryTeaserData(): void {
        this._teaserSubscription = this._teaserService.getHighlightsWheelGroupImagesData('03wheels').subscribe(
            (response: any[]) => {
                response.forEach((x) => {
                    const teaser: CategoryTeaserMedium = {
                        headline: x.name,
                        subHeadline: x.groupId,
                        link: x.urlName,
                        image: x.image,
                        image740: x.image740,
                        image480: x.image480,
                        image400: x.image400,
                        image320: x.image320,
                        imageDescription: x.imageDescription
                    };
                    this.teasers.push(teaser);
                });

                this.setTeaserTexts();
            },
            (error) => {
                console.error('Error at getHighlightsWheelGroupImagesData subscription: ', error);
            }
        );
    }

    private setTeaserTexts(): void {
        const mainKey: string = 'WHEELS.WHEELGROUPSHIGHLIGHT.03WHEELS.';
        // Different sub headlines for the teasers
        for (const t of this.teasers) {
            const token: string = mainKey + t.subHeadline;
            t.subHeadline = this._translationService.translate(token);
        }

        // Headline for complete wheels and wheel accessories
        this.teaserHeadline = this._translationService.translate(mainKey + 'HEADLINE');
    }
}
