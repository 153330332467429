// ng
import { Injectable } from '@angular/core';
import { Subscription } from 'rxjs';
// services
import { GroupMapperService } from '@shared/shared-services/group-mapper/group-mapper.service';
import { ShoppingCartService } from '@shared/shared-services/shopping-cart/shopping-cart.service';
import { AppService } from '../../../app.service';
import { TranslationService } from '@shared/shared-services/translate/translation.service';
import { SelectedCarService } from '@shared/shared-services/selected-car/selected-car.service';
// model
import { Article } from '@shared/global-models/article/article.model';
import { AnalyticsArticle } from '@shared/shared-services/google-analytics/google-analytics.model';
import { ShoppingCartItemModalInfoModel } from '@shared/shared-services/shopping-cart/model/shopping-cart-item-modal-info.model';
import { ShoppingCartItemAction } from '@shared/shared-services/shopping-cart/model/shopping-cart-item-action.model';


// Google Analytics
declare let gtag: Function;

@Injectable()
export class GoogleAnalyticsService {
    private _shoppingCartChangedSub: Subscription = new Subscription();
    private _carChangedSubscription: Subscription = new Subscription();

    constructor(private _groupMapperService: GroupMapperService,
                private _shoppingCartService: ShoppingCartService,
                private _appService: AppService,
                private _translationService: TranslationService,
                private _selectedCarService: SelectedCarService) {

        // handle e-commerce related events
        this._shoppingCartChangedSub = this._shoppingCartService.shoppingCartItemAddedOrRemovedInfo.subscribe(
        (response: ShoppingCartItemModalInfoModel) => {
                if (response.action === ShoppingCartItemAction.ITEM_ADD || response.action === ShoppingCartItemAction.ITEM_QUANTITY_RAISED) {
                    this.trackProductAdd(response.items);
                } else if (response.action === ShoppingCartItemAction.ITEM_REMOVE) {
                    this.trackProductRemove(response.items);
                }
             },
        error => console.log('Error: ', error));

        // listen to car logging
        this._carChangedSubscription = this._selectedCarService.carLineSubscriber.subscribe(() => this.setUserMeta());
    }

    /**
     * Send Event to Google Analytics. If <eventCategory> or <eventLabel> are omitted, they will be set to the default values of "(not set)".
     * @param eventName The value that will appear as the event action in Google Analytics Event reports.
     * @param eventCategory The category of the event.
     * @param eventLabel The label of the event.
     * @param eventValue A non-negative integer that will appear as the event value.
     */
    public trackEvent(
        eventName: string,
        eventCategory: string,
        eventLabel: string = null,
        eventValue: number = null
    ): void {
        gtag('event', eventName, {
             'event_category': eventCategory,
             'event_label': eventLabel,
             'value': eventValue,
        });
    }

    /**
     * SPA workaround to tell Analytics that the site changed.
     * Instead of common routing approach we tell manually because we have translated routes for the same page content.
     * @param pageTitle
     */
    public trackPageView(pageTitle: string): void {
        gtag('event', 'page_view', {
             'page_title': pageTitle,
             'page_location': location.href,
             'page_path': location.pathname,
             'page_market': this._appService.currentMarket.marketCode,
             'page_language': this._translationService.currentLang
        });
    }

    /**
     * Persistent tracking data like market and selected car.
     */
    public setUserMeta(): void {
        gtag('set', {
             'language': this._translationService.currentLang,
             'market': this._appService.currentMarket.marketCode,
             'vehicleClass': this._selectedCarService.carClass ? this._selectedCarService.carClass.name : '(not set)',
             'modelCode': this._selectedCarService.carLine ? this._selectedCarService.carLine.trackingCode : '(not set)',
             'vehicleBody': this._selectedCarService.bodyType ? this._selectedCarService.bodyType.name : '(not set)'
        });
    }


    /**
     * Tracks information about products that have been viewed.
     * "A user views a list of one or more products."
     */
    public trackProductImpressions(articles: Article[]): void {
        let items : AnalyticsArticle[] = [];
        articles.forEach( (x) => items.push(this.convertArticleToAnalyticsFieldObject(x)));

        gtag('event', 'view_item_list', {
             "items": items
        });
    }

    /**
     * Track information about a product that was clicked.
     * Currently not used but maybe wanted later.
     * @param article
     */
    public trackProductClick(article: Article): void {
        const item: AnalyticsArticle = this.convertArticleToAnalyticsFieldObject(article);

        gtag('event', 'select_content', {
             "content_type": "product",
             "items": [item]
        });
    }

    /**
     * Track information about Product Detail Views.
     * @param article
     */
    public trackProductDetailView(article: Article): void {
        const item: AnalyticsArticle = this.convertArticleToAnalyticsFieldObject(article);

        gtag('event', 'view_item', {
             "items": [item]
        });
    }

    /**
     * Track the addition of products to a shopping cart/wishlist.
     * @param articles
     */
    public trackProductAdd(articles: Article[]): void {
        let items : AnalyticsArticle[] = [];
        articles.forEach( (x) => items.push(this.convertArticleToAnalyticsFieldObject(x)));

        gtag('event', 'add_to_cart', {
             "items": items
        });
    }

    /**
     * Track the removal of products from a shopping cart/wishlist.
     * @param articles
     */
    public trackProductRemove(articles: Article[]): void {
        let items : AnalyticsArticle[] = [];
        articles.forEach( (x) => items.push(this.convertArticleToAnalyticsFieldObject(x)));

        gtag('event', 'remove_from_cart', {
            "items": items
        });
    }

    // PRIVATE //

    private convertArticleToAnalyticsFieldObject(article: Article): AnalyticsArticle {
        return  {
            id: article.articleId,
            name: article.headline,
            brand: article.productType,
            category: this.getCategoryFromArticle(article),
            variant: article.headline2,
            price: article.grossPrice as number,
            quantity: article.quantity
        }
    }

    private getCategoryFromArticle(article: Article): string {
        const mainCat = this._groupMapperService.getGroupUrlNameFromGroupId(article.groupIdPath[0]);
        const subCat = this._groupMapperService.getGroupUrlNameFromGroupId(article.groupIdPath[1]);

        return mainCat +'/'+subCat;
    }
}
