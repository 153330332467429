// Modules - ng
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
// Modules - own
import { ModalModule } from '@shared/modals/modal.module';
import { ComponentsModule } from '@shared/components/components.module';
// Components
import { FooterBaseComponent } from '@shared/footer/footer-base/footer-base.component';
import { StickyFooterComponent } from '@shared/footer/sticky-footer/sticky-footer.component';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        ModalModule,
        ComponentsModule
    ],
    declarations: [
        FooterBaseComponent,
        StickyFooterComponent
    ],
    exports: [
        FooterBaseComponent,
        StickyFooterComponent
    ],
    providers: []
})
export class FooterModule {}
