// ng
import { HttpEvent, HttpHandler, HttpInterceptor, HttpParams, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

// services
import { SelectedCarService } from '@shared/shared-services/selected-car/selected-car.service';
import { TranslationService } from '@shared/shared-services/translate/translation.service';
import { AppService } from './app.service';

// model
import { MODE } from '@shared/global-models/mode.enum';
import { environment } from 'environments/environment';

@Injectable()
export class BaseInterceptor implements HttpInterceptor {
    constructor(
        private _translationService: TranslationService,
		private selectedCarService: SelectedCarService,
		private _appService: AppService
    ) {}

    /**
     * Intercepts and alters HTTP requests. Used to change API calls globally.
     * @param {HttpRequest} req
     * @param {HttpHandler} next
     * @returns {Observable<HttpEvent>}
     */
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let urlToCall: string = req.url;

        // point to remote backend API
        if (!/^http|https:\/\/*./.test(urlToCall)) {
            if (urlToCall.indexOf('api/') !== -1)   {
                urlToCall = `${ environment.apiUrl }${ req.url }`;
            }
        }

        let apiReq: HttpRequest<any>;
        let params: HttpParams = new HttpParams();

        // TODO: Could be brought to the services
        // add FIN query param needed for API calls
        if (
            urlToCall.indexOf('api/articles') > -1 ||
            urlToCall.indexOf('api/v2/articles') > -1 ||
            urlToCall.indexOf('api/highlights/article') > -1 ||
            urlToCall.indexOf('api/cart/calc') > -1 ||
            urlToCall.indexOf('api/highlights/all-articles') > -1 ||
            urlToCall.indexOf('api/groups') > -1
        ) {
			if (this.selectedCarService.VIN && this._appService.appMode === MODE.ACCESSORIES) {
				params = this.addVinQueryParameter(params);
			}
            apiReq = req.clone({ url: urlToCall, params: params });
        } else {
            // for all other calls just clone the request
            apiReq = req.clone({ url: urlToCall });
        }

        return next.handle(apiReq);
    }

    /**
     * Adds the VIN/Licence Plate number as a GET param (if it is available) to the passed http param.
     * @param {HttpParams} params
     * @returns {HttpParams}
     */
    private addVinQueryParameter(params: HttpParams): HttpParams {
        const vin: string = this.selectedCarService.VIN;
        if (vin) {
            params = params.append('vin', vin);
        }

        return params;
    }
}
