// ng
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
// services
import { GroupMapperService } from '@shared/shared-services/group-mapper/group-mapper.service';
import { TranslationService } from '@shared/shared-services/translate/translation.service';
import { AppService } from '../../../app.service';
import { LocalStorageService } from '@shared/shared-services/storage/local-storage.service';
import { CampaignService } from '@shared/components/campaign/campaign.service';
// model
import { MarketModel } from '@shared/global-models/market.model';
import { Language } from '@mbcs/mbcs-lib';
import { TranslatedItem, TranslatedItems } from '@shared/global-models/translateItems.model';
import { StorageEnum } from '@shared/shared-services/storage/storage.enum';
import { ShoppingCartData } from '@shared/shared-services/shopping-cart/model/shopping-cart-data.model';

@Component({
    selector: 'zk-language-switch',
    templateUrl: 'language-switch.component.html',
    styleUrls: ['./language-switch.component.scss']
})
export class LanguageSwitchComponent implements OnInit {
    @Input() marketData: MarketModel;

    availableLanguages: Language[] = [];
    currentLanguage: Language;

    constructor(
        private _router: Router,
        private _groupMapperService: GroupMapperService,
        private _translationService: TranslationService,
        private _appService: AppService,
        private _localStorageService: LocalStorageService,
		private _campaignService: CampaignService
    ) {}

    ngOnInit() {
        // init data
        this.marketData.availableLanguages.forEach((x) => {
            this.availableLanguages.push({ shortLang: x, longLang: x });
        });
        // get only language part from market data and find corresponding model
        const currentLanguage = this._translationService.currentLang.substr(0, 2);
        this.currentLanguage = this.availableLanguages.find((x: Language) => x.shortLang === currentLanguage);
    }

    changeLanguage(languageToChangeTo: Language) {
        // clear search history to not have suggestions in wrong language
        this._localStorageService.removeItem(StorageEnum.SEARCH_HISTORY);

        const langMarket: string = languageToChangeTo.shortLang + '-' + this.marketData.marketCode;
        this.updateWishlistAndUrl(langMarket);
    }

    /**
     * Checks the current URL for the possible params and maps them to their unique IDs.
     * Used to translate the URL to new car, categories and article name in the new language.
     */
    private parseCurrentUrl(targetLang: string) {
        let url: string[] = this._router.url.split('/');

		// Only for campaign pages
		if (this._campaignService.campaignTranslations && Object.keys(this._campaignService.campaignTranslations).length > 1) {
			const lang = targetLang.split('-')[0];

			for (const key in this._campaignService.campaignTranslations) {
				if (key === lang) {
					const campaignName = this._campaignService.campaignTranslations[key];
					url[1] = this._translationService.currentLang;

					url.pop();
					url.push(campaignName);

					window.location.href = url.join('/');
				}
			}
		} else if (this._campaignService.campaignTranslations && Object.keys(this._campaignService.campaignTranslations).length === 1) {
            this._router.navigate(['/404']);

            return;
        }

        // check for start page, reserved routes and respect servlet context (stages not running on root domain)
        if (url.length === 4 || url[2] === 'legal' || url[2] === 'vin' || url[2] === '404' || url[3] === '404' || url[4] === 'wishlist' || url.length > 3 && url[4].startsWith('search')) {
            url[1] = this._translationService.currentLang;
            window.location.replace(url.join('/'));

            return;
        }

        // extract only params part for ACC & COL routes
        url = url.slice(4, url.length);
        let query = '?';
        // car line id (ACC) or products (ACC & COL)
        if (url.length > 0 && url[0] !== 'products') {
            const carLineId = url[0].split('-').pop();
            query += `carLines=${carLineId}`;
        }
        // main category
        if (url[1]) {
            const categoryId = this._groupMapperService.getGroupIdFromGroupUrlName(url[1]);
            query += `&groups=${categoryId}`;
        }
        // sub-category
        if (url[2]) {
            const subcategoryId = this._groupMapperService.getGroupIdFromGroupUrlName(url[1], url[2]);
            query += `,${subcategoryId}`;
        }
        // PDP (COL & ACC) || sub-sub category (COL)
        if (url.length === 4) {
            const articleId = url[3].substring(url[3].lastIndexOf('-') + 1);
            if (this.isArticleId(articleId)) {
                query += `&articles=${articleId}`;
            } else {
                const subSubCategoryId = this._groupMapperService.getGroupIdFromGroupUrlName(url[1], url[2], url[3]);
                query += `,${subSubCategoryId}`;
            }
        }
        // PDP (COL)
        if (url.length === 5) {
            const subSubCategoryId = this._groupMapperService.getGroupIdFromGroupUrlName(url[1], url[2], url[3]);
            query += `,${subSubCategoryId}`;

            const articleId = url[4].substring(url[4].lastIndexOf('-') + 1);
            query += `&articles=${articleId}`;
        }
        // translate params
        const sub = this._translationService.translateItems(targetLang, query).subscribe(
            (result: TranslatedItems) => {
                sub.unsubscribe();
                this.rewriteUrl(result);
            },
            (error) => {
                sub.unsubscribe();
                console.log('Error: ', error);
            }
        );
    }

    /**
     * Checks if param matches a valid article ID
     * @param val
     */
    private isArticleId(val: string): boolean {
        return val.match('[A-Za-z]\\d{7}.*') ? true : false;
    }

    /**
     * Performs the rewriting of the current URL to the new language.
     * @param items | Optional. Translated items in new language.
     */
    private rewriteUrl(items?: TranslatedItems): void {
		let url = this._translationService.currentLang;
        // nothing to translate on splash page
		if (this._appService.currentAppMode) {
			url += this._appService.currentAppMode;

            url += items.carLines ? '/' + items.carLines[0].urlName : '/products';
            if (items.groups) {
                items.groups.forEach((x) => (url += '/' + x.urlName));
            }
            if (items.articles) {
                url += '/' + items.articles[0].urlName;
            }
		}
        // console.log('URL: ', url);
        window.location.replace(url);
    }

    /**
     * Updates the wishlist in the local storage.
     * Afterwards updates the language and triggers the URL rewrite.
     * @param targetLang
     */
    private updateWishlistAndUrl(targetLang): void {
        const localStorageCart: ShoppingCartData = this._localStorageService.getItem(StorageEnum.SHOPPING_CART);
        if (localStorageCart?.items?.length > 0) {
            let query = '?articles=';
            localStorageCart.items.forEach(x => query += x.articleId + ',');
            query = query.slice(0, -1);

            const sub = this._translationService.translateItems(targetLang, query).subscribe(
                (result: TranslatedItems) => {
                    sub.unsubscribe();
                    // update article names in storage first
                    localStorageCart.items.forEach(x => {
                        const item: TranslatedItem = result.articles.find(y => y.id === x.articleId);
                        x.headline = item.name;

                        if (x?.propertiesCollection?.color && item?.color) {
                            x.propertiesCollection.color = item.color;
                        }
                        if (x?.propertiesCollection?.size && item?.size) {
                            x.propertiesCollection.size = item.size;
                        }
                    });
                    this._localStorageService.setItem(StorageEnum.SHOPPING_CART, localStorageCart);

                    // update URL
                    this._translationService.currentLang = targetLang;
                    this.parseCurrentUrl(targetLang);
                },
                (error) => {
                    sub.unsubscribe();
                    console.log('Error: ', error);
                }
            );
        } else {
            // update URL
            this._translationService.currentLang = targetLang;
            this.parseCurrentUrl(targetLang);
        }
    }
}
