// Modules - ng
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { UrlSerializer } from '@angular/router';
// Modules - own
import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from '@shared/shared.module';
import { PagesModule} from './pages/pages.module';
import { AccessoriesModule } from './assortment/accessories/accessories.module';
import { LifestyleModule } from './assortment/lifestyle/lifestyle.module';
// Components
import { AppComponent } from './app.component';
import { SplashPageComponent } from './pages/splash-page/splash-page.component';
// Helpers
import { CustomUrlSerializer } from './custom-url-serializer';
import { BaseInterceptor } from './base.interceptor';
import { AppService } from './app.service';

@NgModule({
    declarations: [
		AppComponent,
		SplashPageComponent
	],
    imports: [
        BrowserModule,
        BrowserModule.withServerTransition({ appId: 'zk-app' }), // TODO: check if can be removed
        AppRoutingModule,
        LifestyleModule,
        AccessoriesModule,
        PagesModule,
        BrowserAnimationsModule,
        SharedModule
    ],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: BaseInterceptor, multi: true },
        { provide: UrlSerializer, useClass: CustomUrlSerializer },
        AppService
    ],
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
    bootstrap: [AppComponent]
})
export class AppModule {}
