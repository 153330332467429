import { Component, Input} from '@angular/core';

// own
import { TeaserMedium } from '@shared/components/campaign/teaser-medium/teaser-medium.model';

@Component({
	selector: 'zk-teaser-medium[class=campaign-component]',
	templateUrl: './teaser-medium.component.html',
	styleUrls: ['./teaser-medium.component.scss']
})
export class TeaserMediumComponent {
	@Input() data: TeaserMedium;
}
