// ng
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

// own
import { TranslationService } from '@shared/shared-services/translate/translation.service';
import { SelectedCarService } from '@shared/shared-services/selected-car/selected-car.service';
import { VinLockProgressStepsModel } from '@shared/components/vin-lock/vin-lock-progress-steps.model';
import { VIN } from '@shared/shared-services/compatibility/vin.model';

@Injectable()
export class CarChooserService {

    constructor(private httpClient: HttpClient,
				private _selectedCarService: SelectedCarService,
				private _translationService: TranslationService) {}

    lockVehicleByVin(vin: string, showProgress: Boolean) {
		let vehicleType = this._selectedCarService.vehicleType.vehicleTypeId;

        if (showProgress) {
            const param: HttpParams = new HttpParams().set('progress', 'true');

            return this.httpClient.get<VinLockProgressStepsModel>(`api/vehicle/data/${vin}/${this._translationService.currentLang}/${vehicleType}`, {
                observe: 'response',
                params: param
            });
        }

        return this.httpClient.get<VIN>(`api/vehicle/data/${vin}/${this._translationService.currentLang}/${vehicleType}`, { observe: 'response' });
    }
}
