// NG
import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';

// Models
import { PaginationDesign, Slide, SliderConfig } from '@mbcs/mbcs-lib';
import { PageName } from '@shared/shared-services/google-analytics/google-analytics.model';

// Services
import { SelectedCarService } from '@shared/shared-services/selected-car/selected-car.service';
import { SeoService } from '@shared/shared-services/seo/seo.service';
import { UrlParameterService } from '@shared/shared-services/url-parameters-service/url-parameter.service';
import { TeaserService } from '@shared/shared-services/teaser/teaser.service';
import { GoogleAnalyticsService } from '@shared/shared-services/google-analytics/google-analytics.service';

@Component({
    selector: 'zk-start-page',
    templateUrl: './start-page.component.html',
    styleUrls: ['./start-page.component.scss']
})
export class StartPageComponent implements OnInit, AfterViewInit {
    slides: Slide[] = [];

    readonly sliderConfig: SliderConfig = {
        animation: 'ease-in-out',
        animationDuration: 1000,
        arrows: false,
        autoplay: true,
        direction: -1,
        displayTimePerSlide: 5000,
        initialSlide: 1,
        pagination: true,
        paginationDesign: PaginationDesign.default
    };

    firstSlideHeadline: string;
    heightOfStickyCarChooser: number;

    constructor(
        private _teaserService: TeaserService,
        private selectedCarService: SelectedCarService,
        private _seoService: SeoService,
        private _urlParameterService: UrlParameterService,
        private _router: Router,
        private _gaService: GoogleAnalyticsService
    ) {}

    ngOnInit() {
        // check for special query params entry into start page URL
        this._urlParameterService.checkForCarLineIdParamInURL();
        this._urlParameterService.checkForArticleIdParamInURL();

        this.getSliderData();
        // scroll up on initial page load (important for mobile)
        window.scrollTo(0, 0);
    }

    ngAfterViewInit() {
        this._gaService.trackPageView(PageName.START_PAGE);
    }

    routeToPage(buttonLink: string) {
        this._router.navigate([buttonLink], { queryParamsHandling: 'preserve' });
    }

    /**
     * Adjusts the min-height of the wrapper div for the car chooser to prevent layout jumps
     * when car chooser becomes sticky and a fixed position. See ZKDEV-1592.
     * @param event
     */
    preventLayoutJump(event) {
        this.heightOfStickyCarChooser = event;
    }

    /**
     * Gets data for the slider component (a.k.a. "MultiStageTeaser Component")
     */
    private getSliderData(useFallbackLang?: boolean) {
        const sub = this._teaserService.getSlidesFor(this.selectedCarService.vehicleType.vehicleTypeId, useFallbackLang).subscribe(
            (response) => {
                sub.unsubscribe();
                if (!response) {
                    console.error('Error on slides retrieved from CMS: Response is null');
                    return;
                }

                if (response.slides.length > 0) {
                    this.slides = response.slides;
                    this.firstSlideHeadline = response.slides[0].headline;
                    this.setSeoInfo();
                } else if (!useFallbackLang) {
                    console.log('No sliders found for current market. Needs to be added in CMS. Using fallback to default global market "en-MB", therefore.');
                    this.getSliderData(true);
                }
            },
            (error) => {
                sub.unsubscribe();
                console.log('Error:', error);
            }
        );
    }

    private setSeoInfo() {
		const title: string = this._seoService.getTranslation('GENERAL.PAGES.STARTPAGE_STATICTITLE');
		this._seoService.updatePageTitle(title + ' | ' + this.firstSlideHeadline);
		this._seoService.updateMetaDescription(this._seoService.getTranslation('SEO.METADESCRIPTION.STARTPAGE'));
    }
}
