import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'zk-product-teaser-cards',
    templateUrl: './product-teaser-cards.component.html',
    styleUrls: ['./product-teaser-cards.component.scss']
})
export class ProductTeaserCardsComponent implements OnInit {

    constructor() {}

    ngOnInit(): void {}
}
