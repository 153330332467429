import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

import { LegalPageModel, LegalPageType } from './legal-page.model';
import { TranslationService } from "@shared/shared-services/translate/translation.service";

@Injectable()
export class LegalPagesService {
    constructor(private httpClient: HttpClient,
                private _translationService: TranslationService) {}

    getLegalPageContentFromCMS(type: LegalPageType): Observable<LegalPageModel> {
        return this.httpClient.get<LegalPageModel>(`api/basic/docs/${type}/${this._translationService.currentLang}`);
    }
}
