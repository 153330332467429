// Modules - ng
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

// Components
import { VinLoginPageComponent } from './pages/vin-login-page/vin-login-page.component';
import { SplashPageComponent } from './pages/splash-page/splash-page.component';
import { CanActivateCollectionGuard } from '@shared/shared-services/guards/can-activate-collection-guard';

// Legal Pages
import { ImprintPageComponent } from './pages/legal/imprint-page/imprint-page.component';
import { CopyrightPageComponent } from './pages/legal/copyright-page/copyright-page.component';
import { PrivacyPageComponent } from './pages/legal/privacy-page/privacy-page.component';
import { ModernSlaveryActPageComponent } from './pages/legal/modernslaveryact-page/modern-slavery-act-page.component';

// See URL concept: https://bp-create.de/confluence/display/ZK2/ZK3.URL-Konzept
const appRoutes: Routes = [
	{ path: ':market', component: SplashPageComponent, canActivate: [CanActivateCollectionGuard] },
    // legal pages
    { path: ':market/legal/imprint', component: ImprintPageComponent },
    { path: ':market/legal/copyright', component: CopyrightPageComponent },
    { path: ':market/legal/privacy', component: PrivacyPageComponent },
    { path: ':market/legal/modernslaveryact', component: ModernSlaveryActPageComponent },
    // special vin deeplink entries
    { path: ':market/vin/:page', component: VinLoginPageComponent },
    { path: ':market/vin/:page/:vin', component: VinLoginPageComponent },
    // Then routes from LifestyleRoutingModule are traversed //
    // Then routes from AccessoriesRoutingModule are traversed which handle 404 if nothing matches //
];

@NgModule({
    imports: [RouterModule.forRoot(appRoutes)],
    exports: [RouterModule]
})
export class AppRoutingModule {}
