<footer class="wb-grid-container wrapper"
        [ngClass]="{'add-height': showPriceNotification}">

    <mba-button class="switch-mode-link"
                *ngIf="switchModeVisible"
                (clicked)="switchMode()"
                [isLinkLight]="true"
                [hasIconOnMobile]="true">
        <span [zkTranslate]="isAccessoriesMode ? 'GENERAL.LINKTOLIFESTYLE' : 'GENERAL.LINKTOACCESSORIES'"></span>
    </mba-button>

    <mba-separator separatorClass="separator_is-dark-grey"
                  [separatorStyle]="{'margin-top.px':30}">
    </mba-separator>

    <mba-legal-footer class="links"
                      [config]="legalFooterConfig"
                      (clicked)="linkClicked($event)">
        <a #usercentrics style="display: none" onClick="UC_UI.showSecondLayer();"></a>
    </mba-legal-footer>

    <!--Additional infos for the CO2 labels-->
    <div *ngIf="co2Label && isAmg">
        <wb-text tag="div" size="l" class="description-co2" [innerHTML]="co2Label"></wb-text>
    </div>
</footer>

<zk-sticky-footer *ngIf="showPriceNotification"></zk-sticky-footer>
