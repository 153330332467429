// ng
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
// model
import { ModelDesign } from "@shared/components/car-chooser/models";

@Component({
    selector: 'zk-model-design',
    templateUrl: './model-design.component.html',
    styleUrls: ['./model-design.component.scss']
})
export class ModelDesignComponent implements OnInit {
    @Input()
    modelDesigns: ModelDesign[];

    @Output()
    modelDesignClicked: EventEmitter<ModelDesign> = new EventEmitter<ModelDesign>();

    constructor() {}

    ngOnInit(): void {}

    /**
     * Activate the model design type
     * @param modelDesign model to activate
     */
    activateModelDesign(modelDesign: ModelDesign): void {
        if (modelDesign.isActive) {
            return;
        }

        this.setModelDesignActive(modelDesign);
        this.modelDesignClicked.emit(modelDesign);
    }

    /**
     * Set the model type to active and reset every other model type
     * @param modelDesign ModelDesign to set active
     */
    private setModelDesignActive(modelDesign: ModelDesign): void {
        for (const md of this.modelDesigns) {
            md.isActive = md.modelDesignId === modelDesign.modelDesignId;
        }
    }
}
