// ng
import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";

@Pipe({
  name: 'highlight'
})
export class HighlightTextPipe implements PipeTransform {

    constructor(private _sanitizer: DomSanitizer) {
    }

    /**
     * Highlights the given haystack string as bold except the needle
     * @param haystack - search term
     * @param needle - search value
     */
    transform(haystack: string, needle: string): SafeHtml {
      needle = needle.trim();
      let txt = '';
      const start = haystack.toLowerCase().indexOf(needle.toLowerCase());

      // no match
      if (start === -1) {
          txt += '<span>' + haystack + '</span>';
          return this._sanitizer.bypassSecurityTrustHtml(txt);
      }

      const end = haystack.length;
      const search = haystack.substring(start, needle.length);
      // beginning
      if (start === 0) {
          txt += '<span>' + search + '</span>';
          txt += '<span style="font-weight: bold">' + haystack.substring(search.length, end + 1) + '</span>';
      }
      // middle => not needed according to BE because search service uses a prefix match currently

      // end
      else if (start > 0) {
          const startPart = '<span>' + haystack.substring(0, start) + '</span>';
          txt += '<span style="font-weight: bold">' + startPart + '</span>';
          txt += '<span>' + haystack.substring(start, end) + '</span>';
      }

      return this._sanitizer.bypassSecurityTrustHtml(txt);
    }
}
