// NG
import { AfterViewInit, Component, OnDestroy, OnInit, HostListener } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';

// Services
import { ProductListService } from '@shared/shared-services/product-list/product-list.service';
import { PopupModalService } from "@shared/modals/popup-modal/popup-modal.service";
import { TranslationService } from '@shared/shared-services/translate/translation.service';
import { ShoppingCartService } from '@shared/shared-services/shopping-cart/shopping-cart.service';
import { CompatibilityCheckService } from '@shared/shared-services/compatibility/compatibility.service';
import { ViewportService } from '@mbcs/mbcs-lib';
import { SelectedCarService } from '@shared/shared-services/selected-car/selected-car.service';
import { ProductDetailsService } from './product-details.service';
import { WheelSpecialService } from '../sub-category-page/wheel-category/light-alloy-wheel/wheel-special.service';
import { GroupMapperService } from '@shared/shared-services/group-mapper/group-mapper.service';
import { AppService } from '../../../../app.service';
import { ProductGroupsService } from '@shared/shared-services/product-groups/product-groups.service';
import { GoogleAnalyticsService } from '@shared/shared-services/google-analytics/google-analytics.service';

// Model
import { ProductGroup } from '@mbcs/mbcs-lib';
import { Article } from '@shared/global-models/article/article.model';
import { ShoppingCartItemMaxAmountReachedInfo } from '@shared/shared-services/shopping-cart/model/shopping-cart-item-max-amount-reached-info.model';
import { MarketModel } from '@shared/global-models/market.model';
import { PageName } from '@shared/shared-services/google-analytics/google-analytics.model';
import { MODE } from '@shared/global-models/mode.enum';

@Component({
	selector: 'zk-product-details',
	templateUrl: 'product-details-page.component.html',
	styleUrls: ['product-details-page.component.scss']
})
export class ProductDetailsPageComponent implements OnInit, AfterViewInit, OnDestroy {
	statusMessage: string = null;
	articleExists: boolean = true;
	article: Article;
	addToCartButtonDisabled: boolean = false;
	showLoadingSpinner: boolean = false;
	isMobile: boolean = false;
	displayErrorMessage: boolean = false;
	mainCategoryName: string = '';
	showPageLoadingSpinner: boolean = false;
	recommendedArticles: Article[];
	wasAddedToCart: boolean = false;
	pricePrefix = this.translateService.translate('GENERAL.RETAILPRICEPREFIX');
	isCompleteWheels: boolean = false;
	marketData: MarketModel;
    dropdownLabel = this.translateService.translate('LIFESTYLE.GENERAL.COLORS');
    marketHasShopLink: boolean = false;

	private _carLogged = false;
	private viewPortSubscription: Subscription;
	private routingSubscription: Subscription;
	private loggedInWithVINChangedSubscription: Subscription;
	private shoppingCartErrorSubscription: Subscription;
	private shoppingCartStatusUpdateSubscription: Subscription;
	private groupsChangedSubscription: Subscription;
	private articleID: string;

	constructor(
		private activatedRoute: ActivatedRoute,
		private router: Router,
		private productListService: ProductListService,
		private modalService: PopupModalService,
		private translateService: TranslationService,
		private shoppingCartService: ShoppingCartService,
		private compatibilityService: CompatibilityCheckService,
		private viewPortService: ViewportService,
		private selectedCarService: SelectedCarService,
		private productDetailsService: ProductDetailsService,
		private wheelSpecialService: WheelSpecialService,
		private _groupMapperService: GroupMapperService,
		private _appService: AppService,
		private _productGroupsService: ProductGroupsService,
		private _gaService: GoogleAnalyticsService
    ) {}

	ngOnInit() {
		this.setupPage();
		this.listenToChanges();
	}

	/**
	 * Route to the primary category page
	 */
	goBack() {
		const completeUrl: string = `/${this.activatedRoute.snapshot.params.carclass}/${this.activatedRoute.snapshot.params.category}`;
		this.router.navigate([completeUrl], {queryParamsHandling: 'preserve'});
	}

	/**
	 * Builds the path for the category where the given article is in
	 * @param article article for which an alternative must be found
	 */
	goToCategory(article: Article): string {
		let pathToGoTo: string = '';
		if (article && article.groupIdPath && article.groupIdPath.length > 0) {
			const groupName: string = this._groupMapperService.getGroupUrlNameFromGroupId(article.groupIdPath[0]);
			const subGroupName: string = this._groupMapperService.getGroupUrlNameFromGroupId(article.groupIdPath[1]);
			// After a car is logged in there is a possibility that the group the article belongs to does not exist for this car
			// therefore we can not redirect to the group the article belongs to.
			// if car is not logged in we redirect to a product page that displays common products that are not car specific.
			if (!this.selectedCarService.carClass || !this.selectedCarService.carLine) {
				pathToGoTo = `/products/${groupName}/${subGroupName}`;
			} else {
				const selectedCar: string = this.selectedCarService.carLine.urlName;
				pathToGoTo = `/${selectedCar}/${groupName}/${subGroupName}`;
			}
		}

		return pathToGoTo;
	}

	/**
	 * Add the current article or completeSet to the shopping cart
	 */
	onClickAddToCart(completeWheelData, isCompleteWheels: boolean) {
        if (isCompleteWheels) {
			completeWheelData.appMode = this._appService.appMode;
            const rimSet: Article[] = this.shoppingCartService.buildRimSetFromWheelArticle(completeWheelData, true);
            this.wheelSpecialService.wheelPreviewAsSet = true;
            this.wheelSpecialService.wheelPreviewArticles = rimSet;
        } else {
            this.showLoadingSpinner = true;
            this.addToCartButtonDisabled = true;
            this.shoppingCartService.addItemsToShoppingCart([this.article], 1, true);
        }

		this.wasAddedToCart = true;
    }

    /**
     * Open Compatibility check modal and set the current article from this page as current article
     * (when it was not yet added to the shopping cart) to include in the check.
     */
    onClickCompatibilityCheck() {
        const articleWasAddedToCart: boolean = this.shoppingCartService.getArticleFromCartById(this.article.articleId) !== null;
        if (!articleWasAddedToCart) {
            this.compatibilityService.currentArticles = [this.article];
        }

        this.modalService.open('compatibility-check');
    }

    /**
     * Routes to the selected article from the dropdown
     */
    switchVariant(event: any) {
        const article: Article = this.article.variantArticles.find((x: Article) => x.articleId === event.currentTarget.value);
        this.router.navigate(['../' + article.urlName], {relativeTo: this.activatedRoute, queryParamsHandling: 'preserve'}).then();
    }

    ngAfterViewInit() {
        this._gaService.trackPageView(PageName.PRODUCT_PAGE);
    }

    ngOnDestroy(): void {
        if (this.viewPortSubscription) {
            this.viewPortSubscription.unsubscribe();
        }

        if (this.routingSubscription) {
            this.routingSubscription.unsubscribe();
        }

        if (this.loggedInWithVINChangedSubscription) {
            this.loggedInWithVINChangedSubscription.unsubscribe();
        }

        if (this.shoppingCartErrorSubscription) {
            this.shoppingCartErrorSubscription.unsubscribe();
        }

        if (this.shoppingCartStatusUpdateSubscription) {
            this.shoppingCartStatusUpdateSubscription.unsubscribe();
        }

        if (this.groupsChangedSubscription) {
            this.groupsChangedSubscription.unsubscribe();
        }
    }

    printPDF(): void {
        this.shoppingCartService.proceedToDownloadPDF(this.article);
    }

    /**
     * Redirects to the model start page or start page. Used for error feedback if article ID is invalid.
     */
    routeToStartOrModelPage() {
        const url = this.selectedCarService.carLine ? this.selectedCarService.carLine.urlName : '/';
        this.router.navigate([url], { queryParamsHandling: 'preserve' });
    }

    /**
     * Setup the initial product detail page
     */
    private setupPage() {
        this.marketData = this._appService.currentMarket;
        this.addToCartButtonDisabled = false;
        this._carLogged = this.activatedRoute.snapshot.params.carclass !== 'products';
        this.statusMessage = null;


        // get actual viewport
        const vp: string = this.viewPortService.getCurrentViewPort();
        this.isMobile = vp === 'mq1' || vp === 'mq2';

        // scroll up on initial page load (important for mobile)
        window.scrollTo(0, 0);

        this.articleID = this.productDetailsService.getArticleIdFromUrl(this.activatedRoute.snapshot.params.product);
        // only load article data if it´s the first time or if a different article ID is requested,
        // Also update article when a car was selected via car chooser or vin log in
        if (!this.article || this.article.articleId !== this.articleID || this.selectedCarService.checkCarSelectionChanged) {
            this.requestArticleData(this.articleID);
        }
    }

    private loadArticlesForTeaserCarousel(articleId: string) {
        const sub: Subscription = this.productDetailsService.getRecommendedArticles(articleId).subscribe(
            (value: Article[]) => {
                value.forEach((x) => this.productDetailsService.addContentVolumeInfoTranslation(x));
                this.recommendedArticles = value;

                if (sub) {
                    sub.unsubscribe();
                }
            },
            (error) => {
                console.log('Error: ', error);
            },
            () => {
                if (sub) {
                    sub.unsubscribe();
                }
            }
        );
    }

    /**
     * Browser back button handler. Close eventual displayed modal.
     */
    @HostListener('window:popstate')
    private popState() {
        if (this.modalService.isModalActive('compatibility-check')) {
            this.modalService.close('compatibility-check');
        }
    }

    private requestArticleData(articleID: string) {
        this.showPageLoadingSpinner = true;
        const vehicleTypeId: string = this.selectedCarService.carLine ? this.selectedCarService.carLine.carLineId
                                                                      : this.selectedCarService.vehicleType.vehicleTypeId;

        const subscription: Subscription = this.productListService.getArticleDetails(articleID, vehicleTypeId).subscribe(
            (response: Article) => {
                subscription.unsubscribe();
                this.showPageLoadingSpinner = false;
                this.articleExists = true;
                this.article = response;
                this.article.quantity = 1;

                // check if we need to redirect - some articles like light-alloy wheels should be shown on their own page
                this.redirectToWheelSpecial();
                this.isCompleteWheels = this.completeWheels();

                this.mainCategoryName = this._groupMapperService.getGroupNameFromUrlName(this.activatedRoute.snapshot.params.category);

                this.productDetailsService.setSeoInfo(response, this._carLogged);

                // care product? add missing translation
                this.article = this.productDetailsService.addContentVolumeInfoTranslation(this.article);

                this._gaService.trackProductDetailView(this.article);

                // load new articles for carousel is needed to get articles that fit for the given car via VIN
                this.loadArticlesForTeaserCarousel(this.article.articleId);

                // For the last step check whether we need to change the URL
                this.checkAndUpdateUrl(this.article.groupIdPath, response.urlName);

				this.wasAddedToCart = !!this.shoppingCartService.getArticleFromCartById(this.article.articleId);
            },
            (error) => {
                this.showPageLoadingSpinner = false;
                subscription.unsubscribe();

                // Case: Article does not exist for the new logged in car.
                if (error.status === 404 && this.article) {
                    // Mark shown article as not fitting.
                    this.article.fit = false;
                    // Don´t let the user stuck on the PDP with (possible) also unavailable articles for the new logged in car
                    this.loadArticlesForTeaserCarousel(null);
                } else {
                    // Case: deeplink entry with invalid article id or backend error. Show error message.
                    this.articleExists = false;
                }
            }
        );
    }

    private listenToChanges() {
        this.viewPortSubscription = this.viewPortService.getViewportChangedObserver().subscribe((newViewPort) => {
            this.isMobile = newViewPort === 'mq1' || newViewPort === 'mq2';
        });

        this.shoppingCartErrorSubscription = this.shoppingCartService.shoppingCartChangedError.subscribe(() => {
            this.displayErrorMessage = true;
            this.addToCartButtonDisabled = false;
            this.showLoadingSpinner = false;
        });

        // check if maximum of article quantity 10 is reached and give a feedback to the user
        this.shoppingCartStatusUpdateSubscription = this.shoppingCartService.shoppingCartStatusMessage.subscribe(
            (info: ShoppingCartItemMaxAmountReachedInfo) => {
                if (this.article && this.article.articleId === info.articleId) {
                    this.showLoadingSpinner = false;
                    if (info.maxReached) {
                        this.statusMessage = this.translateService.translate('SHOPPINGCART.MAXITEMSWISHLIST');
                        this.addToCartButtonDisabled = true;
                    } else {
                        this.statusMessage = null;
                        this.addToCartButtonDisabled = false;
                    }
                }
            }
        );

        // Routing subscription is needed to react on car logging in (via selecting a car or VIN input at compatibility check)
        // or if another article from the carousel should be loaded
        this.routingSubscription = this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                this.setupPage();
            }
        });

        // React to car logging via VIN or removing it
        this.loggedInWithVINChangedSubscription = this.selectedCarService.LoggedInWithVINChanged.subscribe((wasLoggedInWithVIN) => {
            // Adjust the URL to the new car line ID that is given by logging in via VIN
            // and trigger a new setup of the page through routing change
            if (wasLoggedInWithVIN) {
                const group: string = this.activatedRoute.snapshot.params.category;
                const subGroup: string = this.activatedRoute.snapshot.params.subcategory;
                const article: string = this.activatedRoute.snapshot.params.product;

                const completeUrl: string = `/${this.selectedCarService.carLine.urlName}/${group}/${subGroup}/${article}`;
                this.router.navigate([completeUrl], { queryParamsHandling: 'preserve' });
            } else if (this.article) {
                // Handles the case when a car was logged in via VIN and then the car is changed by the user via car chooser.
                // The previously given compatibility of the current articles is not valid any more.
                this.article.fit = undefined;
            }
        });

        // when product groups change a car was logged in
        this.groupsChangedSubscription = this._productGroupsService.groupsChangedInfo.subscribe(() => {
            this.checkIfGroupExistsForCar();
        });
    }

    /**
     * Checks whether an URL rewriting is necessary when an article ID was called that actually belongs to another category.
     * Or if a variant article was set automatically because a car selection happened for the first time.
     * Case: Deeplinking - It will reroute and correct the URL if necessary
     * @param groupPathId Array of group path ids to get the group url names
     * @param articleUrl URL of the article to go to
     */
    private checkAndUpdateUrl(groupPathId: string[], articleUrl: string) {
        // Only perform an URL rewrite if we have the expected amount of groupPathIds
        if (!groupPathId || groupPathId.length < 2 || groupPathId.length > 2) {
            return;
        }

        // Get the current categories from the url -
        // note: decoding needed because some categories have special characters like &
        // and we don´t want to trigger a setup of the page again
        const currentMainCategory: string = decodeURIComponent(this.activatedRoute.snapshot.params.category);
        const currentSubCategory: string = decodeURIComponent(this.activatedRoute.snapshot.params.subcategory);
        const currentArticleUrl: string = decodeURIComponent(this.activatedRoute.snapshot.params.product);

        // Get the categories which the article actually belongs to -
        // note: decoding not needed cause special characters are handled already from Backend API which fills the ProductGroupsMapping Map
        const neededMainCategory: string = this._groupMapperService.getGroupUrlNameFromGroupId(groupPathId[0]);
        const neededSubCategory: string = this._groupMapperService.getGroupUrlNameFromGroupId(groupPathId[1]);

        // ZKDEV-1693: Prevent "undefined" as category name in URL when using browser back button cause groups are not yet loaded async
        if (neededMainCategory === undefined || neededSubCategory === undefined) {
            return;
        }

        // Check whether we need to rewrite the URL or not
        if (currentMainCategory !== neededMainCategory || currentSubCategory !== neededSubCategory || currentArticleUrl !== articleUrl) {
            // If something is wrong with one of the categories or a variant article was automatically chosen
            // because of car selection, we need to adjust the URL accordingly
            const carUrl: string = this.selectedCarService.carLine ? this.selectedCarService.carLine.urlName : 'products';
            const url: string = `${carUrl}/${neededMainCategory}/${neededSubCategory}/${articleUrl}`;
            this.router.navigate([url], { queryParamsHandling: 'preserve' });
        }
    }

    /**
     * Handles the special deep linking case of an article that belongs to the wheel specials.
     */
    private redirectToWheelSpecial() {
        const groupType: string = this._groupMapperService.getGroupTypeById(this.article.groupIdPath[1]);
        if (groupType && groupType === 'alloy-wheels') {
            // car logged ?
            let urlToGoTo: string = this.selectedCarService.carLine ? `/${this.selectedCarService.carLine.urlName}/` : '/products/';

            // iterate through our beloved groups...
            for (const groupId of this.article.groupIdPath) {
                // ... and get the url name and attach it to our url
                const singleGroup = this._groupMapperService.getGroupUrlNameFromGroupId(groupId);
                urlToGoTo += `${singleGroup}/`;
            }

            this.router.navigate([urlToGoTo], { skipLocationChange: true, replaceUrl: false, queryParamsHandling: 'preserve' });
        }
    }

    /**
     * Handles the special complete wheel specials
     */
    private completeWheels() {
        const groupType: string = this._groupMapperService.getGroupTypeById(this.article.groupIdPath[1]);

        return groupType && groupType === 'complete-wheels';
    }

    /**
     * Checks if the current active product groups exists for the new selected car.
     * If not it shows a modal that redirects the user to the model start page.
     */
    private checkIfGroupExistsForCar(): void {
        // fix for switching between PDP´s of COL and ACC via article link on wishlist
        if (this._appService.appMode !== MODE.ACCESSORIES) {
            return;
        }

        if (this.article) {
            const currentMainGroup: ProductGroup = this._productGroupsService.groups.find(
                (x: ProductGroup) => x.groupId === this.article.groupIdPath[0]
            );
            if (!currentMainGroup) {
                this.modalService.open('category-not-available-modal');

                return;
            }

            const currentSubGroup: ProductGroup = currentMainGroup.subGroups.find(
                (x: ProductGroup) => x.groupId === this.article.groupIdPath[1]
            );
            if (!currentSubGroup) {
                this.modalService.open('category-not-available-modal');
            }
        }
    }
}
