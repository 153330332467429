// ng
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { HttpResponse } from '@angular/common/http';
// services
import { CarChooserService } from '@shared/components/car-chooser/car-chooser.service';
import { TranslationService } from '@shared/shared-services/translate/translation.service';
import { SelectedCarService } from '@shared/shared-services/selected-car/selected-car.service';
import { GroupMapperService } from '@shared/shared-services/group-mapper/group-mapper.service';
// model
import { VinLockProgressStepsModel } from '@shared/components/vin-lock/vin-lock-progress-steps.model';

@Component({
    selector: 'zk-vin-login-page',
    templateUrl: './vin-login-page.component.html',
    styleUrls: ['./vin-login-page.component.scss']
})
export class VinLoginPageComponent implements OnInit {
    constructor(
        private _router: Router,
        private _activatedRoute: ActivatedRoute,
        private _carChooserService: CarChooserService,
        private _translationService: TranslationService,
        private _selectedCarService: SelectedCarService,
        private _groupMapperService: GroupMapperService
    ) {}

    noVinProvidedOrWrong: boolean = false;
    showLoadingSpinner: boolean = false;
    progressFeedbackText: string = '';
    loadingSpinnerText: string = '';
    errorMessageKey: string = 'VINLOCK.NOCARFOUND';

    private readonly GROUP_ID_WHEELS: string = '03wheels';

    ngOnInit() {
        const params: Params = this._activatedRoute.snapshot.params;

        if (params.vin) {
            this.showLoadingSpinner = true;
            this.requestVinCheck(params.vin, params.page);
        } else {
            this.noVinProvidedOrWrong = true;
        }
    }

    private requestVinCheck(inputVin: string, redirectToPage: string) {
        const sub: Subscription = this._carChooserService.lockVehicleByVin(inputVin, true).subscribe(
            (response: HttpResponse<VinLockProgressStepsModel>) => {
                if (response.status === 202) {
                    // give feedback to the user about the current step (because this call takes some time)
                    this.progressFeedbackText = this._translationService.translate('LOADINGSPINNER.PROGRESS.' + response.body.name.toUpperCase());
                    this.loadingSpinnerText = response.body.step + '/' + response.body.totalSteps;
                    // trigger next step
                    if (sub) {
                        sub.unsubscribe();
                    }
                    this.requestVinCheck(inputVin, redirectToPage);
                } else {
                    // Finished: If it is not 202 we expect it to be 200
                    if (sub) {
                        sub.unsubscribe();
                    }
                    // save valid VIN / Licence plate and select according car
                    this._selectedCarService.VIN = inputVin;
                    this._selectedCarService.selectCarByVINResponse(response.body);
                    this.showLoadingSpinner = false;

                    this.goToPage(redirectToPage);
                }
            },
            (error) => {
                this.noVinProvidedOrWrong = true;
                this.showLoadingSpinner = false;
                console.log('Error getting VIN ' + inputVin + ':', error);
                if (error.status === 404 || error.status === 400) {
                    this.errorMessageKey = 'VINLOCK.NOCARFOUND';
                } else {
                    this.errorMessageKey = 'VINLOCK.ERROR';
                }
            },
            () => {
                if (sub) {
                    sub.unsubscribe();
                }
            }
        );
    }

    /**
     * Redirects to the Model Start Page of the logged in car or to the Wheels Special Page depending on the initial route.
     * @private
     */
    private goToPage(redirectToPage: string) {
        let url = '/' + this._selectedCarService.carLine.urlName;
        if (redirectToPage === 'wheels') {
            url += '/' + this._groupMapperService.getGroupUrlNameFromGroupId(this.GROUP_ID_WHEELS);
        }
        this._router.navigate([url], { queryParamsHandling: 'preserve' });
    }
}
