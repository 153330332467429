import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
// services
import { CurrentRouteService } from '@shared/shared-services/current-route/current-route.service';
// models
import { CarLine } from '@shared/components/car-chooser/models';

@Injectable()
export class CarChooserHelperService {
    constructor(
        private activatedRoute: ActivatedRoute,
        private currentRouteService: CurrentRouteService
    ) {}

    /**
     * Handles the case when a user changes or removes the car:
     * Stay on the same page but adjust the URL to new carLine name or "products" to request new articles.
     * @param {CarLine} activeCarLine new car on car change or null on car removal
     * @returns {string} adjusted url
     */
    updateCurrentRoute(activeCarLine: CarLine): string {
        let url = activeCarLine ?  `/${activeCarLine.urlName}` : '/products';

        const snapshotParams: any = this.activatedRoute.snapshot.params;

        if (snapshotParams.category || this.currentRouteService.categoryName) {
            const pathToAdd: string = snapshotParams.category || this.currentRouteService.categoryName;
            url += `/${pathToAdd}`;
        }

        if (snapshotParams.subcategory || this.currentRouteService.subcategoryName) {
            const pathToAdd: string = snapshotParams.subcategory || this.currentRouteService.subcategoryName;
            url += `/${pathToAdd}`;
        }

        if (snapshotParams.product || this.currentRouteService.articleName) {
            const pathToAdd: string = snapshotParams.product || this.currentRouteService.articleName;
            url += `/${pathToAdd}`;
        }

        return url;
    }
}
