// ng
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
// services
import { TranslationService } from '@shared/shared-services/translate/translation.service';

@Component({
  selector: 'zk-shop-link',
  templateUrl: './shop-link.component.html',
  styleUrls: ['./shop-link.component.scss']
})
export class ShopLinkComponent implements OnInit {
  /**
   * Informs if component renders because it has a correct target url provided by localization service.
  */
  @Output()
  isValid: EventEmitter<boolean> = new EventEmitter<boolean>();

  /**
   * Defines used local keys. Default is true.
  */
  @Input()
  isAccessories = true;

  /**
   * Component renders template only if it has a correct target url provided by localization service.
  */
  hasLink = false;

  /**
   * Link target.
  */
  url = '';

  constructor(private _translate: TranslationService) { }

  ngOnInit(): void {
      const key = 'SHOP_LINK_URL_' + (this.isAccessories ? 'ACC' : 'COLL');
      this.url = this._translate.translate(key);
      this.hasLink = this.isValidHttpUrl(this.url);
      // inform
      this.isValid.emit(this.hasLink);
  }

  private isValidHttpUrl(string) {
        let url;

        try {
            url = new URL(string);
        } catch (_) {
            return false;
        }

        return url.protocol === 'http:' || url.protocol === 'https:';
    }
}
