// ng
import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { ShoppingCartService } from '@shared/shared-services/shopping-cart/shopping-cart.service';
import { Subscription } from 'rxjs';

// Models
import { Notification, NotificationActions, NotificationIcons, NotificationTypes } from './notification.model';

// Services
import { NotificationService } from './notification.service';

@Component({
	selector: 'zk-notification',
	templateUrl: './notification.component.html',
	styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit, OnDestroy {
	notification: Notification | null;

	get notificationIcon() {
		return NotificationIcons;
	}

	private _notificationSubscription: Subscription;
	private _routerSubscription: Subscription;

	constructor(private _router: Router,
				private _notificationService: NotificationService,
				private _shoppingCartService: ShoppingCartService) {
	}

	ngOnInit(): void {
		this._notificationSubscription = this._notificationService.notificationTriggered.subscribe(
			(notification: Notification) => {
				this.notification = notification;
			}
		);

		this._router.events.subscribe((event) => {
			if (event instanceof NavigationEnd) {
				if (this.notification && this.notification.type === NotificationTypes.ERROR) this.notification = null;
			}
		});
	}

	notificationAction() {
		if (this.notification.notificationAction === NotificationActions.GOTO_CART) {
            this._router.navigate(['/wishlist']).then();
		}

		if (this.notification.notificationAction === NotificationActions.UNDO_ARTICLE_DELETE) {
			this._shoppingCartService.undoArticleRemove.next();
			this.notification.isActive = false;
		}

		window.scrollTo(0, 0);
	}

	ngOnDestroy() {
		this._notificationSubscription.unsubscribe();
		this._routerSubscription.unsubscribe();
	}
}
