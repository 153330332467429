// ng
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';

// services
import { SelectedCarService } from '@shared/shared-services/selected-car/selected-car.service';
import { ProductListService } from '../product-list/product-list.service';
import { GroupMapperService } from '@shared/shared-services/group-mapper/group-mapper.service';

// models
import { CarLine } from '@shared/components/car-chooser/models';
import { Article } from '@shared/global-models/article/article.model';

@Injectable()
export class UrlParameterService {
    private readonly PARAM_CAR_LINE_ID: string = 'line';
    private readonly PARAM_ARTICLE_ID: string = 'article';

    constructor(
        private _router: Router,
        private _activatedRoute: ActivatedRoute,
        private _selectedCarService: SelectedCarService,
        private _productListService: ProductListService,
        private _groupMapperService: GroupMapperService
    ) {}

    /**
     * Retrieves queryParam 'line' from URL (one time only) to log in according car and route to its model start page (if id is valid).
     * In all cases removes the queryParam 'line' from the URL afterwards.
     */
    checkForCarLineIdParamInURL() {
        // TODO: Refactor

        this._activatedRoute.queryParams.subscribe((params) => {
            const queryParams = params[this.PARAM_CAR_LINE_ID];
            if (queryParams == undefined) {
                return;
            }

            // react to success if car line id is valid and route to model start and remove param
            const valid: Subscription = this._selectedCarService.carLineSubscriber.subscribe((newCarLine: CarLine) => {
                valid.unsubscribe();
                this._router.navigate(['/' + newCarLine.urlName], {
                    queryParams: { line: null },
                    queryParamsHandling: 'merge'
                });
            });

            // react to fail if car line id is not valid and remove param
            const invalid: Subscription = this._selectedCarService.selectCarByIdFailed.subscribe(() => {
                invalid.unsubscribe();
                this._router.navigate(['/'], {
                    queryParams: { line: null },
                    queryParamsHandling: 'merge'
                });
            });

            // try to log in car
            const carLineId: string = Array.isArray(queryParams) ? queryParams[0] : queryParams;
            this._selectedCarService.selectCarByCarLineId(carLineId);
        });
    }

    /**
     * Retrieves queryParam 'article' from URL (one time only) to route to the PDP (if id is valid).
     * In all cases removes the queryParam 'article' from the URL afterwards.
     */
    checkForArticleIdParamInURL() {
        // TODO: Refactor

        this._activatedRoute.queryParams.subscribe((params) => {
            const queryParams = params[this.PARAM_ARTICLE_ID];
            if (queryParams == undefined) {
                return;
            }

            // request article data from API
            const articleIdParam: string = Array.isArray(queryParams) ? queryParams[0] : queryParams;
            const vehicleTypeId: string = this._selectedCarService.carLine ? this._selectedCarService.carLine.carLineId
                                                                           : this._selectedCarService.vehicleType.vehicleTypeId;

            const articleDataSub: Subscription = this._productListService.getArticleDetails(articleIdParam, vehicleTypeId).subscribe(
                (response: Article) => {
                    articleDataSub.unsubscribe();

                    // Construct route to PDP with its according categories the article belongs to and remove param
                    const carIdOrProducts: string = this._selectedCarService.carLine
                        ? this._selectedCarService.carLine.urlName
                        : 'products';
                    const groupName: string = this._groupMapperService.getGroupUrlNameFromGroupId(response.groupIdPath[0]);
                    const subGroupName: string = this._groupMapperService.getGroupUrlNameFromGroupId(response.groupIdPath[1]);

                    this._router.navigate(
                        [`/${carIdOrProducts}/${groupName}/${subGroupName}/${response.urlName}`],
                        {
                            queryParams: { article: null },
                            queryParamsHandling: 'merge'
                        }
                    );
                },
                (error) => {
                    console.log('Error retrieving article data from passed URL article id param for this market: ', error);
                    articleDataSub.unsubscribe();

                    // remove (invalid) param and stay on start page
                    this._router.navigate(['/'], {
                        queryParams: { article: null },
                        queryParamsHandling: 'merge'
                    });
                }
            );
        });
    }
}
