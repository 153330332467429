// ng
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
// services
import { SelectedCarService } from '@shared/shared-services/selected-car/selected-car.service';
import { TranslationService } from '@shared/shared-services/translate/translation.service';
// models
import { Article } from '@shared/global-models/article/article.model';
import { ArticleCategory } from '@shared/global-models/article/articleCategory.model';
import { ArticlesPaginated } from '@shared/global-models/article/articlePaginated.model';
import { CompleteWheelsCategory, WheelsCategory } from '@shared/global-models/wheelsCategory.model';
import { WheelFilterbar } from '../../../assortment/accessories/pages/sub-category-page/wheel-category/light-alloy-wheel/wheel-filter/wheel-filterbar.model';
import { WheelDetail } from '@shared/global-models/wheelDetail.model';

enum TYPE {
    GROUP = 'group',
    SUBGROUPS = 'subgroups',
    DETAILS = 'details'
}

@Injectable()
export class ProductListService {

    constructor(
        private httpClient: HttpClient,
        private _selectedCarService: SelectedCarService,
        private _translationService: TranslationService
    ) {}

    /**
     * Adds the AMG required signature if an AMG car is logged in
     * @param vehicleId
     */
    private addAmgParam(vehicleId: string): string {
        if (this._selectedCarService.vehicleType?.vehicleTypeId === 'amg' && this._selectedCarService?.modelDesign?.modelDesignId) {
            return `amg/${vehicleId}/${this._selectedCarService.modelDesign.modelDesignId}`;
        } else {
            return vehicleId;
        }
    }

    // *** ACCESSORIES MODE *** //

    /**
     * Get Subgroups with articles for accessories.
     * @param groupId id of the main category
     * @param vehicleId id of logged in car or vehicle type
     * @param amount - page size, defaults to 8 or 10
     * @param variants - also fetch variant articles data of an article
     */
    getArticlesFromMainCategory(groupId: string, vehicleId: string, amount: number, variants: boolean): Observable<ArticleCategory[]> {
        const param = this.addAmgParam(vehicleId);
        const url: string = `api/articles/${TYPE.SUBGROUPS}/${groupId}/${this._translationService.currentLang}/${param}?size=${amount}&includeVariants=${variants}`;

        return this.httpClient.get<ArticleCategory[]>(url);
    }

    /**
     * Get articles for accessories subcategory paginated. Returns list of articles and article info for pagination logic.
     * @param groupId id of the sub category
     * @param vehicleId id of logged in car or vehicle type. Model design id for AMG cars will be added automatically.
     * @param page - page number, starting with 0
     * @param amount - page size, defaults to 8 or 10
     */
    getArticlesFromSubCategory(groupId: string, vehicleId: string, page: number, amount: number): Observable<ArticlesPaginated> {
        const param = this.addAmgParam(vehicleId);
        const url: string = `api/articles/${TYPE.GROUP}/${groupId}/${this._translationService.currentLang}/${param}?page=${page}&size=${amount}`;

        return this.httpClient.get<ArticlesPaginated>(url);
    }

    /**
     * Get accessories article details.
     * @param articleId id of the article
     * @param vehicleId id of logged in car or vehicle type
     * @returns {Observable<Article>} single article with all details
     */
    getArticleDetails(articleId: string, vehicleId: string): Observable<Article> {
        const param = this.addAmgParam(vehicleId);
        const url: string = `api/articles/${TYPE.DETAILS}/${articleId}/${this._translationService.currentLang}/${param}`;

        return this.httpClient.get<Article>(url);
    }


    // *** ACCESSORIES MODE - COMPLETE WHEELS CATEGORY *** //

    /**
     * Get complete wheel articles with filter option vehicle type or car line specific.
     * @param groupId - the id of the sub category
     * @param page - page number, starting with 0
     * @param amount - page size, defaults to 8 or 10
     * @param filters - fetch complete wheel articles by this filters
     */
    getCompleteWheelsWithFilter(groupId: string, page: number, amount: number, filters: WheelFilterbar): Observable<CompleteWheelsCategory> {
        // default params
        let carParam = `?includeCwFilter=true&vehicleType=${this._selectedCarService.vehicleType.vehicleTypeId}`;

        // logged in car param
        if (this._selectedCarService?.carLine?.carLineId) {
            carParam += `&carLineId=${this._selectedCarService.carLine.carLineId}`;
        }

        // logged in amg car param
        if (this._selectedCarService?.modelDesign?.modelDesignId) {
            carParam += `&modelDesignId=${this._selectedCarService.modelDesign.modelDesignId}`;
        }

        // optional filter params
        let filterParam = '';
        if (filters?.diameters?.length > 0) {
            filterParam = '&diameterIds=';
            const diameters: string[] = filters.diameters.map(x => x.diameterId);
            filterParam += diameters.toString();
        }
        if (filters?.tyreTypes?.length > 0) {
            filterParam += '&tyreTypes=';
            const tyreTypes: string[] = filters.tyreTypes.map(x => x.tyreType);
            filterParam += tyreTypes.toString();
        }
        if (filters?.tyrePressureControls?.length > 0) {
            // array can only have one entry
            filterParam += '&hasPressure=' + filters.tyrePressureControls[0].hasPressure.toString();
        }

        const url: string = `api/v2/articles/${TYPE.GROUP}/${groupId}/${this._translationService.currentLang}${carParam}&page=${page}&size=${amount}${filterParam}`;

        return this.httpClient.get<CompleteWheelsCategory>(url);
    }

    // *** ACCESSORIES MODE - LIGHT ALLOY WHEELS CATEGORY *** //

    /**
     * Get light alloy wheels for accessories paginated. Returns list of articles and article info for pagination logic.
     * @param groupId id of the sub category
     * @param vehicleId id of logged in car or vehicle type
     * @param page - page number, starting with 0
     * @param amount - page size, defaults to 8 or 10
     * @param diameterIds - optional. Filter wheels by diameter ids (comma separated)
     */
    getLightAlloyWheelsWithFilter(groupId: string, vehicleId: string, page: number, amount: number, diameterIds: string = null): Observable<WheelsCategory> {
        const diameterFilter = diameterIds ? '&diameterIds=' + diameterIds : '';
        const param = this.addAmgParam(vehicleId);

        return this.httpClient.get<WheelsCategory>(`api/articles/wheelgroup/${groupId}/${this._translationService.currentLang}/${param}?page=${page}&size=${amount}${diameterFilter}`);
    }

    /**
     * Get the detailed information for the given light alloy wheel
     * @param articleId Id of the wheel to get the information for
     * @param vehicleId id of logged in car or vehicle type.
     */
    getLightAlloyWheelDetails(articleId: string, vehicleId: string) {
        const param = this.addAmgParam(vehicleId);

        return this.httpClient.get<WheelDetail>(`api/articles/${TYPE.DETAILS}/${articleId}/${this._translationService.currentLang}/${param}`);
    }



    // *** COLLECTION MODE *** //

    /**
     * Get Subgroups with articles for collection.
     * @param groupId - unique group oid of a collection category
     * @param amount - page size, defaults to 8 or 10
     */
    getCollectionMainCategoryArticles(groupId: string, amount: number): Observable<ArticleCategory[]> {
        const url: string = `api/articles/${TYPE.SUBGROUPS}/${groupId}/${this._translationService.currentLang}/collection?size=${amount}`;

        return this.httpClient.get<ArticleCategory[]>(url);
    }

    /**
     * Get articles for collection subcategory paginated. Returns list of articles and article info for pagination logic.
     * @param groupId - unique group oid of a collection sub category
     * @param page - page number, starting with 0
     * @param amount - page size, defaults to 8 or 10
     */
    getCollectionSubcategoryArticles(groupId: string, page: number, amount: number): Observable<ArticlesPaginated> {
        const url: string = `api/articles/${TYPE.GROUP}/${groupId}/${this._translationService.currentLang}/collection?page=${page}&size=${amount}`;

        return this.httpClient.get<ArticlesPaginated>(url);
    }

    /**
     * Get collection article details. Returns single article with all details.
     * @param {string} articleId - the id of the article
     * @returns {Observable<Article>}
     */
    getCollectionArticleDetails(articleId: string): Observable<Article> {
        const url: string = `api/articles/${TYPE.DETAILS}/${articleId}/${this._translationService.currentLang}/collection`;

        return this.httpClient.get<Article>(url);
    }
}
