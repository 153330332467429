// ng
import { AfterContentChecked, Component, ElementRef, OnDestroy, Renderer2, ViewChild } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { environment } from '../environments/environment';

// Models
import {
    Notification,
    NotificationActions,
    NotificationIcons,
    NotificationTypes
} from '@shared/components/notification/notification.model';
import { ShoppingCartItemAction } from '@shared/shared-services/shopping-cart/model/shopping-cart-item-action.model';
import { ShoppingCartItemModalInfoModel } from '@shared/shared-services/shopping-cart/model/shopping-cart-item-modal-info.model';
import { MODE, SUBMODE } from '@shared/global-models/mode.enum';
import { Article } from "@shared/global-models/article/article.model";

// Services
import { SelectedCarService } from '@shared/shared-services/selected-car/selected-car.service';
import { TranslationService } from '@shared/shared-services/translate/translation.service';
import { ViewportService } from '@mbcs/mbcs-lib';
import { PopupModalService } from "@shared/modals/popup-modal/popup-modal.service";
import { AppService } from './app.service';
import { GoogleAnalyticsService } from '@shared/shared-services/google-analytics/google-analytics.service';
import { ShoppingCartService } from '@shared/shared-services/shopping-cart/shopping-cart.service';
import { NotificationService } from '@shared/components/notification/notification.service';
import { LocalStorageService } from "@shared/shared-services/storage/local-storage.service";
import { WheelSpecialService } from "./assortment/accessories/pages/sub-category-page/wheel-category/light-alloy-wheel/wheel-special.service";

@Component({
    selector: 'zk-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnDestroy, AfterContentChecked {
    @ViewChild('appWrapper', { static: false })
    appWrapper: ElementRef;

    loaded = false;
    isCampaignPage = false;
    isLegalPage = false;
    isSplashPage = false;
    showPriceNotification = false;

    // rim set preview modal data
    rimSetPreview: Article[] = [];
    previewAsCompleteSet = false;
    // shopping cart info modal
    articleInfo: ShoppingCartItemModalInfoModel;

    private _deepLinkSubscription: Subscription;
    private _selectingCarFailedSubscription: Subscription;
    private _routerSubscription: Subscription;
	private _shoppingCartSubscription: Subscription;
	private _undoArticleRemoveSubscription: Subscription;
    // modal
    private _showQuantityPreviewLayerSubscription: Subscription;


    constructor(
        private router: Router,
        private translationService: TranslationService,
        private selectedCarService: SelectedCarService,
        private viewportService: ViewportService,
        private _appService: AppService,
        private _gaService: GoogleAnalyticsService,
		private _shoppingCartService: ShoppingCartService,
		private _notificationService: NotificationService,
        private _localStorageService: LocalStorageService,
		private _renderer: Renderer2,
        private _wheelSpecialService: WheelSpecialService,
        private _modalService: PopupModalService
    ) {
        const sub: Subscription = this._appService.allDataLoadedInfo.subscribe(() => {
            sub.unsubscribe();
            this.loaded = true;
            this.initApp();
        });

        this._appService.fetchEnabledMarkets();

        // Always update App mode and Sub mode based on URL entry and during runtime
        this._routerSubscription = this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                let entryURL = event.url;
                // clean URL from possible query params
                if (entryURL.indexOf('?') > -1) {
                    const currentQueryIndex: number = entryURL.indexOf('?');
                    entryURL = entryURL.slice(0, currentQueryIndex);
                }
                // and get its parts to set app mode
                const url = entryURL.split('/');
                const mode = url[2];

                switch(mode) {
                    case 'accessories': this._appService.appMode = MODE.ACCESSORIES; break;
                    case 'lifestyle': this._appService.appMode = MODE.LIFESTYLE; break;
                    default: this._appService.appMode = MODE.NONE;
				}

                const subMode = url[3];
                switch (subMode) {
                    case 'passengercars': this._appService.appSubMode = SUBMODE.PASSENGERCARS; break;
                    case 'vans': this._appService.appSubMode = SUBMODE.VANS; break;
                    case 'amg': this._appService.appSubMode = SUBMODE.AMG; break;
                    case 'collection': this._appService.appSubMode = SUBMODE.COLLECTION; break;
                    default: this._appService.appSubMode = SUBMODE.NONE;
				}

                this.isSplashPage = this._appService.appMode === MODE.NONE;

                if (this.isSplashPage && !!this.selectedCarService.vehicleType) {
                    this.selectedCarService.removeVehicleType();
                }

                // assign or remove AMG theme
				this.appTheming();
            }
        });
    }

	ngOnInit(): void {
	}

    private initApp(): void {
        this.setupSubscriptions();
        this.checkTrackingProhibited();
        this.assignDoctypeLanguage();

        // force check for empty route without lang-market prefix or invalid routes is needed
        this.router.onSameUrlNavigation = 'reload';
        this.router.navigateByUrl(this.router.url);
    }

    ngOnDestroy(): void {
        this._routerSubscription.unsubscribe();

        if (this._deepLinkSubscription) {
            this._deepLinkSubscription.unsubscribe();
        }

        if (this._selectingCarFailedSubscription) {
            this._selectingCarFailedSubscription.unsubscribe();
        }

		if (this._shoppingCartSubscription) {
			this._shoppingCartSubscription.unsubscribe();
		}

		if (this._undoArticleRemoveSubscription) {
			this._undoArticleRemoveSubscription.unsubscribe();
		}

        if (this._showQuantityPreviewLayerSubscription) {
            this._showQuantityPreviewLayerSubscription.unsubscribe();
        }
    }

    // Check is needed, for re-positioning popup modals correctly on the page when the view content
    // changes dynamically while a popup is still shown => See ZKDEV-1033
    ngAfterContentChecked(): void {
        if (this.appWrapper) {
            this.viewportService.setViewportWidth(this.appWrapper.nativeElement.getBoundingClientRect().width);
            this.viewportService.setAppContentHeight(this.appWrapper.nativeElement.getBoundingClientRect().height);
        }
    }

    /**
     * Callback for modal. Redirects to the model start page or start page.
     * Used if category does not exist for the new logged in car.
     */
    routeToStartOrModelPage() {
        const url = this.selectedCarService.carLine ? this.selectedCarService.carLine.urlName : '/';
        this.router.navigate([url]).then();
    }

    /**
     * Disables Google Analytics Tracking for certain markets or sets initial values.
     */
    private checkTrackingProhibited(): void {
        const market = this._appService.currentMarket.marketCode;
        // ZKDEV-1715
        if (market === 'RU' || market === 'CN') {
            console.log('Google Analytics is forbidden for this market. Tracking is disabled.');
            window['ga-disable-G-JSFVBVBS6X'] = true;
        } else {
            this._gaService.setUserMeta();
        }
    }

    /**
     * Subscribe and react to changes
     */
    private setupSubscriptions() {
        this._deepLinkSubscription = this.router.events.subscribe((event) => {
            if (event instanceof NavigationStart) {
                // console.log('   Routing starts: ', event.url);
                this.prefixUrl(event.url);
            }

            if (event instanceof NavigationEnd) {
                // console.log('   Routing ended: ', event.url);
                this.handleRouteDeeplink(event.url);
            }
        });

        // Add / Remove article from wishlist Notification
        this._shoppingCartSubscription = this._shoppingCartService.shoppingCartItemAddedOrRemovedInfo.subscribe((item: ShoppingCartItemModalInfoModel) => {
                if (item?.items.length > 0) {
                    if (item.action === ShoppingCartItemAction.ITEM_ADD) {
                        this._notificationService.triggerNotification(new Notification(
                            NotificationTypes.NONE,
                            NotificationIcons.ADD_TO_CART,
                            item.items[0].headline + ' ' + this.translationService.translate('LIFESTYLE.NOTIFICATION.ARTICLEADDED'),
                            this.translationService.translate('LIFESTYLE.NOTIFICATION.OPENWISHLIST'),
                            NotificationActions.GOTO_CART));
                    }

                    if (item.action === ShoppingCartItemAction.ITEM_REMOVE) {
                        this._shoppingCartService.deletedArticle = item.items[0];

                        this._notificationService.triggerNotification(new Notification(
                            NotificationTypes.NONE,
                            NotificationIcons.REMOVE_FROM_CART,
                            item.items[0].headline + ' ' + this.translationService.translate('LIFESTYLE.NOTIFICATION.ARTICLEREMOVED'),
                            this.translationService.translate('LIFESTYLE.NOTIFICATION.UNDOARTICLEDELETE'),
                            NotificationActions.UNDO_ARTICLE_DELETE));
                    }

                    // For ACC only - show additional a feedback modal when an article is added to the cart or quantity was raised
                    if (this._appService.appMode === MODE.ACCESSORIES) {
                        if (item.action === ShoppingCartItemAction.ITEM_ADD || item.action === ShoppingCartItemAction.ITEM_QUANTITY_RAISED) {
                            this.articleInfo = item;
                            this.articleInfo.items.forEach((x: Article) => (x.notRemovable = true));
                            this._modalService.open('shopping-cart-info-modal');
                        }
                    }
                }
            },
            (error) => {
                console.log('Error subscribing to shoppingCartItemAddedOrRemovedInfo: ', error);
            }
        );

        this._undoArticleRemoveSubscription = this._shoppingCartService.undoArticleRemove.subscribe(() => {
            this._shoppingCartService.addItemsToShoppingCart([this._shoppingCartService.deletedArticle], this._shoppingCartService.deletedArticle.quantity, false);
        });

        // ** Modals **

        // Handles that the rim set quantity change preview modal should be shown
        this._showQuantityPreviewLayerSubscription = this._wheelSpecialService.showQuantityLayerInfo.subscribe(
            (previewArticles: Article[]) => {
                this.rimSetPreview = previewArticles;
                this.previewAsCompleteSet = this._wheelSpecialService.wheelPreviewAsSet;
                this._modalService.open('rim-set-choose-quantity-modal');
            }
        );
    }

    private prefixUrl(url: string): void {
        const split = window.location.pathname.split('/');
		const isSplashPage = split.length === 2;

        let prefix = '';

        // always add lang-market
        if (url.indexOf(this.translationService.currentLang) === -1) {
            prefix += this.translationService.currentLang;
        }

        // add App mode if not 404 page, splash page or a base route from AppRoutingModule
        if (
            url.indexOf('/404') === -1 &&
            url.indexOf('/legal/') === -1 &&
            url.indexOf('/vin/') === -1 &&
            url.indexOf('/campaign/') === -1 &&
			!isSplashPage
        ) {
            if (this._appService.appMode && url.indexOf(this._appService.appMode) === -1) {
                prefix += this._appService.currentAppMode;
            }
        }

        // add original url destination if not only /
        if (url.length > 1) {
            prefix += url;
        }

        // trigger reroute once
        if (prefix !== url) {
            this.router.navigateByUrl(prefix).then();
        }
    }

    /**
     * Function is called for every routing and ensures that the url is valid.
     * For ACC mode: Set´s the carLineId as active if the url contains it or redirects to the 404 page.
     * @param {string} routeToCheck - Current active route
     */
    private handleRouteDeeplink(routeToCheck: string) {
        // shared legal pages check
        this.isLegalPage = routeToCheck.indexOf('/legal/') > -1;

        // clean URL from query params and get its parts
        if (routeToCheck.indexOf('?') > -1) {
            const currentQueryIndex: number = routeToCheck.indexOf('?');
            routeToCheck = routeToCheck.slice(0, currentQueryIndex);
        }

        this.isCampaignPage = routeToCheck.includes('/campaign/');

        const splitUrl: string[] = routeToCheck.split('/');
        // sticky footer is a market specific configuration
        // and is only allowed to be shown on pages where articles with prices are displayed (so never on start or 404 page for instance)
        this.showPriceNotification = this._appService.currentMarket.showPriceNotification && splitUrl.length > 4 && splitUrl[2] !== '404';

        // ACC mode only related deeplink logic
        if (this._appService.appMode === MODE.ACCESSORIES) {
            // case: if only 'products' is provided and nothing more we redirect to the start page because a model page requires a car
            if (splitUrl.length === 5 && splitUrl[4] === 'products') {
                this.router.navigate(['/'], { queryParamsHandling: 'preserve' });

                return;
            }

            // handle possible vin deeplink routes
            if (splitUrl.length > 2 && splitUrl[2] === 'vin') {
                // allow only routes "/vin/home|wheels" and "/vin/home|wheels/:vin". Otherwise => 404 Page
                if (splitUrl.length === 3 || (splitUrl[3] !== 'home' && splitUrl[3] !== 'wheels') || splitUrl.length > 5) {
                    this.router.navigate(['/404'], { queryParamsHandling: 'preserve' });
                }

                return;
            }

            // check if car line ID is present in URL (deeplink case - login car)
            const carPathSegment: string[] = splitUrl && splitUrl.length > 4 ? splitUrl[4].split('-') : null;
            if (carPathSegment && carPathSegment.length > 1) {
                this._selectingCarFailedSubscription = this.selectedCarService.selectCarByIdFailed.subscribe(
                    (noValidCar) => {
                        // clean up
                        this._selectingCarFailedSubscription.unsubscribe();
                        // case: car line ID from URL does not exist => 404 Page
                        if (noValidCar) {
                            this.router.navigate(['/404'], { queryParamsHandling: 'preserve' });

                            return;
                        }
                    },
                    (error) => {
                        this._selectingCarFailedSubscription.unsubscribe();
                        console.log('Error@ subscribing to selectCarByIdFailed:', error);
                    }
                );

                // sets the car parsed from URL (cars/vans are always identified by their unique SAP value which is the last part)
                let carLineId;
                let modelDesignId;

                if (this._appService.appSubMode === SUBMODE.AMG) {
                    modelDesignId = carPathSegment.pop();
                    carLineId = carPathSegment.pop();
                } else {
                    carLineId = carPathSegment.pop();
                }
                this.selectedCarService.selectCarByCarLineId(carLineId, modelDesignId);
            } // else {
                // case: clean up storage about car data => not wanted to achieve multi tabs same session
                // this.selectedCarService.resetLoggedCarData();
            // }
        }
    }

	private appTheming(): void {
		const isAmg: boolean = this._appService.appSubMode === SUBMODE.AMG;

		isAmg ? this._renderer.setAttribute(document.body,'id','theme-amg')
			: this._renderer.removeAttribute(document.body,'id');
	}

    private assignDoctypeLanguage(): void {
        const lang = this.translationService.currentLang.split('-')[0];
        this._renderer.setAttribute(document.documentElement, 'lang', lang);
    }
}
