// Ng
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

// model
import { ProductGroup } from '@mbcs/mbcs-lib';
import { ResponsiveImage } from '@shared/global-models/responsiveImages.model';

// Services
import { ProductGroupsService } from '@shared/shared-services/product-groups/product-groups.service';
import { SeoService } from '@shared/shared-services/seo/seo.service';
import { TeaserService } from '@shared/shared-services/teaser/teaser.service';

@Component({
    selector: 'zk-collection-product-list-page',
    templateUrl: './collection-product-list-page.component.html',
    styleUrls: ['./collection-product-list-page.component.scss']
})
export class CollectionProductListPageComponent implements OnInit {
    image: ResponsiveImage;
    activeProductGroup: ProductGroup;

    constructor(
        private _teaserService: TeaserService,
        private _productGroupsService: ProductGroupsService,
        private _activatedRoute: ActivatedRoute,
        private _router: Router,
		private _seoService: SeoService
    ) {}

    ngOnInit(): void {
        this._activatedRoute.params.subscribe((params) => {
            this.activeProductGroup = this._productGroupsService.groups.find((x: ProductGroup) => x.urlName === params['category']);
            this.activeProductGroup ? this.initStageTeaser() : this._router.navigate(['lifestyle/404']);

			this.setSeoInfo();
        });
    }

    /**
     * Request category images for current active category route
     * @private
     */
    private initStageTeaser(): void {
        const sub = this._teaserService.getCmsDataForCategoryStageTeaser(this.activeProductGroup.groupId).subscribe(
            (response: ResponsiveImage) => {
                sub.unsubscribe();
                this.image = response;
            },
            (error) => {
                sub.unsubscribe();
                console.log('Error:', error);
            }
        );
    }

	private setSeoInfo() {
		const title: string = this._seoService.getTranslation('ACCESSORIES.FLYOUT.TEASER.TITEL');
		let activeProductGroup = this.activeProductGroup.name;
		let activeProductSubGroups = this.activeProductGroup.subGroups.map((subGroup: ProductGroup) => {
			return subGroup.name
		});

		this._seoService.updatePageTitle(`${activeProductGroup} (${activeProductSubGroups.join(', ')}) | ${title}`);
		this._seoService.updateMetaDescription(this._seoService.getTranslation('LIFESTYLE.SEO.METADESCRIPTION.PRODUCTLIST') + `: ${activeProductGroup} (${activeProductSubGroups.join(', ')})`);
	}
}
