<div *ngIf="activeProductGroup"
     class="wrapper"
     [ngClass]="{'is-sticky' : isSticky}" #stickyEl>

    <wb-horizontal-scroll class="wb-grid-container">
        <wb-subnavigation>
            <wb-subnavigation-item *ngIf="showBackButton" routerLink="../" style="position: relative">
                <wb-icon name="bds/chevron-left/16" class="back-btn-icon"></wb-icon>
                <span zkTranslate="GENERAL.BACK" class="back-btn-text"></span>
            </wb-subnavigation-item>

            <wb-subnavigation-item *ngFor="let group of activeProductGroup.subGroups"
                                   (click)="navItemClicked(group.urlName)">{{group.name}}
            </wb-subnavigation-item>
        </wb-subnavigation>
    </wb-horizontal-scroll>
</div>
